import React, {useContext} from "react";
import { Swal, useHistory, TasasContext } from "../shared/";
import { BotonEliminar, BotonEditar } from "../shared/FormsElements";

const AccionesListarTasas = ({ item }) => {
  const history = useHistory();

  const tasasContext = useContext(TasasContext);
  const { eliminarUsuarioII } = tasasContext;

  const editar = (usuario) => {
    history.push("/tasas/editar/" + usuario.id);
  };

  const eliminar = (item) => {
    Swal.fire({
      title: "Esta Seguro que desea Eliminar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        eliminarUsuarioII(item.id);
      }
    });
  };


  return (
    <div className="text-center">
      <BotonEditar editar={editar} item={item} />{" "}
      <BotonEliminar eliminar={eliminar} item={item} />{" "}
{/*       <BotonVer mostrar={mostrar} item={item} />
 */}    </div>
  );
};

export default AccionesListarTasas;
