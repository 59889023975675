import React from "react";
import { BotonEditar, BotonEliminar } from "../../shared/FormsElements";
import { format, add, sub } from "date-fns";

const Laboral = ({ item, editar, eliminar, soloLectura }) => {
  console.log(item)

  return (
    <tr>
      <td className="text-center">{item.relacion}</td>
      <td className="text-center">{item.inscripcion}</td>
      <td className="text-center">{item.empresa}</td>
      <td className="text-center">{item.actividad}</td>
      <td className="text-center">{item.fecha_inicio !== ""? format(new Date(item.fecha_inicio), "dd/MM/yyyy").toString(): ""}</td>
      <td className="text-center">{item.descripcion}</td>
      <td className="text-center">{"$ "+new Intl.NumberFormat('de-DE').format(item.ingresos)}</td>
      <td className="text-center">{item.cuit}</td>
      <td className="text-center">
        {!soloLectura ? (
          <>
            <BotonEditar editar={editar} item={item} />
            <BotonEliminar eliminar={eliminar} item={item} />
          </>
        ) : null}
      </td>
    </tr>
  );
};

export default Laboral;
