import React from 'react';

const BuscarPersona = ({item, editar}) => {
    return (
        <tr>
        <td className="text-center">{item.nombre} {item.apellido} - {item.nro_documento}</td>

        <td className="text-center">
          <button
            type="button"
            className="btn btn-info btn-sm"
            data-bs-dismiss="modal"
            aria-label="Close"
            style={{color: "white", borderRadius:"15px"}}
            onClick={() => {
              editar(item);
            }}
          >
            Seleccionar
          </button>
        </td>
      </tr>
    );
};

export default BuscarPersona;