import React from "react";
import Emails from "./email/Emails";
import Telefonos from "./telefono/Telefonos";

const FormDatosContacto = ({id,soloLectura}) => {
  return (
    <>
      <Emails id={id} soloLectura={soloLectura} />
      <hr></hr>
      <Telefonos id={id} soloLectura={soloLectura}/>
    </>
  );
};

export default FormDatosContacto;
