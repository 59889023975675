import React, { useReducer } from "react";
import tasasReducer from "./tasasReducer";
import tasasContext from "./tasasContext";
import clienteAxios from "../../config/axios";

import {
  TREGISTRO_ERROR,
  TREGISTRO_EXITO,
  TOBTENER_USUARIOS,
  TACTUALIZAR_USUARIO,
  TSELECCIONAR_USUARIO,
  TELIMINAR_USUARIO,
  TCARGANDO,
  TORDENAR_FILTRAR_USUARIOS,
  TDESELECCIONAR_USUARIO,
} from "../../types";

const TasasState = (props) => {
  const initialState = {
    usuario: null,
    concesionSeleccionada: null,
    usuarios: [],
    resultado: [],
    cargando: false,
    mensaje: { msg: null, class: null },
  };

  const [state, dispatch] = useReducer(tasasReducer, initialState);

  const SiNo10 = (valor) => {
    //console.log("valor",valor);
    if (valor.startsWith("s")) {
      return "1";
    } 
    if (valor.startsWith("n")) {
      return "0";
    }else{
      return valor;
    }
  };

  const ordenarFiltrar = (filtros, campoOrden, tipoOrden) => {
    let elementos = state.usuarios;
    //console.log(elementos);
    if (filtros !== null) {
      elementos = elementos
        .filter((item) =>
          String(item.descripcion)
            .toLowerCase()
            .startsWith(
              `${filtros.descripcion !== undefined ? filtros.descripcion : ""}`
            )
        )
        .filter((item) =>
          String(item.cuotas)
            .toLowerCase()
            .startsWith(`${filtros.cuotas !== undefined ? filtros.cuotas : ""}`)
        )
        .filter((item) =>
          String(item.tna)
            .toLowerCase()
            .startsWith(`${filtros.tna !== undefined ? filtros.tna : ""}`)
        )
        .filter((item) =>
          String(item.tea)
            .toLowerCase()
            .startsWith(`${filtros.tea !== undefined ? filtros.tea : ""}`)
        )
        .filter((item) =>
          String(item.cft)
            .toLowerCase()
            .startsWith(`${filtros.cft !== undefined ? filtros.cft : ""}`)
        )
        .filter((item) =>
          String(item.premium)
            .toLowerCase()
            .startsWith(
              `${filtros.premium !== undefined ? SiNo10(filtros.premium) : ""}`
            )
        );
    } else {
      elementos = state.concesiones;
    }

    //console.log("personas", state.personas);

    // ordenar asc y desc
    if (campoOrden !== null && tipoOrden !== null) {
      if (tipoOrden !== null) {
        if (tipoOrden === "asc") {
          elementos = elementos.sort(function (a, b) {
            return String(a[campoOrden])
              .toLowerCase()
              .localeCompare(String(b[campoOrden]).toLowerCase());
          });
        } else {
          elementos = elementos.sort(function (a, b) {
            return String(b[campoOrden])
              .toLowerCase()
              .localeCompare(String(a[campoOrden]).toLowerCase());
          });
        }
      }
    } else {
      // elementos = state.personas;
    }

    dispatch({
      type: TORDENAR_FILTRAR_USUARIOS,
      payload: elementos,
    });
  };

  const deseleccionarUsuario = () => {
    dispatch({
      type: TDESELECCIONAR_USUARIO,
    });
  };

  const ocultarMensaje = () => {
    dispatch({
      type: TREGISTRO_EXITO,
    });
  };

  const actualizarUsuario = (usuario) => {
    //console.log(usuario);
    dispatch({
      type: TACTUALIZAR_USUARIO,
      payload: usuario,
    });
  };

  const eliminarUsuarioII = async (id) => {
    try {
      await clienteAxios.delete("/Tasa/remove", {
        params: { id },
      });
      dispatch({
        type: TELIMINAR_USUARIO,
        payload: id,
      });
    } catch (e) {
      dispatch({
        type: TREGISTRO_ERROR,
        payload: "Hubo un error",
      });
    }
  };

  const seleccionarUsuario = (esError, respuesta) => {
    //console.log(respuesta.data);
    if (esError) {
    } else {
      dispatch({
        type: TSELECCIONAR_USUARIO,
        payload: respuesta.data.tasa,
      });
    }
  };

  const obtenerUsuariosII = async (texto) => {
    try {
      dispatch({
        type: TCARGANDO,
        payload: true,
      });
      const resultado = await clienteAxios.get("/Tasa/getAll", {
        params: { texto },
      });
      //console.log(resultado);
      dispatch({
        type: TOBTENER_USUARIOS,
        payload: resultado.data.tasas,
      });
    } catch (error) {}
  }; 

  const registrarUsuario = (esError, respuesta) => {
    const mensaje = {
      msg: esError ? respuesta : "Se Registro el Usuario con Exito. ",
      class: esError ? "danger" : "success",
    };

    dispatch({
      type: TREGISTRO_ERROR,
      payload: mensaje, //respuesta.data.msg
    });
  };

  return (
    <tasasContext.Provider
      value={{
        usuario: state.usuario,
        mensaje: state.mensaje,
        usuarios: state.usuarios,
        usuarioSeleccionado: state.usuarioSeleccionado,
        cargando: state.cargando,
        resultado: state.resultado,
        seleccionarUsuario,
        obtenerUsuariosII,
        ocultarMensaje,
        registrarUsuario,
        actualizarUsuario,
        eliminarUsuarioII,
        ordenarFiltrar,
        deseleccionarUsuario,
      }}
    >
      {props.children}
    </tasasContext.Provider>
  );
};

export default TasasState;
