import PDFcomprobante from "../Archivos/PDFcomprobante"

const Comprobante = ({
  data,
  persona,
  onClick
}) => {

  return (
    <div
      class=""

    >
      {
        <PDFcomprobante data={data} persona={persona} />
      }
    </div>
  )
}

export default Comprobante