export const Totals = (pagos) => {
  let subTotal = 0;
  let interes_punitorio_total = 0;
  let interes_total = 0;
  let capital_total = 0;

  pagos.map((pago) => {

    let interes = parseFloat(pago.interes);
    let capital = parseFloat(pago.capital);
    let interes_punitorio =
      parseFloat(pago.interes_punitorios) - parseFloat(pago.importe);

    if (interes_punitorio < 0) {
      interes = interes + interes_punitorio;
    }

    if (interes < 0) {
      capital = capital + interes;
    }
    
    subTotal = parseFloat(pago.importe) + subTotal;

    interes_punitorio_total = interes_punitorio < 0 ? parseFloat(pago.interes_punitorios)  + interes_punitorio_total :  (pago.interes_punitorios - interes_punitorio) + interes_punitorio_total;

    interes_total = interes < 0 ? parseFloat(pago.interes)  + interes_total :  (pago.interes - interes) + interes_total;

    capital_total = parseFloat(capital.toFixed()) <= 0 ? parseFloat(pago.capital)  + capital_total :  (pago.capital - capital) + capital_total;

    /*
    interes_punitorio_total =
      parseFloat(pago.interes_punitorios) + interes_punitorio_total;
    interes_total = parseFloat(pago.interes) + interes_total;
    capital_total = parseFloat(pago.capital) + capital_total;
    */
  });

  return {
    subTotal: subTotal,
    interes_punitorio: interes_punitorio_total,
    interes: interes_total,
    capital: capital_total,
  };
};

export const CalcularResto = (pago) => {
  //console.log(pago);

  let interes = parseFloat(pago.interes);
  let capital = parseFloat(pago.capital);
  let interes_punitorio =
    parseFloat(pago.interes_punitorios) - parseFloat(pago.importe);

  if (interes_punitorio < 0) {
    interes = interes + interes_punitorio;
  }

  if (interes < 0) {
    capital = capital + interes;
  }

  return {
    interes_punitorio: interes_punitorio,
    interes: interes,
    capital: capital < 0 ? 0 : capital,
  };
};
