import React, { useContext, useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import styled from "@emotion/styled";
import { AuthContext } from "../shared/";
import Sidebar from "./Sidebar/Sidebar";
import HeaderController from "./Header/Header";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

import "./SidebarStyle.css?v=0.0.4";

const ContenedorForm = styled.div`
  @media (min-width: 800px) {
    width: 100%;
    margin: 0;
    min-height: 600px;
  }
`;

const Layout = ({ children }) => {
  const { pathname } = useLocation();
  const authContext = useContext(AuthContext);
  const { usuario, cerrarSesion } = authContext;
  const [sidebar, setSidebar] = useState({
    header: {
      marginLeft: "50px",
    },
    toolbar: {
      left: "50px",
    },
    dashboard: {
      display: "block",
      width: "50px",
    },
    state: false,
    automatic: true,
  });

  if (window.location.pathname.startsWith("/creditos/pagare/")) {
    return <>{children}</>;
  }

  const toggle = (e) => {
    let state = sidebar.state;
    if (state) {
      setSidebar({
        ...sidebar,
        dashboard: {
          display: "block",
          width: "238px",
        },
        header: {
          marginLeft: "238px",
        },
        toolbar: {
          left: "238px",
        },
        state: false,
        automatic: false,
      });
    } else {
      setSidebar({
        ...sidebar,
        dashboard: {
          display: "block",
          width: "50px",
        },
        header: {
          marginLeft: "50px",
        },
        toolbar: {
          left: "50px",
        },
        state: true,
        automatic: true,
      });
    }
  };

  const onMouseOver = (e, value) => {
    if (sidebar.automatic) {
      if (value) {
        setSidebar({
          ...sidebar,
          dashboard: {
            display: "block",
            width: "238px",
          },
          header: {
            marginLeft: "238px",
          },
          toolbar: {
            left: "238px",
          },
          state: false,
        });
      } else {
        setSidebar({
          ...sidebar,
          dashboard: {
            display: "block",
            width: "50px",
          },
          header: {
            marginLeft: "50px",
          },
          toolbar: {
            left: "50px",
          },
          state: true,
        });
      }
    }
  };
  
  if(pathname.split('/')[2] == "ver"){
      return <ContenedorForm>{children}</ContenedorForm>
  }

  if (!usuario) {
    return (
      <div
        className="container-fluid"
        style={{
          backgroundColor:
            window.location.pathname === "/" ? "white" : "#ecf0f5",
          paddingBottom: "10px",
        }}
      >
        <ContenedorForm>{children}</ContenedorForm>
      </div>
    );
  }

  const colorRol = () => {
    let color;

    switch (usuario.rol) {
      case "Vendedor":
        color = "f68e1e";
        break;
      case "Admin":
        color = "414141";
        break;
      case "Cajero":
        color = "ac49b5";
        break;
      case "Analista":
        color = "6361bc";
        break;
      case "Precalificador":
        color = "6361bc";
        break;
      default:
        break;
    }

    return "#" + color;
  };

  return (
    <div class="dashboard">
      <div
        class="dashboard-nav"
        style={{ backgroundColor: colorRol(), ...sidebar.dashboard }}
        onMouseOver={(event) => onMouseOver(event, true)}
        onMouseOut={(event) => onMouseOver(event, false)}
      >
        <header>
          <button
            href="#!"
            class="menu-toggle d-none"
            onClic
            k={(event) => toggle(event)}
          >
            <i class="fas fa-bars"></i>
          </button>

          <a href="#" class="brand-logo">
            <span></span>
          </a>
        </header>

        {usuario ? (
          <Sidebar
            usuario={usuario}
            pathname={pathname}
            hidden={sidebar.header.marginLeft == "50px" ? true : false}
          />
        ) : (
          ""
        )}
      </div>

      <div class="dashboard-app w-100" style={sidebar.header}>
        <header class="dashboard-toolbar" style={sidebar.toolbar}>
          <a
            href="#!"
            class="menu-toggle me-5"
            onClick={(event) => toggle(event)}
          >
            <i class="bi bi-list fs-1"></i>
          </a>

          {usuario ? (
            <HeaderController
              usuario={usuario}
              pathname={pathname}
              cerrarSesion={cerrarSesion}
            />
          ) : null}

          {/*
            usuario
              ? <Header usuario={usuario} cerrarSesion={cerrarSesion} />
              : null
              */}
        </header>

        <div class="dashboard-content" style={{width: "100%" ,marginTop: "50px" }}>
          <div class="container-fluid">
            <ContenedorForm>{children}</ContenedorForm>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {usuario ? (
        <>
          <Header usuario={usuario} cerrarSesion={cerrarSesion} />
          <Sidebar />
        </>
      ) : null}

      <div
        className="container-fluid"
        style={{
          backgroundColor:
            window.location.pathname === "/" ? "white" : "#ecf0f5",
          paddingBottom: "10px",
        }}
      >
        <ContenedorForm>{children}</ContenedorForm>
      </div>
      <Footer />
    </>
  );
};

export default Layout;
