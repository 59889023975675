import React from "react";
import { BotonEditar, BotonEliminar } from "../../shared/FormsElements";

const Observacion = ({ item, editar, eliminar, soloLectura }) => {
  return (
    <tr>
      <td className="text-center">
        {item.autor} - {item.date}
      </td>
      <td className="text-center">{item.titulo}</td>
      <td className="text-center">{item.observacion}</td>
      <td className="text-center">{item.prioridad}</td>
      <td className="text-center">
        {!soloLectura ? (
          <>
            <BotonEditar editar={editar} item={item} />
            <BotonEliminar eliminar={eliminar} item={item} />
          </>
        ) : null}
      </td>
    </tr>
  );
};

export default Observacion;
