import {
  CalcularDeudaTotal,
  CalculoCuota,
} from "../../../functions/FunctionCuota";
import { CreditoDetalles } from "../../../functions/Validaciones/CreditoDetalles";
import { CuotasDetalle } from "../../../functions/Validaciones/CuotasDetalle";
import { tipoConcesion } from "../../shared/Helpers/CalculosConcesiones";
const Tabla_creditos = ({ data, onClick, onChange, soloLectura }) => {
  //Calcular cuotas
  const cuotas = (cuotas) => {
    let result = [];

    cuotas.map((cuota) => {
      let re = CalculoCuota(cuota);
      result.push(re);
    });

    return result;
  };

  console.log(soloLectura);

  return (
    <div className="table-responsive">
      <table className="table table-hover align-middle">
        <thead className="border-0">
          <tr className="">
            <th className="border-0 fs-5 p-1">
              <div className="border p-1" style={{ borderRadius: "10px" }}>
                Acciones
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border p-1" style={{ borderRadius: "10px" }}>
                Cliente
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border p-1" style={{ borderRadius: "10px" }}>
                Nº Credito
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border p-1" style={{ borderRadius: "10px" }}>
                Monto
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border p-1" style={{ borderRadius: "10px" }}>
                Cuotas
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border p-1" style={{ borderRadius: "10px" }}>
                Pagas
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border p-1" style={{ borderRadius: "10px" }}>
                Vencidas
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border p-1" style={{ borderRadius: "10px" }}>
                Deuda
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border p-1" style={{ borderRadius: "10px" }}>
                Concesión
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border p-1" style={{ borderRadius: "10px" }}>
                Estado
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="border-0">
          {data.map((credito) => (
            <tr>
              <td className="border-0">
                <label className="btn btn-danger">
                  <i class="bi bi-eye"></i>
                  <button
                    className="d-none"
                    name="ver_credito"
                    onClick={(event) => onClick(event, credito)}
                  ></button>
                </label>
              </td>
              <td className="border-0">
                {credito.apellido + " " + credito.nombre}
              </td>
              <td className="border-0">{credito.id}</td>
              <td className="border-0 text-nowrap">
                $ {new Intl.NumberFormat("de-DE").format(credito.monto)}
              </td>
              <td className="border-0">{credito.detalles.length}</td>
              <td className="border-0">
                {CuotasDetalle(credito.detalles, credito.concesion).pagas}
              </td>
              <td className="border-0">
                {CuotasDetalle(credito.detalles, credito.concesion).vencidas}
              </td>
              <td className="border-0 text-nowrap">
                ${" "}
                {new Intl.NumberFormat("de-DE").format(
                  CalcularDeudaTotal(
                    0,
                    cuotas(CreditoDetalles(credito).detalles)
                  )
                )}
              </td>
              <td className="border-0">
                {tipoConcesion(
                  credito.concesion ? credito.concesion.id_tipoconcesion : ""
                )}
              </td>
              <td className="border-0">{credito.estado}</td>
            </tr>
          ))}
        </tbody>
        <tfoot></tfoot>
      </table>
    </div>
  );
};

export default Tabla_creditos;
