import React from "react";
import PreCalificacion from "./PreCalificacion";
import { TituloTabla } from "../shared/Estilos";

const ListarPreCalificaciones = ({
  lista,
  setMostrarFormulario,
  setSeleccionado,
  setConsultar,
}) => {


  const editar = (item) => {
    setMostrarFormulario(true);
    setSeleccionado(item);
  };

  
  return (
    <div className="table-responsive">
  
      <table className="table">
        <TituloTabla>
          <tr>
            <th scope="col" className="text-center">
              Acciones
            </th>
            <th scope="col" className="text-center">
              Estado
            </th>
            <th scope="col" className="text-center">
              Promedio
            </th>
            <th scope="col" className="text-center">
              Veraz
            </th>
            <th scope="col" className="text-center">
              Bcra
            </th>
            <th scope="col" className="text-center">
              Juicios
            </th>
            <th scope="col" className="text-center">
              Dgr
            </th>
            <th scope="col" className="text-center">
              Referencias Personales
            </th>
            <th scope="col" className="text-center">
              Ingresos
            </th>
            <th scope="col" className="text-center">
              Bienes
            </th>
            <th scope="col" className="text-center">
              Solicito
            </th>
            <th scope="col" className="text-center">
              Límite x Cuota
            </th>
            <th scope="col" className="text-center">
              Límite
            </th>
            <th scope="col" className="text-center">
              Vencimiento
            </th>
            <th scope="col" className="text-center">
              Fecha de análisis
            </th>
            
          </tr>
        </TituloTabla>
        <tbody>
          {lista.personas.map((item) => (
            <PreCalificacion
              key={item.id}
              item={item}
              editar={editar}
              setConsultar={setConsultar}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListarPreCalificaciones;
