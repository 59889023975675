import { CreditoDetalles } from "./CreditoDetalles";
import { CuotaDeuda } from "./CuotaDeuda";

export const SaldoCuotas = (creditos) => {
  const hoy = new Date();
  let saldo = 0;
  let ultimaCuota = 0;


  creditos.map((credito) => {
    if (credito.detalles) {
      let credito_format = CreditoDetalles(credito);

      credito_format.detalles.map((cuota) => {

        //Cuota vencida actual
        if(hoy.getMonth() === new Date(cuota.fecha_vencimiento).getMonth()){
          ultimaCuota = parseFloat(cuota.total)+ultimaCuota;
        }

        let result = CuotaDeuda({
          cuota: cuota,
          interes_punitorio: credito.interes_punitorio,
        });


        let total_deuda = parseFloat(result.interes_punitorio) + parseFloat(cuota.total)
        let bonificacion = cuota.bonificacion_concesion? total_deuda/100*cuota.bonificacion_concesion : 0;

        //Total de deuda ultimas cuotas vencidas de todos los creditos
        total_deuda = total_deuda - parseFloat(result.pagos) - bonificacion;
        
        //Si la deuda es mayo a 0 sumar
        if(result.interes_punitorio > 0 && total_deuda > 0){
          saldo  = total_deuda + saldo;
        }
        

      });

    
    }
  });

  return ultimaCuota;
};

export const Cuotas_vencidas_impagas = (creditos) => {
let vencidas_impagas = 0;
const hoy = new Date();


creditos.map((credito) => {
  if (credito.detalles) {

    credito.detalles.map((cuota) => {
      console.log(cuota);

      //Cuota vencida actual
      if(hoy > new Date(cuota.fecha_vencimiento)){
        
        let status = true;

        cuota.pagos.map((pago) => {
          console.log(pago);
          if(pago.estado == "TOTAL"){
            status = false;
          } else {
            if(pago.estado == "PARCIAL"){
            }
          }
        })

        if(status){
          vencidas_impagas = vencidas_impagas+1;
        }

      }

      

      
    });

  
  }
});

  return vencidas_impagas;
}
