import React, { useState, useEffect } from "react";
import ListarDomicilios from "./ListarDomicilios";
import { Spinner, clienteAxios, Portal } from "../../shared/";
import { BotonAgregar } from "../../shared/Estilos";
import FormDomicilio from "./FormDomicilio";

const Domicilios = ({ id, soloLectura }) => {
  const [loadingLocal, setLoadingLocal] = useState(null);
  const [domicilios, setDomicilios] = useState(null);
  const [consultar, setConsultar] = useState(true);
  const [Seleccionado, setSeleccionado] = useState(null);

  //modal
  const [isOpened, setOpened] = useState(false);
  const openModal = () => {
    setOpened(true);
    window.scrollTo(0, 0);
  };
  const closeModal = (cierro) => {
    if (cierro) {
      setOpened(false);
    }
    setConsultar(true);
    setSeleccionado(null);
  };
  //modal

  useEffect(() => {
    const cargar = async () => {
      if (!consultar) return;

      try {
        setLoadingLocal(true);
        const result = await clienteAxios.get("/personasdomicilio", {
          params: { id },
        });
        setDomicilios(result.data.domicilios);
        setLoadingLocal(null);
      } catch (e) {}
      setConsultar(null);
    };

    cargar();
  }, [consultar, Seleccionado, id]);

  return (
    <div className="center-block">
      <br></br>

      <div className="row">
        {!soloLectura ? (
          <div className="col-sm">
            <div className="form-group">
              {!isOpened ? (
                <BotonAgregar
                  onClick={() => {
                    openModal();
                  }}
                  className="btn btn-dark"
                >
                  <i className="fa fa-plus-circle"></i> Agregar Domicilio
                </BotonAgregar>
              ) : null}
            </div>
          </div>
        ) : null}
        <br></br>
      </div>

      <br></br>
      {loadingLocal || !domicilios ? (
        <Spinner />
      ) : (
        <ListarDomicilios
          domicilios={domicilios}
          setSeleccionado={setSeleccionado}
          openModal={openModal}
          setConsultar={setConsultar}
          soloLectura={soloLectura}
        />
      )}

      <Portal isOpened={isOpened} onClose={closeModal}>
        <FormDomicilio
          idpersona={id}
          closeModal={closeModal}
          Seleccionado={Seleccionado}
        />
      </Portal>
    </div>
  );
};

export default Domicilios;
