import React, { useContext } from "react";
import { PersonasContext, uuidv4 } from "../../shared/";
import TituloDia from "./TituloDia";

const FromDatosHistoriales = () => {
  const personaContext = useContext(PersonasContext);
  const { personaSeleccionada } = personaContext;

  const historial = () => {
    const jsonData = JSON.parse(personaSeleccionada.persona.history? personaSeleccionada.persona.history : [] );
    //console.log(jsonData);

    let dianterior = "";

    return jsonData.map((item) => {
      if (dianterior !== item.date || dianterior === "") {
        dianterior = item.date;
        return <TituloDia item={item} key={uuidv4()} />;
      }
    });
  };

  return (
    <div style={{ backgroundColor: "white", borderRadius: "10px", padding: "15px" }}>
      <ul className="timeline">
        {historial()};
        <li className="header text-center semibold nm">
          Fin
        </li>
      </ul>
    </div>
  );
};

export default FromDatosHistoriales;
