import {
  Document,
  Page,
  Text,
  View,
  Font,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import { format } from "date-fns";
import { ConvertMonth } from "../../../functions/FunctionFecha";
import { FormatterNumber } from "../../../utils/formatter";

//Font
/*
  Font.register({
    family: "Roboto Slab",
    src: "/assets/fonts/RobotoSlab-Light.ttf",
  });
  */

// Create styles
const styles = StyleSheet.create({
  total: {
    marginTop: "10px",
  },
  page: {
    backgroundColor: "white",
    color: "black",
  },
  section: {
    textAlign: "start",
    margin: 10,
    padding: 10,
    fontSize: "10px",
  },
  viewer: {
    width: 700, //window.innerWidth, //the pdf viewer will take up all of the width and height
    height: 700,
  },
  mb: {
    marginBottom: "10px",
  },
  tr: {
    width: 170,
    margin: "auto",
    flexDirection: "row",
  },
  th: {
    fontWeight: 900,
    textAlign: "center",
    fontSize: "7px",
    margin: 0,
    marginBottom: 3,
    padding: 0,
    flex: 1,
  },
  td: {
    textAlign: "center",
    fontSize: "5px",
    margin: 0,
    marginBottom: 3,
    padding: 0,
    flex: 1,
  },
});

function PDFcomprobanteGasto({ data, persona }) {
  console.log(data);

  let fecha = format(
    new Date(data.pago.fecha_importe + " 00:00:00"),
    "dd/MM/yyy"
  ).toString();
  fecha = fecha.split("/");
  console.log(fecha);
  let mes = ConvertMonth(parseInt(fecha[1]) - 1);
  let resto = -data.pago.importe_cuota;

  const resta = (num_1, num_2) => {
    let dif = parseFloat(num_1) + parseFloat(num_2);

    if (dif < 0) {
      resto = dif;
    } else {
      resto = 0;
    }

    //console.log("Numerooooooooo: "+dif);

    if (dif > 0) {
      return num_1 - dif;
    } else {
      return parseFloat(num_1);
    }
  };

  //console.log(persona);

  const sig_cuotas = [];
  /*
    JSON.parse(data.pago.sig_cuotas)
      ? JSON.parse(data.pago.sig_cuotas)
      : [];
      */
  const saldo = (importe, capital, interes) => {
    let resto_interes = parseFloat(interes) - parseFloat(importe);
    let resto_capital = parseFloat(capital);
    if (resto_interes < 0) {
      resto_capital = parseFloat(capital) + resto_interes;
    }

    console.log(resto_capital);

    return {
      resto_interes:
        resto_interes < 0
          ? parseFloat(interes)
          : parseFloat(interes) - resto_interes,
      resto_capital:
        resto_capital < 0
          ? parseFloat(capital)
          : parseFloat(capital) - resto_capital,
    };
  };

  return (
    <PDFViewer style={styles.viewer}>
      {/* Start of the document*/}
      <Document>
        {/*render a single page*/}
        <Page size={"A5"} style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.mb}>
              Recibo n° ({data.pago.estado.slice(0, 1) + data.cuota.id }){" "}
              {
                data.cuota.item
              }
            </Text>
            <Text>
              Córdoba, {fecha[0] + " de " + mes + " de " + fecha[2]}. Recibí de
              Sr. {persona.nombre + " " + persona.apellido}. DNI{" "}
              {persona.nro_documento} a cuenta de mayor monto la suma de PESOS.
              {FormatterNumber(saldo(data.pago.importe, data.pago.exmonto, data.pago.interes_moratorio).resto_interes)}
              (IP) y la suma de PESOS {FormatterNumber(saldo(data.pago.importe, data.pago.exmonto, data.pago.interes_moratorio).resto_capital)}
              (C). El pago de los intereses no extingue la obligación principal
              y representan solo el porcentual del pago de capital a cuenta.
            </Text>
            <Text style={styles.total}>
              Total: {FormatterNumber(data.pago.importe)}
            </Text>
          </View>
        </Page>
        {sig_cuotas.map((cuota) => (
          <Page size={"A5"} style={styles.page}>
            <View style={styles.section}>
              <Text style={styles.mb}>
                Recibo n° ({cuota.estado.slice(0, 1)}){" "}
                {cuota.nro +
                  "/" +
                  (data.cuota.nro + data.cuota.siguientes_cuotas.length) +
                  "/" +
                  data.cuota.id_credito}{" "}
              </Text>
              {(resto = -cuota.importe)}
              <Text>
                Córdoba, {fecha[0] + " de " + mes + " de " + fecha[2]}. Recibí
                de Sr. {persona.nombre + " " + persona.apellido}. DNI
                {persona.nro_documento} a cuenta de mayor monto la suma de
                PESOS. ${" "}
                {cuota.interes_punitorio > 0
                  ? resta(cuota.interes_punitorio, cuota.importe).toFixed(2)
                  : 0}{" "}
                (IP) (P), la suma de PESOS ${" "}
                {cuota.interes > 0 ? resta(cuota.interes, resto).toFixed(2) : 0}{" "}
                (IN) y la suma de PESOS ${" "}
                {cuota.capital > 0 ? resta(cuota.capital, resto).toFixed(2) : 0}{" "}
                (C). El pago de los intereses no extingue la obligación
                principal y representan solo el porcentual del pago de capital a
                cuenta.
              </Text>
            </View>
          </Page>
        ))}
      </Document>
    </PDFViewer>
  );
}

export default PDFcomprobanteGasto;
