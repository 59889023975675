import React from "react";

const Tasa = ({ item, columnas, AccionesListar }) => {
  return (
    <tr>
    <td style={{ width: "15%" }}>
      <AccionesListar item={item} />
    </td>
    {columnas.map((columna) => (
      <td key={columna.nombre}>
        {columna.nombre === "premium" ? (
          <>
            {item[columna.nombre] === "1" ? "Si" : "No"}
          </>
        ) : (
          item[columna.nombre]
        )}
      </td>
    ))}
  </tr>
  );
};

export default Tasa;
