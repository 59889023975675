import React, { useContext, useState, useEffect } from "react";
import { Spinner, ListarUsuarios, TasasContext } from ".";
import { BotonAgregar } from "../shared/Estilos";
import { Input } from "../shared/FormsElements";

const Tasas = (props) => {
  const tasasContext = useContext(TasasContext);
  const { usuarios, cargando, obtenerUsuariosII } = tasasContext;

  const [DatosForm, LeerForm] = useState({ buscar: "" });

  const { buscar } = DatosForm;

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });

    if (buscar.length > 1) {
      obtenerUsuariosII(buscar);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line
    obtenerUsuariosII(buscar);
  }, [buscar]);

  return (
    <div className="center-block">
      <br></br>
      <h3 className="text-center">Gestionar Tasas</h3>
      <br></br>
      <div className="row">
        <div className="col-sm">
          <div className="form-group">
            <BotonAgregar
              onClick={() => {
                props.history.push("/tasas/nuevo");
              }}
              className="btn btn-dark"
            >
              <i className="fa fa-plus-circle"></i> Agregar Tasa
            </BotonAgregar>
            {/*   <BotonAgregar
              onClick={() => {
                //nuevo();
              }}
              className="btn btn-dark"
            >
              <i className="fa fa-download"></i>
            </BotonAgregar>
            <BotonAgregar
              onClick={() => {
                //nuevo();
              }}
              className="btn btn-dark"
            >
              <i className="fa fa-print"></i>
            </BotonAgregar> */}
          </div>
        </div>
        <div className="col-sm">
          <Input
            key={"buscar"}
            sets={{
              label: "",
              type: "text",
              name: "buscar",
              placeholder: "Ingrese su Busqueda",
              valor: buscar,
            }}
            onChange={onChange}
          />
        </div>
        <br></br>
      </div>

      <br></br>
      {cargando ? <Spinner /> : <ListarUsuarios usuarios={usuarios} />}
    </div>
  );
};

export default Tasas;
