import { Select } from "../../shared/FormsElements"

const MisDatos = ({
  onChange,
  onClick,
  form,
  roles,
  usuario,
  eye
}) => {


  return (
    <div className="card p-5" style={{borderRadius: "20px"}}>
      <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label">Nombre </label>
        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com"
          value={form.user_name}
          name="user_name"
          onChange={(event) => onChange(event)}
        />
      </div>
      <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label">Apellido </label>
        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com"
          value={form.lastname}
          name="lastname"
          onChange={(event) => onChange(event)}
        />
      </div>
      <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label">Username </label>
        <input type="text" class="form-control" id="exampleFormControlInput1"
          value={form.user_username}
          name="user_username"
          onChange={(event) => onChange(event)}
        />
      </div>
      <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label">Email </label>
        <input type="text" class="form-control" id="exampleFormControlInput1"
          value={form.email}
          name="email"
          onChange={(event) => onChange(event)}
          disabled
        />
      </div>
      <div className="mb-3">
        <label for="exampleFormControlInput1" class="form-label">Rol </label>
        <input type="text" class="form-control" id="exampleFormControlInput1"
          value={form.user_type}
          name="email"
          onChange={(event) => onChange(event)}
          disabled
        />
      </div>

      <label for="exampleFormControlInput1" class="form-label">Contraseña </label>
      <div class="input-group mb-3">
        <label class="input-group-text me-1 btn btn-primary" >
          {
            eye
              ? <i class="bi bi-eye"></i>
              : <i class="bi bi-eye-slash"></i>
          }
          <button className="d-none" name="eye" onClick={(event) => onClick(event)}></button>
        </label>
        <input type={eye ? "text" : "password"} class="form-control" aria-describedby="basic-addon1" 
        name="user_password"
        value={form.user_password}
        onChange={(event) => onChange(event)}
        />
      </div>

      <div className="d-flex justify-content-end">
        <label className="btn btn-primary">
          Aplicar Cambios
          <button className="d-none" name="aplicar" onClick={(event) => onClick(event)}></button>
        </label>
      </div>
    </div>
  )
}

export default MisDatos