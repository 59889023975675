import React, { useState, useEffect } from "react";
import { Spinner, clienteAxios, axios } from "../../shared/";
import Pagare from "./Pagare";
import CondicionesPagare from "./CondicionesPagare";

const ImprimirPagare = (props) => {
  let idcredito;

  if (props.match.params.idcredito) {
    idcredito = props.match.params.idcredito;
  } else {
    idcredito = null;
  }

  const [loadingLocal, setLoadingLocal] = useState(null);
  const [personaSeleccionada, setPersonaSeleccionada] = useState(null);
  const [texto, setTexto] = useState(null);
  const [textocondicion, setTextoCondicion] = useState(null);

  const [DatosForm, LeerForm] = useState(null);

  useEffect(() => {
    const cargar = async () => {
      try {
        setLoadingLocal(true);
        axios
          .all([
            clienteAxios.get("/PersonaCreditos/get", {
              params: { id: idcredito },
            }),
            clienteAxios.get("/Impresiones/get", { params: { id: 1 } }),
          ])
          .then(
            axios.spread(function (credito, testo) {
              setTexto(testo.data.impresion[0].cuerpo);
              setTextoCondicion(testo.data.impresion[1].cuerpo);
              LeerForm(credito.data);
              cargarPersona(credito.data.credito.id_persona);
            })
          );
        setLoadingLocal(null);
      } catch (e) {}
    };

    const cargarPersona = async (id) => {
      try {
        setLoadingLocal(true);
        const result = await clienteAxios.get("/persona/getonepersona", {
          params: { id },
        });
        //console.log(result.data.personas[0]);
        setPersonaSeleccionada(result.data.personas[0]);
        setLoadingLocal(null);
      } catch (e) {}
    };
    if (!DatosForm && !personaSeleccionada) {
      cargar();
    }
  }, [loadingLocal, idcredito, personaSeleccionada]);

  if (!DatosForm && !personaSeleccionada) {
    return (
      <>
        <Spinner />
      </>
    );
  }

  const totals = (detalle) => {
    let monto = 0;

    detalle.map((cuota) => {
      monto = parseFloat(cuota.capital) + parseFloat(cuota.interes) + monto;
    })

    return monto
  }

  console.log("MONTO:"+totals(DatosForm.detalle));
  console.log(DatosForm);

  if (DatosForm.credito.tipo_impresion === "1") {
    return (
      <>
        <div>
          {!DatosForm && !texto && !personaSeleccionada ? (
            <Spinner />
          ) : (
            <Pagare
              monto={totals(DatosForm.detalle)}
              fecha={DatosForm.credito.fecha_otorgamiento}
              vencimiento={DatosForm.credito.vencimiento}
              texto={texto}
              personaSeleccionada={personaSeleccionada}
              interes_moratoria={DatosForm.credito.interes_moratoria}
              interes_punitorio={DatosForm.credito.interes_punitorio}
              cuota={"1"}
              idcredito={DatosForm.credito.id}
              garante={DatosForm.credito.garante}
            />
          )}
          <CondicionesPagare
            texto={textocondicion}
            monto={DatosForm.detalle.total}
            fecha={DatosForm.credito.fecha_otorgamiento}
            personaSeleccionada={personaSeleccionada}
            cuotas={DatosForm.credito.cuotas}
            valorCuota={DatosForm.detalle[0].total}
            primerVto={DatosForm.credito.vencimiento}
            interes_moratoria={DatosForm.credito.interes_moratoria}
            interes_punitorio={DatosForm.credito.interes_punitorio}
            tipo_impresion={DatosForm.credito.tipo_impresion}
            garante={DatosForm.credito.garante}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <div>
          {!DatosForm && !texto && !personaSeleccionada ? (
            <Spinner />
          ) : (
            <>
              {DatosForm.detalle.map((item, index) => (
                <Pagare
                  key={item.id}
                  monto={totals(DatosForm.detalle)}
                  fecha={DatosForm.credito.fecha_otorgamiento}
                  vencimiento={item.fecha_vencimiento}
                  texto={texto}
                  personaSeleccionada={personaSeleccionada}
                  interes_moratoria={DatosForm.credito.interes_moratoria}
                  interes_punitorio={DatosForm.credito.interes_punitorio}
                  cuota={index + 1}
                  cuotas={item.cuotas}
                  idcredito={DatosForm.credito.id}
                />
              ))}
            </>
          )}
          <CondicionesPagare
            texto={textocondicion}
            fecha={DatosForm.credito.fecha_otorgamiento}
            personaSeleccionada={personaSeleccionada}
            cuotas={DatosForm.credito.cuotas}
            valorCuota={DatosForm.detalle[0].total}
            primerVto={DatosForm.credito.vencimiento}
            interes_moratoria={DatosForm.credito.interes_moratoria}
            interes_punitorio={DatosForm.credito.interes_punitorio}
            tipo_impresion={DatosForm.credito.tipo_impresion}
          />
        </div>
      </>
    );
  }
};
export default ImprimirPagare;
