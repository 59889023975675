import React, { useContext, useState, useEffect } from "react";
import usePuntosVenta from "../../hooks/usePuntosVenta";
import PuntosVentas from "../puntosVentas/PuntosVentas";
import { Spinner, AuthContext } from "../shared";
import { BotonAgregar } from "../shared/Estilos";
import { Input } from "../shared/FormsElements";
import ListarUsuarios from "./ListarUsuarios";
import Tabla from "./tablas/Tabla";

const Usuarios = (props) => {
  const authContext = useContext(AuthContext);
  const { usuarios, cargando, obtenerUsuariosII } = authContext;

  const [steps, setSteps] = useState(0);
  const [DatosForm, LeerForm] = useState({ buscar: "" });

  const { buscar } = DatosForm;

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });

    if (buscar.length > 1) {
      obtenerUsuariosII(buscar);
    }
  };

  useEffect(() => {
    obtenerUsuariosII(buscar);
  }, [buscar]);

  
  //Parametros
  const [params, setParams] = useState({
    url: "PuntosDeVenta/index",
    id: "",
  });

  //Consultas Api
  const { puntoVenta_data, puntoVenta_isLoading, puntoVenta_message } =
    usePuntosVenta(params.url, params, steps);
  return (
    <div className="container-md min-vh-100" style={{maxWidth: "1500px"}}>
      <br></br>
      <h3 className="text-center">Gestionar Usuarios</h3>
      <br></br>
      <div className="row">
        <div className="col-sm">
          <div className="form-group">
            <BotonAgregar
              onClick={() => {
                props.history.push("/usuarios/nuevo");
              }}
              className="btn btn-dark"
            >
              <i className="fa fa-plus-circle"></i> Agregar Usuario
            </BotonAgregar>
          </div>
        </div>
        <div className="col-sm">
          <Input
            key={"buscar"}
            sets={{
              label: "",
              type: "text",
              name: "buscar",
              placeholder: "Ingrese su Busqueda",
              valor: buscar,
            }}
            onChange={onChange}
          />
        </div>
        <br></br>
      </div>

      <div className="my-4">
      { cargando ? <Spinner /> : <Tabla data={usuarios} puntoVenta={puntoVenta_data} setSteps={setSteps} steps={steps} /> }
      </div>

      {/* cargando ? <Spinner /> : <ListarUsuarios usuarios={usuarios} /> */}

        
      <PuntosVentas data={puntoVenta_data} params={params} setParams={setParams} />

    </div>
  );
};

export default Usuarios;
