import { useEffect, useRef, useState } from "react";
import HeaderComponent from "./HeaderComponent"
import clienteAxios from "../../../config/axios";
import { Permissions } from "../../../utils/permissions";
import { useDetectOutsideClick } from "../DropdownMenu/useDetectOutsideClick";

const HeaderController = ({
  usuario,
  pathname,
  cerrarSesion
}) => {
  const [notificaciones, setNotificaciones] = useState(null);
  const [consultar, setConsultar] = useState(null);
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const onClick = () => setIsActive(!isActive);
  const path = [
    {
      icon: ' <i class="bi bi-coin me-2 fs-5"></i>',
      title: "Pagos",
      path: "/pagos",
      dropdown: false,
      nav_dropdown: []
    },
    {
      icon: ' <i class="bi bi-bar-chart-fill me-2 fs-5"></i>',
      title: "Reportes",
      path: "/reportes",
      dropdown: false,
      nav_dropdown: []
    },
    {
      icon: '<i class="bi bi-credit-card me-2 fs-5"></i>',
      title: "Creditos",
      path: "/creditos",
      dropdown: false,
      nav_dropdown: []
    },
    {
      icon: '<i class="bi bi-credit-card-fill me-2 fs-5"></i>',
      title: "Gastos",
      path: "/gestion de mora/gastos",
      dropdown: false,
      nav_dropdown: []
    },
    {
      icon: '<i class="bi bi-calculator me-2 fs-5"></i>',
      title: "Calculadora",
      path: "/prestamos/calculadora",
      dropdown: false,
      nav_dropdown: []
    },
    /*
    {
      icon: "",
      title: "Gestion de mora",
      path: "/gestion de mora",
      dropdown: true,
      nav_dropdown: [
        {
          icon: "",
          title: "Gastos judiciales",
          path: "/gestion de mora/gastos",
        },
        {
          icon: "",
          title: "Concesiones",
          path: "/gestion de mora/concesiones",
        }
      ]
    },
    */
  ];

  const Quitarnotificacion = async (item) => {
    try {
      await clienteAxios.post("/Notificaciones/update", { id: item.id });
      onClick();
      window.location = item.link;

    } catch (e) { }
  };

  const onClickNotificacion = (item) => {
    Quitarnotificacion(item);
  };

  useEffect(() => {
    const notificar = async () => {
      try {
        const result = await clienteAxios.get("/Notificaciones/getAll", {
          params: { id: usuario.rol },
        });
        setNotificaciones(result.data.observaciones);
      } catch (e) { }
    };
    notificar();
  }, [consultar]);
  return (
    <HeaderComponent
      usuario={usuario}
      pathname={pathname}
      path={path}
      cerrarSesion={cerrarSesion}
      notificaciones={notificaciones}
      onClickNotificacion={onClickNotificacion}
      Quitarnotificacion={Quitarnotificacion}
    />
  )
}

export default HeaderController