import React, { useReducer } from "react";
import personasReducer from "./personasReducer";
import personasContext from "./personasContext";
import clienteAxios from "../../config/axios";

import {
  REGISTRO_ERROR,
  REGISTRO_EXITO,
  ELIMINAR_PERSONA,
  SELECCIONAR_PERSONA,
  DESELECCIONAR_PERSONA,
  OBTENER_PERSONAS,
  CONSEGUIR_PERSONA,
  ORDENAR_FILTRAR_PERSONAS,
  CARGANDO,
  SELECCIONAR_PRECALIFICAR,
} from "../../types";

const PersonasState = (props) => {
  const initialState = {
    personaSeleccionada: null,
    SeleccionadoPrecalificar: null,
    personas: [],
    resultado: [],
    cargando: false,
    mensaje: { msg: null, class: null },
  };

  const [state, dispatch] = useReducer(personasReducer, initialState);

  const SeleccionarPrecalificar = (obj) => {
    dispatch({
      type: SELECCIONAR_PRECALIFICAR,
      payload: obj,
    });
  };

  const ordenarFiltrar = (filtros, campoOrden, tipoOrden) => {
    let elementos = state.personas;

    if (filtros !== null) {
      elementos = elementos
        .filter((item) =>
          String(item.nombre)
            .toLowerCase()
            .startsWith(`${filtros.nombre !== undefined ? filtros.nombre : ""}`)
        )
        .filter((item) =>
          String(item.apellido)
            .toLowerCase()
            .startsWith(
              `${filtros.apellido !== undefined ? filtros.apellido : ""}`
            )
        )
        .filter((item) =>
          String(item.nro_documento)
            .toLowerCase()
            .startsWith(
              `${
                filtros.nro_documento !== undefined ? filtros.nro_documento : ""
              }`
            )
        )
        .filter((item) =>
          String(item.fecha_nacimiento)
            .toLowerCase()
            .startsWith(
              `${
                filtros.fecha_nacimiento !== undefined
                  ? filtros.fecha_nacimiento
                  : ""
              }`
            )
        )
        .filter((item) =>
          String(item.calle)
            .toLowerCase()
            .startsWith(`${filtros.calle !== undefined ? filtros.calle : ""}`)
        )
        .filter((item) =>
          String(item.numero)
            .toLowerCase()
            .startsWith(`${filtros.numero !== undefined ? filtros.numero : ""}`)
        )
        .filter((item) =>
          String(item.provincia)
            .toLowerCase()
            .startsWith(
              `${filtros.provincia !== undefined ? filtros.provincia : ""}`
            )
        )
        .filter((item) =>
          String(item.ciudad)
            .toLowerCase()
            .startsWith(`${filtros.ciudad !== undefined ? filtros.ciudad : ""}`)
        );
    } else {
      elementos = state.personas;
    }

    //console.log("personas", state.personas);

    // ordenar asc y desc
    if (campoOrden !== null && tipoOrden !== null) {
      if (tipoOrden !== null) {
        if (tipoOrden === "asc") {
          elementos = elementos.sort(function (a, b) {
            return String(a[campoOrden])
              .toLowerCase()
              .localeCompare(String(b[campoOrden]).toLowerCase());
          });
        } else {
          elementos = elementos.sort(function (a, b) {
            return String(b[campoOrden])
              .toLowerCase()
              .localeCompare(String(a[campoOrden]).toLowerCase());
          });
        }
      }
    } else {
      // elementos = state.personas;
    }

    dispatch({
      type: ORDENAR_FILTRAR_PERSONAS,
      payload: elementos,
    });
  };

  const ocultarMensaje = () => {
    dispatch({
      type: REGISTRO_EXITO,
    });
  };

  const eliminarPersona = async (id) => {
    try {
      await clienteAxios.delete("/personas", {
        params: { id },
      });
      dispatch({
        type: ELIMINAR_PERSONA,
        payload: id,
      });
    } catch (e) {
      dispatch({
        type: REGISTRO_ERROR,
        payload: "Hubo un error",
      });
    }
  };

  const seleccionarPersona = (id) => {
    dispatch({
      type: SELECCIONAR_PERSONA,
      payload: id,
    });
  };

  const conseguirPersona = (obj) => {
    dispatch({
      type: CONSEGUIR_PERSONA,
      payload: obj,
    });
  };

  const deseleccionarPersona = () => {
    dispatch({
      type: DESELECCIONAR_PERSONA,
    });
  };

  const obtenerPersonasII = async (texto) => {
    try {
      dispatch({
        type: CARGANDO,
        payload: true,
      });
      const resultado = await clienteAxios.get("/personas", {
        params: { texto },
      });
      dispatch({
        type: OBTENER_PERSONAS,
        payload: resultado.data,
      });
    } catch (error) {}
  };

  return (
    <personasContext.Provider
      value={{
        mensaje: state.mensaje,
        personas: state.personas,
        personaSeleccionada: state.personaSeleccionada,
        resultado: state.resultado,
        cargando: state.cargando,
        SeleccionadoPrecalificar: state.SeleccionadoPrecalificar,
        ocultarMensaje,
        obtenerPersonasII,
        seleccionarPersona,
        deseleccionarPersona,
        eliminarPersona,
        conseguirPersona,
        ordenarFiltrar,
        SeleccionarPrecalificar,
      }}
    >
      {props.children}
    </personasContext.Provider>
  );
};

export default PersonasState;
