import { format, add, sub } from "date-fns";

export const CuotaDeuda = ({
  cuota,
  interes_punitorio
}) => { 
  const {
  //concesion 
  //concesion_fecha_vencimiento,
  fecha_alta_concesion,
  //cuota  
  capital,
  credito_id,
  estado,
  fecha_vencimiento,
  id,
  interes,
  interes_mora,
  pagos,
  total,
} = cuota;


//validacion de fecha de alta concesion congela IP
let actual = fecha_alta_concesion ? new Date(fecha_alta_concesion) : new Date();
//Pago parcial
let pago_parcial = 0;

//contador de pagos
let totalPago = 0;


pagos.map((pago) => {
  if(pago.estado == "PARCIAL"){
    totalPago = parseFloat(pago.importe)+totalPago;
    pago_parcial = parseFloat(pago.importe)+pago_parcial;
  } else {
    pago_parcial = 0;
    totalPago = parseFloat(pago.importe)+totalPago;

    let fecha_importe = format(add(new Date(pago.fecha_importe), {days: 1}), "yyyy-MM-dd").toString();
    //si contiene un pago total se cancela el interes punitorio x fecha de importe
    if(new Date(fecha_importe) < actual){
      actual = new Date(fecha_importe);
    }
  }
})


//validacion hasta que fecha se estiman los IP
actual = format(actual, "yyyy-MM-dd").toString();
actual = new Date(actual).getTime()
let vto = new Date(fecha_vencimiento).getTime();


let diferencia_moratoria = actual - vto;
diferencia_moratoria = diferencia_moratoria / (1000 * 60 * 60 * 24);

let calculo_punitorios = 0;

if (diferencia_moratoria > 0) {
  calculo_punitorios = (capital - pago_parcial)* (interes_punitorio / 100);
  calculo_punitorios = calculo_punitorios / 30;

  //intereses punitorios
  calculo_punitorios = calculo_punitorios * diferencia_moratoria;
}




return {
  diferencia_moratoria: diferencia_moratoria > 0? diferencia_moratoria : 0,
  interes_punitorio: calculo_punitorios,
  //importes
  pagos: totalPago,
  pago_parcial: pago_parcial
}
}