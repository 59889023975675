import React, { useState, useContext, useEffect } from "react";
import { Input, BotoneraForm, Select } from "../../../shared/FormsElements";
import { Spinner, useAlerta, PersonasContext, clienteAxios, useLog } from "../../../shared/";

const FormEmail = ({ idpersona, closeModal, emailSeleccionado }) => {
  const personaContext = useContext(PersonasContext);
  const { ocultarMensaje, mensaje } = personaContext;

  const [InsertarLog] = useLog(null);

  const [inicio, setInicio] = useState(true);
  const [loadingLocal, setLoadingLocal] = useState(null);
  const [deshabilitado, setDeshabilitado] = useState(false);

  const [setAlerta, MostrarAlerta] = useAlerta(null);

  const [DatosForm, LeerForm] = useState({
    referencia_email: "",
    email: "",
    descripcion: "",
    id_persona: idpersona,
  });

  const { referencia_email, email, descripcion } = DatosForm;

  useEffect(() => {
    const cargar = () => {
      if (emailSeleccionado) {
        LeerForm(emailSeleccionado);
      }
    };

    const alertar = () => {
      if (loadingLocal) {
        setAlerta(null);
      }
      if (inicio) {
        // eslint-disable-next-line
        ocultarMensaje();
        setInicio(null);
        setAlerta(null);
      } else {
        if (mensaje.msg && !loadingLocal) {
          setAlerta({ msg: mensaje.msg, class: mensaje.class });
        }
      }
    };

    cargar();

    alertar();
  }, [mensaje, inicio]);

  const sets = [
    {
      label: "Email",
      type: "email",
      name: "email",
      placeholder: "ingresar un Email",
      valor: email,
    },
  ];

  const Insertar = async () => {
    if (email.trim() === "") {
      setAlerta({ msg: "Email es obligatorio", class: "danger" });
      return;
    }

    setAlerta(null);

    try {
      setLoadingLocal(true);
      await clienteAxios.post("/personasemail", DatosForm);
      InsertarLog( "crear", "Creacion de Email");
      setLoadingLocal(null);
      setAlerta({ msg: "Se Creo El Email Exito", class: "success" });
      setDeshabilitado(true);
      closeModal(false);
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const Actualizar = async () => {
    if (email.trim() === "") {
      setAlerta({ msg: "Email es obligatorio", class: "danger" });
      return;
    }

    setAlerta(null);

    try {
      setLoadingLocal(true);
      await clienteAxios.put("/personasemail", DatosForm);
      InsertarLog( "editar", "Edicion de Email");
      setLoadingLocal(null);
      setAlerta({ msg: "Se Actualizo con Exito", class: "success" });
      setDeshabilitado(true);
      closeModal(false);
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!emailSeleccionado) {
      Insertar();
    } else {
      Actualizar();
    }
  };

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="center-block">
      <h5 className="text-center">
        {emailSeleccionado ? "Editar " : "Agregar "} Email
      </h5>
      <form onSubmit={onSubmit} className="border p-3 form">
        {sets.map((set) => (
          <Input key={set.name} sets={set} onChange={onChange} />
        ))}

        <Select
          key={"referencia_email"}
          sets={{
            label: "Referencia ",
            name: "referencia_email",
            valor: referencia_email,
            opciones: [
              { label: "Personal", value: "Personal" },
              { label: "Familiar", value: "Familiar" },
              { label: "Laboral", value: "Laboral" },
              { label: "Otro", value: "Otro" },
            ],
          }}
          onChange={onChange}
        />

        <Input
          key={"descripcion"}
          sets={{
            label: "Descripcion",
            type: "text",
            name: "descripcion",
            placeholder: "ingresar descripcion",
            valor: descripcion,
          }}
          onChange={onChange}
        />

    
        {loadingLocal ? <Spinner /> : <MostrarAlerta />}

        <BotoneraForm funcionCancelar={closeModal} valorfuncion={true} deshabilitado={deshabilitado}/>
      </form>
    </div>
  );
};
export default FormEmail;
