import React from "react";

const Evento = ({ item }) => {
 
 
  const ClassIcono = () => {
    //div class="figure bgcolor-green"><i class=""></i></div>
    //icon: "<div class="figure bgcolor-success"><i class="ico-file-plus"></i></div>"

    return item.icon
      .replace('"><i class="ico-file-plus"></i></div>', "")
      .replace('<div class="', "")
      .replace('"><i class=""></i></div>', "");
  };

  const Icono = () => {
    //div class="figure bgcolor-green"><i class=""></i></div>
    //icon: "<div class="figure bgcolor-success"><i class="ico-file-plus"></i></div>"

    return item.icon
      .replace('<div class="figure bgcolor-default"><i class="', "")
      .replace('<div class="figure bgcolor-success"><i class="', "")
      .replace('<div class="figure bgcolor-green"><i class="', "")
      .replace('<div class="figure bgcolor-red"><i class="', "")
      .replace('"></i></div>', "");
  };

  return (
    <li className="wrapper">
      <div className={ClassIcono()}>
        <i className={Icono()}></i>
      </div>
      <div
        className="panel"
        style={{
          backgroundColor: "#EDF2FA",
        }}
      >
        <div className="panel-body">
          <ul className="list-table">
            <li className="text-left" style={{ width: "60px" }}>
              <img
                style={{ backgroundColor: "#5EA251", height: "60px" }}
                src="/assets/theme/image/avatar/avatar.png"
                className="img-circle"
                alt=""
              />
            </li>
            <li className="text-left">
              <p className="mb5">
                <span className="semibold">{item.author}</span>{" "}
                {item.changeType}
              </p>
              <small>{item.time}</small>
              <h5 className="text-accent semibold nm"></h5>
            </li>
          </ul>
        </div>
      </div>
      {/* <!--/ panel --> */}
    </li>
  );
};

export default Evento;
