import React, { useState } from "react";
import { TituloTabla } from "../../shared/Estilos";
import { clienteAxios, useAlerta, Spinner, useLog } from "../../shared/";
import BuscarGarante from "./BuscarGarante";

const BuscarListarGarante = ({ lista, idpersona, closeModal }) => {
  //alert
  const [loadingLocal, setLoadingLocal] = useState(null);
  const [setAlerta, MostrarAlerta] = useAlerta(null);
  //alert

  const [InsertarLog] = useLog(null);


  const editar = async (item) => {
    try {
      setLoadingLocal(true);
      await clienteAxios.post("/personasgarante", {
        id_garante: item.id,
        id_persona: idpersona,
      });
      InsertarLog( "editar", "Edicion de Garante");
      //setConsultar(true);
      setLoadingLocal(null);
      setAlerta({ msg: "Se Registro con Exito", class: "success" });
      closeModal(false);
    } catch (e) {}
  };

  return (
    <div className="table-responsive">
      <table className="table table-striped" style={{backgroundColor:"white"}}>
        <TituloTabla>
          <tr>
            <th scope="col" className="text-center">
              Nombre y Apellido / DNI
            </th>
            <th scope="col" className="text-center">
              Estado
            </th>
            <th scope="col" className="text-center">
              Opciones
            </th>
          </tr>
        </TituloTabla>
        <tbody>
          {lista.map((item) => (
            <BuscarGarante key={item.id} item={item} editar={editar} />
          ))}
        </tbody>
      </table>
      {loadingLocal ? <Spinner /> : <MostrarAlerta />}
    </div>
  );
};

export default BuscarListarGarante;
