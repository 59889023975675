const ModalPuntoVenta = ({ data, onClick, form, setForm }) => {
  const initialState = ({
    id: "",
    nombre: "",
    limite_credito: "",
    provincia: "",
    ciudad: "",
    direccion: "",
    status: "",
    created_at: "",
  });

  const {
    id,
    nombre,
    limite_credito,
    provincia,
    ciudad,
    direccion,
    status,
    created_at
  } = data? data : initialState
  
  const onChange = (e, value) => {
    switch (e.target.name) {
      case "direccion":
      case "ciudad":
      case "provincia":
      case "limite_credito":
      case "nombre":{
        setForm({
          ...form,
          [e.target.name]: e.target.value
        })
      }break;
    }
  };


  return (
    <div
      class="d-block effect-blur align-middle position-absolute min-vh-100 w-100"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ zIndex: 2, top: 0, left: 0 }}
    >
      <div
        class="bg-light modal-dialog animation-modal my-md-5  overflow-hidden  texto-normal "
        style={{ maxWidth: "800px" }}
      >
        <div className="modal-content bg-light">
          <div class="title m-0 bg-white p-4 d-flex justify-content-between">
            <div>
              {data ? (
                <h1>Editar punto de venta</h1>
              ) : (
                <h1>Agregar nuevo punto de venta</h1>
              )}
              <span class="fs-6 text-muted"></span>
            </div>
            <div>
              <label className="btn border-0 bg-transparent">
                <i class="bi bi-x-lg"></i>
                <button
                  class="d-none"
                  name="close"
                  onClick={(event) => onClick(event, null)}
                ></button>
              </label>
            </div>
          </div>
          <div class="body p-4">
            <div className="row">
              <div class="mb-3 col-md-6">
                <label for="exampleFormControlInput1" class="form-label">
                  Nombre
                </label>
                <input type="text" class="form-control" 
                defaultValue={nombre}
                name="nombre"
                onChange={(event) => onChange(event)}
                />
              </div>
              <div class="mb-3 col-md-6">
                <label for="exampleFormControlInput1" class="form-label">
                  Monto limite para creditos
                </label>
                <input type="number" class="form-control" 
                defaultValue={limite_credito}
                name="limite_credito"
                onChange={(event) => onChange(event)}/>
              </div>
              <div class="mb-3 col-md-4">
                <label for="exampleFormControlInput1" class="form-label">
                  Provincia
                </label>
                <select class="form-select" aria-label="Default select example" 
                name="provincia"
                onChange={(event) => onChange(event)}>
                  <option selected>{provincia}</option>
                  <option value="Buenos Aires">BUENOS AIRES</option>
                  <option value="Córdoba">CORDOBA</option>
                </select>
              </div>
              <div class="mb-3 col-md-4">
                <label for="exampleFormControlInput1" class="form-label">
                  Ciudad
                </label>
                <input type="text" class="form-control" 
                defaultValue={ciudad}
                name="ciudad"
                onChange={(event) => onChange(event)}/>
              </div>
              <div class="mb-3 col-md-4">
                <label for="exampleFormControlInput1" class="form-label">
                  Direccion
                </label>
                <input type="text" class="form-control" 
                defaultValue={direccion}
                name="direccion"
                onChange={(event) => onChange(event)}/>
              </div>
            </div>
          </div>
          <div class="footer bg-white p-4">
            <div class="d-flex justify-content-between">
              <div></div>
              <div>
                <button
                  class="btn btn-secondary rounded-pill px-4 me-2"
                  name="close"
                  onClick={(event) => onClick(event)}
                >
                  Cerrar
                </button>
                {data ? (
                  <label className="btn btn-primary rounded-pill">
                    Aplicar cambios
                    <button className="d-none"
                    name="aplicar"
                    onClick={(event) => onClick(event, data)}></button>
                  </label>
                ) : (
                  <label className="btn btn-primary rounded-pill">
                    Guardar
                    <button className="d-none"
                    name="guardar"
                    onClick={(event) => onClick(event)}></button>
                  </label>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPuntoVenta;
