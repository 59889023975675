import { useEffect, useState } from "react";
import AlertSuccess from "../../../CustomAlerts/AlertSuccess";
import AlertWarning from "../../../CustomAlerts/AlertWarning";
import { clienteAxios } from "../../../shared";

const autocomplete = {
  top: "60px",
  zIndex: "2",
};

const Juicio = ({ data, setModal, setSteps, steps, setForm, form }) => {
  const [dataPersonas, setPersonas] = useState([]);
  const [loading, setLoading] = useState(false);

  const [params_persona, setParamsPersona] = useState({
    buscar: "",
    page: 1,
    paginator: 30,
  });

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "close":
        {
        }
        break;
      case "persona":
        {
          console.log(value);
          //reset
          setParamsPersona({
            ...params_persona,
            buscar: value.nombre + " " + value.apellido,
          });

          setForm({
            ...form,
            id_persona: value.id,
          });
        }
        break;
     
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "monto":
      case "interes_moratorio":
      case "fecha_otorgamiento":
      case "item":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value,
          });
        }
        break;
      case "buscar":
        {
          setParamsPersona({
            ...params_persona,
            [e.target.name]: e.target.value,
            page: 1,
            paginator: 30,
          });
        }
        break;
    }
  };

  //personas
  useEffect(() => {
    const load = async () => {
      const response = await clienteAxios.get("/Persona/getAll", {
        params: { texto: params_persona.buscar, page: 1, paginator: 30 },
      });

      setPersonas(response.data.personas.personas);
    };
    load();
  }, [params_persona]);
  return (
    <div class="body p-4">
      <div className="mb-3">
        <div class="input-group" style={{ width: "500px" }}>
          <input
            type="text"
            class="form-control py-3"
            style={{ borderRadius: "20px", width: "400px" }}
            placeholder="Busca una persona para añadir un gasto."
            aria-describedby="basic-addon2"
            autoComplete="off"
            name="buscar"
            onChange={(event) => onChange(event)}
            value={params_persona.buscar}
          />
          <span
            class="input-group-text bg-transparent border-0"
            id="basic-addon1"
          >
            <i class="bi bi-search fs-4"></i>
          </span>

          {params_persona.buscar.trim() !== "" ? (
            <ul class="list-group position-absolute w-100" style={autocomplete}>
              {dataPersonas.map((persona) => (
                <li class="list-group-item w-100 p-0">
                  <label className="border-0  btn btn-outline-dark w-100 d-flex justify-content-start">
                    <div className="me-2">
                      {persona.nombre + " " + persona.apellido}
                    </div>
                    <div className="">
                      <span className="fw-bold">DNI: </span>
                      {persona.nro_documento}
                    </div>
                    <button
                      title="Seleccionar persona"
                      className="d-none"
                      name="persona"
                      onClick={(event) => onClick(event, persona)}
                    ></button>
                  </label>
                </li>
              ))}
            </ul>
          ) : (
            ""
          )}
        </div>
      </div>

      {form.id_persona ? (
        <>
          <div class="mb-3 w-100">
            <label for="exampleFormControlInput1" class="form-label fw-bold">
              INTERES MORATORIO
            </label>
            <input
              type="number"
              step="0.00"
              class="form-control fs-4 py-3"
              style={{ borderRadius: "20px" }}
              id="exampleFormControlInput1"
              name="interes_moratorio"
              onChange={(event) => onChange(event)}
              value={form.interes_moratiorio}
            />
          </div>

          <div class="mb-3 w-100">
            <label for="exampleFormControlInput1" class="form-label fw-bold">
              FECHA DE OTORGAMIENTO
            </label>
            <input
              type="date"
              class="form-control fs-4 py-3"
              style={{ borderRadius: "20px" }}
              id="exampleFormControlInput1"
              name="fecha_otorgamiento"
              onChange={(event) => onChange(event)}
              value={form.fecha_otorgamiento}
            />
          </div>

          <div class="mb-3 w-100">
            <label for="exampleFormControlInput1" class="form-label fw-bold">
              CONCEPTO
            </label>
            <select
              class="form-select fs-4 py-3"
              style={{ borderRadius: "20px" }}
              aria-label="Default select example"
              name="item"
              onChange={(event) => onChange(event)}
            >
              <option selected></option>
              <option value="Tasa de Justicia">Tasa de Justicia</option>
              <option value="Sellados">Sellados</option>
              <option value="Gastos Administrativos">
                Gastos Administrativos
              </option>
              <option value="Honorarios">Honorarios</option>
            </select>
          </div>

          <div class="mb-3 w-100">
            <label for="exampleFormControlInput1" class="form-label fw-bold">
              CAPITAL
            </label>
            <input
              type="text"
              class="form-control fs-4 py-3"
              style={{ borderRadius: "20px" }}
              id="exampleFormControlInput1"
              name="monto"
              onChange={(event) => onChange(event)}
              value={form.monto}
            />
          </div>
        </>
      ) : (
        <div>
          <span>Selecciona una persona para continuar</span>
        </div>
      )}
    </div>
  );
};

export default Juicio;
