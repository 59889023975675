import React, { useState, useContext, useEffect } from "react";
import { format, add, sub } from "date-fns";
import {
  Input,
  BotoneraForm,
  TextArea,
  Select,
} from "../shared/FormsElements";
import {
  Spinner,
  AuthContext,
  useAlerta,
  Portal,
  clienteAxios,
  Swal,
  PersonasContext,
  Link,
  BuscarPersonas,
} from "../shared/index";
import { Cuotas_vencidas_impagas, SaldoCuotas } from "../../functions/Validaciones/CuotasSaldo";
import Precalculo from "../creditos/formulario/preCalculo/Precalculo";
import { tipoConcesion } from "../shared/Helpers/CalculosConcesiones";
import DetalleCuota from "../creditos/formulario/detalleCredito/DetalleCuota";


const Calculadora = (props) => {
  let idcredito = null;
  let idBien = null;

  const authContext = useContext(AuthContext);
  const { ocultarMensaje, mensaje } = authContext;

  const personaContext = useContext(PersonasContext);
  const { conseguirPersona } = personaContext;

  const [inicio, setInicio] = useState(true);
  const [consultar, setConsultar] = useState(true);
  const [setAlerta, MostrarAlerta] = useAlerta(null);
  const [loadingLocal, setLoadingLocal] = useState(null);

  const [DatosForm, LeerForm] = useState({
    id_persona: "",
    id_tasa: "",
    interes: "",
    monto: "",
    cuotas: "",
    garante: "",
    producto: "",
    vencimiento: format(add(new Date(), { months: 1 }), "yyyy-MM-dd"),
    estado: "Vigente",
    fecha_finalizacion: "",
    fecha_otorgamiento: format(new Date(), "yyyy-MM-dd").toString(),
    comentarios: "",
    interes_moratoria: "",
    interes_punitorio: "",
    creditos: "",

    calificacion: null,
    persona: "",
    importe_devolver: "",
    saldo_limite_cuota: 0,
    detalleCredito: [],
    id: "",
    tipo_impresion: "1",
  });

  const {
    tipo_impresion,
    id,
    id_persona,
    monto,
    persona,
    producto,
    calificacion,
    creditos,
    comentarios,
    cuotas,
    importe_devolver,
    fecha_otorgamiento,
    vencimiento,
    fecha_finalizacion,
    estado,
    garantes,
    garante,
    saldo_limite_cuota,
    id_tasa,
    interes,
    interes_moratoria,
    interes_punitorio,
    detalleCredito,
    estado_analisis_financiero
  } = DatosForm;

  const [tasas, setTasas] = useState(null);
  const [tasaSelecionada, setTasaSeleccionada] = useState({ tna: "0" });
  const [tasasGenericas, setTasasGenericas] = useState(null);
  const [personaSeleccionada, setPersonaSeleccionada] = useState({
    apellido: "",
    barrio: "",
    calle: "Ayacucho",
    ciudad: "Cordoba",
    codigo_postal: "3503",
    departamento: "1",
    estado: "1",
    estado_civil: "Soltero/a",
    fecha_nacimiento: "1998-09-14",
    id: "0",
    nacionalidad: "Argentina",
    nombre: "",
    nro_documento: "123123",
    numero: "5230",
    piso: "1",
    creditos: [],
    garantes: [],
    pivot_garantes: [],
    precalificacion: {
      bcra: "3",
      bienes: "3",
      created: null,
      dgr: "3",
      estado: "Aceptada",
      fecha_analisis: "2022-12-19",
      fecha_vencimiento: "2023-12-19",
      id: "2",
      id_persona: "1",
      ingresos: "10",
      juicios: "3",
      limite: "10000000000",
      limite_x_cuota: "Monto Libre",
      observacion: null,
      premium: "NO",
      promedio: "3.00",
      referencias_personales: "3",
      solicito: "0",
      veraz: "3",
    },
    provincia: "CORDOBA",
    sexo: "Masculino",
    solicito: "",
    tipo_documento: "DU",
  });
  const [diasDiferimiento, setDiasDiferimiento] = useState(0);
  const [concesion, setConcesion] = useState(null);
  const [cuotasDetalle, setCuotasDetalle] = useState(null);

  //modal
  const [isOpened, setOpened] = useState(false);

  const openModal = () => {
    setOpened(true);
    window.scrollTo(0, 0);
  };

  const closeModal = () => {
    setOpened(false);
    setConsultar(true);
  };
  //modal

  useEffect(() => {
    const cargarCredito = async () => {
      try {
        setLoadingLocal(true);
        const result = await clienteAxios.get("/PersonaCreditos/get", {
          params: { id: idcredito },
        });
        LeerForm(result.data.credito);
        setCuotasDetalle(result.data.detalle);
        setConcesion(result.data.concesion);
        cargarPersona(
          result.data.credito.id_persona,
          result.data.credito.monto
        );
        setTasaSeleccionada({
          tna: result.data.credito.interes,
          cuotas: result.data.credito.cuotas,
          id: result.data.credito.id_tasa,
        });
        setLoadingLocal(null);
      } catch (e) { }
    };

    const cargarPersona = async (id, montoConfig) => {
      setLoadingLocal(true);
      const result = await clienteAxios.get("/persona/getonepersona", {
        params: { id: idBien ? idBien : id },
      })

      if (!idcredito) {
        result.data.personas[0].solicito = "";
      } else {
        result.data.personas[0].solicito = montoConfig;
      }

      setPersonaSeleccionada(result.data.personas[0]);
      conseguirPersona(result.data.personas[0]);
      setLoadingLocal(null);

    };

    const cargarTasasGenericas = async () => {
      try {
        setLoadingLocal(true);
        const result = await clienteAxios.get("/tasasGenericas/getAll");
        setTasasGenericas(
          result.data.tasasGenericas.filter((v) => v.hasta === "0000-00-00")
        );
        setLoadingLocal(null);
      } catch (e) { }
    };

    const cargarTasas = async () => {
      try {
        setLoadingLocal(true);
        const result = await clienteAxios.get("/tasa/getAll", {
          params: { texto: "" },
        });

        result.data.tasas.sort(function (a, b) {
          return a.cuotas - b.cuotas;
        });

        setTasas(result.data.tasas);
        setLoadingLocal(null);
      } catch (e) { }
    };

    if (inicio) {
      if (idcredito) {
        cargarCredito();
      }
      cargarTasas();
      cargarTasasGenericas();
      if (idBien) {
        cargarPersona(null, "");
      }
    }

    const alertar = () => {
      if (loadingLocal) {
        setAlerta(null);
      }
      if (inicio) {
        // eslint-disable-next-line
        ocultarMensaje();
        setInicio(null);
        setAlerta(null);
      } else {
        if (mensaje.msg && !loadingLocal) {
          setAlerta({ msg: mensaje.msg, class: mensaje.class });
        }
      }
    };

    alertar();
  }, [mensaje, loadingLocal, inicio, idBien, idcredito, consultar]);

  useEffect(() => {
    if (tasasGenericas !== null && !idcredito) {
      const moratoria = tasasGenericas.filter(
        (tasa) => tasa.tipo === "MORATORIA"
      )[0].valor;
      const punitorio = tasasGenericas.filter(
        (tasa) => tasa.tipo === "PUNITORIO"
      )[0].valor;
      LeerForm({
        ...DatosForm,
        interes_moratoria: moratoria,
        interes_punitorio: punitorio,
      });
    }

    if (personaSeleccionada !== null) {


      const saldo = SaldoCuotas(personaSeleccionada.creditos);

      //console.log(saldo);
      if (!idcredito) {
        LeerForm({
          ...DatosForm,
          persona:
            personaSeleccionada.nombre + " " + personaSeleccionada.apellido,
          id_persona: personaSeleccionada.id, personaSeleccionada,
          monto: personaSeleccionada.precalificacion.solicito,
          saldo_limite_cuota: personaSeleccionada.precalificacion.limite - saldo,
          estado_analisis_financiero: personaSeleccionada.precalificacion.estado,
          garantes: personaSeleccionada.garantes,
          calificacion: personaSeleccionada.precalificacion
        });
      } else {
        LeerForm({
          ...DatosForm,
          persona: personaSeleccionada.nombre + " " + personaSeleccionada.apellido,
          id_persona: personaSeleccionada.id, personaSeleccionada,
          saldo_limite_cuota: personaSeleccionada.precalificacion.limite - saldo,
          estado_analisis_financiero: personaSeleccionada.precalificacion.estado,
          garantes: personaSeleccionada.garantes,
          calificacion: personaSeleccionada.precalificacion
        });
      }
    }
  }, [personaSeleccionada, tasasGenericas, consultar]);

  useEffect(() => {
    if (monto !== "" && monto !== "0" && tasaSelecionada.tna !== "0") {
      CalcularCredito();
    }
  }, [tasaSelecionada, monto]);

  useEffect(() => {
    if (id !== "") {
      props.history.push("/creditos/ver/" + id);
    }
  }, [id]);

  useEffect(() => {
    const calcularDiffDias = () => {
      if (vencimiento) {
        let vto = new Date(vencimiento.replace("-", "/"));
        vto = sub(new Date(vto), { months: 1 });

        const otr = new Date(fecha_otorgamiento.replace("-", "/"));
        const dif = Math.floor((vto - otr) / (1000 * 60 * 60 * 24));

        setDiasDiferimiento(dif);
      }
    };
    calcularDiffDias();
  }, [vencimiento]);

  const Precalcular = (tasa, montoo) => {
    if (monto === "" || monto === "0") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Debe Seleccionar un Cliente e Ingresar el Importe",
      });
      return;
    }
    setTasaSeleccionada(tasa);
    LeerForm({
      ...DatosForm,
      cuotas: tasa.cuotas,
      monto: montoo,
      id_tasa: tasa.id,
      interes: tasa.tna,
    });
  };

  const CalcularCredito = () => {
    //console.log(monto, tasaSelecionada.tna, personaSeleccionada);
    if (monto === "" || monto === "0" || tasaSelecionada.tna === "0") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Debe Seleccionar un Cliente, Ingresar el Importe y Seleccionar una Tasa",
      });
      return;
    }

    let montoc = parseInt(monto);
    const tiempo = parseInt(tasaSelecionada.cuotas);
    const interesc = parseFloat(tasaSelecionada.tna / 12 / 100);

    let resultado = [];
    const hoy = new Date();

    let pagoInteres = 0,
      pagoCapital = 0,
      cuota = 0,
      pagoInteresDiferimiento = 0;

    const vto = new Date(vencimiento.replace("-", "/"));
    const factor = Math.pow(interesc + 1, tiempo);
    cuota = (monto * interesc * factor) / (factor - 1);
    pagoInteresDiferimiento =
      diasDiferimiento > 0
        ? parseFloat(
          (montoc * (tasaSelecionada.tna / 100) * diasDiferimiento) /
          365 /
          tiempo
        )
        : 0;

    for (let i = 1; i <= tiempo; i++) {
      pagoInteres = parseFloat(montoc * interesc);
      pagoCapital = parseFloat(cuota - pagoInteres);
      montoc = parseFloat(montoc - pagoCapital);

      let fechaI = "";
      let fechaF = "";
      let fechaFF = "";

      if (i === 1) {
        fechaI = format(hoy, "yyyy-MM-dd").toString();
        fechaF = format(vto, "yyyy-MM-dd").toString();
        fechaFF = format(vto, "yyy-MM-dd").toString();
      } else {
        fechaI = format(
          add(hoy, { months: i - 1, days: 1 }),
          "yyyy-MM-dd"
        ).toString();
        fechaF = format(add(vto, { months: i - 1 }), "yyyy-MM-dd").toString();
        fechaFF = format(add(vto, { months: i - 1 }), "yyyy-MM-dd").toString();
      }

      //console.log(fechaI);

      /*
      resultado = [
        ...resultado,
        {
          nrocuota: i,
          Capital: (montoc + pagoCapital).toFixed(2),
          pagoCapital: pagoCapital.toFixed(2),
          pagoInteres: (pagoInteres + pagoInteresDiferimiento).toFixed(2),
          cuota: (cuota + pagoInteresDiferimiento).toFixed(2),
          monto: Math.abs(montoc).toFixed(2),
          fechaInicio: fechaI,
          fechaFin: fechaF,
          fechaFinFormat: fechaFF,
          interesDiasDiferimiento: pagoInteresDiferimiento,
        },
      ];
      */

      resultado = [
        ...resultado,
        {
          nrocuota: i,
          pagoCapital: pagoCapital.toFixed(2),
          pagoInteres: (pagoInteres + pagoInteresDiferimiento).toFixed(2),
          cuota: (cuota + pagoInteresDiferimiento).toFixed(2),
          monto: Math.abs(montoc).toFixed(2),
          fechaInicio: fechaI,
          fechaFin: fechaF,
          fechaFinFormat: fechaFF,
          interesDiasDiferimiento: pagoInteresDiferimiento,

          concesion_fecha_vencimiento: null,
          concesion_fecha_alta: null,
          fecha_vencimiento: fechaF,

          //capital: (montoc + pagoCapital).toFixed(2),
          capital: pagoCapital.toFixed(2),
          interes: pagoInteres,
          total: (cuota + pagoInteresDiferimiento).toFixed(2),

          interes_mora: "",
          pagos: []
        },
      ];
    }

    LeerForm({
      ...DatosForm,
      detalleCredito: resultado,
      fecha_finalizacion: format(
        add(hoy, { months: tiempo }),
        "yyyy-MM-dd"
      ).toString(),
      importe_devolver: (tiempo * cuota).toFixed(2),
    });
  };

  const MostrarTasaGenerica = (tipo) => {
    let valor;

    if (!idcredito) {
      valor = tasasGenericas.filter((tasa) => tasa.tipo === tipo)[0].valor;
    } else {
      if (tipo === "MORATORIA") {
        valor = DatosForm.interes_moratoria;
      }
      if (tipo === "PUNITORIO") {
        valor = DatosForm.interes_punitorio;
      }
    }

    return valor;
  };

  const Insertar = async () => {
    try {
      setLoadingLocal(true);
      const result = await clienteAxios.post("/PersonaCreditos/add", DatosForm);
      //InsertarLog("crear", "Creacion de Credito");
      //console.log(result.data.id);
      LeerForm({
        ...DatosForm,
        id: result.data.id,
      });
      setLoadingLocal(null);
      setAlerta({ msg: "Se Creo el Credito con Exito", class: "success" });
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const Actualizar = () => {
    /*  if (
      user_username.trim() === "" ||
      user_name.trim() === "" ||
      lastname.trim() === "" ||
      user_type.trim() === ""
    ) {
      setAlerta({ msg: "Todos los campos son obligatorios", class: "danger" });
      return;
    }

    const api = {
      url: "/users",
      type: "put",
      datosJson: {
        user_id: idBien,
        user_username,
        user_type,
        user_password,
        name: user_name,
        lastname,
        email,
        codigo_postal,
      },
      getParams: null,
      funcion: registrarUsuario,
    };

    load(api);
    setTimeout(() => {
      props.history.push("/usuarios");
    }, 2000); */
  };

  const onClick = (e, value) => {
    e.preventDefault()
    switch (e.target.name) {
      case "premium": {
        if(personaSeleccionada.precalificacion.premium == "SI"){
          setPersonaSeleccionada({
            ...personaSeleccionada,
            precalificacion: {
              ...personaSeleccionada.precalificacion,
              premium: "NO"
            }
          })
        } else {
          setPersonaSeleccionada({
            ...personaSeleccionada,
            precalificacion: {
              ...personaSeleccionada.precalificacion,
              premium: "SI"
            }
          })
        }
      } break;
    }
  }

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });
  };

  const funcionCancelar = () => {
    props.history.push("/creditos");
  };

  //Logs
  //console.log(idcredito);
  //console.log(idBien);
  //console.log(DatosForm);
  //console.log(calificacion);
  //console.log(detalleCredito);
  console.log(personaSeleccionada);
  //console.log(cuotasDetalle);
  //console.log(tasaSelecionada);
  return (
    <div className="container-md min-vh-100">
      <br></br>
      <h2 className="text-center">Calculadora de creditos</h2>
      <br></br>
      <form className="border p-3 form">
        <div className="row mb-3">
          {concesion ? "hols" : ""}
          <div className="col-sm ">
            <div className="fs-4">
              Simular con un tipo de cliente
            </div>
            <div className="d-flex mb-3">
                <label className={
                  personaSeleccionada.precalificacion.premium == "SI" ? "btn w-100 btn-primary me-2 disabled" : "btn w-100 btn-outline-primary me-2 "}>
                  Cliente Premium
                  <button className="d-none" name="premium" onClick={(event) => onClick(event)}>
                  </button>
                </label>
                <label className={
                  personaSeleccionada.precalificacion.premium == "NO" ? "btn w-100 btn-primary me-2 disabled" : "btn w-100 btn-outline-primary me-2"}>
                  Cliente Comun
                  <button className="d-none" name="premium" onClick={(event) => onClick(event)}>
                  </button>
                </label>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <Input
                  style={{ with: "10px" }}
                  key={"monto"}
                  sets={{
                    label: "Importe Credito",
                    type: "text",
                    name: "monto",
                    placeholder: "Importe Credito ",
                    valor: idcredito ? DatosForm.monto : monto,
                    requerido: true,
                    disabled: idcredito ? true : false,
                  }}
                  onChange={onChange}
                />
              </div>
              <div className="col-sm"></div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <Input
                  style={{ with: "10px" }}
                  key={"cuotas"}
                  sets={{
                    label: "Nro. de Cuotas",
                    type: "number",
                    name: "cuotas",
                    placeholder: "Nro cuotas ",
                    valor: cuotas,
                    disabled: true,
                    requerido: true,
                  }}
                  onChange={onChange}
                />
              </div>
              <div className="sm"></div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <Input
                  style={{ with: "10px" }}
                  key={"importe_devolver"}
                  sets={{
                    label: "Importe Devolver",
                    type: "text",
                    name: "importe_devolver",
                    placeholder: "Importe Devolver ",
                    valor: idcredito ? DatosForm.importe_devolver : importe_devolver,
                    disabled: true,
                    requerido: true,
                  }}
                  onChange={onChange}
                />
              </div>
              <div className="col-sm"></div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <Input
                  style={{ with: "10px" }}
                  key={"fecha_otorgamiento"}
                  sets={{
                    label: "Fecha Otorgamiento",
                    type: "date",
                    name: "fecha_otorgamiento",
                    placeholder: "Fecha Otorgamiento",
                    valor: fecha_otorgamiento,
                    disabled: true,
                    requerido: true,
                  }}
                  onChange={onChange}
                />
              </div>
              <div className="col-sm"></div>
            </div>

            {/* Fecha vto 1ra cuota */}
            <div className="row">
              <div className="col-sm-12">
                <Input
                  style={{ with: "10px" }}
                  key={"vencimiento"}
                  sets={{
                    label: "Fecha Vto. 1 Cuota",
                    type: "date",
                    name: "vencimiento",
                    placeholder: "Fecha Vto. 1 Cuota",
                    valor: vencimiento,
                    disabled: idcredito ? true : false,
                    requerido: true,
                  }}
                  onChange={onChange}
                />
              </div>
              <div className="col-sm d-none">
                {" "}
                <br></br>
                <i>{`${diasDiferimiento} Dias`}</i>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <Input
                  style={{ with: "10px" }}
                  key={"fecha_finalizacion"}
                  sets={{
                    label: "Fecha Vto. Prestamo",
                    type: "date",
                    name: "fecha_finalizacion",
                    placeholder: "Fecha Vto. Prestamo",
                    valor: fecha_finalizacion,
                    disabled: true,
                    requerido: true,
                  }}
                  onChange={onChange}
                />
              </div>
              <div className="col-sm"></div>
            </div>

            <div className="row">
              <div className="col-sm-12">


                {garantes && !idcredito ?
                  garantes.map((garante) => (
                    <div class="form-check text-center">
                      <input class="form-check-input ms-1" type="radio" id="flexRadioDefault1"
                        name="garante"
                        value={garante.nombre}
                        onChange={(event) => onChange(event)}
                      />
                      <label class="form-check-label" for="flexRadioDefault1">
                        {garante.nombre + " " + garante.apellido}
                      </label>
                    </div>
                  ))
                  : garantes
                    ? garantes.map((garante, index) => (
                      <Input
                        key=""
                        sets={{
                          label: "Garante - " + (index + 1),
                          type: "text",
                          name: "saldo_limite_cuota",
                          placeholder: " ",
                          valor: garante.nombre + " " + garante.apellido,
                          disabled: true,
                        }}
                        onChange={onChange}
                      />
                    ))
                    : ""
                }
              </div>
              <div className="sm"></div>
            </div>

            <div className="row">
              <div className="col-sm-12 d-none">
                <Input
                  key={"saldo_limite_cuota"}
                  sets={{
                    label: "Saldo Limite Couta",
                    type: "text",
                    name: "saldo_limite_cuota",
                    placeholder: " ",
                    valor: saldo_limite_cuota ? saldo_limite_cuota.toFixed(2) : 0,
                    disabled: true,
                  }}
                  onChange={onChange}
                />
              </div>
              <div className="col-sm"></div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <Input
                  key={"interes"}
                  sets={{
                    label: "Tasa aplicada al credito",
                    type: "number",
                    step: "0.00",
                    name: "interes",
                    placeholder: " ",
                    valor: interes,
                    disabled: true,
                    requerido: true,
                  }}
                  onChange={onChange}
                />
              </div>
              <div className="col-sm"></div>
            </div>
          </div>

          {/* Precalculo de Tasas */}
          <div className="col-sm">
            <TextArea
              key={"producto"}
              sets={{
                label: "Producto * ",
                name: "producto",
                placeholder: "ingresar Producto",
                valor: producto,
                rows: "2",
                cols: "10",
                requerido: true,
                disabled: idcredito ? true : false,
              }}
              onChange={onChange}
            />
            <TextArea
              key={"comentarios"}
              sets={{
                label: "Comentario * ",
                name: "comentarios",
                placeholder: "ingresar Comentario",
                valor: comentarios,
                rows: "2",
                cols: "10",
                disabled: idcredito ? true : false,
              }}
              onChange={onChange}
            />
            <hr></hr>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "5px",
                padding: "5px",
              }}
            >
              <h4 className="text-center">Tasas de Intereses Acordados</h4>
              {tasasGenericas ? (
                <div style={{ padding: "20px" }}>
                  <b
                    style={{
                      color: tasaSelecionada.tna !== "0" ? "black" : "red",
                    }}
                  >
                    Tasa Prestamo: {tasaSelecionada.tna} mensual{" "}
                  </b>
                  <br></br>
                  Tasa Moratorio: {MostrarTasaGenerica("MORATORIA")} mensual
                  <br></br>
                  Tasa Punitorio: {MostrarTasaGenerica("PUNITORIO")} mensual
                </div>
              ) : null}
            </div>
            <br></br>

            {calificacion ? (
              <Precalculo
                calificacion={calificacion}
                tasas={tasas}
                montop={monto}
                Precalcular={Precalcular}
                saldo_limite_cuota={saldo_limite_cuota}
                diasDiferimiento={diasDiferimiento}
              />
            ) : null}
          </div>
        </div>

        {concesion !== null ? (
          <>
            <div
              className="text-center"
              style={{ paddingTop: "30px", fontWeight: "bold" }}
            >
              <hr></hr>
              <Link to={"/concesiones/ver/" + concesion.id} target="blank">
                Tiene Concesion Activa de Tipo:{" "}
                {tipoConcesion(concesion.id_condicion.toString())}
              </Link>
              <br></br>
              <br></br>
              Detalle de la Aplicación de la Conseción:
              <br></br>
            </div>
            <div>

              {cuotasDetalle ? (
                <DetalleCuota
                  data={cuotasDetalle}
                  onClick={onClick}
                />
              ) : null}

              <hr></hr>
              <br></br>
            </div>
          </>
        ) : <div className="">
          {/* cuotas detalle con concesion o sin concesion */}
          {
            detalleCredito && cuotasDetalle
              ? detalleCredito.length > 0 ? (
                <DetalleCuota
                  data={cuotasDetalle}
                  onClick={onClick}
                />
              ) : ""
              : ""}
        </div>}


        {/*
          detalleCredito
            ? detalleCredito.length > 0 ? (
              <DetalleCuota
                data={detalleCredito}
                onClick={onClick}
              />
            ) : ""
            : ""*/}

        {detalleCredito && !cuotasDetalle ? (
          <DetalleCuota
            data={detalleCredito}
            onClick={onClick}
          />
        ) : null}

        {/* Impresion Pagare */}
        <div className="d-none" style={{ marginTop: "10px", marginBottom: "20px" }}>
          <Select
            key={"tipo_impresion"}
            sets={{
              label: "Seleccione como desea Imprimir el Pagare: ",
              name: "tipo_impresion",
              valor: tipo_impresion,
              disabled: idcredito ? true : false,
              opciones: [
                { label: "Un Solo Pagare por el Total", value: "1" },
                { label: "Un Pagare por Cuota", value: "2" },
              ],
            }}
            onChange={onChange}
          />
        </div>

        {loadingLocal ? <Spinner /> : <MostrarAlerta />}

        <div className="d-none" style={{ paddingTop: "10px" }}>
          <BotoneraForm
            funcionCancelar={funcionCancelar}
            valorfuncion={null}
            deshabilitado={idcredito ? true : false}
          />
        </div>
      </form>

      <Portal isOpened={isOpened} onClose={closeModal}>
        <BuscarPersonas
          closeModal={closeModal}
          setPersonaSeleccionada={setPersonaSeleccionada}
        />
      </Portal>
    </div>
  );
};
export default Calculadora;
