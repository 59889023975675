import React, { useState, useContext, useEffect } from "react";
import { Input, BotoneraForm, Select } from "../../shared/FormsElements";
import { Spinner, useAlerta, PersonasContext, clienteAxios, useLog } from "../../shared/";

const FormDomicilio = ({ idpersona, closeModal, Seleccionado }) => {
  const personaContext = useContext(PersonasContext);
  const { ocultarMensaje, mensaje, personaSeleccionada } = personaContext;

  const [inicio, setInicio] = useState(true);
  const [loadingLocal, setLoadingLocal] = useState(null);
  const [deshabilitado, setDeshabilitado] = useState(false);

  const [setAlerta, MostrarAlerta] = useAlerta(null);

  const [InsertarLog] = useLog(null);

  const [DatosForm, LeerForm] = useState({
    calle: "",
    numero: "",
    provincia: "",
    ciudad: "",
    barrio: "",
    departamento: "",
    piso: "",
    codigo_postal: "",
    referencia: "",
    es_principal: "",
    id_persona: idpersona,
  });

  const {
    calle,
    numero,
    provincia,
    ciudad,
    barrio,
    departamento,
    piso,
    codigo_postal,
    referencia,
    es_principal,
  } = DatosForm;

  useEffect(() => {
    const cargar = () => {
      if (Seleccionado) {
        LeerForm(Seleccionado);
      }
    };

    const alertar = () => {
      if (loadingLocal) {
        setAlerta(null);
      }
      if (inicio) {
        // eslint-disable-next-line
        ocultarMensaje();
        setInicio(null);
        setAlerta(null);
      } else {
        if (mensaje.msg && !loadingLocal) {
          setAlerta({ msg: mensaje.msg, class: mensaje.class });
        }
      }
    };

    cargar();

    alertar();
  }, [mensaje, inicio]);

  const Insertar = async () => {

    try {
      setLoadingLocal(true);
      await clienteAxios.post("/personasdomicilio", DatosForm);
      InsertarLog( "crear", "Creacion de Domicilio");
      setLoadingLocal(null);
      setDeshabilitado(true);
      closeModal(false);
      setAlerta({ msg: "Se Creo con Exito", class: "success" });
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const Actualizar = async () => {

    try {
      setLoadingLocal(true);
      await clienteAxios.put("/personasdomicilio", DatosForm);
      InsertarLog( "editar", "Edicion de Domicilio");
      setLoadingLocal(null);
      setAlerta({ msg: "Se Actualizo con Exito", class: "success" });
      setDeshabilitado(true);
      closeModal(false);
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (
      calle.trim() === "" ||
      numero.trim() === "" ||
      provincia.trim() === "" ||
      ciudad.trim() === "" ||
      codigo_postal.trim() === "" ||
      referencia.trim() === "" ||
      es_principal.trim() === ""
    ) {
      setAlerta({ msg: "Calle, numero, provincia, ciudad, codigo postal, principal son obligatorios", class: "danger" });
      return;
    }
    
    setAlerta(null);

    if(es_principal==="SI"){
      personaSeleccionada.barrio = barrio;
      personaSeleccionada.provincia = provincia;
      personaSeleccionada.ciudad= ciudad;
      personaSeleccionada.numero = numero;
      personaSeleccionada.codigo_postal = codigo_postal;
      personaSeleccionada.departamento = departamento;
      personaSeleccionada.calle = calle;
      //conseguirPersona();
    }

    if (!Seleccionado) {
      Insertar();
    } else {
      Actualizar();
    }
  };

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="center-block">
      <h5 className="text-center">
        {Seleccionado ? "Editar " : "Agregar "} Domicilio
      </h5>
      <form onSubmit={onSubmit} className="border p-3 form">
        <Input
          key={"calle"}
          sets={{
            label: "Calle *",
            type: "text",
            name: "calle",
            placeholder: "Ingrese Calle ",
            valor: calle,
          }}
          onChange={onChange}
        />

        <Input
          key={"numero"}
          sets={{
            label: "Numero *",
            type: "text",
            name: "numero",
            placeholder: "Ingrese Numero ",
            valor: numero,
          }}
          onChange={onChange}
        />

        <Select
          key={"provincia"}
          sets={{
            label: "Provincia *",
            name: "provincia",
            valor: provincia,
            opciones: [
              { label: "BUENOS AIRES", value: "BUENOS AIRES" },
              { label: "CORDOBA", value: "CORDOBA" },
            ],
          }}
          onChange={onChange}
        />

        <Input
          key={"ciudad"}
          sets={{
            label: "Ciudad * ",
            type: "text",
            name: "ciudad",
            placeholder: "ingresar Ciudad",
            valor: ciudad,
          }}
          onChange={onChange}
        />

        <Input
          key={"barrio"}
          sets={{
            label: "Barrio",
            type: "text",
            name: "barrio",
            placeholder: "Ingresar Barrio",
            valor: barrio,
          }}
          onChange={onChange}
        />

        <Input
          key={"departamento"}
          sets={{
            label: "Departamento",
            type: "text",
            name: "departamento",
            placeholder: "Ingresar Departamento",
            valor: departamento,
          }}
          onChange={onChange}
        />

        <Input
          key={"piso"}
          sets={{
            label: "Piso",
            type: "text",
            name: "piso",
            placeholder: "Ingresar Piso",
            valor: piso,
          }}
          onChange={onChange}
        />

        <Input
          key={"codigo_postal"}
          sets={{
            label: "Codigo Postal* ",
            type: "text",
            name: "codigo_postal",
            placeholder: "Ingresar Codigo Postal",
            valor: codigo_postal,
          }}
          onChange={onChange}
        />

        <Select
          key={"referencia"}
          sets={{
            label: "Referencia *",
            name: "referencia",
            valor: referencia,
            opciones: [
              { label: "Particular", value: "Particular" },
              { label: "Familiar", value: "Familiar" },
              { label: "Laboral", value: "Laboral" },
            ],
          }}
          onChange={onChange}
        />

        <Select
          key={"es_principal"}
          sets={{
            label: "Es Principal *",
            name: "es_principal",
            valor: es_principal,
            opciones: [
              { label: "SI", value: "SI" },
              { label: "NO", value: "NO" },
            ],
          }}
          onChange={onChange}
        />

        {loadingLocal ? <Spinner /> : <MostrarAlerta />}

        <BotoneraForm
          funcionCancelar={closeModal}
          valorfuncion={true}
          deshabilitado={deshabilitado}
        />
      </form>
    </div>
  );
};
export default FormDomicilio;
