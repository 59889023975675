import {
  REGISTRO_EXITO,
  REGISTRO_ERROR,
  USUARIO_AUTENTICADO,
  LOGIN_EXITO,
  LOGIN_ERROR,
  CERRAR_SESION,
  OBTENER_USUARIOS,
  ACTUALIZAR_USUARIO,
  SELECCIONAR_USUARIO,
  ELIMINAR_USUARIO,
  CARGANDO,
  ORDENAR_FILTRAR_USUARIOS,
  DESELECCIONAR_USUARIO,
} from "../../types";

const authReducer = (state, action) => {
  switch (action.type) {
    case DESELECCIONAR_USUARIO:
      return {
        ...state,
        usuarioSeleccionado: null,
      };
    case ORDENAR_FILTRAR_USUARIOS:
      return {
        ...state,
        resultado: action.payload,
      };
    case CARGANDO:
      return {
        ...state,
        cargando: action.payload,
      };

    case ELIMINAR_USUARIO:
      return {
        ...state,
        usuarios: state.usuarios.filter(
          (usuario) => usuario.id !== action.payload
        ),
        resultado: state.resultado.filter(
          (result) => result.id !== action.payload
        ),
      };
    case SELECCIONAR_USUARIO:
      return {
        ...state,
        usuarioSeleccionado: action.payload,
      };

    case ACTUALIZAR_USUARIO:
      return {
        ...state,
        usuarios: state.usuarios.map((usuario) =>
          usuario.id === action.payload.id ? action.payload : usuario
        ),
      };

    case OBTENER_USUARIOS:
      return {
        ...state,
        usuarios: action.payload,
        resultado: action.payload,
        cargando: false,
      };

    case USUARIO_AUTENTICADO:
      return {
        ...state,
        usuario: action.payload,
        autenticado: true,
        mensaje: { msg: null, class: null },
      };

    case CERRAR_SESION:
      localStorage.removeItem("token");
      return {
        ...state,
        usuario: null,
        token: null,
        mensaje: { msg: null, class: null },
        autenticado: null,
      };

    case LOGIN_EXITO:
      localStorage.setItem("token", action.payload);
      return {
        ...state,
        autenticado: true,
        mensaje: { msg: null, class: null },
        token: action.payload,
      };

    case LOGIN_ERROR:
      localStorage.removeItem("token");
      return {
        ...state,
        autenticado: null,
        mensaje: action.payload,
        token: null,
      };

    case REGISTRO_EXITO:
      return {
        ...state,
        mensaje: { msg: null, class: null },
      };

    case REGISTRO_ERROR:
      return {
        ...state,
        mensaje: action.payload,
      };

    default:
      return state;
  }
};

export default authReducer;
