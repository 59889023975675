import { Permissions } from "../../../utils/permissions";

const SidebarComponent = ({
  path,
  pathname,
  hidden,
  usuario,
}) => {


  return (
    <nav class="dashboard-nav-list" >

      {
        path.map((p, index) => {
          if (p.dropdown && !Permissions(usuario.rol, p.path)) {
            return (
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header ">
                    <button
                      class="dashboard-nav-item accordion-button collapsed bg-transparent border-0 pe-0 active"
                      style={{ paddingLeft: "45px" }}
                      type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <span dangerouslySetInnerHTML={{ __html: p.icon }}></span>
                      <span className={hidden ? "d-none" : "text-white"} >{p.title}</span>
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <ul class="list-group">
                        {
                          p.nav_dropdown.map((n, index) => (
                            <li className="list-group-item bg-transparent border-0">
                              <a class="dropdown-item" href={n.path}>
                                <span dangerouslySetInnerHTML={{ __html: n.icon }}></span>
                                {n.title}
                              </a>
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

            )
          }

          if(!Permissions(usuario.rol, p.path)){
            return (
              <a
                href={p.path}
                class={p.path == pathname ? "dashboard-nav-item active" : "dashboard-nav-item"} style={{ paddingLeft: "50px" }}
              >
                <span dangerouslySetInnerHTML={{ __html: p.icon }}></span>
                <span className={hidden ? "d-none" : "text-white"}>{p.title}</span>
              </a>
            )
          }
        })
      }

      <div class="nav-item-divider"></div>
      <a
        href="#"
        class="dashboard-nav-item d-none">
        <i class="fas fa-sign-out-alt"></i> Logout
      </a>
    </nav>
  )
}

export default SidebarComponent