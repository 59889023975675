import {
  CCREGISTRO_ERROR,
  CCREGISTRO_EXITO,
  CCOBTENER_CONSECIONES,
  CCACTUALIZAR_CONCESION,
  CCSELECCIONAR_CONSECION,
  CCELIMINAR_CONCESION,
  CCCARGANDO,
  CCORDENAR_FILTRAR_CONSECIONES,
  CCDESELECCIONAR_CONCESION,
} from "../../types";

const concesionesReducer = (state, action) => {
  switch (action.type) {
    case CCDESELECCIONAR_CONCESION:
      return {
        ...state,
        concesionSeleccionada: null,
      };
    case CCORDENAR_FILTRAR_CONSECIONES:
      return {
        ...state,
        resultado: action.payload,
      };
    case CCCARGANDO:
      return {
        ...state,
        cargando: action.payload,
      };

    case CCELIMINAR_CONCESION:
      return {
        ...state,
        concesiones: state.concesiones.filter(
          (concesion) => concesion.id !== action.payload
        ),
        resultado: state.resultado.filter(
          (result) => result.id !== action.payload
        ),
      };
    case CCSELECCIONAR_CONSECION:
      return {
        ...state,
        concesionSeleccionada: action.payload,
      };

    case CCACTUALIZAR_CONCESION:
      return {
        ...state,
        concesiones: state.concesiones.map((concesion) =>
          concesion.id === action.payload.id ? action.payload : concesion
        ),
      };

    case CCOBTENER_CONSECIONES:
      return {
        ...state,
        concesiones: action.payload,
        resultado: action.payload,
        cargando: false,
      };

    case CCREGISTRO_EXITO:
      return {
        ...state,
        mensaje: { msg: null, class: null },
      };

    case CCREGISTRO_ERROR:
      return {
        ...state,
        mensaje: action.payload,
      };

    default:
      return state;
  }
};

export default concesionesReducer;
