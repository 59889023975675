
export const links = (pagina, paginas, onClick) => {
  //console.log(paginas)

  let result = []
  for (let i = 1; i <= paginas; i++) {
    if(i !== pagina && i > pagina && i < pagina+3 && i !== paginas){
        result.push(i)
    }
  }

  return result;
};
