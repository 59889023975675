import { tipoConcesion } from "../../shared/Helpers/CalculosConcesiones";

const Tabla_concesiones = ({data, onClick}) => {

  return ( <div className="">
  <table className="table table-hover align-middle" style={{
        overflowY: "auto"
      }}>
    <thead className="border-0">
      <tr className="tr_sticky">
        <th className="border-0 fs-5 p-1"><div className="border p-1" style={{borderRadius: "10px"}}>Acciones</div></th>
        <th className="border-0 fs-5 p-1"><div className="border p-1" style={{borderRadius: "10px"}}>Cliente</div></th>
        <th className="border-0 fs-5 p-1"><div className="border p-1" style={{borderRadius: "10px"}}>Nº Credito</div></th>
        <th className="border-0 fs-5 p-1"><div className="border p-1" style={{borderRadius: "10px"}}>Monto</div></th>
        <th className="border-0 fs-5 p-1"><div className="border p-1" style={{borderRadius: "10px"}}>Cuotas</div></th>
        <th className="border-0 fs-5 p-1"><div className="border p-1" style={{borderRadius: "10px"}}>Concesión</div></th>
        <th className="border-0 fs-5 p-1"><div className="border p-1" style={{borderRadius: "10px"}}>Estado</div></th>
      </tr>
    </thead>
    <tbody className="border-0">
      {
        data.map((concesion) => (
          <tr >
            <td className="border-0">
              
          <label className="btn btn-danger me-1 mb-1" title="Eliminar?">
          <i class="bi bi-trash"></i>
          <button className="d-none"
          name="delete_concesion"
          onClick={(event) => onClick(event, concesion)}></button>  
          </label> 
            </td>
            <td className="border-0">{concesion.persona.apellido+" "+concesion.persona.nombre}</td>
            <td className="border-0">{concesion.credito.id}</td>
            <td className="border-0">{concesion.credito.monto}</td>
            <td className="border-0">{concesion.credito.cuotas}</td>
            <td className="border-0">{tipoConcesion(
              concesion.id_tipoconcesion
            )}</td>
            <td className="border-0">{
              concesion.id_estado == 1
              ? <span className="text-muted">Vigente</span>
              : concesion.id_estado == 2
              ? <span className="text-danger">Vencida</span>
              : concesion.id_estado == 3
              ? <span className="text-success">Finalizada</span>
              : concesion.id_estado == 4
              ? <span className="text-danger">Baja</span>
              : ""
            }</td>
          </tr>
        ))
      }
    </tbody>
    <tfoot></tfoot>
  </table>
</div>
  );
}

export default Tabla_concesiones