import { useContext, useState } from "react";
import { format, add, sub } from "date-fns";
import { ApiPagos } from "../../Helpers/ApiPagos";
import { useEffect } from "react";
import Tabla_pagos from "./tablas/Tabla";
import Filtro from "./filtro/Filtro";
import { useHistory } from "react-router";
import Tabla_pagos_judicial from "./tablas/TablaJudicial";
import apiAxios from "../../config/ApiAxios";
import { AuthContext } from "../shared";

const Reportes = () => {
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;
  const hoy = format(new Date(), "yyy-MM-dd").toString();
  const [steps, setSteps] = useState(0);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  //Modals
  const [judicial, setJudicial] = useState(false);
  const [modalDetalleCredito, setModalDetalleCredito] = useState(false);

  //
  const [form, setForm] = useState({});

  //params
  const [params_pago, setParamsPago] = useState({
    nro_documento: "",
    search: "",
    start_date: hoy,
    end_date: hoy,
    estado: ["TOTAL", "EXCEDENTE", "PARCIAL"],
    id_cajero: usuario.id
  });

  const [params_pago_judicial, setParamsPagoJudicial] = useState({
    id: "",
    buscar: "",
    fecha: [hoy, hoy],
    estado: ["TOTAL", "EXCEDENTE", "PARCIAL"],
  });

  //InitialStates
  const [pagos, setPagos] = useState(null);
  const [pagosJudicial, setPagosJudicial] = useState(null);

  //Eventos
  const onClick = (e, value) => {
    switch (e.target.name) {
      case "ver_credito":
        {
          history.push("/creditos/ver/" + value.id);
        }
        break;
      case "estado":
        {
          let result = params_pago.estado;
          let state = false;
          let key = 0;

          console.log(value);

          result.map((campos, index) => {
            if (value == campos) {
              state = true;
              key = index;
            }
          });

          if (state) {
            delete result[key];
            result = result.filter((item) => {
              return item != null;
            });
          } else {
            result.push(value);
          }

          setParamsPago({
            ...params_pago,
            estado: result,
          });
        }
        break;
    }
  };

  const onChange = (e) => {
    switch (e.target.name) {
      //Filtro
      case "fecha":
        {
        }
        break;
    }
  };

  //Pagos
  useEffect(() => {
    const load = async () => {
      setLoading(true);
      const response = await apiAxios.get("/reportes/pagos", {
        params: params_pago,
      });
      if (response.status == 200 || response.status == 201) {
        setPagos(response.data.data);
        setLoading(false);
      }
    };
    if (!judicial) {
      load();
    }
  }, [params_pago, steps, judicial]);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      const response = await apiAxios.get("/reportes/pagos/judicial", {
        params: params_pago,
      });
      if (response.status == 200 || response.status == 201) {
        setPagosJudicial(response.data.data);
        setLoading(false);
      }
    };

    if (judicial) {
      load();
    }
  }, [params_pago, steps, judicial]);

  /* Logs result Apis */
  //console.log(pagosJudicial);
  return (
    <div className="container-md min-vh-100" style={{ maxWidth: "2000px" }}>
      <div className="fw-bold text-uppercase mb-5">
        <h3 className="text-nowrap me-3 fw-bold">REPORTE DE CAJA</h3>
      </div>

      <div className="row">
        <div className="col-md-12">
          <Filtro
            judicial={judicial}
            setJudicial={setJudicial}
            params={params_pago}
            setParams={setParamsPago}
            onClick={onClick}
            loading={loading}
          />
        </div>
        <div className="col-md-12">
          {!judicial && pagos ? (
            <Tabla_pagos data={pagos} onClick={onClick} />
          ) : (
            ""
          )}

          {judicial && pagosJudicial ? (
            <Tabla_pagos_judicial data={pagosJudicial} onClick={onClick} />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Reportes;
