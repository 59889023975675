import {
  REGISTRO_ERROR,
  REGISTRO_EXITO,
  ELIMINAR_PERSONA,
  SELECCIONAR_PERSONA,
  DESELECCIONAR_PERSONA,
  OBTENER_PERSONAS,
  CONSEGUIR_PERSONA,
  ORDENAR_FILTRAR_PERSONAS,
  CARGANDO,
  SELECCIONAR_PRECALIFICAR
} from "../../types";

const personasReducer = (state, action) => {
  switch (action.type) {
    case SELECCIONAR_PRECALIFICAR:
      return {
        ...state,
        SeleccionadoPrecalificar: action.payload,
      };
   
    case CARGANDO:
      return {
        ...state,
        cargando: action.payload,
      };

    case ORDENAR_FILTRAR_PERSONAS:
      return {
        ...state,
        resultado: action.payload,
      };

    case CONSEGUIR_PERSONA:
      return {
        ...state,
        personaSeleccionada: action.payload,
      };

    case ELIMINAR_PERSONA:
      return {
        ...state,
        personas: state.personas.filter(
          (persona) => persona.id !== action.payload
        ),
        resultado: state.resultado.filter(
          (persona) => persona.id !== action.payload
        ),
      };

    case SELECCIONAR_PERSONA:
      return {
        ...state,
        personaSeleccionada: state.payload
        /* personaSeleccionada: state.personas.filter(
          (persona) => persona.id === action.payload
        )[0], */
      };
    case DESELECCIONAR_PERSONA:
      return {
        ...state,
        personaSeleccionada: null,
      };

    case OBTENER_PERSONAS:
      return {
        ...state,
        personas: action.payload.personas,
        resultado: action.payload.personas,
        cargando: false,
      };

    case REGISTRO_EXITO:
      return {
        ...state,
        mensaje: { msg: null, class: null },
      };

    case REGISTRO_ERROR:
      return {
        ...state,
        mensaje: action.payload,
      };

    default:
      return state;
  }
};

export default personasReducer;
