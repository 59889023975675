const Filtro = ({ setForm, form, setConsultar, fetch }) => {

  const onChange = (e) => {
    switch (e.target.name) {
      case "estado":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value,
            page: 1,
          });

          let params = {
            ...form,
            [e.target.name]: e.target.value,
            page: 1,
          }

          fetch(params)
          //setConsultar(true)
        }
        break;
    }
  };

  return (
    <div className="row">
      <div className="col-md-3 mb-3">
        <label>
          Estado de tus calificaciones
        </label>
        <select class="form-select rounded-pill" aria-label="Default select example"
        name="estado"
        onChange={(event) => onChange(event)}>
          <option selected>Selecciona un estado...</option>
          <option value="Todos">Todos</option>
          <option value="Solicitada">Solicitadas</option>
          <option value="Aceptada">Aceptada</option>
          <option value="Pendiente">Pendiente</option>
          <option value="Observada">Observada</option>
          <option value="Rechazada">Rechazada</option>
          <option value="En espera">En Espera</option>
        </select>
      </div>
    </div>
  );
};

export default Filtro;
