import React, { useContext, useState } from "react";
import { TasasContext, Tabla } from "../shared/";
import AccionesListarUsuarios from "./AccionesListarTasas";
import Tasa from "./Tasa";

const ListarTasas = ({ usuarios }) => {
  const [columnas] = useState([
    { label: "Descripcion", nombre: "descripcion" },
    { label: "Cuotas", nombre: "cuotas" },
    { label: "TNA", nombre: "tna" },
    { label: "TEA", nombre: "tea" },
    { label: "CFT", nombre: "cft" },
    { label: "Premium", nombre: "premium" },
  ]);

  const tasasContext = useContext(TasasContext);
  const { resultado, ordenarFiltrar } = tasasContext;

  const [filtros, setFiltros] = useState({
    descripcion: "",
    cuotas: "",
    tna: "",
    tea: "",
    cft: "",
    premium: "",
  });

  return (
    <Tabla
      registrosPorPagina={20}
      itemsOriginal={usuarios}
      itemsResultado={resultado}
      columnas={columnas}
      filtros={filtros}
      setFiltros={setFiltros}
      fnordenarFiltrar={ordenarFiltrar}
      AccionesListar={AccionesListarUsuarios}
      TemplateFila={Tasa}
    />
  );
};

export default ListarTasas;
