import React from "react";
import Domicilio from "./Domicilio";
import { TituloTabla } from "../../shared/Estilos";
import { clienteAxios, useLog, Swal } from "../../shared/";

const ListarDomicilios = ({
  domicilios,
  openModal,
  setSeleccionado,
  setConsultar,
  soloLectura
}) => {

  const [InsertarLog] = useLog(null);


  const borrar = async (id) => {
    try {
      await clienteAxios.delete("/personasdomicilio", {
        params: { id },
      });
      InsertarLog( "eliminar", "Eliminacion de Domicilio");
      setConsultar(true);
    } catch (e) {}
  };

  const editar = (domicilio) => {
    setSeleccionado(domicilio);
    openModal();
  };

  const eliminar = (domicilio) => {
    Swal.fire({
      title: "Esta Seguro que desea Eliminar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        borrar(domicilio.id);
      }
    });
  };

  return (
    <div className="table-responsive">
      <table className="table table-striped" style={{ backgroundColor: "white" }}>
        <TituloTabla>
          <tr>
            <th scope="col" className="text-center">
              Calle
            </th>
            <th scope="col" className="text-center">
              Número
            </th>
            <th scope="col" className="text-center">
              Provincia
            </th>
            <th scope="col" className="text-center">
              Ciudad
            </th>
            <th scope="col" className="text-center">
              Barrio
            </th>
            <th scope="col" className="text-center">
              Departamento
            </th>
            <th scope="col" className="text-center">
              Piso
            </th>
            <th scope="col" className="text-center">
              Codigo Postal
            </th>
            <th scope="col" className="text-center">
              Referencia
            </th>
            <th scope="col" className="text-center">
              Principal
            </th>
            <th scope="col" className="text-center">
              Acciones
            </th>
          </tr>
        </TituloTabla>
        <tbody>
          {domicilios.map((domicilio) => (
            <Domicilio
              key={domicilio.id}
              domicilio={domicilio}
              editar={editar}
              eliminar={eliminar}
              soloLectura={soloLectura}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListarDomicilios;
