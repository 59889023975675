import { format } from "date-fns";
import { useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const Filtro = ({
  params,
  judicial,
  setJudicial,
  setParams,
  loading
}) => {
  //Initial States
  const [param, setParam] = useState(params)
  const estados = ["TOTAL", "PARCIAL", "EXCEDENTE", "BAJA"];

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "estado": {
        let result = param.estado;
        let state = false;
        let key = 0;


        result.map((campos, index) => {
          if(value == campos){
            state = true;
            key = index;
          } 
        })


        if(state){
          delete(result[key]);
          result = result.filter((item) => {
            return item != null;
          });
        } else {
          result.push(value)
        }

        setParam({
          ...param,
          estado: result
        })
      } break;
    }
  }

  const onChange = (e) => {
    switch (e.target.name) {
      case "gasto-judicial":
        {
          setJudicial(e.target.checked ? true : false);
          //setParams({
          //  ...params,
          //  [e.target.name]: e.target.checked ? 1 : 0
          //})
        }
        break;
      case "search":
        {
          setParam({
            ...param,
            search: e.target.value,
          });
        }
        break;
      case "nro_documento":
        {
          setParam({
            ...param,
            nro_documento: e.target.value,
          });
        }
        break;
      case "start_date":
        {
          setParam({
            ...param,
            start_date: e.target.value,
          });
        }
        break;
      case "end_date":
        {
          setParam({
            ...param,
            end_date: e.target.value,
          });
        }
        break;
    }
  };

  console.log(params);
  return (
    <div className="row">
      <div className="col-md-2 col-12">
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label fw-bold">
            FECHA DE INICIO
          </label>
          <input
            type="date"
            class="form-control py-3"
            style={{ borderRadius: "20px" }}
            defaultValue={params.start_date}
            name="start_date"
            onChange={(event) => onChange(event)}
            disabled={loading}
          />
        </div>
      </div>
      <div className="col-md-2 col-12">
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label fw-bold">
            FECHA DE FIN
          </label>
          <input
            type="date"
            class="form-control py-3"
            style={{ borderRadius: "20px" }}
            defaultValue={params.end_date}
            name="end_date"
            onChange={(event) => onChange(event)}
            disabled={loading}
          />
        </div>
      </div>

      <div className="col-md-2 col-12">
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label fw-bold">
            DNI PERSONA
          </label>
          <input
            type="text"
            class="form-control py-3"
            style={{ borderRadius: "20px" }}
            name="nro_documento"
            onChange={(event) => onChange(event)}
            disabled={loading}
          />
        </div>
      </div>

      <div className="col-md-3 col-12">
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label fw-bold">
            ESTADOS
          </label>
          <div>
            <div class="btn-group" role="group" aria-label="Basic example">
              <button
                type="button"
                class={
                  param.estado.indexOf("TOTAL") > -1
                    ? "btn btn-outline-primary active"
                    : "btn btn-outline-primary"
                }
                name="estado"
                onClick={(e) => onClick(e, "TOTAL")}
              >
                TOTAL
              </button>
              <button
                type="button"
                class={
                  param.estado.indexOf("PARCIAL") > -1
                    ? "btn btn-outline-primary active"
                    : "btn btn-outline-primary"
                }
                name="estado"
                onClick={(e) => onClick(e, "PARCIAL")}
              >
                PARCIAL
              </button>
              <button
                type="button"
                class={
                  param.estado.indexOf("EXCEDENTE") > -1
                    ? "btn btn-outline-primary active"
                    : "btn btn-outline-primary"
                }
                name="estado"
                onClick={(e) => onClick(e, "EXCEDENTE")}
              >
                EXCEDENTE
              </button>
              <button
                type="button"
                class={
                  param.estado.indexOf("BAJA") > -1
                    ? "btn btn-outline-primary active"
                    : "btn btn-outline-primary"
                }
                name="estado"
                onClick={(e) => onClick(e, "BAJA")}
              >
                BAJA
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-3 col-12">
        <label for="exampleFormControlInput1" class="form-label fw-bold">
          GASTO JUDICIAL
        </label>
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
            name="gasto-judicial"
            onChange={(e) => onChange(e)}
            checked={judicial}
            disabled={loading}
          />
          <label class="form-check-label" for="flexSwitchCheckDefault">
            Filtrar por gastos judiciales
          </label>
        </div>
      </div>

      <div className="col-md-12 d-flex">
        <div className="mb-3 d-flex">
          {" "}
          <input
            type="text"
            class="form-control"
            style={{ borderRadius: "20px", width: "400px" }}
            placeholder="Busca el pago utilizando el nombre del cliente."
            aria-describedby="basic-addon2"
            autoComplete="off"
            name="search"
            onChange={(event) => onChange(event)}
            disabled={loading}
          />
          <span
            class="input-group-text bg-transparent border-0"
            id="basic-addon1"
          >
            <i class="bi bi-search fs-3"></i>
          </span>
        </div>
      </div>
      <div className="col-md-12">
        <div className="mb-3 h-100">
          <label className="btn btn-primary">
                FILTRAR
            <button className="d-none"
            onClick={(e) => setParams({...param})}
            ></button>
          </label>
        </div>
      </div>
      <div className="col-md-12">
      <div className="mb-3">
          <label
            className="btn btn-outline-dark"
            title="Descargar reporte formato Excel"
          >
            <i class="bi bi-filetype-xls fs-3"></i>
            <ReactHTMLTableToExcel
              id="buttonDownloadExcel"
              className="d-none"
              table="tabla_caja"
              filename={
                "cierre-caja-" + format(new Date(), "d-M-yyyy").toString()
              }
              sheet="pagina 1"
              buttonText="Exportar tabla"
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default Filtro;
