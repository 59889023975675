import React, { useState, useContext, useEffect } from "react";
import { Input, BotoneraForm, Select } from "../shared/FormsElements";
import { useRest, Spinner, TasasContext, useAlerta } from "../shared/";

const FormTasa = (props) => {
  let idBien;

  if (props.match.params.id) {
    idBien = props.match.params.id;
  } else {
    idBien = null;
  }

  const tasasContext = useContext(TasasContext);
  const {
    usuarioSeleccionado,
    ocultarMensaje,
    registrarUsuario,
    seleccionarUsuario,
    deseleccionarUsuario,
    mensaje,
  } = tasasContext;

  const [inicio, setInicio] = useState(true);
  const [consultar, setConsultar] = useState(true);

  const [setAlerta, MostrarAlerta] = useAlerta(null);
  const [loading, load] = useRest();

  const [DatosForm, LeerForm] = useState({
    descripcion: "",
    cuotas: "",
    tna: "",
    tea: "",
    cft: "",
    premium: "",
  });

  const { descripcion, cuotas, tna, tea, cft, premium } = DatosForm;

  useEffect(() => {
    const cargar = () => {
      if (consultar) {
        setConsultar(false);

        const api = {
          url: "/Tasa/get",
          type: "get",
          datosJson: null,
          getParams: { id: idBien },
          funcion: seleccionarUsuario,
        };
        /* eslint-disable */
        load(api);
      }
    };

    const alertar = () => {
      if (loading) {
        setAlerta(null);
      }
      if (inicio) {
        // eslint-disable-next-line
        ocultarMensaje();
        setInicio(null);
        setAlerta(null);
      } else {
        if (mensaje.msg && !loading) {
          setAlerta({ msg: mensaje.msg, class: mensaje.class });
        }
      }
    };

    deseleccionarUsuario();
    if (idBien !== null) {
      cargar();
    }

    alertar();

    if (usuarioSeleccionado !== null) {
      LeerForm(usuarioSeleccionado);
    }
  }, [mensaje, loading, inicio, usuarioSeleccionado, idBien]);

  useEffect(() => {
    const mytea = calcularTEA(tna);
    const mycft = calcularCFT();

    LeerForm({
      ...DatosForm, tea: mytea, cft: mycft
    });
  }, [tna]);

  const sets = [
    {
      label: "Descripcion",
      type: "text",
      name: "descripcion",
      placeholder: "ingresar en Descripcion",
      valor: descripcion,
    },
    {
      label: "Cuotas",
      type: "number",
      name: "cuotas",
      placeholder: "ingresar el Cuotas",
      valor: cuotas,
    },
    {
      label: "TNA",
      type: "number",
      name: "tna",
      placeholder: "ingresar TNA",
      valor: tna,
    },
    {
      label: "TEA",
      type: "text",
      name: "tea",
      placeholder: "",
      valor: tea,
      disabled: true
    },
    {
      label: "CFT",
      type: "text",
      name: "cft",
      placeholder: "",
      valor: cft,
      disabled: true
    },
  ];

  const Insertar = () => {
    
    const api = {
      url: "/Tasa/add",
      type: "post",
      datosJson: {
        descripcion,
        cuotas,
        tna,
        tea,
        cft,
        premium
      },
      getParams: null,
      funcion: registrarUsuario,
    };

    load(api);
    setTimeout(() => {
      props.history.push("/tasas");
    }, 2000);
  };

  const Actualizar = () => {
    
    const api = {
      url: "/Tasa/update",
      type: "put",
      datosJson: {
        id: idBien,
        descripcion,
        cuotas,
        tna,
        tea,
        cft,
        premium
      },
      getParams: null,
      funcion: registrarUsuario,
    };

    load(api);
    setTimeout(() => {
      props.history.push("/tasas");
    }, 2000);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (
      descripcion.trim() === "" ||
      cuotas.trim() === "" ||
      tna.trim() === "" ||
      tea.trim() === "" ||
      cft.trim() === ""
    ) {
      setAlerta({ msg: "Todos los campos son obligatorios", class: "danger" });
      return;
    }

    if (!idBien) {
      Insertar();
    } else {
      Actualizar();
    }
  };

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });
  };

  const funcionCancelar = () => {
    props.history.push("/tasas");
  };

  const calcularTEA =(tna)=>{
    const porctna = tna / 100;
    const paso2 = (porctna / 12) + 1;
    const paso3 = Math.pow(paso2, 12) -1;
    return paso3.toFixed(2);
  }

  const calcularCFT =()=>{
    return "0.00";
  }


  return (
    <div className="center-block">
      <br></br>
      <h2 className="text-center">{idBien ? "Editar " : "Agregar "} Tasa</h2>
      <br></br>
      <form onSubmit={onSubmit} className="border p-3 form">
        {sets.map((set) => (
          <Input key={set.name} sets={set} onChange={onChange} />
        ))}

        <Select
          key={"premium"}
          sets={{
            label: "Premium",
            name: "premium",
            valor: premium,
            opciones: [
              { label: "Si", value: "1" },
              { label: "No", value: "0" },
            ],
          }}
          onChange={onChange}
        />

        {loading ? <Spinner /> : <MostrarAlerta />}

        <BotoneraForm funcionCancelar={funcionCancelar} valorfuncion={null} />
      </form>
    </div>
  );
};
export default FormTasa;
