import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { NavItem } from "react-bootstrap";
import { AuthContext } from "../../shared";

const ModalNotificacion = ({
  user,
  usuarios,
  onClick,
  setForm,
  form,
  search,
  setSearch,
}) => {


  const autocomplete = {
    top: "150px",
    zIndex: "4",
  };
  //http://localhost/SISTEMA-CREDITO/backend-old/public/Notificaciones/add

  //Eventos
  const onChange = (e) => {
    switch (e.target.name) {
      case "search":
        {
          setSearch(e.target.value);
        }
        break;
      case "asunto":
      case "mensaje":
        setForm({
          ...form,
          [e.target.name]: e.target.value,
        });
        break;
    }
  };

  //Logs
  //console.log(usuarios)
  return (
    <div>
      
      <div class="body p-4">
            <div className="row">
              <div className="col-md-12">
                <div className="fs-4 d-flex mb-2">
                  <div className="me-4"> Para </div>
                  <div className="row">
                    {form.user_to.map((para, index) => (
                      <div
                        className="col-auto fs-5 border mb-1  me-2 p-2"
                        style={{ borderRadius: "10px" }}
                      >
                        {" "}
                        <i class="me-1 bi bi-tags"></i>
                        {para}{" "}
                        <label className="btn btn">
                          X
                          <button
                            className="d-none"
                            name="delete_user_to"
                            onClick={(event) => onClick(event, index)}
                          ></button>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="..."
                    name="search"
                    onChange={(event) => onChange(event)}
                  />
                    <span class="input-group-text btn " id="basic-addon2"><i class="bi bi-search"></i></span>
                </div>
                <div className="mb-3">
                  {search.trim() !== "" ? (
                    <ul
                      class="list-group w-100 m-0 text-center "
                      style={autocomplete}
                    >
                      {usuarios.map((persona) => {
                        if (
                          persona.name
                            .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
                            .toUpperCase()
                            .search(
                              search
                                .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
                                .toUpperCase()
                            ) !== -1
                        ) {
                          return (
                            <li class="list-group-item w-100 p-0">
                              <label className="border-0  btn btn-outline-info text-dark w-100 d-flex justify-content-start">
                                <div className="me-2">{"Nombre: "+persona.name+" Rol: "+persona.detalle}</div>
                                <button
                                  title="Seleccionar persona"
                                  className="d-none"
                                  name="user_to"
                                  onClick={(event) =>
                                    onClick(event, persona.detalle)
                                  }
                                ></button>
                              </label>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
                <div className="fs-4 d-flex mb-3">
                  <span>Asunto </span>
                  <input
                    type="email"
                    class="form-control ms-2"
                    placeholder="..."
                    name="asunto"
                    onChange={(event) => onChange(event)}
                  />
                </div>
                <div action="">
                  <textarea
                    className="form-control"
                    name="mensaje"
                    onChange={(event) => onChange(event)}
                    id=""
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
    </div>
  );
};

export default ModalNotificacion;
