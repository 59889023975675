const Filtro = ({ form, setForm, params, setParams, setModalPuntoVenta, setSelect }) => {
  const onClick = (e, value) => {
    switch (e.target.name) {
      case "modal_punto_venta":
        {
          setModalPuntoVenta(true);

          setSelect(value);
        }
        break;
    }
  };

  const onChange = (e) => {};

  return (
    <div className="row">
      <div className="col-md-3 mb-3">
        <label className="btn btn-primary rounded-pill">
          <i className="fa fa-plus-circle me-1"></i>
          Agregar Punto de venta
          <button className="d-none"
          name="modal_punto_venta"
          onClick={(event) => onClick(event, null)}></button>
        </label>
      </div>
      <div className="col-md-3 mb-3">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Busca tu punto de venta..."
            aria-describedby="basic-addon2"
          />
          <span class="ms-1 input-group-text btn-primary btn rounded-pill" id="basic-addon2">
          <i class="bi bi-search"></i>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Filtro;
