import React, { useState, useContext, useEffect } from "react";
import { Input, BotoneraForm, Select } from "../../shared/FormsElements";
import { Spinner, useAlerta, PersonasContext, clienteAxios, useLog } from "../../shared/";

const FormLaboral = ({ idpersona, closeModal, Seleccionado }) => {
  const personaContext = useContext(PersonasContext);
  const { ocultarMensaje, mensaje } = personaContext;

  const [inicio, setInicio] = useState(true);
  const [loadingLocal, setLoadingLocal] = useState(null);
  const [deshabilitado, setDeshabilitado] = useState(false);

  const [setAlerta, MostrarAlerta] = useAlerta(null);
  const [InsertarLog] = useLog(null);

  const [DatosForm, LeerForm] = useState({
    relacion: "",
    empresa: "",
    actividad: "",
    fecha_inicio: "",
    descripcion: "",
    ingresos: "",
    cuit: "",
    inscripcion: "",
    id_persona: idpersona,
  });

  const {
    relacion,
    empresa,
    actividad,
    fecha_inicio,
    descripcion,
    ingresos,
    cuit,
    inscripcion,
/*     es_principal,*/  
} = DatosForm;

  useEffect(() => {
    const cargar = () => {
      if (Seleccionado) {
        LeerForm(Seleccionado);
      }
    };

    const alertar = () => {
      if (loadingLocal) {
        setAlerta(null);
      }
      if (inicio) {
        // eslint-disable-next-line
        ocultarMensaje();
        setInicio(null);
        setAlerta(null);
      } else {
        if (mensaje.msg && !loadingLocal) {
          setAlerta({ msg: mensaje.msg, class: mensaje.class });
        }
      }
    };

    cargar();

    alertar();
  }, [mensaje, inicio]);

  const Insertar = async () => {
    if (ingresos.trim() === "") {
      setAlerta({ msg: "Ingresos es obligatorio", class: "danger" });
      return;
    }

    setAlerta(null);

    try {
      setLoadingLocal(true);
      await clienteAxios.post("/personaslaboral", DatosForm);
      InsertarLog( "crear", "Creacion de Experiencia Laboral");
      setLoadingLocal(null);
      setAlerta({ msg: "Se Creo con Exito", class: "success" });
      setDeshabilitado(true);
      closeModal(false);
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const Actualizar = async () => {
    if (ingresos.trim() === "") {
      setAlerta({ msg: "Ingresos es obligatorio", class: "danger" });
      return;
    }

    setAlerta(null);

    try {
      setLoadingLocal(true);
      await clienteAxios.put("/personaslaboral", DatosForm);
      InsertarLog( "editar", "Edicion de Experiencia Laboral");
      setLoadingLocal(null);
      setAlerta({ msg: "Se Actualizo con Exito", class: "success" });
      setDeshabilitado(true);
      closeModal(false);
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!Seleccionado) {
      Insertar();
    } else {
      Actualizar();
    }
  };

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="center-block">
      <h5 className="text-center">
        {Seleccionado ? "Editar " : "Agregar "} Experiencia Laboral
      </h5>
      <form onSubmit={onSubmit} className="border p-3 form">
        
      <Select
          key={"relacion"}
          sets={{
            label: "Relacion *",
            name: "relacion",
            valor: relacion,
            opciones: [
              { label: "Informal", value: "Informal" },
              { label: "Dependencia", value: "Dependencia" },
              { label: "Independiente", value: "Independiente" },
            ],
          }}
          onChange={onChange}
        />
        
        <Input
          key={"empresa"}
          sets={{
            label: "Empresa *",
            type: "text",
            name: "empresa",
            placeholder: "Ingrese Empresa ",
            valor: empresa,
          }}
          onChange={onChange}
        />
        <Input
          key={"cuit"}
          sets={{
            label: "CUIT *",
            type: "",
            name: "cuit",
            placeholder: "Ingrese CUIT ",
            valor: cuit,
          }}
          onChange={onChange}
        />

        <Input
          key={"actividad"}
          sets={{
            label: "Actividad *",
            type: "text",
            name: "actividad",
            placeholder: "Ingrese Actividad ",
            valor: actividad,
          }}
          onChange={onChange}
        />

        <Input
          key={"fecha_inicio"}
          sets={{
            label: "Fecha Inicio *",
            type: "date",
            name: "fecha_inicio",
            placeholder: "Ingrese Fecha de Inicio ",
            valor: fecha_inicio,
          }}
          onChange={onChange}
        />

        <Input
          key={"descripcion"}
          sets={{
            label: "Descripcion *",
            type: "text",
            name: "descripcion",
            placeholder: "Ingrese Descripcion ",
            valor: descripcion,
          }}
          onChange={onChange}
        />

        <Input
          key={"ingresos"}
          sets={{
            label: "Ingresos *",
            type: "",
            name: "ingresos",
            placeholder: "Ingrese Ingresos ",
            valor: ingresos,
          }}
          onChange={onChange}
        />

        <Select
          key={"inscripcion"}
          sets={{
            label: "Inscripcion *",
            name: "inscripcion",
            valor: inscripcion,
            opciones: [
              { label: "No Inscripto", value: "No Inscripto" },
              { label: "Monotributista", value: "Monotributista" },
              { label: "Responsable Inscripto", value: "Responsable Inscripto" },
              { label: "Exento", value: "Exento" },
              { label: "Autónomo", value: "Autónomo" },
              { label: "Empleado", value: "Empleado" },
            ],
          }}
          onChange={onChange}
        />

        {loadingLocal ? <Spinner /> : <MostrarAlerta />}

        <BotoneraForm
          funcionCancelar={closeModal}
          valorfuncion={true}
          deshabilitado={deshabilitado}
        />
      </form>
    </div>
  );
};
export default FormLaboral;
