import { format } from "date-fns";
import React, { useState, useEffect, useContext } from "react";
import {
  Spinner,
  useAlerta,
  clienteAxios,
  PersonasContext,
  useHistory,
  useLog,
} from "../shared/";
import { Input, Select, BotoneraForm } from "../shared/FormsElements";

const FormDatosPersonales = ({
  id,
  SetearIdPersona,
  seleccionarTab,
  personaLoc,
  inicio,
  reiniciar,
  soloLectura,
}) => {
  const history = useHistory();
  const [setAlerta, MostrarAlerta] = useAlerta(null);
  const [loadingLocal, setLoadingLocal] = useState(null);

  const personaContext = useContext(PersonasContext);
  const { ocultarMensaje, mensaje, personaSeleccionada } = personaContext;

  const [InsertarLog] = useLog(null);

  useEffect(() => {
    const alertar = () => {
      if (loadingLocal) {
        setAlerta(null);
      }
      if (inicio) {
        // eslint-disable-next-line
        ocultarMensaje();
        reiniciar(null);
        setAlerta(null);
      } else {
        if (mensaje.msg && !loadingLocal) {
          setAlerta({ msg: mensaje.msg, class: mensaje.class });
        }
      }
    };

    const cargarDatos = async () => {
      if (personaLoc.persona) {
        LeerForm(personaLoc.persona);
      }

      if (id && personaSeleccionada) {
        seleccionarTab(1);
        LeerForm({
          id: personaSeleccionada.persona.id,
          nombre: personaSeleccionada.persona.nombre
            ? personaSeleccionada.persona.nombre
            : "",
          apellido: personaSeleccionada.persona.apellido
            ? personaSeleccionada.persona.apellido
            : "",
          tipo_documento: personaSeleccionada.persona.tipo_documento
            ? personaSeleccionada.persona.tipo_documento
            : "",
          nro_documento: personaSeleccionada.persona.nro_documento
            ? personaSeleccionada.persona.nro_documento
            : "",
          sexo: personaSeleccionada.persona.sexo
            ? personaSeleccionada.persona.sexo
            : "",
          estado_civil: personaSeleccionada.persona.estado_civil
            ? personaSeleccionada.persona.estado_civil
            : "",
          fecha_nacimiento: personaSeleccionada.persona.fecha_nacimiento
            ? personaSeleccionada.persona.fecha_nacimiento
            : "",
          nacionalidad: personaSeleccionada.persona.nacionalidad
            ? personaSeleccionada.persona.nacionalidad
            : "",
          calle: personaSeleccionada.persona.calle
            ? personaSeleccionada.persona.calle
            : "",
          numero: personaSeleccionada.persona.numero
            ? personaSeleccionada.persona.numero
            : "",
          provincia: personaSeleccionada.persona.provincia
            ? personaSeleccionada.persona.provincia
            : "",
          ciudad: personaSeleccionada.persona.ciudad
            ? personaSeleccionada.persona.ciudad
            : "",
          barrio: personaSeleccionada.persona.barrio
            ? personaSeleccionada.persona.barrio
            : "",
          departamento: personaSeleccionada.persona.departamento
            ? personaSeleccionada.persona.departamento
            : "",
          piso: personaSeleccionada.persona.piso
            ? personaSeleccionada.persona.piso
            : "",
          codigo_postal: personaSeleccionada.persona.codigo_postal
            ? personaSeleccionada.persona.codigo_postal
            : "",
        });
      }
    };

    alertar();
    cargarDatos();
  }, [mensaje, loadingLocal, personaLoc, personaSeleccionada, id]);

  const [DatosForm, LeerForm] = useState({
    id: "",
    nombre: "",
    apellido: "",
    tipo_documento: "",
    nro_documento: "",
    nro_cuit: "",
    sexo: "",
    estado_civil: "",
    fecha_nacimiento: format(new Date(), "yyyy-MM-dd").toString(),
    nacionalidad: "",
    calle: "",
    numero: "",
    provincia: "",
    ciudad: "",
    barrio: "",
    departamento: "",
    piso: "",
    codigo_postal: "",
    es_principal: true,
    history: "[]",
  });

  const {
    nombre,
    apellido,
    sexo,
    tipo_documento,
    nro_documento,
    nro_cuit,
    estado_civil,
    fecha_nacimiento,
    nacionalidad,
    calle,
    numero,
    provincia,
    ciudad,
    barrio,
    departamento,
    piso,
    codigo_postal,

  } = DatosForm;

  const sets1 = [
    {
      disabled: soloLectura,
      label: "Nombre * ",
      type: "text",
      name: "nombre",
      placeholder: "ingresar Nombre",
      valor: nombre,
    },
    {
      disabled: soloLectura,
      label: "Apellido * ",
      type: "text",
      name: "apellido",
      placeholder: "ingresar Apellido",
      valor: apellido,
    },
  ];

  const habilitarDomicilio = () => {
    if ((id && personaSeleccionada) || soloLectura) {
      return true;
    } else {
      return false;
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();

    if (!id) {
      Insertar();
    } else {
      Actualizar();
    }
  };

  const Actualizar = async () => {
    if (
      nombre.trim() === "" ||
      apellido.trim() === "" ||
      tipo_documento.trim() === "" ||
      nro_documento.trim() === "" ||
      calle.trim() === "" ||
      numero.trim() === "" ||
      provincia.trim() === "" ||
      ciudad.trim() === "" ||
      fecha_nacimiento.trim() === ""
    ) {
      setAlerta({
        msg: "Debe Completar los Campos Obligatorios",
        class: "danger",
      });
      return;
    }

    setAlerta(null);

    LeerForm({ ...DatosForm, id });
    personaSeleccionada.persona.nombre = nombre;
    personaSeleccionada.persona.apellido = apellido;
    personaSeleccionada.persona.tipo_documento = tipo_documento;
    personaSeleccionada.persona.nro_documento = nro_documento;
    personaSeleccionada.persona.sexo = sexo;
    personaSeleccionada.persona.estado_civil = estado_civil;
    personaSeleccionada.persona.fecha_nacimiento = fecha_nacimiento;
    personaSeleccionada.persona.nacionalidad = nacionalidad;
    personaSeleccionada.persona.calle = calle;
    personaSeleccionada.persona.numero = numero;
    personaSeleccionada.persona.provincia = provincia;
    personaSeleccionada.persona.ciudad = ciudad;

    try {
      setLoadingLocal(true);
      //console.log(DatosForm);

      await clienteAxios.put("/personas", DatosForm);
      InsertarLog("editar", "Edicion de Persona");
      //SetearIdPersona(result.data.idpersona, DatosForm);
      setLoadingLocal(null);
      setAlerta({ msg: "Se Actualizo La Persona con Exito", class: "success" });
      //seleccionarTab(2);
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const Insertar = async () => {

    if (
      nombre.trim() === "" ||
      apellido.trim() === "" ||
      tipo_documento.trim() === "" ||
      nro_documento.trim() === "" ||
      calle.trim() === "" ||
      numero.trim() === "" ||
      provincia.trim() === "" ||
      ciudad.trim() === "" ||
      fecha_nacimiento.trim() === ""
    ) {
      setAlerta({
        msg: "Debe Completar los Campos Obligatorios",
        class: "danger",
      });
      return;
    }

    let año = format(new Date(), "dd-MM-yyyy").toString().split("-")
    let añoNacimiento = fecha_nacimiento.split("-")
    let años = parseInt(año[2]) - parseInt(añoNacimiento[0]);

    if (parseInt(años) < 18) {
      return setAlerta({
        msg: "El cliente no es mayor de edad",
        class: "warning",
      });
    }

    setAlerta(null);

    try {
      setLoadingLocal(true);
      const result = await clienteAxios.post("/personas", DatosForm);
      SetearIdPersona(result.data.idpersona, DatosForm);
      setLoadingLocal(null);
      setAlerta({ msg: "Se Creo La Persona con Exito", class: "success" });
      InsertarLog(
        "crear persona",
        "Creacion de Persona",
        result.data.idpersona
      );

      return history.push("/clientes/editar/" + result.data.idpersona)
      //seleccionarTab(2);
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });
  };

  const funcionCancelar = () => {
    history.push("/clientes");
  };
  return (
    <div className="center-block">
      <form onSubmit={onSubmit} className="border p-3 form">
        <div className="row">
          <div className="col-sm">
            {sets1.map((set1) => (
              <Input key={set1.name} sets={set1} onChange={onChange} />
            ))}

            <Select
              key={"tipo_documento"}
              sets={{
                disabled: soloLectura,
                label: "Tipo de Documento *",
                name: "tipo_documento",
                valor: tipo_documento,
                opciones: [
                  { label: "DU", value: "DU" },
                  { label: "LE", value: "LE" },
                  { label: "Cedula", value: "Cedula" },
                ],
              }}
              onChange={onChange}
            />

            <Input
              key={"nro_documento"}
              sets={{
                disabled: soloLectura,
                label: "Nro. Documento * ",
                type: "number",
                name: "nro_documento",
                placeholder: "ingresar Nro. de Documento",
                valor: nro_documento,
              }}
              onChange={onChange}
            />

            <Input
              key={"cuit"}
              sets={{
                disabled: soloLectura,
                label: "Nro. CUIT * ",
                type: "number",
                name: "nro_cuit",
                placeholder: "ingresar Nro. de CUIT",
                valor: nro_cuit,
              }}
              onChange={onChange}
            />

            <Select
              key={"sexo"}
              sets={{
                disabled: soloLectura,
                label: "Sexo",
                name: "sexo",
                valor: sexo,
                opciones: [
                  { label: "Masculino", value: "Masculino" },
                  { label: "Femenino", value: "Femenino" },
                ],
              }}
              onChange={onChange}
            />

            <Select
              key={"estado_civil"}
              sets={{
                disabled: soloLectura,
                label: "Estado Civil",
                name: "estado_civil",
                valor: estado_civil,
                opciones: [
                  { label: "Soltero/a", value: "Soltero/a" },
                  { label: "Casado/a", value: "Casado/a" },
                  { label: "Viudo/a", value: "Viudo/a" },
                  { label: "Divorciado/a", value: "Divorciado/a" },
                ],
              }}
              onChange={onChange}
            />

            <Input
              key={"fecha_nacimiento"}
              sets={{
                disabled: soloLectura,
                label: "Fecha de Nacimiento * ",
                type: "date",
                name: "fecha_nacimiento",
                placeholder: "ingresar Fecha de Nacimiento",
                valor: fecha_nacimiento == "" ? new Date() : format(new Date(fecha_nacimiento + " 00:00:00"), "yyyy-MM-dd").toString(),
              }}
              onChange={onChange}
            />
            <Input
              key={"nacionalidad"}
              sets={{
                disabled: soloLectura,
                label: "Nacionalidad",
                type: "text",
                name: "nacionalidad",
                placeholder: "ingresar Nacionalidad",
                valor: nacionalidad,
              }}
              onChange={onChange}
            />
          </div>

          <div className="col-sm">
            <Input
              key={"calle"}
              sets={{
                disabled: habilitarDomicilio(), //soloLectura || personaSeleccionada ? true : false,
                label: "Calle * ",
                type: "text",
                name: "calle",
                placeholder: "ingresar Calle",
                valor: calle,
              }}
              onChange={onChange}
            />
            <Input
              key={"numero"}
              sets={{
                disabled: habilitarDomicilio(), //soloLectura || personaSeleccionada ? true : false,
                label: "Numero * ",
                type: "text",
                name: "numero",
                placeholder: "ingresar Numero",
                valor: numero,
              }}
              onChange={onChange}
            />

            <Select
              key={"provincia"}
              sets={{
                disabled: habilitarDomicilio(), //soloLectura || personaSeleccionada ? true : false,
                label: "Provincia *",
                name: "provincia",
                valor: provincia,
                opciones: [
                  { label: "BUENOS AIRES", value: "BUENOS AIRES" },
                  { label: "CORDOBA", value: "CORDOBA" },
                ],
              }}
              onChange={onChange}
            />

            <Input
              key={"ciudad"}
              sets={{
                disabled: habilitarDomicilio(), //soloLectura || personaSeleccionada ? true : false,
                label: "Ciudad * ",
                type: "text",
                name: "ciudad",
                placeholder: "ingresar Ciudad",
                valor: ciudad,
              }}
              onChange={onChange}
            />

            <Input
              key={"barrio"}
              sets={{
                disabled: habilitarDomicilio(), //soloLectura || personaSeleccionada ? true : false,
                label: "Barrio",
                type: "text",
                name: "barrio",
                placeholder: "Ingresar Barrio",
                valor: barrio,
              }}
              onChange={onChange}
            />

            <Input
              key={"departamento"}
              sets={{
                disabled: habilitarDomicilio(), //soloLectura || personaSeleccionada ? true : false,
                label: "Departamento",
                type: "text",
                name: "departamento",
                placeholder: "Ingresar Departamento",
                valor: departamento,
              }}
              onChange={onChange}
            />

            <Input
              key={"piso"}
              sets={{
                disabled: habilitarDomicilio(), //soloLectura || personaSeleccionada ? true : false,
                label: "Piso",
                type: "text",
                name: "piso",
                placeholder: "Ingresar Piso",
                valor: piso,
              }}
              onChange={onChange}
            />

            <Input
              key={"codigo_postal"}
              sets={{
                disabled: habilitarDomicilio(), //soloLectura || personaSeleccionada ? true : false,
                label: "Codigo Postal* ",
                type: "text",
                name: "codigo_postal",
                placeholder: "Ingresar Codigo Postal",
                valor: codigo_postal,
              }}
              onChange={onChange}
            />
          </div>
        </div>
        {loadingLocal ? <Spinner /> : <MostrarAlerta />}

        <BotoneraForm
          funcionCancelar={funcionCancelar}
          valorfuncion={null}
          deshabilitado={soloLectura}
        />
      </form>
    </div>
  );
};

export default FormDatosPersonales;
