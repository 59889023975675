export const Permissions = (rol, path) => {
    let hidden = true

    if(rol == "Cajero"){
        switch (path) {
            case "/estadisticas": return true
            break;
            case "/pagos": return false
            break;
            case "/reportes": return false
            break;
            case "/creditos": return true
            break;
            case "/gestion de mora/gastos": return true
            break;
            case "/prestamos/calculadora": return true
            break;
            case "/usuarios": return true
            break;
            case "/tasas": return true
            break;
            case "/gestion de mora": return true
            break;
        }
    }

    if(rol == "Vendedor"){
        switch (path) {
            case "/estadisticas": return true
            break;
            case "/pagos": return true
            break;
            case "/reportes": return true
            break;
            case "/creditos": return false
            break;
            case "/gestion de mora/gastos": return true
            break;
            case "/prestamos/calculadora": return false
            break;
            case "/usuarios": return true
            break;
            case "/tasas": return true
            break;
            case "/gestion de mora": return true
            break;
        }
    }

    if(rol == "Precalificador"){
        switch (path) {
            case "/estadisticas": return true
            break;
            case "/pagos": return true
            break;
            case "/reportes": return true
            break;
            case "/creditos": return true
            break;
            case "/gestion de mora/gastos": return true
            break;
            case "/prestamos/calculadora": return true
            break;
            case "/usuarios": return true
            break;
            case "/tasas": return true
            break;
            case "/gestion de mora": return true
            break;
        }
    }

    return false
}