import React from "react";
import { CuotasDetalle } from "../../../../functions/Validaciones/CuotasDetalle";
import { BotonEditar } from "../../../shared/FormsElements";

const Credito = ({ re, setCreditoSeleccionado }) => {
  const cuotasPagadas = () => {
    const cuotasobj = re.resumen.filter((item) => item.estado === "Pagadas");

    if (cuotasobj.length > 0) {
      return cuotasobj[0].cuotas;
    } else {
      return "0";
    }
  };

  const cuotasVencidas = () => {
    const cuotasobj = re.resumen.filter((item) => item.estado === "Vencidas");

    if (cuotasobj.length > 0) {
      return cuotasobj[0].cuotas;
    } else {
      return "0";
    }
  };

  return (
    <tr>
      <td className="text-center">
        <BotonEditar editar={setCreditoSeleccionado} item={re} />
      </td>
      <td className="text-center"> {re.id}</td>
      <td className="text-center">
        {re.nombre} {re.apellido}
      </td>
      <td className="text-center">{re.cuotas}</td>
      <td className="text-center">{CuotasDetalle(re.detalles, re.concesion).pagas}</td>
      <td className="text-center">{CuotasDetalle(re.detalles, re.concesion).vencidas}</td>
    </tr>
  );
};

export default Credito;
