import React, { useState, useContext, useEffect } from "react";
import { Input, BotoneraForm, Select } from "../../../shared/FormsElements";
import { Spinner, useAlerta, PersonasContext, clienteAxios, useLog } from "../../../shared/";

const FormTelefono = ({ idpersona, closeModal, Seleccionado }) => {
  const personaContext = useContext(PersonasContext);
  const { ocultarMensaje, mensaje } = personaContext;

  const [inicio, setInicio] = useState(true);
  const [loadingLocal, setLoadingLocal] = useState(null);
  const [deshabilitado, setDeshabilitado] = useState(false);

  const [InsertarLog] = useLog(null);

  const [setAlerta, MostrarAlerta] = useAlerta(null);

  const [DatosForm, LeerForm] = useState({
    tipo_telefono: "",
    nro_telefono_prefix: "",
    nro_telefono: "",
    referencia_telefono: "",
    descripcion: "",
    id_persona: idpersona,
  });

  const {
    tipo_telefono,
    nro_telefono_prefix,
    nro_telefono,
    referencia_telefono,
    descripcion,
  } = DatosForm;

  useEffect(() => {
    const cargar = () => {
      if (Seleccionado) {
        LeerForm(Seleccionado);
      }
    };

    const alertar = () => {
      if (loadingLocal) {
        setAlerta(null);
      }
      if (inicio) {
        // eslint-disable-next-line
        ocultarMensaje();
        setInicio(null);
        setAlerta(null);
      } else {
        if (mensaje.msg && !loadingLocal) {
          setAlerta({ msg: mensaje.msg, class: mensaje.class });
        }
      }
    };

    cargar();

    alertar();
  }, [mensaje, inicio]);

  const Insertar = async () => {
    if (nro_telefono.trim() === "") {
      setAlerta({ msg: "Telefono es obligatorio", class: "danger" });
      return;
    }

    setAlerta(null);

    try {
      setLoadingLocal(true);
      await clienteAxios.post("/personascontacto", DatosForm);
      InsertarLog( "crear", "Creacion de Contacto");
      setLoadingLocal(null);
      setAlerta({ msg: "Se Creo con Exito", class: "success" });
      setDeshabilitado(true);
      closeModal(false);
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const Actualizar = async () => {
    if (nro_telefono.trim() === "") {
      setAlerta({ msg: "Telefono es obligatorio", class: "danger" });
      return;
    }

    setAlerta(null);

    try {
      setLoadingLocal(true);
      await clienteAxios.put("/personascontacto", DatosForm);
      InsertarLog( "editar", "Edicion de Contacto");
      setLoadingLocal(null);
      setAlerta({ msg: "Se Actualizo con Exito", class: "success" });
      setDeshabilitado(true);
      closeModal(false);
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!Seleccionado) {
      Insertar();
    } else {
      Actualizar();
    }
  };

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="center-block">
      <h5 className="text-center">
        {Seleccionado ? "Editar " : "Agregar "} Contacto
      </h5>
      <form onSubmit={onSubmit} className="border p-3 form">

      <Select
          key={"tipo_telefono"}
          sets={{
            label: "Tipo ",
            name: "tipo_telefono",
            valor: tipo_telefono,
            opciones: [
              { label: "Celular", value: "Celular" },
              { label: "Fijo", value: "Fijo" },
            ],
          }}
          onChange={onChange}
        />

        <Input
          key={"nro_telefono_prefix"}
          sets={{
            label: "Prefijo ",
            type: "number",
            name: "nro_telefono_prefix",
            placeholder: " ",
            valor: nro_telefono_prefix,
          }}
          onChange={onChange}
        />

        <Input
          key={"nro_telefono"}
          sets={{
            label: "Nro Tel/Cel ",
            type: "text",
            name: "nro_telefono",
            placeholder: " ",
            valor: nro_telefono,
          }}
          onChange={onChange}
        />

        <Select
          key={"referencia_telefono"}
          sets={{
            label: "Referencia ",
            name: "referencia_telefono",
            valor: referencia_telefono,
            opciones: [
              { label: "Personal", value: "Personal" },
              { label: "Familiar", value: "Familiar" },
              { label: "Laboral", value: "Laboral" },
              { label: "Otro", value: "Otro" },
            ],
          }}
          onChange={onChange}
        />


        <Input
          key={"descripcion"}
          sets={{
            label: "Descripcion ",
            type: "text",
            name: "descripcion",
            placeholder: "Ingrese Descripcion",
            valor: descripcion,
          }}
          onChange={onChange}
        />

        {loadingLocal ? <Spinner /> : <MostrarAlerta />}

        <BotoneraForm funcionCancelar={closeModal} valorfuncion={true} deshabilitado={deshabilitado}/>
      </form>
    </div>
  );
};
export default FormTelefono;
