import React, { useContext, useState, useEffect } from "react";
import Swal from "sweetalert2";
import { links } from "../../functions/links";
import AlertSuccess from "../CustomAlerts/AlertSuccess";
import { Spinner, CreditosContext, clienteAxios, useHistory } from "../shared/";
import { BotonAgregar } from "../shared/Estilos";
import { Input } from "../shared/FormsElements";
import Listar from "./ListarCreditos";
import ModalEditarCredito from "./modals/ModalEditarCredito";
import Tabla_creditos from "./tablas/Tabla_creditos";


const Creditos = (props) => {
  const Context = useContext(CreditosContext);
  const { usuarios, cargando, obtenerUsuariosII } = Context;

  const history = useHistory();

  //States
  const [modalEditarCredito, setModalEditarCredito] = useState(false);
  const [select, setSelect] = useState(null);
  const [creditos, setCreditos] = useState(null);
  //Formularios
  const [DatosForm, LeerForm] = useState({ buscar: "" });

  const { buscar } = DatosForm;


  const [params, setParams] = useState({
    texto: "",

    page: 1,
    paginator: 15
  })

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });

    if (e.target.name == "buscar") {
      //obtenerUsuariosII(buscar);
      setParams({
        ...params,
        texto: e.target.value
      })
    }

  };

  //Eventos
  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "anterior": {
        if (params.page !== 1) {
          setCreditos(null);
          setParams({
            ...params,
            page: params.page - 1
          })
        }
      } break;
      case "siguiente": {
        setCreditos(null);
        setParams({
          ...params,
          page: params.page + 1
        })
      } break;
      case "link": {
        setCreditos(null);
        setParams({
          ...params,
          page: value
        })
      } break;
      case "ver_credito":
        history.push("/creditos/ver/" + value.id);
        break;
      case "editar_credito": {
        setSelect(value);
        setModalEditarCredito(true)
      } break;
      case "delete_credito": {
        //LLave de administrador
        Swal.fire({
          title: "Eliminar credito",
          html:
            "<p>¿Quieres dar de baja el siguiente Credito?</p>" +
            '<ul class="list-group"><li class="list-group-item border-0 d-flex justify-content-between"><div>Nº de Credito </div><div>' +
            value.id,
          input: "password",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Eliminar",
          showLoaderOnConfirm: true,
          preConfirm: async (result) => {
            
            let formData = new FormData();
            formData.append("id", value.id);
            formData.append("status", 'Eliminado');
            formData.append("password", result);
            
            const response = await clienteAxios({
              method: "post",
              url: "/PersonaCreditos/delete",
              data: formData,
            }).then((result) => {
                Swal.fire({
                  title: '¡Credito Eliminado!',
                  text: 'El credito se elimino correctamente',
                });

                return window.location.reload();
              })
              .catch((result) => {
                return Swal.showValidationMessage(
                  `Request failed: ${result.response.data.messages.msg}`
                );
              });
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
          console.log("ok");
        });

        /*
        Swal.fire({
          title: '¿Quieres eliminar el credito Nº'+value.id+'?',
          text: "",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Eliminar',
          cancelButtonText: "Cancelar"
        }).then(async (result) => {
          if (result.isConfirmed) {
            let formData = {
              id: value.id,
              status: 'Eliminado',
            }
    
            const result = await clienteAxios.post(
              "/PersonaCreditos/delete",
              formData
            );

            Swal.fire(
              '¡Credito Eliminado!',
              'El credito se elimino correctamente.',
              'success'
            )
    
            return window.location.reload();
          }
        })
*/

      } break;
    }
  }

  /*
  useEffect(() => {
    // eslint-disable-next-line no-console
    obtenerUsuariosII(buscar); 
  }, [buscar]);
  */

  useEffect(() => {
    const obtenerCreditos = async () => {
      try {
        const result = await clienteAxios.get(
          "/PersonaCreditos/getAll",
          { params }
        );
        setCreditos(
          result.data //.filter((result) => result.id_credito === null)
        );
        /*  console.log(result.data.creditos.filter(
          (result) => result.id_credito === null
        )); */
      } catch (e) { }
    };

    setCreditos(null)
    obtenerCreditos();
  }, [params])

  //Logs
  //console.log(creditos);
  return (
    <div className="container-md min-vh-100">
      <br></br>
      <h3 className="text-center">Gestionar Credito</h3>
      <br></br>
      <div className="row mb-3">
        <div className="col-sm">
          <div className="form-group">
            <BotonAgregar
              onClick={() => {
                props.history.push("/creditos/nuevo");
              }}
              className="btn btn-dark"
            >
              <i className="fa fa-plus-circle"></i> Agregar Credito
            </BotonAgregar>
            {/*   <BotonAgregar
              onClick={() => {
                //nuevo();
              }}
              className="btn btn-dark"
            >
              <i className="fa fa-download"></i>
            </BotonAgregar>
            <BotonAgregar
              onClick={() => {
                //nuevo();
              }}
              className="btn btn-dark"
            >
              <i className="fa fa-print"></i>
            </BotonAgregar> */}
          </div>
        </div>
        <div className="col-sm">
          <Input
            key={"buscar"}
            sets={{
              label: "",
              type: "text",
              name: "buscar",
              placeholder: "Ingrese su Busqueda",
              valor: buscar,
            }}
            onChange={onChange}
          />
        </div>
        <br></br>
      </div>


      <div className="mb-3">
        {
          creditos ? <>
          
          <div className="w-100 d-flex justify-content-between mb-3">
              <div className="btn btn-light">
                Total de registros {creditos.registros}
              </div>
              <div>
                <label className="btn btn-primary fs-5 me-2">
                  <i class="bi bi-arrow-left"></i>
                  <button className="d-none"
                    name="anterior"
                    onClick={(event) => onClick(event)}>
                  </button>
                </label>
                {
                  creditos.pagina == 1
                    ? ""
                    : <label className="btn btn-outline-primary fs-5 me-2">
                      ..1
                      <button className="d-none"
                        name="link"
                        onClick={(event) => onClick(event, 1)}>
                      </button>
                    </label>
                }
                <label className="btn btn-primary fs-5 me-2">
                  {creditos.pagina}
                  <button className="d-none"
                    name=""
                    onClick={(event) => onClick(event)}>
                  </button>
                </label>
                {
                  links(creditos.pagina, creditos.paginas).map((link) => (
                    <label className="btn btn-outline-primary fs-5 me-2">
                      {link}
                      <button
                        className="d-none"
                        name="link"
                        onClick={(event) => onClick(event, link)}
                      ></button>
                    </label>
                  ))
                }
                {
                  creditos.pagina == creditos.paginas
                    ? ""
                    : <label className="btn btn-outline-primary fs-5 me-2">
                      {creditos.paginas}
                      <button className="d-none"
                        name="link"
                        onClick={(event) => onClick(event, creditos.paginas)}>
                      </button>
                    </label>
                }
                <label className="btn btn-primary fs-5">
                  <i class="bi bi-arrow-right"></i>
                  <button className="d-none"
                    name="siguiente"
                    onClick={(event) => onClick(event)}>
                  </button>
                </label>
              </div>
            </div>
          <Tabla_creditos
            data={creditos.creditos}
            onClick={onClick}
            onChange={onChange}

            select={select}
            setSelect={setSelect}
            modalEditarCredito={modalEditarCredito}
            setModalEditarCredito={setModalEditarCredito}
          />
           <div className="w-100 d-flex justify-content-between mb-3">
              <div className="btn btn-light">
                Total de registros {creditos.registros}
              </div>
              <div>
                <label className="btn btn-primary fs-5 me-2">
                  <i class="bi bi-arrow-left"></i>
                  <button className="d-none"
                    name="anterior"
                    onClick={(event) => onClick(event)}>
                  </button>
                </label>
                {
                  creditos.pagina == 1
                    ? ""
                    : <label className="btn btn-outline-primary fs-5 me-2">
                      ..1
                      <button className="d-none"
                        name="link"
                        onClick={(event) => onClick(event, 1)}>
                      </button>
                    </label>
                }
                <label className="btn btn-primary fs-5 me-2">
                  {creditos.pagina}
                  <button className="d-none"
                    name=""
                    onClick={(event) => onClick(event)}>
                  </button>
                </label>
                {
                  links(creditos.pagina, creditos.paginas).map((link) => (
                    <label className="btn btn-outline-primary fs-5 me-2">
                      {link}
                      <button
                        className="d-none"
                        name="link"
                        onClick={(event) => onClick(event, link)}
                      ></button>
                    </label>
                  ))
                }
                {
                  creditos.pagina == creditos.paginas
                    ? ""
                    : <label className="btn btn-outline-primary fs-5 me-2">
                      {creditos.paginas}
                      <button className="d-none"
                        name="link"
                        onClick={(event) => onClick(event, creditos.paginas)}>
                      </button>
                    </label>
                }
                <label className="btn btn-primary fs-5">
                  <i class="bi bi-arrow-right"></i>
                  <button className="d-none"
                    name="siguiente"
                    onClick={(event) => onClick(event)}>
                  </button>
                </label>
              </div>
            </div>

          </>
            : <Spinner />
        }
      </div>




      {
        modalEditarCredito
          ? <ModalEditarCredito
            setModal={setModalEditarCredito}
            setSelect={setSelect}
            select={select}
          />
          : ""
      }
    </div>
  );
};

export default Creditos;
