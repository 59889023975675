const Modal = ({ id, children, width, title, onClose, onClick }) => {
  return (
    <div
      class="modal fade "
      id={id}
      tabindex="-1"
      aria-labelledby={id}
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div
        class="modal-dialog border-0 shadow"
        style={{ minWidth: width, maxWidth: width }}
      >
        <div class="modal-content border-0">
          <div class="modal-header border-0">
            <h1 class="modal-title fs-3 fw-bold text-uppercase" id={id}>
              {title}
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              name={onClose.name}
              onClick={(event) => onClose.function(event)}
            ></button>
          </div>
          <div class="modal-body">{children}</div>
          <div class="modal-footer border-0">
            {onClick ? (
              <button
                type="button"
                class="btn btn-primary text-uppercase fw-bold px-3"
                style={{ borderRadius: "20px" }}
                name={onClick.name}
                onClick={(event) => onClick.function()}
              >
                {onClick.title}
              </button>
            ) : (
              ""
            )}
            <button
              type="button"
              class="btn btn-secondary text-uppercase fw-bold px-3"
              style={{ borderRadius: "20px" }}
              data-bs-dismiss="modal"
              name={onClose.name}
              onClick={(event) => onClose.function(event)}
              id={onClose.id}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
