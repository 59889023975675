import React, { useState, useContext, useEffect } from "react";
import { BotoneraForm, Select, TextArea, Input } from "../../shared/FormsElements";
import { Spinner, useAlerta, PersonasContext, clienteAxios, useLog } from "../../shared/";

const FormDeclaracionBien = ({ idpersona, closeModal, Seleccionado }) => {
  //alert
  const personaContext = useContext(PersonasContext);
  const { ocultarMensaje, mensaje } = personaContext;
  const [inicio, setInicio] = useState(true);
  const [loadingLocal, setLoadingLocal] = useState(null);
  const [setAlerta, MostrarAlerta] = useAlerta(null);
  //alert

  const [deshabilitado, setDeshabilitado] = useState(false);

  const [InsertarLog] = useLog(null);

  const [DatosForm, LeerForm] = useState({
    tipo: "",
    descripcion: "",
    detalle: "",
    id_persona: idpersona,
  });
  const { tipo, descripcion, detalle } = DatosForm;

  useEffect(() => {
    const cargar = () => {
      if (Seleccionado) {
        LeerForm(Seleccionado);
      }
    };

    const alertar = () => {
      if (loadingLocal) {
        setAlerta(null);
      }
      if (inicio) {
        // eslint-disable-next-line
        ocultarMensaje();
        setInicio(null);
        setAlerta(null);
      } else {
        if (mensaje.msg && !loadingLocal) {
          setAlerta({ msg: mensaje.msg, class: mensaje.class });
        }
      }
    };

    cargar();

    alertar();
  }, [mensaje, inicio]);

  const Insertar = async () => {
    if (descripcion.trim() === "") {
      setAlerta({ msg: "Descripcion es obligatorio", class: "danger" });
      return;
    }

    setAlerta(null);

    try {
      setLoadingLocal(true);
      await clienteAxios.post("/personasdeclaracion", DatosForm);
      InsertarLog( "crear", "Creacion de Declaracion de Bienes");
      setLoadingLocal(null);
      setAlerta({ msg: "Se Creo con Exito", class: "success" });
      setDeshabilitado(true);
      closeModal(false);
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const Actualizar = async () => {
    if (descripcion.trim() === "") {
      setAlerta({ msg: "Descripcion es obligatorio", class: "danger" });
      return;
    }

    setAlerta(null);

    try {
      setLoadingLocal(true);
      await clienteAxios.put("/personasdeclaracion", DatosForm);
      InsertarLog( "editar", "Edicion de Declaracion de Bienes");
      setLoadingLocal(null);
      setAlerta({ msg: "Se Actualizo con Exito", class: "success" });
      setDeshabilitado(true);
      closeModal(false);
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!Seleccionado) {
      Insertar();
    } else {
      Actualizar();
    }
  };

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="center-block">
      <h5 className="text-center">
        {Seleccionado ? "Editar " : "Agregar "} Declaracion de un Bien
      </h5>
      <form onSubmit={onSubmit} className="border p-3 form">
        <Select
          key={"tipo"}
          sets={{
            label: "Tipo ",
            name: "tipo",
            valor: tipo,
            opciones: [
              { label: "Inmuebles", value: "Inmuebles" },
              { label: "Automotor", value: "Automotor" },
              { label: "Muebles", value: "Muebles" },
            ],
          }}
          onChange={onChange}
        />

        <Input
          key={"descripcion"}
          sets={{
            label: "Dominio * ",
            name: "descripcion",
            placeholder: "ingresar Dominio",
            valor: descripcion,
          }}
          onChange={onChange}
        />

        <TextArea
          key={"detalle"}
          sets={{
            label: "Detalle * ",
            name: "detalle",
            placeholder: "ingresar Detalle",
            valor: detalle,
            rows: "5",
            cols: "10",
          }}
          onChange={onChange}
        />

        {loadingLocal ? <Spinner /> : <MostrarAlerta />}

        <BotoneraForm
          funcionCancelar={closeModal}
          valorfuncion={true}
          deshabilitado={deshabilitado}
        />
      </form>
    </div>
  );
};
export default FormDeclaracionBien;
