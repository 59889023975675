import clienteAxios from "../config/axios";

export const ApiPagos = async ({
url,
params,
setLoading
}) => {
  setLoading(true);
  
  const response = await clienteAxios.get(url, { 
    params,
  }).then((result) => {
    setLoading(false);
    return result.data.pagos;
  }).catch((result) => {
    setLoading(false);
    return [];
  });

  return response
}