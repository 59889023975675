import clienteAxios from "../config/axios";

export const ApiJudiciales = async ({
  url,
  params,
  setLoading
}) => {
  setLoading(true);

  const response = await clienteAxios.get(
    url,
    {
      params: { id_persona: params.persona.id },
    }
  ).then((result) => {
    setLoading(false);
    return result.data.judiciales;
  }).catch((result) => {
    setLoading(false);
    return [];
  });

  return response
}