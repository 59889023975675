import React from 'react';

const BuscarGarante = ({item, editar}) => {
    return (
        <tr>
        <td className="text-center">{item.nombre} {item.apellido} - {item.nro_documento}</td>
        <td className="text-center">{item.estado}</td>

        <td className="text-center">
          <button
            type="button"
            className="btn btn-info btn-sm"
            style={{color: "white", borderRadius:"15px"}}
            onClick={() => {
              editar(item);
            }}
          >
            Solicitar Como Garante
          </button>
        </td>
      </tr>
    );
};

export default BuscarGarante;