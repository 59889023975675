import { useState } from "react";
import SidebarComponent from "./SidebarComponent"

const Sidebar = ({
  usuario,
  pathname,
  hidden
}) => {

  const [path, setPath] = useState([
    {
      icon: '<i class="bi bi-speedometer me-2 fs-5"></i>',
      title: "Dashboard",
      path: "/estadisticas", 
      dropdown: false,
      nav_dropdown: [],
    },
    {
      icon: '<i class="bi bi-pc-display-horizontal me-2 fs-5"></i>',
      title: "Escritorio",
      path: "/dashboard", 
      dropdown: false,
      nav_dropdown: [],
    },
    {
      icon: '<i class="bi bi-people-fill  me-2 fs-5"></i>',
      title: "Clientes",
      path: "/clientes",
      dropdown: false,
      nav_dropdown: [],
    },
    {
      icon: '<i class="bi bi-person-fill-gear text-white me-2 fs-5"></i>',
      title: "Usuarios",
      path: "/usuarios",
      dropdown: false,
      nav_dropdown: [],
    },
    {
      icon: '<i class="bi bi-calculator-fill text-white me-2 fs-5"></i>',
      title: "Tasas",
      path: "/tasas",
      dropdown: false,
      nav_dropdown: [],
    },
    {
      icon: '<i class="bi bi-clock-history text-white me-2 fs-5"></i>',
      title: "Gestion de mora",
      path: "/gestion de mora",
      dropdown: true,
      nav_dropdown: [
        {
          icon: '<i class="bi bi-credit-card-fill me-2 fs-5"></i>',
          title: "Gastos judiciales",
          path: "/gestion de mora/gastos",
        },
        {
          icon: '<i class="bi bi-bank2 me-2 fs-5"></i>',
          title: "Concesiones",
          path: "/concesiones",
        }
      ],
      hidden: false
    },
  ]);

 
  return (
    <SidebarComponent 
    path={path}
    pathname={pathname}
    hidden={hidden}
    usuario={usuario}
    />
  )
}

export default Sidebar