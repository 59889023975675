import React from "react";
import Telefono from "./Telefono";
import { TituloTabla } from "../../../shared/Estilos";
import { clienteAxios, useLog, Swal } from "../../../shared/";

const ListarTelefonos = ({
  telefonos,
  openModal,
  setSeleccionado,
  setConsultar,
  soloLectura
}) => {

  const [InsertarLog] = useLog(null);

  const borrar = async (id) => {
    try {
      await clienteAxios.delete("/personascontacto", {
        params: { id },
      });
      InsertarLog( "eliminar", "Eliminacion de Contacto");
      setConsultar(true);

    } catch (e) {}
  };

  const editar = (telefono) => {
    setSeleccionado(telefono);
    openModal();
  };

  const eliminar = (telefono) => {
    Swal.fire({
      title: "Esta Seguro que desea Eliminar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        borrar(telefono.id);
      }
    });
  };

  return (
    <div className="table-responsive">
      <table className="table table-striped" style={{ backgroundColor: "white" }}>
        <TituloTabla>
          <tr>
            <th scope="col" className="text-center">
              Tipo de teléfono
            </th>
            <th scope="col" className="text-center">
              Prefijo
            </th>
            <th scope="col" className="text-center">
              Número
            </th>
            <th scope="col" className="text-center">
              Referencia teléfono
            </th>
            <th scope="col" className="text-center">
              Descripción
            </th>
            <th scope="col" className="text-center">
              Acciones
            </th>
          </tr>
        </TituloTabla>
        <tbody>
          {telefonos.map((telefono) => (
            <Telefono
              key={telefono.id}
              telefono={telefono}
              editar={editar}
              eliminar={eliminar}
              soloLectura={soloLectura}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListarTelefonos;
