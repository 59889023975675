import React from "react";
import { BotonEliminar, BotonVer } from "../shared/FormsElements";
import { clienteAxios, Swal, useHistory } from "../shared";
import { format } from "date-fns";

const PreCalificacion = ({ item, setConsultar }) => {
  const history = useHistory();

  const borrar = async (id) => {
    try {
      await clienteAxios.delete("/PersonaPreCalificacion/remove", {
        params: { id },
      });
      setConsultar(true);
    } catch (e) {}
  };
  
  const eliminar = () => {
    Swal.fire({
      title: "Esta Seguro que desea Eliminar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        borrar(item.id);
      }
    });
  };

  const mostrar = () => {
    history.push("/precalificar/ver/" + item.id_persona);
  };

  return (
    <tr>
      <td className="text-center">
        <BotonEliminar eliminar={eliminar} item={item} />{" "}
        <BotonVer mostrar={mostrar} item={item} />
      </td>
      <td className="text-center">{item.estado}</td>
      <td className="text-center">{item.promedio}</td>
      <td className="text-center">{item.veraz}</td>
      <td className="text-center">{item.bcra}</td>
      <td className="text-center">{item.juicios}</td>
      <td className="text-center">{item.dgr}</td>
      <td className="text-center">{item.referencias_personales}</td>
      <td className="text-center">${new Intl.NumberFormat('de-DE').format(item.ingresos)}</td>
      <td className="text-center">{item.bienes}</td>
      <td className="text-center text-nowrap">$ {new Intl.NumberFormat('de-DE').format(item.solicito)}</td>
      <td className="text-center">{item.limite_x_cuota}</td>
      <td className="text-center text-nowrap">$ {new Intl.NumberFormat('de-DE').format(item.limite)}</td>
      <td className="text-center text-nowrap">{item.fecha_vencimiento
      ? format(new Date(item.fecha_vencimiento+" 00:00:00"), "dd/MM/yyyy").toString() : ""}</td>
      <td className="text-center text-nowrap">{item.fecha_analisis? format(new Date(item.fecha_analisis+" 00:00:00"), "dd/MM/yyyy").toString() : ""}</td>
    </tr>
  );
};

export default PreCalificacion;
