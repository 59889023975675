const Tabla = ({ data, setModalPuntoVenta, setSelect }) => {

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "modal_punto_venta":{
        setModalPuntoVenta(true);
        setSelect(value);
      }break;
    }
  } 

  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead className="border-0">
          <tr className="">
            <th className="border-0 fs-5 p-1">
              <div className="border p-1" style={{ borderRadius: "10px" }}>
                Id
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border p-1" style={{ borderRadius: "10px" }}>
                Nombre
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border p-1" style={{ borderRadius: "10px" }}>
                Provincia
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border p-1" style={{ borderRadius: "10px" }}>
                Ciudad
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border p-1" style={{ borderRadius: "10px" }}>
                Direccion
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border p-1" style={{ borderRadius: "10px" }}>
                Monto Limite / creditos
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border p-1" style={{ borderRadius: "10px" }}>
                Estados
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border p-1" style={{ borderRadius: "10px" }}>
                Editar
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="border-0">
          {
        data.map((venta) => (
          <tr>
            <td className="border-0">{venta.id}</td>
            <td className="border-0">{venta.nombre}</td>
            <td className="border-0">{venta.provincia}</td>
            <td className="border-0">{venta.ciudad}</td>
            <td className="border-0">{venta.direccion}</td>
            <td className="border-0">{"$ "+new Intl.NumberFormat('de-DE').format(venta.limite_credito)}</td>
            <td className="border-0">{venta.status}</td>
            <td className="border-0">
              <label className="btn btn-primary">
                Editar
                <button className="d-none"
                name="modal_punto_venta"
                onClick={(event) => onClick(event, venta)}></button>
              </label>
            </td>
          </tr>
        ))
        }
        </tbody>
        <tfoot></tfoot>
      </table>
    </div>
  );
};

export default Tabla;
