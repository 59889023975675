import { CalcularDeudaTotal, CalculoCuota, verificar_bug } from "../../../functions/FunctionCuota";
import { CreditoDetalles } from "../../../functions/Validaciones/CreditoDetalles";
import { CuotasDetalle } from "../../../functions/Validaciones/CuotasDetalle";
import { tipoConcesion } from "../../shared/Helpers/CalculosConcesiones";
const Tabla_creditos = ({ data, onClick, onChange }) => {
  //Calcular cuotas
  const cuotas = (cuotas) => {
    let result = [];

    cuotas.map((cuota) => {
      let re = CalculoCuota(cuota);
      result.push(re);
    })

    return result;
  }


  
  return ( <div className="">
  <table className="table table-hover table-striped align-middle" style={{
        overflowY: "auto"
      }}>
    <thead className="border-0">
      <tr className="tr_sticky">
        <th className="border-0 fs-5 p-1"><div className="border p-1" style={{borderRadius: "10px"}}>Acciones</div></th>
        <th className="border-0 fs-5 p-1"><div className="border p-1" style={{borderRadius: "10px"}}>Cliente</div></th>
        <th className="border-0 fs-5 p-1"><div className="border p-1" style={{borderRadius: "10px"}}>Nº Credito</div></th>
        <th className="border-0 fs-5 p-1"><div className="border p-1" style={{borderRadius: "10px"}}>Monto</div></th>
        <th className="border-0 fs-5 p-1"><div className="border p-1" style={{borderRadius: "10px"}}>Cuotas</div></th>
        <th className="border-0 fs-5 p-1"><div className="border p-1" style={{borderRadius: "10px"}}>Pagas</div></th>
        <th className="border-0 fs-5 p-1"><div className="border p-1" style={{borderRadius: "10px"}}>Vencidas</div></th>
        <th className="border-0 fs-5 p-1"><div className="border p-1" style={{borderRadius: "10px"}}>Deuda</div></th>
        <th className="border-0 fs-5 p-1"><div className="border p-1" style={{borderRadius: "10px"}}>Concesión</div></th>
        <th className="border-0 fs-5 p-1"><div className="border p-1" style={{borderRadius: "10px"}}>Estado</div></th>
      </tr>
    </thead>
    <tbody className="border-0">
      {data.map((credito) => (
        <tr className={verificar_bug(credito.detalles) ? "bg-danger" : ""}>
          <td className="border-0" >
          <label className="btn btn-danger me-1 mb-1" title="Eliminar?">
          <i class="bi bi-trash"></i>
          <button className="d-none"
          name="delete_credito"
          onClick={(event) => onClick(event, credito)}></button>  
          </label> 
          <label className="btn btn-success me-1 mb-1"  title="Editar">
          <i class="bi bi-pencil-fill"></i>
          <button className="d-none"
          name="editar_credito"
          onClick={(event) => onClick(event, credito)}></button>  
          </label>  
          <label className="btn btn-info"  title="Ver credito">
          <i class="bi bi-eye"></i>
          <button className="d-none"
          name="ver_credito"
          onClick={(event) => onClick(event, credito)}></button>  
          </label></td>
          <td className="border-0">{credito.persona.apellido+" "+credito.persona.nombre}</td>
          <td className="border-0">{credito.id}</td>
          <td className="border-0 text-nowrap">${" "} {
          new Intl.NumberFormat('de-DE').format(credito.monto)
          }</td>
          <td className="border-0">{credito.detalles.length}</td>
          <td className="border-0">
            {CuotasDetalle(credito.detalles, credito.concesion).pagas}
          </td>
          <td className="border-0">
            {CuotasDetalle(credito.detalles, credito.concesion).vencidas}
          </td>
          <td className="border-0 text-nowrap">
            ${" "} 
            {
            new Intl.NumberFormat('de-DE').format(CalcularDeudaTotal(0, cuotas(CreditoDetalles(credito).detalles)))
            }
          </td>
          <td className="border-0">
            {tipoConcesion(
              credito.concesion ? credito.concesion.id_tipoconcesion : ""
            )}
          </td>
          <td className="border-0">
            {
            credito.estado == "Pagado"
            ? <span className="text-success">Pagado</span>
            : credito.estado == "Vigente"
            ? CuotasDetalle(credito.detalles, credito.concesion).vencidas > 0
            ? <span className="text-danger">Vencida</span>
            : <span className="text-warning">Vigente</span>
            : ""
            }
          </td>
        </tr>
      ))}
    </tbody>
    <tfoot></tfoot>
  </table>
</div>
  );
};

export default Tabla_creditos;
