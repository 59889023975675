import React from "react";
import Email from "./Email";
import { TituloTabla } from "../../../shared/Estilos";
import { clienteAxios, useLog, Swal } from "../../../shared/";

const ListarEmails = ({
  emails,
  setemailSeleccionado,
  setConsultar,
  openModal,
  soloLectura
}) => {

  const [InsertarLog] = useLog(null);

  const borrar = async (id) => {
    try {
      await clienteAxios.delete("/personasemail", {
        params: { id },
      });
      InsertarLog( "eliminar", "Eliminacion de Email");
      setConsultar(true);
    } catch (e) {}
  };

  const editar = (email) => {
    setemailSeleccionado(email);
    openModal();
  };

  const eliminar = (email) => {
    Swal.fire({
      title: "Esta Seguro que desea Eliminar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        borrar(email.id);
      }
    });
  };

  return (
    <div className="table-responsive">
      <table className="table table-striped" style={{ backgroundColor: "white" }}>
        <TituloTabla>
          <tr>
            <th scope="col" className="text-center">
              Email
            </th>
            <th scope="col" className="text-center">
              Referencia
            </th>
            <th scope="col" className="text-center">
              Descripcion
            </th>
            <th scope="col" className="text-center">
              Acciones
            </th>
          </tr>
        </TituloTabla>
        <tbody>
          {emails.map((email) => (
            <Email
              key={email.id}
              email={email}
              editar={editar}
              eliminar={eliminar}
              soloLectura={soloLectura}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListarEmails;
