import { useEffect, useState } from "react";
import clienteAxios from "../config/axios";

const usePuntosVenta = (url, parametros, steps) => {
  const [puntoVenta_isLoading, setIsLoading] = useState(true);
  const [puntoVenta_message, setMessage] = useState(null); 
  const [puntoVenta_data, setData] = useState(null);

  let params = {};

  Object.keys(parametros).map((p) => {
    if(p !== "date_range"){
      if(String(parametros[p]).trim() !== ""){
        params = {
          ...params,
          [p]: parametros[p] 
        }
      }
    } else {
      if(parametros[p].length !== 0){
        params = {
          ...params,
          [p]: parametros[p] 
        } 
      }
    }
  })

  const doAxios = async () => { 
    const response = await clienteAxios.get(url, { params })
    .then((result) => {
      setIsLoading(false);
      setData(result.data.data);
      return "";
    })
    .catch((result) => {
      setIsLoading(false);
      setMessage(result.response.data.msg);
      return console.log(result.response.data.msg);
    });

    

  }


  useEffect(() => { 
    doAxios()
  },[steps, parametros])
  return {
    puntoVenta_data,
    puntoVenta_message,
    puntoVenta_isLoading,
  }
}

export default usePuntosVenta
