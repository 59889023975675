import React, { useState, useContext, useEffect } from "react";
import { BotoneraForm, Input } from "../../shared/FormsElements";
import {
  Spinner,
  useAlerta,
  PersonasContext,
  clienteAxios,
  Swal,
  AuthContext,
  useLog,
} from "../../shared/";

const FromMonto = ({item, closeModal }) => {
  const personasContext = useContext(PersonasContext);
  const { SeleccionadoPrecalificar, ocultarMensaje, mensaje } = personasContext;

  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  const [inicio, setInicio] = useState(true);
  const [loadingLocal, setLoadingLocal] = useState(null);
  //const [deshabilitado, setDeshabilitado] = useState(false);

  const [setAlerta, MostrarAlerta] = useAlerta(null);

  const [InsertarLog] = useLog(null);

  const [DatosForm, LeerForm] = useState({
    montocredito: "",
    id_persona: SeleccionadoPrecalificar? SeleccionadoPrecalificar.iddepersona? SeleccionadoPrecalificar.iddepersona : SeleccionadoPrecalificar.id : item.id,
    estado: "Solicitada",
  });

  const { montocredito, id_persona, estado } = DatosForm;

  useEffect(() => {
    const alertar = () => {
      if (loadingLocal) {
        setAlerta(null);
      }
      if (inicio) {
        // eslint-disable-next-line
        ocultarMensaje();
        setInicio(null);
        setAlerta(null);
      } else {
        if (mensaje.msg && !loadingLocal) {
          setAlerta({ msg: mensaje.msg, class: mensaje.class });
        }
      }
    };

    alertar();
  }, [mensaje, inicio]);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (montocredito.trim() === "") {
      setAlerta({ msg: "Monto es obligatorio", class: "danger" });
      return;
    }

    setAlerta(null);

    Swal.fire({
      title: "Esta Seguro que desea Solicitar Análisis Crediticio?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Solicitar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        solictar();
        localStorage.removeItem("id");
      }
    });
  };

  const solictar = async () => {
    try {
      setLoadingLocal(true);
      await clienteAxios.post("/precalificacion", {
        solicito: montocredito,
        estado,
        id_persona: id_persona? id_persona : item,
      });
      InsertarLog("crear", "Solicitud de Analisis Crediticio");
      setLoadingLocal(null);
      setAlerta({
        msg: "Se Solicito el Análisis Crediticio con Exito",
        class: "success",
      });
      notificar();
      //setDeshabilitado(true);
      closeModal(false);
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });
  };

  const cancelar = () => {
    localStorage.removeItem("id");
    closeModal();
  };

  const notificar = async () => {
    try {
      await clienteAxios.post("/Notificaciones/add", {
        user_from: usuario.id,
        user_to: ["Precalificador"],
        asunto: "Análizar",
        mensaje: `Realizar Análisis`,
        body: `${SeleccionadoPrecalificar? SeleccionadoPrecalificar.nombre : item.nombre} ${SeleccionadoPrecalificar ? SeleccionadoPrecalificar.apellido : item.apellido}`,
        link: `/precalificar/${SeleccionadoPrecalificar? SeleccionadoPrecalificar.id : item} `,
      });
    } catch (e) {}
  };

  //console.log(SeleccionadoPrecalificar);
  //console.log(item);
  return (
    <div>
      <h5 className="text-center">
        Por favor, ingrese el importe del crédito <br></br>para el cual quiere
        calificar a <br></br>
        {SeleccionadoPrecalificar? SeleccionadoPrecalificar.nombre : item.nombre} {SeleccionadoPrecalificar ? SeleccionadoPrecalificar.apellido : item.apellido}
      </h5>
      <form onSubmit={onSubmit} className="border p-3 form">
        <Input
          key={"montocredito"}
          sets={{
            label: "Monto Credito ",
            type: "number",
            name: "montocredito",
            placeholder: "Ingrese Monto del Credito a Solicitar",
            valor: montocredito,
          }}
          onChange={onChange}
        />
        {loadingLocal ? <Spinner /> : <MostrarAlerta />}
        <br></br>
        <BotoneraForm
          funcionCancelar={cancelar}
          valorfuncion={true}
          deshabilitado={null}
        />
      </form>
    </div>
  );
};

export default FromMonto;
