import { format, add, sub } from "date-fns";
import { Totals, CalcularResto } from "../../../functions/FunctionReportes";

const Tabla_pagos = ({ data, onClick }) => {
  const totales = Totals(data);
  return (
    <div className="min-vh-100">
      <table
        className="table"
        id="tabla_caja"
        style={{
          overflowY: "auto",
        }}
      >
        <thead className="tr_sticky">
          <tr
            className="border-0"
            style={{
              borderRadius: "20px",
              background: "rgba(217, 217, 217, 0.5)",
            }}
          >
            <th className="border-0 text-nowrap">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Id
              </div>
            </th>
            <th className="border-0 text-nowrap">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Cajero
              </div>
            </th>
            <th className="border-0 text-nowrap">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Punto de venta
              </div>
            </th>
            <th className="border-0 text-nowrap">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Cliente
              </div>
            </th>
            <th className="border-0 text-nowrap">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Fecha de importe
              </div>
            </th>
            <th className="border-0 text-nowrap">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Tipo de pago
              </div>
            </th>
            <th className="border-0 text-nowrap">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Tipo de importe
              </div>
            </th>
            <th className="border-0 text-nowrap">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Importe
              </div>
            </th>
            <th className="border-0 text-nowrap">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Interes Punitorio
              </div>
            </th>
            <th className="border-0 text-nowrap">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Interes
              </div>
            </th>
            <th className="border-0 text-nowrap">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Capital
              </div>
            </th>
            <th className="border-0 text-nowrap d-none">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Deuda
              </div>
            </th>
            <th className="border-0 text-nowrap">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Credito
              </div>
            </th>
            <th className="border-0 text-nowrap">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Nro Cuota
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((pago) => (
            <tr>
              <td className="border-0">{pago.id}</td>
              <td className="border-0">
                {pago.cajero.name + " " + pago.cajero.lastname}
              </td>
              <td className="border-0">
                {pago.puntos_venta
                  ? pago.puntos_venta.nombre
                  : "Sin punto de venta asignado"}
              </td>
              <td className="border-0">
                {pago.persona_nombre + " " + pago.persona_apellido}
              </td>
              <td className="border-0">
                {format(
                  new Date(pago.fecha_importe + " 00:00:00"),
                  "dd/MM/yyyy"
                ).toString()}
                <div className="text-nowrap">Hora: {format(
                  new Date(pago.created_at),
                  "H:mm:ss"
                ).toString()}</div>
              </td>
              <td className="border-0">{pago.forma_pago}</td>
              <td className="border-0">{pago.estado}</td>
              <td className="border-0 text-nowrap">
                {"$ " + new Intl.NumberFormat("de-DE").format(pago.importe)}
              </td>

              <td className="border-0 text-nowrap">
                <div>
                  {CalcularResto(pago).interes_punitorio < 0
                    ? new Intl.NumberFormat("de-DE").format(
                        pago.interes_punitorios
                      )
                    : new Intl.NumberFormat("de-DE").format(
                        pago.interes_punitorios -
                          CalcularResto(pago).interes_punitorio
                      )}
                </div>
              </td>

              <td className="border-0 text-nowrap">
                <div>
                  {CalcularResto(pago).interes < 0
                    ? new Intl.NumberFormat("de-DE").format(pago.interes)
                    : new Intl.NumberFormat("de-DE").format(
                        pago.interes - CalcularResto(pago).interes
                      )}
                </div>
              </td>

              <td className="border-0 text-nowrap">
                <div>
                  {CalcularResto(pago).capital < 0
                    ? new Intl.NumberFormat("de-DE").format(pago.capital)
                    : new Intl.NumberFormat("de-DE").format(
                        pago.capital - CalcularResto(pago).capital
                      )}
                </div>
              </td>

              <td className="border-0 text-nowrap d-none">
                {"$ " +
                  new Intl.NumberFormat("de-DE").format(
                    parseFloat(pago.capital) +
                      parseFloat(pago.interes) +
                      parseFloat(pago.interes_punitorios)
                  )}
              </td>
              <td className="border-0 text-nowrap">
                <label className="btn btn-dark">
                  Credito: {pago.creditos.id}
                  <button
                    className="d-none"
                    name="ver_credito"
                    onClick={(event) => onClick(event, pago.creditos)}
                  ></button>
                </label>
              </td>
              <td className="border-0 text-center text-nowrap">
                <div>{pago.nro_cuota}</div>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr className="py-3" style={{background: "rgba(217, 217, 217, 0.5)",}}>
            <td className="fw-bold fs-4" colSpan={8}>TOTAL</td>
            <td className="text-nowrap fs-4">
              {"$ " + new Intl.NumberFormat("de-DE").format(totales.subTotal)}
            </td>
            <td className="text-nowrap fs-4">
              IP{" "}
              {"$ " +
                new Intl.NumberFormat("de-DE").format(
                  totales.interes_punitorio
                )}
            </td>
            <td className="text-nowrap fs-4">
              INT{" "}
              {"$ " + new Intl.NumberFormat("de-DE").format(totales.interes)}
            </td>
            <td colSpan={3} className="text-nowrap fs-4">
              CAP{" "}
              {"$ " + new Intl.NumberFormat("de-DE").format(totales.capital)}
            </td>
            <td colSpan={3} className="text-nowrap d-none fs-4">
              D{" "}
              {"$ " +
                new Intl.NumberFormat("de-DE").format(
                  totales.interes_punitorio + totales.interes + totales.capital
                )}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default Tabla_pagos;
