import React from "react";
import { numeroFormateado, FechaVencimiento, LugaryFecha } from "../../shared/Helpers/Pagare";

const CondicionesPagare = ({
  texto,
  fecha,
  personaSeleccionada,
  cuotas,
  valorCuota,
  primerVto,
  interes_moratoria,
  interes_punitorio,
  tipo_impresion,
  garante
}) => {


  const armarTexto = (texto) => {
    let textoFinal = texto;

    if (!personaSeleccionada) return "";

    const domicilio = ` en la calle ${personaSeleccionada.calle} ${personaSeleccionada.numero} ciudad ${personaSeleccionada.ciudad} provincia ${personaSeleccionada.provincia} `;

    textoFinal = textoFinal.replace("--domicilio--", domicilio);
    textoFinal = textoFinal.replace("--nombre--", personaSeleccionada.nombre.toUpperCase());
    textoFinal = textoFinal.replace("--apellido--", personaSeleccionada.apellido.toUpperCase());
    textoFinal = textoFinal.replace("--documento--", numeroFormateado(personaSeleccionada.nro_documento));
    textoFinal = textoFinal.replace("--cuotas--", cuotas);
    textoFinal = textoFinal.replace("--monto--", numeroFormateado(valorCuota));

    textoFinal = textoFinal.replace("--interes-moratorio--", interes_moratoria);
    textoFinal = textoFinal.replace("--interes-punitorio--", interes_punitorio);
    textoFinal = textoFinal.replace("--fecha-vencimiento--", FechaVencimiento(primerVto));

    return textoFinal.replace(/(\r\n|\n|\r)/gm, "<br>");
  };

  return (
    <div
      className="center-block"
      style={{
        margin: tipo_impresion === "1" ? "25px 15px 15px 15px" : "250px 15px 15px 15px",
        border: "3px solid black",
        borderStyle: "solid",
        padding: "10px",
        paddingBottom: "30px",
        textAlign: "justify",
        fontSize: "15px",
        width: "813px"
      }}
    >
      <from>
        <div className="text-center mb-3">
          <b>CONDICIONES OPERACIÓN</b>
        </div>
        <div className="row">
          <div className="col-sm"></div>
          <div className="col-sm text-end">{LugaryFecha(fecha)}</div>
        </div>
        <br></br>
        <div dangerouslySetInnerHTML={{ __html: armarTexto(texto) }}>
        </div>
        {
          garante
            ? <p>Garante {garante}</p>
            : ""
        }
        <br></br>
        Firma:
        <br></br>
        Aclaración:
        <br></br>
        DNI:
        <br></br>
      </from>
    </div>
  );
};

export default CondicionesPagare;
