import React, { useState, useEffect } from "react";
import { Spinner, clienteAxios } from "./../../shared/";
import BuscarListarGarante from "./BuscarListarGarante";
import { BotonCancelar } from "../../shared/Estilos";

const BuscarGarantes = ({ closeModal, idpersona }) => {
  const [loadingLocal, setLoadingLocal] = useState(null);
  const [lista, setLista] = useState(null);

  const [consultar, setConsultar] = useState(true);

  const [DatosForm, LeerForm] = useState({ buscar: "" });

  const { buscar } = DatosForm;

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });

    if (buscar.length > 1) {
      setConsultar(true);
      cargar();
    }
  };

  const cargar = async () => {
    if (consultar) {
      setConsultar(false);
      if (!consultar) return;
      if (!buscar) return;

      try {
        setLoadingLocal(true);
        const result = await clienteAxios.get("/personas", {
          params: { texto: buscar, page: 1, paginator: 30 },
        });
        setLista(result.data.personas.personas);
        setLoadingLocal(null);
      } catch (e) {}
      setConsultar(null);
    }
  };

  useEffect(() => {
    cargar();
  }, [consultar]);

  return (
    <div className="center-block">
      <h5 className="text-center">Buscar Garantes</h5>
      <div className="row">
        <div className="col-sm">
          <div className="form-group">
            <a
              href="/clientes/nueva"
              className="btn btn-dark"
              style={{ backgroundColor: "#1a73e8", color:"white" }}
            >
              <i className="fa fa-plus-circle"></i> Agregar Garante
            </a>
          </div>
          <br></br>
          <div className="form-group">
            <input
              type="buscar"
              name="buscar"
              id="buscar"
              className="form-control"
              placeholder="Ingrese su DNI o Nombre u Apellido"
              onChange={onChange}
              value={buscar}
            />
          </div>
        </div>
        <br></br>
      </div>

      <br></br>
      {loadingLocal ? (
        <Spinner />
      ) : (
        <>
          {lista ? (
            <BuscarListarGarante
              lista={lista}
              idpersona={idpersona}
              closeModal={closeModal}
            />
          ) : null}
        </>
      )}

      <div className="col-sm d-grid gap-2">
        <BotonCancelar
          type="button"
          className="btn btn-primary"
          onClick={() => closeModal(true)}
        >
          Cerrar
        </BotonCancelar>
      </div>
    </div>
  );
};

export default BuscarGarantes;
