import React, { useContext, useState, useEffect } from "react";
import Swal from "sweetalert2";
import { links } from "../../functions/links";
import Tabla_creditos from "../creditos/tablas/Tabla_creditos";
import { Spinner, ConcesionesContext, clienteAxios, useHistory } from "../shared/";
import { BotonAgregar } from "../shared/Estilos";
import { Input } from "../shared/FormsElements";
import ListarConcesiones from "./ListarConcesiones";
import Tabla_concesiones from "./tablas/Tabla_concesiones";

const Concesiones = (props) => {
  const concesionesContext = useContext(ConcesionesContext);
  const { cargando, obtener } = concesionesContext;

  const history = useHistory();
  const [concesiones, setConcesiones] = useState(null);


  const [DatosForm, LeerForm] = useState({
    buscar: "",

    page: 1,
    paginator: 10
  });

  const [params, setParams] = useState({
    texto: "",

    concesion: "true",
    page: 1,
    paginator: 30
  })
  //state
  const [creditos, setCreditos] = useState(null)

  const { buscar } = DatosForm;

  //Eventos
  const onClick = (e, value) => {
    switch (e.target.name) {
      case "ver_credito":
        history.push("/creditos/ver/" + value.id);
        break;
      case "delete_concesion":{
        Swal.fire({
          title: "Eliminar credito",
          html:
            "<p>¿Quieres dar de baja la siguiente concesion?</p>" +
            '<ul class="list-group"><li class="list-group-item border-0 d-flex justify-content-between"><div>Nº de Concesion '+ value.id +'</div></li><li class="list-group-item border-0 d-flex justify-content-center "><div>Contraseña de adminsitrador </div></li><ul>' 
           ,
          input: "password",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Eliminar",
          showLoaderOnConfirm: true,
          preConfirm: async (result) => {
            
            let formData = new FormData();
            formData.append("id", value.id);
            formData.append("status", 'Eliminado');
            formData.append("password", result);
            
            const response = await clienteAxios({
              method: "post",
              url: "/Concesion/darBaja",
              data: formData,
            }).then((result) => {
                Swal.fire({
                  title: 'Concesion Eliminado!',
                  text: 'El credito se elimino correctamente',
                });

                return window.location.reload();
              })
              .catch((result) => {
                return Swal.showValidationMessage(
                  `Request failed: ${result.response.data.messages.msg}`
                );
              });
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
          console.log("ok");
        });
      } break;
      case "anterior": {
        if (params.page !== 1) {
          setParams({
            ...params,
            page: params.page - 1
          })
        }
      } break;
      case "siguiente": {
        setParams({
          ...params,
          page: params.page + 1
        })
      } break;
    }
  }

  const onChange = (e) => {
    setParams({
      ...params,
      [e.target.name]: e.target.value,
    });

    /*
    if (buscar.length > 1) {
      obtener(buscar);
    }
    */
  };

  useEffect(() => {
    const obtenerCreditos = async () => {
      try {
        const result = await clienteAxios.get(
          "/PersonaCreditos/getAll",
          { params }
        );

        let creditos = [];

        result.data.creditos.map((credito) => {
          if (credito.concesion) {
            creditos.push(credito);
          }
        })

        setCreditos(
          result.data //.filter((result) => result.id_credito === null)
        );
        /*  console.log(result.data.creditos.filter(
          (result) => result.id_credito === null
        )); */
      } catch (e) { }
    };

    obtenerCreditos();
  }, [params])

  useEffect(() => {
    const fetch = async () => {
      try {
        const result = await clienteAxios.get(
          "/Concesion/getAll",
          { params }
        );

        setConcesiones(result.data)

        /*  console.log(result.data.creditos.filter(
          (result) => result.id_credito === null
        )); */
      } catch (e) { }
    }

    fetch()
  }, [params])

  /*
  useEffect(() => {
    // eslint-disable-next-line
    obtener(buscar);
  }, [buscar]);
  */

  //Logs
  console.log(concesiones);
  return (
    <div className="container-md min-vh-100">
      <br></br>
      <h3 className="text-center">Gestionar Concesiones</h3>
      <br></br>
      <div className="row">
        <div className="col-sm">
          <div className="form-group">
            <BotonAgregar
              onClick={() => {
                props.history.push("/concesiones/nuevo");
              }}
              className="btn btn-dark"
            >
              <i className="fa fa-plus-circle"></i> Agregar Concesión
            </BotonAgregar>

          </div>
        </div>
        <div className="col-sm">
          <Input
            key={"buscar"}
            sets={{
              label: "",
              type: "text",
              name: "texto",
              placeholder: "Ingrese su Busqueda",
              valor: params.buscar,
            }}
            onChange={onChange}
          />
        </div>
        <br></br>
      </div>

      <br></br>
      {/* cargando ? <Spinner /> : <ListarConcesiones concesiones={concesiones} /> */}

      <div className="mb-3">

        {
          concesiones
            ? <>
               <div className="w-100 d-flex justify-content-between mb-3">
                <div className="btn btn-light">
                  Total de registros {concesiones.registros}
                </div>
                <div>
                  <label className="btn btn-primary fs-5 me-2">
                    <i class="bi bi-arrow-left"></i>
                    <button className="d-none"
                      name="anterior"
                      onClick={(event) => onClick(event)}>
                    </button>
                  </label>
                  {
                    concesiones.pagina == 1
                      ? ""
                      : <label className="btn btn-outline-primary fs-5 me-2">
                        ..1
                        <button className="d-none"
                          name="link"
                          onClick={(event) => onClick(event, 1)}>
                        </button>
                      </label>
                  }
                  <label className="btn btn-primary fs-5 me-2">
                    {concesiones.pagina}
                    <button className="d-none"
                      name=""
                      onClick={(event) => onClick(event)}>
                    </button>
                  </label>
                  {
                    links(concesiones.pagina, concesiones.paginas).map((link) => (
                      <label className="btn btn-outline-primary fs-5 me-2">
                        {link}
                        <button
                          className="d-none"
                          name="link"
                          onClick={(event) => onClick(event, link)}
                        ></button>
                      </label>
                    ))
                  }
                  {
                    concesiones.pagina == concesiones.paginas
                      ? ""
                      : <label className="btn btn-outline-primary fs-5 me-2">
                        {concesiones.paginas}
                        <button className="d-none"
                          name="link"
                          onClick={(event) => onClick(event, concesiones.paginas)}>
                        </button>
                      </label>
                  }
                  <label className="btn btn-primary fs-5">
                    <i class="bi bi-arrow-right"></i>
                    <button className="d-none"
                      name="siguiente"
                      onClick={(event) => onClick(event)}>
                    </button>
                  </label>
                </div>
              </div>

              <Tabla_concesiones
              data={concesiones.concesiones}
              onClick={onClick}
              onChange={onChange}
            />
              
              
              <div className="w-100 d-flex justify-content-between mb-3">
                <div className="btn btn-light">
                  Total de registros {concesiones.registros}
                </div>
                <div>
                  <label className="btn btn-primary fs-5 me-2">
                    <i class="bi bi-arrow-left"></i>
                    <button className="d-none"
                      name="anterior"
                      onClick={(event) => onClick(event)}>
                    </button>
                  </label>
                  {
                    concesiones.pagina == 1
                      ? ""
                      : <label className="btn btn-outline-primary fs-5 me-2">
                        ..1
                        <button className="d-none"
                          name="link"
                          onClick={(event) => onClick(event, 1)}>
                        </button>
                      </label>
                  }
                  <label className="btn btn-primary fs-5 me-2">
                    {concesiones.pagina}
                    <button className="d-none"
                      name=""
                      onClick={(event) => onClick(event)}>
                    </button>
                  </label>
                  {
                    links(concesiones.pagina, concesiones.paginas).map((link) => (
                      <label className="btn btn-outline-primary fs-5 me-2">
                        {link}
                        <button
                          className="d-none"
                          name="link"
                          onClick={(event) => onClick(event, link)}
                        ></button>
                      </label>
                    ))
                  }
                  {
                    concesiones.pagina == concesiones.paginas
                      ? ""
                      : <label className="btn btn-outline-primary fs-5 me-2">
                        {concesiones.paginas}
                        <button className="d-none"
                          name="link"
                          onClick={(event) => onClick(event, concesiones.paginas)}>
                        </button>
                      </label>
                  }
                  <label className="btn btn-primary fs-5">
                    <i class="bi bi-arrow-right"></i>
                    <button className="d-none"
                      name="siguiente"
                      onClick={(event) => onClick(event)}>
                    </button>
                  </label>
                </div>
              </div>
              </>
            : ""
        }
      </div>


      <div className="mb-3 d-none">
        {
          creditos ?
            <>

              <div className="w-100 d-flex justify-content-between mb-3">
                <div className="btn btn-light">
                  Total de registros {creditos.registros}
                </div>
                <div>
                  <label className="btn btn-primary fs-5 me-2">
                    <i class="bi bi-arrow-left"></i>
                    <button className="d-none"
                      name="anterior"
                      onClick={(event) => onClick(event)}>
                    </button>
                  </label>
                  {
                    creditos.pagina == 1
                      ? ""
                      : <label className="btn btn-outline-primary fs-5 me-2">
                        ..1
                        <button className="d-none"
                          name="link"
                          onClick={(event) => onClick(event, 1)}>
                        </button>
                      </label>
                  }
                  <label className="btn btn-primary fs-5 me-2">
                    {creditos.pagina}
                    <button className="d-none"
                      name=""
                      onClick={(event) => onClick(event)}>
                    </button>
                  </label>
                  {
                    links(creditos.pagina, creditos.paginas).map((link) => (
                      <label className="btn btn-outline-primary fs-5 me-2">
                        {link}
                        <button
                          className="d-none"
                          name="link"
                          onClick={(event) => onClick(event, link)}
                        ></button>
                      </label>
                    ))
                  }
                  {
                    creditos.pagina == creditos.paginas
                      ? ""
                      : <label className="btn btn-outline-primary fs-5 me-2">
                        {creditos.paginas}
                        <button className="d-none"
                          name="link"
                          onClick={(event) => onClick(event, creditos.paginas)}>
                        </button>
                      </label>
                  }
                  <label className="btn btn-primary fs-5">
                    <i class="bi bi-arrow-right"></i>
                    <button className="d-none"
                      name="siguiente"
                      onClick={(event) => onClick(event)}>
                    </button>
                  </label>
                </div>
              </div>
              <Tabla_creditos
                data={creditos.creditos}
                onClick={onClick}
                onChange={onChange}
              />

              <div className="w-100 d-flex justify-content-between mb-3">
                <div className="btn btn-light">
                  Total de registros {creditos.registros}
                </div>
                <div>
                  <label className="btn btn-primary fs-5 me-2">
                    <i class="bi bi-arrow-left"></i>
                    <button className="d-none"
                      name="anterior"
                      onClick={(event) => onClick(event)}>
                    </button>
                  </label>
                  {
                    creditos.pagina == 1
                      ? ""
                      : <label className="btn btn-outline-primary fs-5 me-2">
                        ..1
                        <button className="d-none"
                          name="link"
                          onClick={(event) => onClick(event, 1)}>
                        </button>
                      </label>
                  }
                  <label className="btn btn-primary fs-5 me-2">
                    {creditos.pagina}
                    <button className="d-none"
                      name=""
                      onClick={(event) => onClick(event)}>
                    </button>
                  </label>
                  {
                    links(creditos.pagina, creditos.paginas).map((link) => (
                      <label className="btn btn-outline-primary fs-5 me-2">
                        {link}
                        <button
                          className="d-none"
                          name="link"
                          onClick={(event) => onClick(event, link)}
                        ></button>
                      </label>
                    ))
                  }
                  {
                    creditos.pagina == creditos.paginas
                      ? ""
                      : <label className="btn btn-outline-primary fs-5 me-2">
                        {creditos.paginas}
                        <button className="d-none"
                          name="link"
                          onClick={(event) => onClick(event, creditos.paginas)}>
                        </button>
                      </label>
                  }
                  <label className="btn btn-primary fs-5">
                    <i class="bi bi-arrow-right"></i>
                    <button className="d-none"
                      name="siguiente"
                      onClick={(event) => onClick(event)}>
                    </button>
                  </label>
                </div>
              </div>
            </>

            : <Spinner />
        }
      </div>

    </div>
  );
};

export default Concesiones;
