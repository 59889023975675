import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import RutaPrivada from "./components/rutas/RutaPrivada";
import Layout from "./components/shared/Layout";
import ScrollToTop from "./components/shared/ScrollToTop";

// states
import AuthState from "./context/autenticacion/authState";
import PersonasState from "./context/personas/personasState";
import TasasState from "./context/tasas/tasasState";
import CreditosState from "./context/creditos/creditosState";
import ConcesionesState from "./context/concesiones/concesionesState";
import PagosState from "./context/pagos/pagosState";

// paginas
import PageError from "./components/pages/PageError";
import Login from "./components/auth/Login";
import Dashboard from "./components/escritorio/Dashboard";
import CambiarPassword from "./components/auth/CambiarPassword";
import Usuarios from "./components/usuarios/Usuarios";
import FormUsuario from "./components/usuarios/FormUsuario";
import Calculadora from "./components/prestamos/Calculadora";
import Tasas from "./components/tasas/Tasas";
import FormTasas from "./components/tasas/FormTasa";
import Personas from "./components/personas/Personas";
import FormContenedor from "./components/personas/FormContenedor";
import FormPrecalificaciones from "./components/precalificaciones/FormPrecalificaciones";
import PreCalificaciones from "./components/precalificaciones/PreCalificaciones";
import Creditos from "./components/creditos/Creditos";
import FormCredito from "./components/creditos/formulario/FormCredito";
import ImprimirPagare from "./components/creditos/pagare/ImprimirPagare";
import Concesiones from "./components/concesiones/Concesiones";
import FormConcesion from "./components/concesiones/formulario/FormConcesion";
import Pagos from "./components/pagos/Pagos";
import FormPagos from "./components/pagos/formulario/FormPagos";
import GestionPagos from "./components/Gestion_pagos/GestionPagos";
import Reportes from "./components/reportes/Reportes";
import FormCreditoEspecial from "./components/creditos/formulario/FormCreditoEspecial";
import MiPerfil from "./components/miperfil";
import Persona from "./components/public/persona";
import gastosController from "./components/gestion_mora/gastos/gastosController";
import GastosController from "./components/gestion_mora/gastos/gastosController";

function App() {
  return (
    <AuthState>
      <PersonasState>
        <TasasState>
          <CreditosState>
            <ConcesionesState>
              <PagosState>
                <Router>
                  <Layout>
                    <ScrollToTop />
                    <Switch>



                      <Route exact path="/cliente/publico/:token" component={Persona} />

                      <Route exact path="/login" component={Login} />
                      <Route exact path="/" component={Login} />
                      <RutaPrivada path="/dashboard" component={Dashboard} />
                      <RutaPrivada
                        exact
                        path="/cambiarpassword"
                        component={CambiarPassword}
                      />

                      <RutaPrivada
                        exact
                        path="/perfil"
                        component={MiPerfil}
                      />

                      <RutaPrivada
                        exact
                        path="/creditos"
                        component={Creditos}
                      />
                      <RutaPrivada
                        exact
                        path="/creditos/nuevo"
                        component={FormCredito}
                      />
                      <RutaPrivada
                        exact
                        path="/creditos/nuevo/:id"
                        component={FormCredito}
                      />
                      <RutaPrivada
                        exact
                        path="/creditos/ver/:idcredito"
                        component={FormCredito}
                      />
                      <RutaPrivada
                        exact
                        path="/creditos/especial/nuevo/:idpersona/:id"
                        component={FormCreditoEspecial}
                      />
                      <RutaPrivada
                        exact
                        path="/creditos/pagare/:idcredito"
                        component={ImprimirPagare}
                      />

                      <RutaPrivada
                        exact
                        path="/usuarios"
                        component={Usuarios}
                      />
                      <RutaPrivada
                        exact
                        path="/usuarios/nuevo"
                        component={FormUsuario}
                      />
                      <RutaPrivada
                        exact
                        path="/usuarios/editar/:id"
                        component={FormUsuario}
                      />

                      <RutaPrivada
                        exact
                        path="/prestamos/calculadora"
                        component={Calculadora}
                      />

                      <RutaPrivada exact path="/tasas" component={Tasas} />
                      <RutaPrivada
                        exact
                        path="/tasas/nuevo"
                        component={FormTasas}
                      />
                      <RutaPrivada
                        exact
                        path="/tasas/editar/:id"
                        component={FormTasas}
                      />

                      <RutaPrivada
                        exact
                        path="/clientes"
                        component={Personas}
                      />
                      <RutaPrivada
                        exact
                        path="/clientes/nueva"
                        component={FormContenedor}
                      />
                      <RutaPrivada
                        exact
                        path="/clientes/editar/:id"
                        component={FormContenedor}
                      />
                      <RutaPrivada
                        exact
                        path="/clientes/ver/:id"
                        component={FormContenedor}
                      />

                      <RutaPrivada
                        exact
                        path="/precalificar/:id"
                        component={FormPrecalificaciones}
                      />
                      <RutaPrivada
                        exact
                        path="/precalificar/ver/:id"
                        component={FormPrecalificaciones}
                      />

                      <RutaPrivada
                        exact
                        path="/calificaciones/:id"
                        component={PreCalificaciones}
                      />

                      <RutaPrivada
                        exact
                        path="/concesiones"
                        component={Concesiones}
                      />
                      <RutaPrivada
                        exact
                        path="/concesiones/nuevo"
                        component={FormConcesion}
                      />
                      <RutaPrivada
                        exact
                        path="/concesiones/ver/:id"
                        component={FormConcesion}
                      />

                      {/****************** Gastos judiciales *****************/}

                      <RutaPrivada
                        exact
                        path="/gestion de mora/gastos"
                        component={GastosController}
                      />

                      {/************Gestions de pagos****************/}

                      <RutaPrivada exact path="/pagos" component={GestionPagos} />

                      {/************Gestions de reportes****************/}
                      
                      <RutaPrivada exact path="/reportes" component={Reportes} />

                      {/* 
                      
                      <RutaPrivada exact path="/pagos" component={Pagos} />

                      <RutaPrivada
                        exact
                        path="/pagos/nuevo"
                        component={FormPagos}
                      />

                      */}

                      

                      <Route component={PageError} />
                    </Switch>
                  </Layout>
                </Router>
              </PagosState>
            </ConcesionesState>
          </CreditosState>
        </TasasState>
      </PersonasState>
    </AuthState>
  );
}

export default App;
