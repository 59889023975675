import React, {useContext} from "react";
import { BotonAmarillo, BotonRojo, BotonVerde } from "../../shared/Estilos";
import {AuthContext} from "../../shared/";

const Garante = ({ item, eliminar, activar,inactivar, confirmar, soloLectura }) => {

  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  return (
    <tr>
      <td className="text-center">
        <a href={`/clientes/editar/${item.id_garante}`} >{item.nombre} {item.apellido} / {item.nro_documento}</a>
      </td>
      <td className="text-center">{item.estado}</td>
      <td className="text-center">{item.activo}</td>
      <td className="text-center">
       {usuario.rol === "Precalificador" || usuario.rol === "Admin" ? (<>
        {!soloLectura ? <>{item.activo === null  || item.activo === "Inactivo" ? (
          <BotonVerde
            type="button"
            onClick={() => {
              activar(item);
            }}
          >
            Activar
          </BotonVerde>
        ) : null}
        <br></br>

        {item.activo === "Activo" ? (
          <BotonAmarillo
            type="button"
            onClick={() => {
              inactivar(item);
            }}
          >
            Inactivar
          </BotonAmarillo>
        ) : null}
        <br></br>

        {item.estado === "Pendiente" ? (
          <BotonVerde
            type="button"
            onClick={() => {
              confirmar(item);
            }}
          >
            Confirmar
          </BotonVerde>
        ) : null}
        <br></br>

        <BotonRojo
          type="button"
          onClick={() => {
            eliminar(item);
          }}
        >
          Eliminar
        </BotonRojo></> : null}
        </>) : null}
      </td>
    </tr>
  );
};

export default Garante;
