import React, { useContext, useState, useEffect } from "react";
import { links } from "../../functions/links";
import { Spinner, PersonasContext, clienteAxios, AuthContext, useHistory } from "../shared/";
import { BotonAgregar } from "../shared/Estilos";
import { Input } from "../shared/FormsElements";
import ListarPersonas from "./ListarPersonas";
import TablaPersonas from "./tablas/TablaPersonas";

const Personas = (props) => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;
  const personasContext = useContext(PersonasContext);
  const { personas, cargando, obtenerPersonasII } = personasContext;

  const [usuarios, setUsuarios] = useState();
  const [loadingLocal, setLoadingLocal] = useState(null);
  const [lista, setLista] = useState(null);
  const [mostarFormulario, setMostrarFormulario] = useState(null);
  const [consultar, setConsultar] = useState(true);
  const [Seleccionado, setSeleccionado] = useState(null);
  const [inicio, setInicio] = useState(1);

  const [DatosForm, LeerForm] = useState(
    {
      buscar: "",
      estado: usuario.rol == "Precalificador"
        ? "Solicitada"
        : "Todos",
      page: 1,
      paginator: 30
    });

  const { buscar, estado, page, paginator } = DatosForm;

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
      page: 1
    });

    setConsultar(true);
  };

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "anterior": {
        if (DatosForm.page !== 1) {
          LeerForm({
            ...DatosForm,
            page: DatosForm.page - 1
          })


          setConsultar(true)
        }
      } break;
      case "siguiente": {
        if (DatosForm.page < lista.paginas) {
          LeerForm({
            ...DatosForm,
            page: DatosForm.page + 1
          })


          setConsultar(true)
        }
      } break;
      case "link": {
        LeerForm({
          ...DatosForm,
          page: value
        })


        setConsultar(true)
      } break;
      case "ver_credito":
        history.push("/creditos/ver/" + value.id);
        break;
    }
  }


  useEffect(() => {
    const Load = async () => {
      const result = await clienteAxios.get("/Persona/getAll", {
        params: { texto: DatosForm.buscar, page: DatosForm.page, paginator: DatosForm.paginator },
      });
      setLista(result.data.personas)
    }

    Load()
  }, [buscar, page])

  useEffect(() => {
    const Load = async () => {
      const result = await clienteAxios.get("/User/getAll");
      setUsuarios(result.data.usuarios)
    }

    Load()
  }, []);

  console.log(lista);
  return (
    <div className="container-md min-vh-100">
      <br></br>
      <h3 className="text-center">Gestionar Clientes</h3>
      <br></br>
      <div className="row">
        <div className="col-sm">
          <div className="form-group">
            <BotonAgregar
              onClick={() => {
                props.history.push("/clientes/nueva");
              }}
              className="btn btn-dark"
            >
              <i className="fa fa-plus-circle"></i> Agregar Cliente
            </BotonAgregar>
          </div>
        </div>

        <div className="col-sm">
          <Input
            key={"buscar"}
            sets={{
              label: "",
              type: "text",
              name: "buscar",
              placeholder: "Ingrese su Busqueda",
              valor: buscar,
            }}
            onChange={onChange}
          />
        </div>
        <br></br>
      </div>
      <br></br>

      {loadingLocal && !lista ? (
        <Spinner />
      ) : (
        ""
      )}

      {
        lista
          ? <>
            <div className="w-100 d-flex justify-content-between mb-3">
              <div className="btn btn-light">
                Total de registros {lista.registros}
              </div>
              <div>
                <label className="btn btn-primary fs-5 me-2">
                  <i class="bi bi-arrow-left"></i>
                  <button className="d-none"
                    name="anterior"
                    onClick={(event) => onClick(event)}>
                  </button>
                </label>
                {
                  lista.pagina == 1
                    ? ""
                    : <label className="btn btn-outline-primary fs-5 me-2">
                      ..1
                      <button className="d-none"
                        name="link"
                        onClick={(event) => onClick(event, 1)}>
                      </button>
                    </label>
                }
                <label className="btn btn-primary fs-5 me-2">
                  {lista.pagina}
                  <button className="d-none"
                    name=""
                    onClick={(event) => onClick(event)}>
                  </button>
                </label>
                {
                  links(lista.pagina, lista.paginas).map((link) => (
                    <label className="btn btn-outline-primary fs-5 me-2">
                      {link}
                      <button
                        className="d-none"
                        name="link"
                        onClick={(event) => onClick(event, link)}
                      ></button>
                    </label>
                  ))
                }
                {
                  lista.pagina == lista.paginas
                    ? ""
                    : <label className="btn btn-outline-primary fs-5 me-2">
                      {lista.paginas}
                      <button className="d-none"
                        name="link"
                        onClick={(event) => onClick(event, lista.paginas)}>
                      </button>
                    </label>
                }
                <label className="btn btn-primary fs-5">
                  <i class="bi bi-arrow-right"></i>
                  <button className="d-none"
                    name="siguiente"
                    onClick={(event) => onClick(event)}>
                  </button>
                </label>
              </div>
            </div>
            <TablaPersonas
              usuario={usuario}
              usuarios={usuarios}
              lista={lista.personas}
              setSeleccionado={setSeleccionado}
              setMostrarFormulario={setMostrarFormulario}
              setConsultar={setConsultar}
            />
            <div className="w-100 d-flex justify-content-between mb-3">
              <div className="btn btn-light">
                Total de registros {lista.registros}
              </div>
              <div>
                <label className="btn btn-primary fs-5 me-2">
                  <i class="bi bi-arrow-left"></i>
                  <button className="d-none"
                    name="anterior"
                    onClick={(event) => onClick(event)}>
                  </button>
                </label>
                {
                  lista.pagina == 1
                    ? ""
                    : <label className="btn btn-outline-primary fs-5 me-2">
                      ..1
                      <button className="d-none"
                        name="link"
                        onClick={(event) => onClick(event, 1)}>
                      </button>
                    </label>
                }
                <label className="btn btn-primary fs-5 me-2">
                  {lista.pagina}
                  <button className="d-none"
                    name=""
                    onClick={(event) => onClick(event)}>
                  </button>
                </label>
                {
                  links(lista.pagina, lista.paginas).map((link) => (
                    <label className="btn btn-outline-primary fs-5 me-2">
                      {link}
                      <button
                        className="d-none"
                        name="link"
                        onClick={(event) => onClick(event, link)}
                      ></button>
                    </label>
                  ))
                }
                {
                  lista.pagina == lista.paginas
                    ? ""
                    : <label className="btn btn-outline-primary fs-5 me-2">
                      {lista.paginas}
                      <button className="d-none"
                        name="link"
                        onClick={(event) => onClick(event, lista.paginas)}>
                      </button>
                    </label>
                }
                <label className="btn btn-primary fs-5">
                  <i class="bi bi-arrow-right"></i>
                  <button className="d-none"
                    name="siguiente"
                    onClick={(event) => onClick(event)}>
                  </button>
                </label>
              </div>
            </div>
          </>
          : <Spinner />
      }

      { /* cargando ? <Spinner /> : <ListarPersonas personas={personas} />  */}
    </div>
  );
};

export default Personas;
