import React from "react";
import Garante from "./Garante";
import { TituloTabla } from "../../shared/Estilos";
import { clienteAxios, useLog, Swal } from "../../shared/";

const ListarGarantes = ({ lista, setConsultar, id_persona, soloLectura }) => {
  
  const [InsertarLog] = useLog(null);


  const borrar = async (id) => {
    try {
      await clienteAxios.delete("/personasgarante", {
        params: { id },
      });
      InsertarLog( "eliminar", "Eliminacion de Garante");
      setConsultar(true);
    } catch (e) {}
  };

  const activar =  (item) => {
    Swal.fire({
      title: "Esta Seguro que desea Activar ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Activar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        item.id_persona = id_persona;
        item.activo = "Activo";
        actualizar(item);
      }
    });
  };

  const inactivar = (item) => {
    Swal.fire({
      title: "Esta Seguro que desea Inactivar ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Inactivar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        item.id_persona = id_persona;
        item.activo = "Inactivo";
        actualizar(item);
      }
    });
  };

  const confirmar = (item) => {
    Swal.fire({
      title: "Esta Seguro que desea Confirmar ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Confirmar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        item.id_persona = id_persona;
        item.estado = "Aceptada";
        actualizar(item);
      }
    });
  };

  const actualizar = async (item) => {
    try {
      await clienteAxios.put("/personasgarante", item);
      InsertarLog( "editar", "Edicion de Garante");
      setConsultar(true);
    } catch (e) {}
  };

  const eliminar = (item) => {
    Swal.fire({
      title: "Esta Seguro que desea Eliminar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        borrar(item.id);
      }
    });
  };

  return (
    <div className="table-responsive">
      <table className="table table-striped" style={{backgroundColor:"white"}}>
        <TituloTabla>
          <tr>
            <th scope="col" className="text-center">
              Nombre y Apellido
            </th>
            <th scope="col" className="text-center">
              Estado
            </th>
            <th scope="col" className="text-center">
              Activo
            </th>
            <th scope="col" className="text-center">
              Acciones
            </th>
          </tr>
        </TituloTabla>
        <tbody>
          {lista.map((item) => (
            <Garante
              key={item.id}
              item={item}
              activar={activar}
              inactivar={inactivar}
              confirmar={confirmar}
              eliminar={eliminar}
              soloLectura={soloLectura}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListarGarantes;
