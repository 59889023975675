import { format } from "date-fns";

const CalculoMoratoria = (
  interes_mora,
  capital,

  fecha_actual,
  fecha_vencimiento
) => {
  //Punitorios
  let interes_punitorio = 0;
  let diferencia_moratoria = 0;

  //Formatear fecha actual
  fecha_actual = format(fecha_actual, "yyyy-MM-dd").toString();

  //console.log(fecha_actual);

  fecha_actual = new Date(fecha_actual+ " 00:00:00").getTime();

  //console.log(fecha_vencimiento);

  //Fecha de vencimiento de cuota
  let vto = new Date(fecha_vencimiento+ " 00:00:00").getTime();

  //Calculo de moratoria
  diferencia_moratoria = fecha_actual - vto;
  diferencia_moratoria = diferencia_moratoria / (1000 * 60 * 60 * 24);

  if (diferencia_moratoria > 0) {
    interes_punitorio = capital * (interes_mora / 100);
    interes_punitorio = interes_punitorio / 30;

    //intereses punitorios
    interes_punitorio = interes_punitorio * diferencia_moratoria;
  }

  //console.log(interes_punitorio);
  //console.log(diferencia_moratoria);

  return {
    p_interes: interes_punitorio,
    p_moratoria: diferencia_moratoria,
  };
};

export const CalculoPagoJudicial = (data) => {
  let re_interes_punitorio = 0;
  let real_interes_punitorio = 0;
  let diferencia_dias = 0;
  let interes = 0;
  let monto = parseFloat(data.monto);
  let fecha_vencimiento = data.fecha_otorgamiento; 
  let re_fecha_vencimiento = data.fecha_otorgamiento; 
  let fecha_actual = new Date();
  let interes_moratorio = parseFloat(data.interes_moratorio);
  let fecha_importe = "";
  let existe_total = false;
  let re_capital = parseFloat(data.monto);

  data.pagos.map((p, index) => {
    fecha_importe = new Date(p.fecha_importe+" 00:00:00");
    let importe = p.importe;

    switch (p.estado) {
      case "EXCEDENTE":
        {
          /* 
          Si la fecha de importe es mayor a la fecha de vencimiento 
          Entonces 
         */

          //let p_interes = 0;
          //let p_moratoria = 0;

          let { p_interes, p_moratoria } = CalculoMoratoria(
            interes_moratorio,
            re_capital,

            //fecha_importe,
            fecha_importe,
            re_fecha_vencimiento
          );

          /* Seteo diferencia de dias */
          if (p_moratoria > 0) {
            diferencia_dias = p_moratoria + diferencia_dias;
          }

          /* Resto */
          re_interes_punitorio =
            (re_interes_punitorio < 0 ? 0 : re_interes_punitorio)  -importe;


          /* 3 Capital */
          if (re_interes_punitorio < 0) {
            re_capital = re_capital + re_interes_punitorio;
          }

          if (new Date(fecha_importe) > new Date(fecha_vencimiento)) {
            re_fecha_vencimiento = format(
              fecha_importe,
              "yyyy-MM-dd"
            ).toString();
          }
        }
        break;
      case "PARCIAL":
        {
          /* 
          Se calcula interes de acuerdo a la fecha de importe
          */

          let { p_interes, p_moratoria } = CalculoMoratoria(
            interes_moratorio,
            re_capital,

            fecha_importe,
            re_fecha_vencimiento
          );


          if (p_interes > 0) {
            if (re_interes_punitorio > 0) {
              re_interes_punitorio = p_interes + re_interes_punitorio;
            } else {
              re_interes_punitorio = p_interes;
            }
            real_interes_punitorio = p_interes + real_interes_punitorio;
          }
         
          /* Seteo diferencia de dias */
          if (p_moratoria > 0) {
            diferencia_dias = p_moratoria + diferencia_dias;
          }

          /* Resto */
          /* 1 Interes punitorio */
          re_interes_punitorio = (re_interes_punitorio < 0 ? 0 : re_interes_punitorio) - importe

          /* 3 Capital */
          if (re_interes_punitorio < 0) {
            re_capital = re_capital + re_interes_punitorio;
          }

          if (new Date(fecha_importe) > new Date(fecha_vencimiento)) {
            re_fecha_vencimiento = format(
              fecha_importe,
              "yyyy-MM-dd"
            ).toString();
          }

        }
        break;
      case "TOTAL":
        {
          existe_total = true;
          /* 
        Si la fecha de importe es mayor a la fecha de vencimiento 
        Entonces 
        */

          let { p_interes, p_moratoria } = CalculoMoratoria(
            interes_moratorio,
            re_capital,

            fecha_importe,
            //fecha_importe,
            re_fecha_vencimiento
          );


          /* Seteo diferencia de dias */
          if (p_moratoria > 0) {
            //console.log('MOOOOOOOO '+p_moratoria)
            diferencia_dias = p_moratoria + diferencia_dias;
          }

        
          /* Resto */
          /* 1 Interes punitorio */
          re_interes_punitorio = (re_interes_punitorio < 0 ? 0 : re_interes_punitorio)  -  importe;

         

          /* 3 Capital */
          if (re_interes_punitorio < 0) {
            re_capital = re_capital  + re_interes_punitorio;
          }


          //re_fecha_vencimiento = format(fecha_importe, "yyyy-MM-dd").toString();
          if (new Date(fecha_importe) > new Date(fecha_vencimiento)) {
            re_fecha_vencimiento = format(
              fecha_importe,
              "yyyy-MM-dd"
            ).toString();
          }
        }
        break;
    }
  })

  if (parseFloat(re_capital).toFixed(0) > 0  && data.pagos.length > 0 || !existe_total && data.pagos.length > 0) {
    let { p_interes, p_moratoria } = CalculoMoratoria(
      parseFloat(interes_moratorio),
      re_capital,

      fecha_actual,
      re_fecha_vencimiento
    );

    /* Seteo interes punitorio */
    //if (p_interes > 0) {
    //  console.log('intereeeees '+p_interes)
    //  re_interes_punitorio = p_interes;
    //  real_interes_punitorio = p_interes;
    //}
    if (p_interes > 0) {
      if (re_interes_punitorio > 0) {
        re_interes_punitorio = p_interes + re_interes_punitorio;
      } else {
        re_interes_punitorio = p_interes;
      }
      real_interes_punitorio = p_interes + real_interes_punitorio;
    }

    /* Seteo diferencia de dias */
    if (p_moratoria > 0) {
      diferencia_dias = p_moratoria + diferencia_dias;
    }
  }

  if (data.pagos.length == 0) {
    let { p_interes, p_moratoria } = CalculoMoratoria(
      interes_moratorio,
      re_capital,

      fecha_actual,
      fecha_vencimiento
    );

    /* Seteo interes punitorio */
    if (p_interes > 0) {
      re_interes_punitorio = p_interes;
      real_interes_punitorio = p_interes;
    }

    /* Seteo diferencia de dias */
    if (p_moratoria > 0) {
      diferencia_dias = p_moratoria + diferencia_dias;
    }
  }

  let deuda = 0;
  re_interes_punitorio = re_interes_punitorio < 0 ? 0 : re_interes_punitorio;
  re_capital = re_capital < 0 ? 0 : re_capital;
  deuda = re_capital + re_interes_punitorio;

  return {
    exmonto: (re_capital).toFixed(2),
    interes: (re_interes_punitorio).toFixed(2),
    diferencia: diferencia_dias,
    deuda: (deuda).toFixed(2),
  }
}



export const CalcularRestoJudicial = (pago) => {
  
  let capital = parseFloat(pago.monto);
  let interes_punitorio = parseFloat(pago.interes_moratorio) - parseFloat(pago.importe);

  if (interes_punitorio < 0) {
    capital = capital + interes_punitorio;
  }

  return {
    interes_punitorio: interes_punitorio,
    capital: capital < 0 ? 0 : capital,
  };
}

export const TotalsJuicio = (pagos) => {
  let importe = 0;
  let interes_punitorio_total = 0;
  let capital_total = 0;

  pagos.map((pago) => {

    let capital = parseFloat(pago.monto);
    let interes_punitorio = parseFloat(pago.interes_moratorio) - parseFloat(pago.importe);

    if (interes_punitorio < 0) {
      capital = capital + interes_punitorio;
    }
    
    importe = parseFloat(pago.importe) + importe;

    interes_punitorio_total = interes_punitorio < 0 ? parseFloat(pago.interes_moratorio)  + interes_punitorio_total :  (pago.interes_moratorio - interes_punitorio) + interes_punitorio_total;

    capital_total = parseFloat(capital.toFixed()) <= 0 ? parseFloat(pago.monto)  + capital_total :  (pago.monto - capital) + capital_total;

    /*
    interes_punitorio_total =
      parseFloat(pago.interes_punitorios) + interes_punitorio_total;
    interes_total = parseFloat(pago.interes) + interes_total;
    capital_total = parseFloat(pago.capital) + capital_total;
    */
  });

  return {
    subTotal: importe,
    interes_punitorio: interes_punitorio_total,
    capital: capital_total,
  };
};