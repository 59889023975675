import React from "react";

const CuotaPreCalculada = ({ tasa, montop, Precalcular, saldo_limite_cuota, diasDiferimiento, calificacion }) => {
  const valorCta = () => {
    //console.log(monto, tasa.cuotas)
    if (montop === null || tasa.cuotas === null || montop === undefined || saldo_limite_cuota < 0) {
      return 0;
    } else {
      const capital = montop;
      const interes = parseFloat((tasa.tna / 12) / 100);
      const meses = tasa.cuotas;

      const factor = Math.pow(interes + 1, meses);

      //calculo interes dia diferimiento
      //Importe de DF= Interes TNA / 365 *Total del crédito * Días de Diferimiento / Cantidad de cuotas
      const interesDF = diasDiferimiento > 0 ? parseFloat((montop * (tasa.tna / 100) * (diasDiferimiento / 365)) / meses) : 0;

      const cuota = ((capital * interes * factor) / (factor - 1) + interesDF).toFixed(2);
      //console.log(interesDF, cuota);

      return cuota;
    }
  };

console.log(saldo_limite_cuota);

  const label = (premium, tasa_premium) => {
    switch (premium) {
      case "SI":{
        if(tasa_premium == "1"){
          return <button
          style={{ display: saldo_limite_cuota > 0 ? "block" : "none" }}
          type="button"
          className="list-group-item border mx-auto w-75 px-5 list-group-item-action mb-1"
          aria-current="true"
          onClick={() => {
            Precalcular(tasa, montop);
          }}>
          <ul class="list-group bg-transparent w-100">
            <li className="list-group-item  bg-transparent border-0 d-flex justify-content-between m-0 p-0"><div className="border-0 w-50 px-1">Cuotas</div><div className="border rounded-pill w-100 text-end px-1">{tasa.cuotas}</div></li>
            <li className="list-group-item  bg-transparent border-0 d-flex justify-content-between m-0 p-0"><div className="border-0 w-50 px-1">Monto</div><div className="border rounded-pill w-100 text-end px-1">${new Intl.NumberFormat('de-DE').format(valorCta())}</div></li>
            <li className="list-group-item  bg-transparent border-0 d-flex justify-content-between m-0 p-0"><div className="border-0 w-50 px-1">TEA</div><div className="border rounded-pill w-100 text-end px-1">{(tasa.tea * 100).toFixed(2).toString().replace(/\./g, ',')} %</div></li>
            <li className="list-group-item  bg-transparent border-0 d-flex justify-content-between m-0 p-0"><div className="border-0 w-50 px-1">TNA</div><div className="border rounded-pill w-100 text-end px-1">{(tasa.tna).toString().replace(/\./g, ',')} %</div></li>
            <li className="list-group-item bg-transparent border-0 d-flex justify-content-between m-0 p-0">
              <div className="border-0 w-50 px-1">Tipo</div><div className="border-0 rounded-pill w-100 text-end px-1">{tasa.descripcion}</div>
            </li>
          </ul>

          {/* `${tasa.cuotas} Cuotas de $ ${new Intl.NumberFormat('de-DE').format(valorCta())} - ${tasa.descripcion} - (TEA: ${tasa.tea}) - (TNA: ${tasa.tna})` */}
        </button>
        }
      }break;
      case "NO":{
        if(tasa_premium !== "1"){
          return  <button
          style={{ display: valorCta() <= saldo_limite_cuota ? "block" : "none" }}
          type="button"
          className="list-group-item border mx-auto w-75 px-5 list-group-item-action mb-1"
          aria-current="true"
          onClick={() => {
            Precalcular(tasa, montop);
          }}
        >
          <ul class="list-group bg-transparent w-100">
            <li className="list-group-item  bg-transparent border-0 d-flex justify-content-between m-0 p-0"><div className="border-0 w-50 px-1">Cuotas</div><div className="border rounded-pill w-100 text-end px-1">{tasa.cuotas}</div></li>
            <li className="list-group-item  bg-transparent border-0 d-flex justify-content-between m-0 p-0"><div className="border-0 w-50 px-1">Monto</div><div className="border rounded-pill w-100 text-end px-1">${new Intl.NumberFormat('de-DE').format(valorCta())}</div></li>
            <li className="list-group-item  bg-transparent border-0 d-flex justify-content-between m-0 p-0"><div className="border-0 w-50 px-1">TEA</div><div className="border rounded-pill w-100 text-end px-1">{(tasa.tea * 100).toFixed(2).toString().replace(/\./g, ',')} %</div></li>
            <li className="list-group-item  bg-transparent border-0 d-flex justify-content-between m-0 p-0"><div className="border-0 w-50 px-1">TNA</div><div className="border rounded-pill w-100 text-end px-1">{(tasa.tna).toString().replace(/\./g, ',')} %</div></li>
          </ul>

          {/* `${tasa.cuotas} Cuotas de $ ${new Intl.NumberFormat('de-DE').format(valorCta())} - ${tasa.descripcion} - (TEA: ${tasa.tea}) - (TNA: ${tasa.tna})` */}
        </button>
        }
      } break;
    }
  }

  return <>
    {
    calificacion && tasa
    ? label(calificacion.premium, tasa.premium)
    : ""
    }
  </>
};

export default CuotaPreCalculada;
