import clienteAxios from "../config/axios";

export const ApiCreditos = async ({
  url,
  params,
  setLoading
}) => {
  setLoading(true);

  const response = await clienteAxios.get(
    url,
    {
      params: { id: params.persona.id },
    }
  ).then((result) => {
    setLoading(false);
    return result.data.creditos;
  }).catch((result) => {
    setLoading(false);
    return [];
  });

  return response
}