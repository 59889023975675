import React from "react";
import { TituloTabla } from "../../../shared/Estilos";
import DetalleCredito from "./Credito";

const ListarCreditos = ({ resultado, setCreditoSeleccionado }) => {
  if (resultado.length === 0) return <></>;
  return (
    <div
      className="table-responsive"
      style={{ paddingTop: "20px", paddingBottom: "20px" }}
    >
      Seleccione Credito: <br></br>
      <br></br>
      <table
        className="table table-striped"
        style={{ backgroundColor: "white" }}
      >
        <TituloTabla>
          <tr>
            <th scope="col" className="text-center">
              Seleccionar
            </th>
            <th scope="col" className="text-center">
              Nro de Credito
            </th>
            <th scope="col" className="text-center">
              Cliente
            </th>
            <th scope="col" className="text-center">
              Cuotas
            </th>
            <th scope="col" className="text-center">
              Pagas
            </th>
            <th scope="col" className="text-center">
              Vencidas
            </th>
          </tr>
        </TituloTabla>
        <tbody>
          {resultado.map((re) => (
            <DetalleCredito
              re={re}
              key={re.id}
              setCreditoSeleccionado={setCreditoSeleccionado}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListarCreditos;
