import { useState } from "react";
import clienteAxios from "../../../config/axios";
import AlertWarning from "../../CustomAlerts/AlertWarning";
import AlertSuccess from "../../CustomAlerts/AlertSuccess";
import { format, add, sub } from "date-fns";
import { tipoConcesion } from "../../shared/Helpers/CalculosConcesiones";
import { CuotasDetalle } from "../../../functions/Validaciones/CuotasDetalle";

import { CuotaDeuda } from "../../../functions/Validaciones/CuotaDeuda";
import { CalculoCuota } from "../../../functions/FunctionCuota";
import { useEffect } from "react";
import Swal from "sweetalert2";

const dateExtra = () => {
  return format(add(new Date(), { days: 1 }), "yyyy-MM-dd").toString();
};

const PanelJudicial = ({ juicio, setJuicio, setSteps, steps, setComprobante, setPago }) => {
  //formularios
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    //PAGO
    estado: "PARCIAL",
    forma_pago: "",
    fecha_importe: "",
    importe: 0,
    exmonto: juicio.exmonto,
    deuda: juicio.deuda,
    interes_moratorio: juicio.interes,
    //judicial
    judicial_id: juicio.id,
    comentarios: "",
  });

  const {
    id,
    id_persona,
    id_cajero,
    punto_venta_id,
    interes_punitorio,
    interes_moratorio,
    fecha_otorgamiento,
    item,
    monto,
    deuda,
    updated_at,
  } = juicio;

  const onClick = async (e) => {
    switch (e.target.name) {
      case "pagar":
        {
          if (form.forma_pago == "") {
            return AlertWarning({
              message: "La forma de pago es obligatoria",
              title: "Danger",
            });
          }
          if (form.importe <= 0) {
            return AlertWarning({
              message: "El importe debe ser mayor a 0",
              title: "Danger",
            });
          }

          let vuelto = 0;
          if (form.importe > deuda) {
            setForm({
              ...form,
              importe: deuda,
            });
            vuelto = form.importe - deuda;
          }

          let reporte = {
            //PAGO
            estado: form.estado,
            forma_pago: form.forma_pago,
            fecha_importe: format(new Date(), "yyyy-MM-dd").toString(),
            importe: vuelto > 0 ? deuda : form.importe,
            exmonto: juicio.exmonto,
            deuda: juicio.deuda,
            interes_moratorio: juicio.interes,
            //judicial
            judicial_id: juicio.id,
            comentarios: "",
          };

          setLoading(true);
          try {
            const result = await clienteAxios.post(
              "/PagosJudicial/store",
              reporte
            );
            AlertSuccess({ message: "Pago realizado!" });

            if (vuelto > 0) {
              Swal.fire({
                title: "Vuelto $" + vuelto,
                icon: "success",
              });
            }

            setSteps(steps + 1);
            setPago({
              pago: reporte,
              cuota: juicio,
            });
            setJuicio(null);
            setComprobante(true)
          } catch (e) {
            setLoading(false);
            AlertWarning({
              message: "",
              title: "Danger",
            });
          }
        }
        break;
    }
  };

  const onChange = (e) => {
    switch (e.target.name) {
      case "importe":
        {
          let estado = "PARCIAL";
          let importe = parseFloat(e.target.value);
          if (importe > deuda) {
            estado = "EXCEDENTE";
          }

          if (importe < deuda) {
            estado = "PARCIAL";
          }

          if (importe == deuda) {
            estado = "TOTAL";
          }

          setForm({
            ...form,
            estado: estado,
            importe: importe,
          });
        }
        break;
      case "forma_pago":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value,
          });
        }
        break;
    }
  };

  useEffect(() => {
    setForm({
      ...{
        //PAGO
        estado: "PARCIAL",
        forma_pago: "",
        fecha_importe: "",
        importe: 0,
        monto: juicio.deuda,
        interes_moratorio: juicio.interes,
        //judicial
        judicial_id: juicio.id,
        comentarios: "",
      },
    });
  }, [juicio]);
  return (
    <div className="row bg-white shadow">
    <div className="col-md-12">
      <div
        className="fw-bold fs-3 mb-1 text-center w-100 py-2"
        style={{
          borderRadius: "20px 20px 20px 20px",
        }}
      >
        PAGAR GASTO
      </div>
    </div>
      <div
        className="col-md-12  "
        style={{ borderRadius: "20px" }}
      >
        <div className="d-md-flex justify-content-center pt-3">
          <div class="mb-3 w-100 text-center">
            <label for="exampleFormControlInput1" class="form-label fw-bold">
              TOTAL A PAGAR
            </label>
            <input
              type="text"
              class="form-control bg-white fs-3 text-center fw-bold mx-auto py-3"
              style={{ borderRadius: "20px", width: "400px" }}
              id="exampleFormControlInput1"
              placeholder="0.00"
              value={"$ " + new Intl.NumberFormat("de-DE").format(form.monto)}
              disabled
            />
          </div>
          <div class="mb-3 w-100">
            <label for="exampleFormControlInput1" class="form-label fw-bold">
              FORMA DE PAGO
            </label>
            <select
              class="form-select fs-4 py-3"
              style={{ borderRadius: "20px" }}
              aria-label="Default select example"
              name="forma_pago"
              onChange={(event) => onChange(event)}
            >
              <option selected></option>
              <option value="efectivo">Efectivo</option>
              <option value="tarjeta">Tarjeta</option>
              <option value="cheque">Cheque</option>
              <option value="Judicial">Judicial</option>
              <option value="Bancario">Bancario</option>
            </select>
          </div>
        </div>

        <div className="d-md-flex">
          <div class="mb-3 w-100 text-center">
            <label for="exampleFormControlInput1" class="form-label fw-bold">
              ESTADO DEL PAGO
            </label>
            <input
              type="text"
              class="form-control bg-white border-0 fs-4 text-center"
              id="exampleFormControlInput1"
              value={form.estado}
              disabled
            />
          </div>
          <div class="mb-3 w-100">
            <label for="exampleFormControlInput1" class="form-label fw-bold">
              IMPORTE
            </label>
            <input
              type="number"
              step="0.00"
              class="form-control fs-4 py-3"
              id="exampleFormControlInput1"
              placeholder="0.00"
              style={{ borderRadius: "20px" }}
              name="importe"
              onChange={(event) => onChange(event)}
              value={form.importe}
            />
          </div>
        </div>
        <div class="mb-3 d-flex justify-content-center">
          <button
            className="fs-4 w-100 btn btn-danger rounded-pill shadow-sm"
            name="pagar"
            onClick={(event) => onClick(event)}
          >
            Pagar
          </button>
        </div>
      </div>
    </div>
  );
};

export default PanelJudicial;
