import { useState } from "react";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import usePuntosVenta from "../../hooks/usePuntosVenta";
import AlertWarning from "../CustomAlerts/AlertWarning";
import { clienteAxios } from "../shared";
import Filtro from "./filtros/Filtro";
import ModalPuntoVenta from "./modals/ModalPuntoVenta";
import Tabla from "./tablas/Tabla";

const PuntosVentas = ({ data, params, setParams }) => {
  const [steps, setSteps] = useState(0);
  const [select, setSelect] = useState(null);
  //Modals
  const [modal_punto_venta, setModalPuntoVenta] = useState(false);
  //Formulario
  const [form, setForm] = useState({
    id: null,
    nombre: "",
    limite_credito: "",
    direccion: "",
    provincia: "",
    ciudad: "",
    status: "",
  });

  //Eventos
  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "guardar":
        {
          if (
            form.nombre == "" ||
            form.limite_credito == "" ||
            form.direccion == "" ||
            form.provincia == "" ||
            form.ciudad == ""
          ) {
            return AlertWarning({
              message: "Complete todos los datos del formulario",
              title: "Advertencia",
            });
          }

          const result = await clienteAxios.post("PuntosDeVenta/store", form);
          //
          Swal.fire({
            title: "Punto de venta " + form.nombre + " Creado con exito",
          });

          //Actualizar
          setModalPuntoVenta(false);
          setSteps(steps + 1);
          setForm({
            id: null,
            nombre: "",
            limite_credito: "",
            direccion: "",
            provincia: "",
            ciudad: "",
            status: "",
          });
        }
        break;
      case "aplicar":
        {
          let formData = select;

          Object.keys(form).map((item) => {
            if(form[item] !== "" && form[item]){
              formData[item] = form[item];
            }
          })

          const result = await clienteAxios.post(
            "PuntosDeVenta/update",
            formData
          );
          //
          Swal.fire({
            title: "Punto de venta " + formData.nombre + " Actualizado con exito",
          });
          //Actualizar
          setSelect(null);
          setModalPuntoVenta(false);
          setSteps(steps + 1);
          setForm({
            id: null,
            nombre: "",
            limite_credito: "",
            direccion: "",
            provincia: "",
            ciudad: "",
            status: "",
          });
        }
        break;
      //Modals
      case "modal_punto_venta":
        {
          setModalPuntoVenta(true);
          setSelect(value);
        }
        break;
      case "close":
        {
          setModalPuntoVenta(false);
        }
        break;
    }
  };
  return (
    <div className="container-md min-vh-100 my-5">
      <h3 className="text-center mb-3">Gestionar puntos de ventas</h3>

      <div className="row">
        <div className="col-md-12 mb-3">
          <Filtro
            setParams={setParams}
            params={params}
            setForm={setForm}
            form={form}
            setModalPuntoVenta={setModalPuntoVenta}
            setSelect={setSelect}
          />
        </div>
        <div className="col-md-12 mb-3">
          {data ? (
            <Tabla
              data={data}
              setModalPuntoVenta={setModalPuntoVenta}
              setSelect={setSelect}
            />
          ) : (
            ""
          )}
        </div>

        {modal_punto_venta ? (
          <ModalPuntoVenta
            data={select}
            onClick={onClick}
            form={form}
            setForm={setForm}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default PuntosVentas;
