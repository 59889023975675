export const calcularDiffDias = (vencimiento) => {
  if (vencimiento) {
    let vto = new Date(vencimiento.replace("-", "/"));
    const hoy = new Date();
    const dif = Math.floor((hoy - vto) / (1000 * 60 * 60 * 24));
    if (vto > hoy) {
      return dif < 0 ? 0 : dif;
    } else {
      return 0;
    }
  }
};

export const intPunitorio = (re, interes_punitorio) => {
  return (
    ((parseFloat(interes_punitorio) / 30) *
      parseInt(calcularDiffDias(re.fecha_vencimiento)) *
      parseFloat(re.capital)) /
    100
  ).toFixed(2);
};

export const tipoConcesion = (id_condicion) => {
  switch (id_condicion) {
    case "1":
      return "Congela IP";
    case "2":
      return "Bonificar Porcentaje & Corre IP";
    case "3":
      return "Bonificar Porcentaje & Congela IP";
    default:
      return "";
  }
};
