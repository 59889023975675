import React from "react";
import { BotonEditar, BotonEliminar } from "../../../shared/FormsElements";

const Telefono = ({ telefono, editar, eliminar, soloLectura }) => {
  return (
    <tr>
      <td className="text-center">{telefono.tipo_telefono}</td>
      <td className="text-center">{telefono.nro_telefono_prefix}</td>
      <td className="text-center">{telefono.nro_telefono}</td>
      <td className="text-center">{telefono.referencia_telefono}</td>
      <td className="text-center">{telefono.descripcion}</td>
      <td className="text-center">
        {!soloLectura ? (
          <>
            <BotonEditar editar={editar} item={telefono} />
            <BotonEliminar eliminar={eliminar} item={telefono} />
          </>
        ) : null}
      </td>
    </tr>
  );
};

export default Telefono;
