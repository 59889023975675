import { CalcularDeudaTotal, CalculoCuota } from "../../../functions/FunctionCuota";
import { CreditoDetalles } from "../../../functions/Validaciones/CreditoDetalles";
import { CuotasDetalle } from "../../../functions/Validaciones/CuotasDetalle";
import { tipoConcesion } from "../../shared/Helpers/CalculosConcesiones";
const Tabla_creditos = ({ data, onClick, onChange }) => {
  //Calcular cuotas
  const cuotas = (cuotas) => {
    let result = [];

    cuotas.map((cuota) => {
      let re = CalculoCuota(cuota);
      result.push(re);
    })

    return result;
  }

  return (
    <div className="table-responsive">
      <table className="table table-hover align-middle">
        <thead className="border-0">
          <tr className="border" style={{ borderRadius: "20px", background: "rgba(217, 217, 217, 0.5)" }}>
            <th className="border-0 fs-5 p-1"><div className="border-0 p-1" style={{borderRadius: "10px"}}>Nº</div></th>
            <th className="border-0 fs-5 p-1"><div className="border-0 p-1" style={{borderRadius: "10px"}}>Producto</div></th>
            <th className="border-0 fs-5 p-1"><div className="border-0 p-1" style={{borderRadius: "10px"}}>Monto</div></th>
            <th className="border-0 fs-5 p-1"><div className="border-0 p-1" style={{borderRadius: "10px"}}>Cuotas</div></th>
            <th className="border-0 fs-5 p-1"><div className="border-0 p-1" style={{borderRadius: "10px"}}>Pagas</div></th>
            <th className="border-0 fs-5 p-1"><div className="border-0 p-1" style={{borderRadius: "10px"}}>Vencidas</div></th>
            <th className="border-0 fs-5 p-1"><div className="border-0 p-1" style={{borderRadius: "10px"}}>Deuda</div></th>
            <th className="border-0 fs-5 p-1"><div className="border-0 p-1" style={{borderRadius: "10px"}}>Concesión</div></th>
            <th className="border-0 fs-5 p-1"><div className="border-0 p-1" style={{borderRadius: "10px"}}>Resumen</div></th>
            <th className="border-0 fs-5  p-1"><div className="border-0 p-1" style={{borderRadius: "10px"}}>Pagar</div></th>
          </tr>
        </thead>
        <tbody className="border-0">
          {data.map((credito) => (
            <tr>
              <td className="border-0">{credito.id}</td>
              <td className="border-0">{credito.producto}</td>
              <td className="border-0 text-nowrap">${" "} {
              new Intl.NumberFormat('de-DE').format(credito.monto)
              }</td>
              <td className="border-0">{credito.detalles.length}</td>
              <td className="border-0">
                {CuotasDetalle(credito.detalles, credito.concesion).pagas}
              </td>
              <td className="border-0">
                {CuotasDetalle(credito.detalles, credito.concesion).vencidas}
              </td>
              <td className="border-0 text-nowrap">
                ${" "} 
                {
                new Intl.NumberFormat('de-DE').format(CalcularDeudaTotal(0, cuotas(CreditoDetalles(credito).detalles)))
                }
              </td>
              <td className="border-0">
                {tipoConcesion(
                  credito.concesion ? credito.concesion.id_tipoconcesion : ""
                )}
              </td>
              <td className="border-0">
                <label className="btn btn-dark rounded-pill">
                  <i class="bi bi-card-checklist me-1"></i>
                  Resumen
                  <button 
                  className="d-none"
                  name="resumen"
                  data-bs-toggle="modal" 
                  data-bs-target="#resumen-credito"
                  onClick={(event) => onClick(event, credito)}
                  ></button>
                </label>
              </td>
              <td className="border-0">
                <label className="btn btn-primary rounded-pill">
                  <i class="me-1 bi bi-plus-circle"></i>
                  Pagar
                  <button
                    className="d-none"
                    name="credito"
                    onClick={(event) => onClick(event,  credito)}
                  >
                    {" "}
                  </button>
                </label>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot></tfoot>
      </table>
    </div>
  );
};

export default Tabla_creditos;
