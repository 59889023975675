import React, { useReducer } from "react";
import concesionesReducer from "./concesionesReducer";
import concesionesContext from "./concesionesContext";
import clienteAxios from "../../config/axios";

import {
  CCREGISTRO_ERROR,
  CCREGISTRO_EXITO,
  CCOBTENER_CONSECIONES,
  CCACTUALIZAR_CONCESION,
  CCSELECCIONAR_CONSECION,
  CCELIMINAR_CONCESION,
  CCCARGANDO,
  CCORDENAR_FILTRAR_CONSECIONES,
  CCDESELECCIONAR_CONCESION,
} from "../../types";

const ConcesionesState = (props) => {
  const initialState = {
    concesionSeleccionada: null,
    concesiones: [],
    resultado: [],
    cargando: false,
    mensaje: { msg: null, class: null },
  };

  const [state, dispatch] = useReducer(concesionesReducer, initialState);

  const ordenarFiltrar = (filtros, campoOrden, tipoOrden) => {
    let elementos = state.concesiones;
    //console.log(elementos);
    if (filtros !== null) {
      elementos = elementos
        .filter((item) =>
          String(item.id_credito)
            .toLowerCase()
            .startsWith(
              `${filtros.id_credito !== undefined ? filtros.id_credito : ""}`
            )
        )
        .filter((item) =>
          String(item.cliente)
            .toLowerCase()
            .startsWith(
              `${filtros.cliente !== undefined ? filtros.cliente : ""}`
            )
        )
        .filter((item) =>
          String(item.nombre_condicion)
            .toLowerCase()
            .startsWith(
              `${
                filtros.nombre_condicion !== undefined
                  ? filtros.nombre_condicion
                  : ""
              }`
            )
        )
        .filter((item) =>
          String(item.estado)
            .toLowerCase()
            .startsWith(`${filtros.estado !== undefined ? filtros.estado : ""}`)
        )
        .filter((item) =>
          String(item.fecha_alta)
            .toLowerCase()
            .startsWith(
              `${filtros.fecha_alta !== undefined ? filtros.fecha_alta : ""}`
            )
        )
        .filter((item) =>
          String(item.user_username)
            .toLowerCase()
            .startsWith(
              `${
                filtros.user_username !== undefined ? filtros.user_username : ""
              }`
            )
        );
    } else {
      elementos = state.concesiones;
    }

    //console.log("personas", state.personas);

    // ordenar asc y desc
    if (campoOrden !== null && tipoOrden !== null) {
      if (tipoOrden !== null) {
        if (tipoOrden === "asc") {
          elementos = elementos.sort(function (a, b) {
            return String(a[campoOrden])
              .toLowerCase()
              .localeCompare(String(b[campoOrden]).toLowerCase());
          });
        } else {
          elementos = elementos.sort(function (a, b) {
            return String(b[campoOrden])
              .toLowerCase()
              .localeCompare(String(a[campoOrden]).toLowerCase());
          });
        }
      }
    } else {
      // elementos = state.personas;
    }

    dispatch({
      type: CCORDENAR_FILTRAR_CONSECIONES,
      payload: elementos,
    });
  };

  const deseleccionar = () => {
    dispatch({
      type: CCDESELECCIONAR_CONCESION,
    });
  };

  const ocultarMensaje = () => {
    dispatch({
      type: CCREGISTRO_EXITO,
    });
  };

  const actualizar = (concesion) => {
    //console.log(usuario);
    dispatch({
      type: CCACTUALIZAR_CONCESION,
      payload: concesion,
    });
  };

  const eliminar = async (id) => {
    try {
      await clienteAxios.delete("/Tasa/remove", {
        params: { id },
      });
      dispatch({
        type: CCELIMINAR_CONCESION,
        payload: id,
      });
    } catch (e) {
      dispatch({
        type: CCREGISTRO_ERROR,
        payload: "Hubo un error",
      });
    }
  };

  const seleccionar = (esError, respuesta) => {
    //console.log(respuesta.data);
    if (esError) {
    } else {
      dispatch({
        type: CCSELECCIONAR_CONSECION,
        payload: respuesta.data.tasa,
      });
    }
  };

  const obtener = async (texto) => {
    try {
      dispatch({
        type: CCCARGANDO,
        payload: true,
      });
      const resultado = await clienteAxios.get("/concesion/getAll", {
        params: { texto },
      });
      dispatch({
        type: CCOBTENER_CONSECIONES,
        payload: resultado.data.concesiones,
      });
    } catch (error) {}
  };

  const registrar = (esError, respuesta) => {
    const mensaje = {
      msg: esError ? respuesta : "Se Registro el Usuario con Exito. ",
      class: esError ? "danger" : "success",
    };

    dispatch({
      type: CCREGISTRO_ERROR,
      payload: mensaje, //respuesta.data.msg
    });
  };

  return (
    <concesionesContext.Provider
      value={{
        mensaje: state.mensaje,
        concesiones: state.concesiones,
        concesionSeleccionada: state.concesionSeleccionada,
        cargando: state.cargando,
        resultado: state.resultado,
        seleccionar,
        obtener,
        ocultarMensaje,
        registrar,
        actualizar,
        eliminar,
        ordenarFiltrar,
        deseleccionar,
      }}
    >
      {props.children}
    </concesionesContext.Provider>
  );
};

export default ConcesionesState;
