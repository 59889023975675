import { useContext, useEffect, useState } from "react";
import GastosComponent from "./gastosComponent";
import { ApiJudiciales } from "../../../Helpers/ApiJudiciales";
import { AuthContext, clienteAxios } from "../../shared";
import AlertSuccess from "../../CustomAlerts/AlertSuccess";
import AlertWarning from "../../CustomAlerts/AlertWarning";
import { CalculoPagoJudicial } from "../../../functions/Validaciones/PagoJudicial";
import apiAxios from "../../../config/ApiAxios";
import Swal from "sweetalert2";

const GastosController = () => {
  const authContext = useContext(AuthContext);
  const { usuario, cerrarSesion } = authContext;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState(0);
  const [resumenJudicial, setResumenJudicial] = useState(false);
  const [juicio, setJuicio] = useState(null);
  const [params, setParams] = useState({
    page: 1,
    estado: ['ACTIVO','CANCELADA'],
  })
  const [form, setForm] = useState({
    id_persona: null, //data.id,
    estado: "",
    interes_moratorio: "8.00",
    fecha_otorgamiento: "",
    item: "",
    monto: "",
  });

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "anterior": {
        if (data.page !== 1) {
          setData(null);
          let payload = {
            ...params,
            page: params.page - 1
          }
          fetch(payload)
          setParams({
            ...payload
          })
        }
      } break;
      case "siguiente": {
        setData(null);
        let payload = {
          ...params,
          page: params.page + 1
        }
        fetch(payload)
        setParams({
          ...payload
        })
      } break;
      case "link": {
        setData(null);
        let payload = {
          ...params,
          page: value
        }
        fetch(payload)
        setParams({
          ...payload
        })
      } break;
      
      case "resumen-judicial":
        {
          setJuicio({
            ...value,
            exmonto: CalculoPagoJudicial(value).exmonto,
            deuda: CalculoPagoJudicial(value).deuda,
            interes: CalculoPagoJudicial(value).interes,
          });
          setResumenJudicial(true);
        }
        break;
      case "crear-gasto":
        {
          try {
            const result = await clienteAxios.post(
              "/PersonaJudicial/store",
              form
            );
            AlertSuccess({ message: "Gasto creado!" });
            //setSteps(steps + 1);
            setData(null);
            return document.getElementById("btn-close-modal").click();
          } catch (e) {
            setLoading(false);
            AlertWarning({
              message: "",
              title: "Danger",
            });
          }
        }
        break;
      case "search": {
        let payload = {
          search: e.target.value,
        };
        setParams({
          ...params,
          payload
        })
        setLoading(true);
        const response = await apiAxios.get("/gestion/mora/judicial", {
          params: payload,
        });
        if (response.status == 200 || response.status == 201) {
          setData(response.data.data);
          setLoading(false);
        }
      } break;
      case "eliminar-judicial": {
        if (value.pagos.length > 0) {
          return AlertWarning({
            message:
              "El gasto contiene pagos habilitados, no se puede eliminar",
            title: "Danger",
          });
        }
        //LLave de administrador
        Swal.fire({
          title: "Cancelar pago",
          html:
            "<p>¿Quieres dar de baja el siguiente gasto?</p>" +
            '<ul class="list-group"><li class="list-group-item border-0 d-flex justify-content-between"><div>Estado</div><div>' +
            value.item +
            '</div></li><li class="list-group-item border-0 d-flex justify-content-between"><div>Fecha de importe</div><div>' +
            value.fecha_otorgamiento +
            '</div></li><li class="list-group-item border-0 d-flex justify-content-between"><div>Tipo de importe</div><div>' +
            value.monto +
            '</ul><div class="my-3 text-muted"> Para confirmar la baja de un pago se necesitan credenciales de administrador, ingrese su clave para completar la baja.</div>',
          input: "password",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Eliminar",
          showLoaderOnConfirm: true,
          preConfirm: async (result) => {
            let formData = new FormData();
            formData.append("id", value.id);
            formData.append("password", result);

            const response = await clienteAxios({
              method: "post",
              url: "/PersonaJudicial/delete",
              data: formData,
            })
              .then((result) => {
                Swal.fire({
                  title: `Se dio de Baja Correctamente`,
                });

                //Actualizar datos
                //setSteps(steps + 1);
                fetch(params);
              })
              .catch((result) => {
                return Swal.showValidationMessage(
                  `Request failed: ${result.response.data.messages.msg}`
                );
              });
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
          console.log("ok");
        });
      } break;
      default:
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      default:
        break;
    }
  };

  const fetch = async (params) => {
    setLoading(true);
    const response = await apiAxios.get("/gestion/mora/judicial", {
      params: params,
    });
    if (response.status == 200 || response.status == 201) {
      setData(response.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!data) {
      fetch(params);
    }
  }, [data]);
  return (
    <GastosComponent
      data={data}
      onClick={onClick}
      onChange={onChange}
      setSteps={setSteps}
      steps={steps}
      usuario={usuario}
      form={form}
      setForm={setForm}
      setResumenJudicial={setResumenJudicial}
      resumenJudicial={resumenJudicial}
      setJuicio={setJuicio}
      juicio={juicio}
    />
  );
};

export default GastosController;
