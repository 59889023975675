import { format, add, sub } from "date-fns";
import { Totals, CalcularResto } from "../../../functions/FunctionReportes";
import { CalcularRestoJudicial, TotalsJuicio } from "../../../functions/Validaciones/PagoJudicial";

const Tabla_pagos_judicial = ({ data, onClick }) => {
  const totales = TotalsJuicio(data);
  return (
    <div className="min-vh-100">
      <table className="table" id="tabla_caja" style={{
        overflowY: "auto"
      }}>
        <thead className="tr_sticky">
          <tr 
            className="border-0"
            style={{
              borderRadius: "20px",
              background: "rgba(217, 217, 217, 0.5)",
            }}>
            <th className="border-0 text-nowrap">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Id
              </div>
            </th>
            <th className="border-0 text-nowrap">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Judicial
              </div>
            </th>
            <th className="border-0 text-nowrap">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Cajero
              </div>
            </th>
            <th className="border-0 text-nowrap">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Punto de venta
              </div>
            </th>
            <th className="border-0 text-nowrap">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Cliente
              </div>
            </th>
            <th className="border-0 text-nowrap">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Fecha de importe
              </div>
            </th>
            <th className="border-0 text-nowrap">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Tipo de pago
              </div>
            </th>
            <th className="border-0 text-nowrap">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Tipo de importe
              </div>
            </th>
            <th className="border-0 text-nowrap">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Importe
              </div>
            </th>
            <th className="border-0 text-nowrap">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Interes Moratorio
              </div>
            </th>
            <th className="border-0 text-nowrap">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Monto
              </div>
            </th>
            <th className="border-0 text-nowrap d-none">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Deuda
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((pago) => (
            <tr>
              <td className="border-0">{pago.id}</td>
              <td className="border-0">{pago.gasto.item}</td>
              <td className="border-0">
                {pago.cajero.name + " " + pago.cajero.lastname}
              </td>
              <td className="border-0">
                {pago.punto_venta
                  ? pago.punto_venta.nombre
                  : "Sin punto de venta asignado"}
              </td>
              <td className="border-0">
                {pago.persona_nombre + " " + pago.persona_apellido}
              </td>
              <td className="border-0">
                {format(
                  new Date(pago.fecha_importe + " 00:00:00"),
                  "dd/MM/yyyy"
                ).toString()}
              </td>
              <td className="border-0">{pago.forma_pago}</td>
              <td className="border-0">{pago.estado}</td>
              <td className="border-0 text-nowrap">
                {"$ " + new Intl.NumberFormat("de-DE").format(pago.importe)}
              </td>

              <td className="border-0 text-nowrap">
                <div>
                  {
                    CalcularRestoJudicial(pago).interes_punitorio < 0
                      ? new Intl.NumberFormat("de-DE").format(pago.interes_moratorio)
                      : new Intl.NumberFormat("de-DE").format(
                        pago.interes_moratorio - CalcularRestoJudicial(pago).interes_punitorio
                      )
                  }
                </div>
              </td>


              <td className="border-0 text-nowrap">
                <div>
                  {
                    CalcularRestoJudicial(pago).capital < 0
                      ? new Intl.NumberFormat("de-DE").format(pago.monto)
                      : new Intl.NumberFormat("de-DE").format((pago.monto - CalcularRestoJudicial(pago).capital))
                  }
                </div>
              </td>

              <td className="border-0 text-nowrap d-none">
                {"$ " +
                  new Intl.NumberFormat("de-DE").format(
                    parseFloat(pago.monto) +
                    parseFloat(pago.interes_moratorio)
                  )}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr className="table-secondary ">
            <td colSpan={8}>TOTAL</td>
            <td className="text-nowrap">
              {"$ " + new Intl.NumberFormat("de-DE").format(totales.subTotal)}
            </td>
            <td colSpan={3} className="text-nowrap d-none">
              {"$ " +
                new Intl.NumberFormat("de-DE").format(
                  totales.interes_punitorio + totales.capital
                )}
            </td>
            <td className="text-nowrap">
              {"$ " +
                new Intl.NumberFormat("de-DE").format(
                  totales.interes_punitorio
                )}
            </td>
            <td colSpan={3} className="text-nowrap">
              {"$ " + new Intl.NumberFormat("de-DE").format(totales.capital)}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default Tabla_pagos_judicial;
