import React, { useReducer } from "react";
import Reducer from "./pagosReducer";
import Context from "./pagosContext";
import clienteAxios from "../../config/axios";

import {
  PREGISTRO_ERROR,
  PREGISTRO_EXITO,
  POBTENER_PAGOS,
  PACTUALIZAR_PAGO,
  PSELECCIONAR_PAGO,
  PELIMINAR_PAGO,
  PCARGANDO,
  PORDENAR_FILTRAR_PAGOS,
  PDESELECCIONAR_PAGO,
} from "../../types";

const ConcesionesState = (props) => {
  const initialState = {
    pagoSeleccionado: null,
    pagos: [],
    resultado: [],
    cargando: false,
    mensaje: { msg: null, class: null },
  };

  const [state, dispatch] = useReducer(Reducer, initialState);

  const ordenarFiltrar = (filtros, campoOrden, tipoOrden) => {
    let elementos = state.pagos;
    //console.log(elementos);
    if (filtros !== null) {
      elementos = elementos
        .filter((item) =>
          String(item.id)
            .toLowerCase()
            .startsWith(
              `${filtros.id !== undefined ? filtros.id : ""}`
            )
        )
        .filter((item) =>
          String(item.cliente)
            .toLowerCase()
            .startsWith(
              `${filtros.cliente !== undefined ? filtros.cliente : ""}`
            )
        )
        .filter((item) =>
          String(item.forma_pago)
            .toLowerCase()
            .startsWith(
              `${
                filtros.forma_pago !== undefined
                  ? filtros.forma_pago
                  : ""
              }`
            )
        )
        .filter((item) =>
          String(item.estado_pago)
            .toLowerCase()
            .startsWith(`${filtros.estado_pago !== undefined ? filtros.estado_pago : ""}`)
        )
        .filter((item) =>
          String(item.importe_pagado)
            .toLowerCase()
            .startsWith(
              `${filtros.importe_pagado !== undefined ? filtros.importe_pagado : ""}`
            )
        )
        .filter((item) =>
          String(item.fecha_pagado)
            .toLowerCase()
            .startsWith(
              `${
                filtros.fecha_pagado !== undefined ? filtros.fecha_pagado : ""
              }`
            )
        )
        .filter((item) =>
        String(item.cajero)
          .toLowerCase()
          .startsWith(
            `${
              filtros.cajero !== undefined ? filtros.cajero : ""
            }`
          )
      );
    } else {
      elementos = state.pagos;
    }

    //console.log("personas", state.personas);

    // ordenar asc y desc
    if (campoOrden !== null && tipoOrden !== null) {
      if (tipoOrden !== null) {
        if (tipoOrden === "asc") {
          elementos = elementos.sort(function (a, b) {
            return String(a[campoOrden])
              .toLowerCase()
              .localeCompare(String(b[campoOrden]).toLowerCase());
          });
        } else {
          elementos = elementos.sort(function (a, b) {
            return String(b[campoOrden])
              .toLowerCase()
              .localeCompare(String(a[campoOrden]).toLowerCase());
          });
        }
      }
    } else {
      // elementos = state.personas;
    }

    dispatch({
      type: PORDENAR_FILTRAR_PAGOS,
      payload: elementos,
    });
  };

  const deseleccionar = () => {
    dispatch({
      type: PDESELECCIONAR_PAGO,
    });
  };

  const ocultarMensaje = () => {
    dispatch({
      type: PREGISTRO_EXITO,
    });
  };

  const actualizar = (pago) => {
    //console.log(usuario);
    dispatch({
      type: PACTUALIZAR_PAGO,
      payload: pago,
    });
  };

  const eliminar = async (id) => {
    try {
      await clienteAxios.delete("/Pagos/remove", {
        params: { id },
      });
      dispatch({
        type: PELIMINAR_PAGO,
        payload: id,
      });
    } catch (e) {
      dispatch({
        type: PREGISTRO_ERROR,
        payload: "Hubo un error",
      });
    }
  };

  const seleccionar = (esError, respuesta) => {
    //console.log(respuesta.data);
    if (esError) {
    } else {
      dispatch({
        type: PSELECCIONAR_PAGO,
        payload: respuesta.data.pago,
      });
    }
  };

  const obtener = async (texto) => {
    try {
      dispatch({
        type: PCARGANDO,
        payload: true,
      });
      const resultado = await clienteAxios.get("/Pago/getAll", {
        params: { texto },
      });
      dispatch({
        type: POBTENER_PAGOS,
        payload: resultado.data.pagos,
      });
    } catch (error) {}
  };

  const registrar = (esError, respuesta) => {
    const mensaje = {
      msg: esError ? respuesta : "Se Registro el Pago con Exito. ",
      class: esError ? "danger" : "success",
    };

    dispatch({
      type: PREGISTRO_ERROR,
      payload: mensaje, //respuesta.data.msg
    });
  };

  return (
    <Context.Provider
      value={{
        mensaje: state.mensaje,
        pagos: state.pagos,
        pagoSeleccionado: state.pagoSeleccionado,
        cargando: state.cargando,
        resultado: state.resultado,
        seleccionar,
        obtener,
        ocultarMensaje,
        registrar,
        actualizar,
        eliminar,
        ordenarFiltrar,
        deseleccionar,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default ConcesionesState;
