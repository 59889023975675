import React, { useState, useContext, useEffect } from "react";
import { BotonLogin } from "../shared/Estilos";
import { AuthContext, Spinner, useRest, useAlerta, styled } from "../shared/";
import LoginComponent from "./LoginComponent";
//xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

const ContenedorFormLogin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
`;

const FormLogin = styled.form`
  width: 450px;
`;

const Login = (props) => {
  const authContext = useContext(AuthContext);
  const { autenticado, iniciarSesion, ocultarMensaje, mensaje } = authContext;

  const [setAlerta, MostrarAlerta] = useAlerta(null);
  const [loading, load] = useRest();
  const [inicio, setInicio] = useState(true);
  const [eye, setEye] = useState(false);

  useEffect(() => {
    if (autenticado) {
      ocultarMensaje();
      // eslint-disable-next-line
      props.history.push("/dashboard");
    }

    const alertar = () => {
      if (loading) {
        setAlerta(null);
      }
      if (inicio) {
        // eslint-disable-next-line
        ocultarMensaje();
        setInicio(null);
        setAlerta(null);
      } else {
        if (mensaje.msg && !loading) {
          setAlerta({ msg: mensaje.msg, class: mensaje.class });
        }
      }
    };

    alertar();
  }, [
    autenticado,
    mensaje,
    loading,
    inicio,
    setAlerta,
    props.history,
    ocultarMensaje,
  ]);

  const [DatosForm, LeerForm] = useState({
    email: "",
    password: "",
  });
  const { email, password } = DatosForm;

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });
  };

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "":

        break;

      default:
        break;
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();

    if (email.trim() === "" || password.trim() === "") {
      setAlerta({ msg: "Todos los campos son obligatorios", class: "danger" });
      return;
    } else {
      const api = {
        url: "/auth",
        type: "post",
        datosJson: {
          email,
          password,
        },
        getParams: null,
        funcion: iniciarSesion,
      };

      load(api);
    }
  };

  return (
    <div className="container-md min-vh-100 d-flex justify-content-center items-align-center">
      
      <LoginComponent 
          onSubmit={onSubmit}
          onChange={onChange}
          form={{
            email: email,
            password: password
          }}
          setEye={setEye}
          eye={eye}
      />

      <h2 className="text-center d-none">Iniciar Sesion</h2>

      <ContenedorFormLogin className="d-none" >
        <FormLogin onSubmit={onSubmit} className="border p-3 form" style={{ borderRadius: "10px" }}>
          <div className="text-center">
            <img src="./assets/images/iconos-para-prestamos_Credixpress-05.jpg" width="100px" alt="logo" />
          </div>
          <div className="form-group">
            <label htmlFor="email">Usuario</label>
            <input
              type="text"
              name="email"
              className="form-control"
              id="email"
              aria-describedby="emailHelp"
              placeholder="Ingrese su Usuario"
              style={{ borderRadius: "15px" }}
              onChange={onChange}
              value={email}
            />
          </div>
          <br></br>
          <div className="form-group">
              <label htmlFor="password">Contraseña</label>
            <div class="input-group mb-3">
              <label class="input-group-text rounded-pill" id="basic-addon1">
              {
                eye 
                ? <i class="bi bi-eye"></i>
                : <i class="bi bi-eye-slash"></i>
              }
              <button className="d-none" onClick={(e) => {
                e.preventDefault()
                setEye(!eye)}
              }></button>
              </label>
              <input class="form-control" placeholder="Contraseña" aria-label="Username" aria-describedby="basic-addon1"
                type={eye ? "test" : "password"}
                name="password"
                onChange={onChange}
                value={password}
              />
            </div>
          </div>
          <br></br>
          {loading ? <Spinner /> : <MostrarAlerta />}
          <br></br>
          <div className="col-sm d-grid gap-2">
            <BotonLogin type="submit" className="btn btn-dark">
              Ingresar
            </BotonLogin>
          </div>
        </FormLogin>
      </ContenedorFormLogin>
    </div>
  );
};

export default Login;
