import { format } from "date-fns";
import { useState } from "react";
import { log } from "util";
import { PagarCuota } from "../../../functions/FunctionCuota";
import { CuotasDetalle } from "../../../functions/Validaciones/CuotasDetalle";
import AlertSuccess from "../../CustomAlerts/AlertSuccess";
import { clienteAxios } from "../../shared";
import { tipoConcesion } from "../../shared/Helpers/CalculosConcesiones";

const ModalEditarCredito = ({ setModal, select, setSelect }) => {
  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "close":
        setModal(false);
        break;
      case "guardar":
        {
          const result = await clienteAxios.post(
            "PersonaCreditosDetalle/update_vto",
            form
          );

          AlertSuccess({
            message: result.data.msg,
          });

          return setModal(false);
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "vencimiento":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value,
            id_cuota: cuota_actual.id,
            id_credito: cuota_actual.id_credito,
          });
        }
        break;
    }
  };

  const {
    concesion,
    comentarios,
    cuotas,
    detalles,
    estado,
    persona,
    fecha_finalizacion,
    fecha_otorgamiento,
    garante,
    id,
    id_persona,
    id_ultimo_pago,
    interes,
    interes_moratoria,
    interes_punitorio,
    monto,
    producto,
    resumen,
    vencimiento,
  } = select;

  const cuotas_detalle = CuotasDetalle(detalles);
  const cuota_actual = PagarCuota(detalles);

  const [form, setForm] = useState({
    vencimiento: cuota_actual ? cuota_actual.fecha_vencimiento : "",
    id_cuota: null,
    id_credito: null,
  });

  //console.log(cuota_actual);
  //console.log(select);
  //console.log(persona);
  return (
    <div
      class="d-block effect-blur align-middle position-absolute min-vh-100 w-100"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ zIndex: 2, top: 0, left: 0 }}
    >
      <div
        class="bg-light modal-dialog animation-modal my-md-5  overflow-hidden  texto-normal "
        style={{ maxWidth: "900px" }}
      >
        <div className="modal-content bg-light">
          <div class="title m-0 bg-white p-4 d-flex justify-content-between">
            <div>
              <h1>Credito - ID {select.id} </h1>
              <span class="fs-6 text-muted"></span>
            </div>
            <div>
              <label className="btn border-0 bg-transparent">
                <i class="bi bi-x-lg"></i>
                <button
                  class="d-none"
                  name="close"
                  onClick={(event) => onClick(event, null)}
                ></button>
              </label>
            </div>
          </div>
          <div class="body p-4">
            <div
              className={
                estado == "Pagado"
                  ? "fs-4 mb-3 alert alert-success rounded-pill border p-2"
                  : estado == "Vigente"
                  ? "fs-5 mb-3 alert alert-primary rounded-pill border p-3"
                  : "fs-4 mb-3 alert alert-alert rounded-pill border p-2"
              }
            >
              Credito {estado}
            </div>
            <div className="row">
              <div className=" col-md-6 mb-3">
                <ul class="list-group bg-transparent">
                  <li class="list-group-item border-0 py-1 p-3 d-flex justify-content-start">
                    Nombre y apellido:{" "}
                    <span className="fw-bold">
                      {persona.nombre + " " + persona.apellido}
                    </span>
                  </li>
                  <li class="list-group-item border-0 py-1 p-3 d-flex justify-content-start">
                    {persona.tipo_documento}:{" "}
                    <span className="fw-bold">{persona.nro_documento}</span>
                  </li>
                  <li class="list-group-item border-0 py-1 p-3 d-flex justify-content-start">
                    Dirección:{" "}
                    <span className="fw-bold">
                      {persona.provincia +
                        " - " +
                        persona.ciudad +
                        " - " +
                        persona.barrio}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-md-6 mb-3">
                <ul class="list-group">
                  <li class="list-group-item border-0 m-0 p-0 pb-2">
                    Cuotas Pagas:{" "}
                    <span className="fw-bold">{cuotas_detalle.pagas}</span>
                  </li>
                  <li class="list-group-item border-0 m-0 p-0 pb-2">
                    Cuotas Vencidas:{" "}
                    <span className="fw-bold">{cuotas_detalle.vencidas}</span>
                  </li>
                  <li class="list-group-item border-0 m-0 p-0 pb-2">
                    Deuda Acumulada:{" "}
                    <span className="fw-bold">$ {cuotas_detalle.deuda}</span>
                  </li>

                  <li class="list-group-item border-0 m-0 p-0 pb-2">
                    Cuota actual:{" "}
                    <span className="fw-bold">
                      {cuota_actual ? cuota_actual.nro + "/" + cuotas : ""}
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            {concesion ? (
              <div
                className="w-100 mb-3"
                style={{
                  borderRadius: "20px",
                  background: "rgba(217, 217, 217, 0.5)",
                }}
              >
                <ul class="list-group bg-transparent">
                  <li class="list-group-item py-1 bg-transparent border-0 p-3 d-flex justify-content-start">
                    <div className="fs-6 me-2">Concesion</div>
                    <div className="fs-6 fw-bold">
                      {tipoConcesion(concesion.id)}
                    </div>
                  </li>
                  <li class="list-group-item py-1 bg-transparent border-0 p-3 d-flex justify-content-start">
                    <div className="fs-6 me-2">Fecha de pacto</div>
                    <div className="fs-6 fw-bold">
                      {format(
                        new Date(concesion.fecha_alta),
                        "dd/MM/yyyy"
                      ).toString()}
                    </div>
                  </li>
                  <li class="list-group-item py-1 bg-transparent border-0 p-3 d-flex justify-content-start">
                    <div className="fs-6 me-2">Fecha vencimiento</div>
                    <div className="fs-6 fw-bold">
                      {format(
                        new Date(concesion.inicio_vencimiento + " 00:00:00"),
                        "dd/MM/yyyy"
                      ).toString()}
                    </div>
                  </li>
                  <li class="list-group-item py-1 bg-transparent border-0 p-3 d-flex justify-content-start">
                    <div className="fs-6 me-2">Bonificación</div>
                    <div className="fs-6 fw-bold">
                      {concesion.porcentaje == 0
                        ? "Sin bonificación"
                        : concesion.porcentaje}
                    </div>
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}

            {estado != "Pagado" &&
            !concesion &&
            cuotas_detalle.vencidas == 0 ? (
              <div className="row py-2">
                <div className="col-md-6">
                  <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">
                      Editar fecha actual de vencimiento
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      value={form.vencimiento}
                      min={new Date()}
                      name="vencimiento"
                      onChange={(event) => onChange(event)}
                    />
                    <span className="" style={{ fontSize: "12px" }}>
                      <p className="mb-0">
                        Requisitos necesarios para cambiar fecha de vencimiento
                      </p>
                      <p className="mb-0">- No contiene concesion asignada</p>
                      <p className="mb-0">- No posee cuotas vencidas </p>
                    </span>
                  </div>
                </div>
                <div className="col-md-6 d-none">
                    <label for="exampleFormControlInput1" class="form-label">
                      Cambiar estado del credito
                    </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                  >
                    <option selected></option>
                    <option value="1">Dar de baja</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div>
            ) : (
              <div className="w-100">
                <span>
                  El credito no puede ser modificado porque contiene cuotas
                  vencidas sin cancelar, una consecion o el credito ya fue
                  cancelado.
                </span>
              </div>
            )}
          </div>
          <div class="footer bg-white p-4">
            <div class="d-flex justify-content-between">
              <div></div>
              <div>
                <button
                  class="btn btn-primary rounded-pill px-4 me-2"
                  name="guardar"
                  onClick={(event) => onClick(event)}
                >
                  Guardar
                </button>
                <button
                  class="btn btn-secondary rounded-pill px-4"
                  name="close"
                  onClick={(event) => onClick(event)}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalEditarCredito;
