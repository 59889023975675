import {
  CREGISTRO_ERROR,
  CREGISTRO_EXITO,
  COBTENER_USUARIOS,
  CACTUALIZAR_USUARIO,
  CSELECCIONAR_USUARIO,
  CELIMINAR_USUARIO,
  CCARGANDO,
  CORDENAR_FILTRAR_USUARIOS,
  CDESELECCIONAR_USUARIO,
} from "../../types";

const creditosReducer = (state, action) => {
  switch (action.type) {
    case CDESELECCIONAR_USUARIO:
      return {
        ...state,
        usuarioSeleccionado: null,
      };
    case CORDENAR_FILTRAR_USUARIOS:
      return {
        ...state,
        resultado: action.payload,
      };
    case CCARGANDO:
      return {
        ...state,
        cargando: action.payload,
      };

    case CELIMINAR_USUARIO:
      return {
        ...state,
        /*usuarios: state.usuarios.filter(
          (usuario) => usuario.id !== action.payload
        ), */
        /* usuarios: state.usuarios.map((usuario) =>
          usuario.id === action.payload.id ? action.payload : usuario
        ), */
        resultado: state.resultado.map((result) =>
          result.id === action.payload.id ? action.payload : result
        ),
        /* 
        resultado: state.resultado.filter(
          (result) => result.id !== action.payload
        ), */
      };
    case CSELECCIONAR_USUARIO:
      return {
        ...state,
        usuarioSeleccionado: action.payload,
      };

    case CACTUALIZAR_USUARIO:
      return {
        ...state,
        usuarios: state.usuarios.map((usuario) =>
          usuario.id === action.payload.id ? action.payload : usuario
        ),
      };

    case COBTENER_USUARIOS:
      return {
        ...state,
        usuarios: action.payload,
        resultado: action.payload,
        cargando: false,
      };

    case CREGISTRO_EXITO:
      return {
        ...state,
        mensaje: { msg: null, class: null },
      };

    case CREGISTRO_ERROR:
      return {
        ...state,
        mensaje: action.payload,
      };

    default:
      return state;
  }
};

export default creditosReducer;
