import React, { useState, useContext, useEffect } from "react";
import {
  Input,
  BotoneraForm,
  Select,
  TextArea,
} from "../../shared/FormsElements";
import {
  useRest,
  Spinner,
  ConcesionesContext,
  useAlerta,
  Portal,
  AuthContext,
  format,
  BuscarPersonas,
  clienteAxios,
} from "../../shared";
import ListarCreditos from "./listaCreditos/ListarCreditos";
import Tabla_creditos from "../../creditos/tablas/Tabla_creditos";

const FormConcesion = (props) => {
  let idBien;

  if (props.match.params.id) {
    idBien = props.match.params.id;
  } else {
    idBien = null;
  }

  const Context = useContext(AuthContext);
  const { usuario } = Context;

  const concesionesContext = useContext(ConcesionesContext);
  const {
    concesionSeleccionada,
    ocultarMensaje,
    registrar,
    deseleccionar,
    mensaje,
  } = concesionesContext;

  const [inicio, setInicio] = useState(true);
  const [consultar, setConsultar] = useState(true);

  const [setAlerta, MostrarAlerta] = useAlerta(null);
  const [loading, load] = useRest();

  const [DatosForm, LeerForm] = useState({
    id_credito: "",
    id_tipoconcesion: "",
    porcentaje: "",
    id_condicion: "",
    fecha_inicio_vencimiento: "",
    inicio_vencimiento: "",
    mes_inicio_vto: "",
    id_estado: "1",
    fecha_alta: format(new Date(), "yyyy-MM-dd").toString(),
    fecha_baja: "",
    user_id: "",
    user_id_baja: "",
    nombreUsuarioBaja: "",
    id: "",
    nombreUsuario: usuario.username,
    personaNombre: "",
    personaId: "",
    observacion: "",
  });

  const {
    id_tipoconcesion,
    porcentaje,
    id_condicion,
    inicio_vencimiento,
    fecha_inicio_vencimiento,
    mes_inicio_vto,
    id_estado,
    fecha_alta,
    fecha_baja,
    id,
    nombreUsuario,
    nombreUsuarioBaja,
    personaNombre,
    observacion,
  } = DatosForm;

  const [personaSeleccionada, setPersonaSeleccionada] = useState(null);
  const [creditos, setCreditos] = useState([]);
  const [creditoSeleccionado, setCreditoSeleccionado] = useState(null);

  //modal
  const [isOpened, setOpened] = useState(false);
  const openModal = () => {
    setOpened(true);
    window.scrollTo(0, 0);
  };
  const closeModal = () => {
    setOpened(false);
    setConsultar(true);
  };
  //modal

  useEffect(() => {
    const cargar = async () => {
      if (consultar) {
        setConsultar(false);

        //try {
        const result = await clienteAxios.get("/Concesion/get", {
          params: { id: idBien },
        });
        console.log(result);
        LeerForm({

          nombreUsuario: result.data.consecion.nombreUsuario,
          user_id: result.data.consecion.user_id,

          nombreUsuarioBaja: result.data.consecion.nombreUsuarioBaja ? result.data.consecion.nombreUsuarioBaja : "",
          user_id_baja: result.data.consecion.user_id_baja ? result.data.consecion.user_id_baja : "",
          fecha_baja: result.data.consecion.fecha_baja && result.data.consecion.fecha_baja !== "0000-00-00 00:00:00" ? format(new Date(result.data.consecion.fecha_baja), "yyyy-MM-dd").toString() : "",

          id_credito: result.data.consecion.id_credito,


          id_tipoconcesion: result.data.consecion.id_tipoconcesion,
          porcentaje: result.data.consecion.porcentaje ? result.data.consecion.porcentaje : "",

          id_condicion: result.data.consecion.id_condicion,
          inicio_vencimiento: result.data.consecion.inicio_vencimiento ? result.data.consecion.inicio_vencimiento : "",
          mes_inicio_vto: result.data.consecion.mes_inicio_vto ? result.data.consecion.mes_inicio_vto : "",

          id_estado: result.data.consecion.id_estado,

          fecha_alta: format(new Date(result.data.consecion.fecha_alta), "yyyy-MM-dd").toString(),
          id: result.data.consecion.id,
          observacion: result.data.consecion.observacion ? result.data.consecion.observacion : "",
        });
        setPersonaSeleccionada({
          nombre: result.data.credito.nombre,
          apellido: result.data.credito.apellido,
        });
        setCreditoSeleccionado(result.data.credito);
        console.log(result);
        //  } catch (e) {}
      }
    };

    const alertar = () => {
      if (loading) {
        setAlerta(null);
      }
      if (inicio) {
        // eslint-disable-next-line
        ocultarMensaje();
        setInicio(null);
        setAlerta(null);
      } else {
        if (mensaje.msg && !loading) {
          setAlerta({ msg: mensaje.msg, class: mensaje.class });
        }
      }
    };

    deseleccionar();
    if (idBien !== null) {
      cargar();
    }

    alertar();

    if (concesionSeleccionada !== null) {
      LeerForm(concesionSeleccionada);
    }
  }, [mensaje, loading, inicio, concesionSeleccionada, idBien]);

  useEffect(() => {
    const obtenerCreditos = async () => {
      console.log(personaSeleccionada);
      try {
        const result = await clienteAxios.get(
          "/PersonaCreditos/getAllByIdPersona",
          {
            params: { id: personaSeleccionada.id, estados: ['Vigente'], concesion: "false" },
          }
        );
        setCreditos(
          result.data.creditos //.filter((result) => result.id_credito === null)
        );
        /*  console.log(result.data.creditos.filter(
          (result) => result.id_credito === null
        )); */
      } catch (e) { }
    };

    if (personaSeleccionada !== null) {
      LeerForm({
        ...DatosForm,
        personaNombre:
          personaSeleccionada.nombre + " " + personaSeleccionada.apellido,
      });

      obtenerCreditos();
    }
  }, [personaSeleccionada]);

  useEffect(() => {
    if (id_condicion !== "1") {
      LeerForm({
        ...DatosForm,
        inicio_vencimiento: "",
        mes_inicio_vto: "",
      });
    }
  }, [id_condicion]);

  useEffect(() => {
    if (id_tipoconcesion === "1" || id_tipoconcesion === "") {
      LeerForm({
        ...DatosForm,
        porcentaje: "",
      });
    }
  }, [id_tipoconcesion]);

  const Insertar = () => {
    const api = {
      url: "/Concesion/add",
      type: "post",
      datosJson: {
        id_credito: creditoSeleccionado.id,
        id_tipoconcesion,
        porcentaje,
        id_condicion,
        fecha_inicio_vencimiento,
        inicio_vencimiento,
        mes_inicio_vto,
        id_estado,
      },
      getParams: null,
      funcion: registrar,
    };

    load(api);
    setTimeout(() => {
      props.history.push("/concesiones");
    }, 2000);
  };

  const Actualizar = () => {
    /*  const api = {
      url: "/Tasa/update",
      type: "put",
      datosJson: {
        id: idBien,
        descripcion,
        cuotas,
        tna,
        tea,
        cft,
        premium,
      },
      getParams: null,
      funcion: registrar,
    }; */

    // load(api);
    setTimeout(() => {
      props.history.push("/tasas");
    }, 2000);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      !creditoSeleccionado ||
      id_condicion.trim() === "" ||
      id_estado.trim() === "" ||
      id_tipoconcesion.trim() === ""
    ) {
      setAlerta({ msg: "Todos los campos son obligatorios", class: "danger" });
      return;
    }

    if (
      (id_tipoconcesion === "2" || id_tipoconcesion === "3") && porcentaje.trim() === "") {
      setAlerta({
        msg: "Ha Seleccionado un Tipo de consecion en el que debe ingresar un porcentaje.",
        class: "danger",
      });
      return;
    }

    if (
      id_condicion === "1" && (fecha_inicio_vencimiento.trim() === "")
    ) {
      setAlerta({
        msg: "Ha Seleccionado una Condicion de consecion en la que debe ingresar dia y mes de inicio de vto.",
        class: "danger",
      });
      return;
    }

    /*
    if (
      id_condicion === "1" && (inicio_vencimiento.trim() === "" || mes_inicio_vto.trim() === "")
    ) {
      setAlerta({
        msg: "Ha Seleccionado una Condicion de consecion en la que debe ingresar dia y mes de inicio de vto.",
        class: "danger",
      });
      return;
    }
    */

    if (!idBien) {
      Insertar();
    } else {
      Actualizar();
    }
  };

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });


  };

  const onClick = (e) => {
  }

  const funcionCancelar = () => {
    props.history.push("/concesiones");
  };

  return (
    <div className="container-md min-vh-100">
      <br></br>
      <h2 className="text-center">{idBien ? "Ver " : "Agregar "} Concesión</h2>
      <br></br>
      <form onSubmit={onSubmit} className="border p-3 form">
        <div className="row">
          <div className="col-sm">
            <div className="row">
              <div className="col-sm-10">
                <Input
                  key={"id"}
                  sets={{
                    label: "Nro Concesión",
                    type: "text",
                    name: "id",
                    placeholder: " ",
                    valor: id,
                    disabled: true,
                  }}
                  onChange={onChange}
                />
              </div>
              <div className="col-sm"></div>
            </div>

            <div className="row">
              <div className="col-sm-10">
                <Select
                  key={"id_estado"}
                  sets={{
                    label: "Estado",
                    name: "id_estado",
                    valor: id_estado,
                    disabled: true,
                    opciones: [
                      { label: "Vigente", value: "1" },
                      { label: "Vencida", value: "2" },
                      { label: "Finalizada", value: "3" },
                      { label: "Baja", value: "4" },
                    ],
                  }}
                  onChange={onChange}
                />
              </div>
              <div className="col-sm"></div>
            </div>

            <div className="row">
              <div className="col-sm-10">
                <Input
                  style={{ with: "10px" }}
                  key={"personaNombre"}
                  sets={{
                    label: "Cliente",
                    type: "text",
                    name: "personaNombre",
                    placeholder: "Seleccione Cliente ",
                    valor: personaNombre,
                    disabled: true,
                    requerido: true,
                  }}
                  onChange={onChange}
                />
              </div>
              <div className="col-sm">
                {!id ? (
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ marginTop: "10px", marginRight: "2px" }}
                    onClick={() => {
                      openModal();
                    }}
                  >
                    Buscar
                  </button>
                ) : null}
              </div>
            </div>
          </div>

          <div className="col-sm">
            <div className="row">
              <div className="col-sm-10">
                <Input
                  style={{ with: "10px" }}
                  key={"fecha_alta"}
                  sets={{
                    label: "Fecha Alta",
                    type: "date",
                    name: "fecha_alta",
                    placeholder: "Fecha Alta",
                    valor: fecha_alta,
                    disabled: true,
                    requerido: true,
                  }}
                  onChange={onChange}
                />
              </div>
              <div className="col-sm"></div>
            </div>
            <div className="row">
              <div className="col-sm-10">
                <Input
                  key={"nombreUsuario"}
                  sets={{
                    label: "Usuario Creador",
                    type: "text",
                    name: "nombreUsuario",
                    placeholder: "",
                    valor: nombreUsuario,
                    disabled: true,
                  }}
                  onChange={onChange}
                />
              </div>
              <div className="col-sm"></div>
            </div>

            <div className="row">
              <div className="col-sm-10">
                <Input
                  key={"nombreUsuarioBaja"}
                  sets={{
                    label: "Usuario Baja",
                    type: "text",
                    name: "nombreUsuarioBaja",
                    placeholder: " ",
                    valor: nombreUsuarioBaja,
                    disabled: true,
                  }}
                  onChange={onChange}
                />
              </div>
              <div className="col-sm"></div>
            </div>
            <div className="row">
              <div className="col-sm-10">
                <Input
                  style={{ with: "10px" }}
                  key={"fecha_baja"}
                  sets={{
                    label: "Fecha Baja",
                    type: "date",
                    name: "fecha_baja",
                    placeholder: "Fecha Baja",
                    valor: fecha_baja,
                    disabled: true,
                  }}
                  onChange={onChange}
                />
              </div>
              <div className="col-sm"></div>
            </div>

            <div className="row">
              <div className="col-sm-10">
                <TextArea
                  key={"observacion"}
                  sets={{
                    label: "Comentarios  ",
                    name: "observacion",
                    placeholder: "ingresar Comentario",
                    valor: observacion,
                    rows: "3",
                    cols: "10",
                    disabled: true,
                  }}
                  onChange={onChange}
                />
              </div>
              <div className="col-sm"></div>
            </div>
          </div>
        </div>

        <div
          style={{
            padding: "20px 10px 20px",
            backgroundColor: "white",
            margin: "20px 0px 20px",
          }}
        >


          {creditos.length > 0 && !creditoSeleccionado ? (
            <ListarCreditos
              resultado={creditos}
              setCreditoSeleccionado={setCreditoSeleccionado}
            />
          ) : (
            <>
              {creditoSeleccionado ? (
                <div className="text-center">
                  {`Seleccionó Crédito Nro:  ${creditoSeleccionado.id} Cliente: ${creditoSeleccionado.nombre} ${creditoSeleccionado.apellido} `}
                  <>
                    {!id ? (
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ marginTop: "0px", marginRight: "2px" }}
                        onClick={() => {
                          setCreditoSeleccionado(null);
                        }}
                      >
                        Cambiar
                      </button>
                    ) : null}
                  </>
                </div>
              ) : (
                "No tiene Créditos"
              )}
            </>
          )}
        </div>

        {
          creditoSeleccionado
          ? <div className="row" style={{ marginBottom: "20px" }}>
          <div className="col-sm">
            <div className="row">
              <div className="col-sm-10">
                <Select
                  key={"id_tipoconcesion"}
                  sets={{
                    label: "Tipo Conseción",
                    name: "id_tipoconcesion",
                    valor: id_tipoconcesion,
                    disabled: id ? true : false,
                    opciones: [
                      { label: "Congela IP", value: "1" },
                      { label: "Bonificar Porcentaje & Corre IP", value: "2" },
                      {
                        label: "Bonificar Porcentaje & Congela IP",
                        value: "3",
                      },
                    ],
                  }}
                  onChange={onChange}
                />
              </div>
              <div className="col-sm"></div>
            </div>

            <div className="row">
              <div className="col-sm-10">
                <Select
                  key={"id_condicion"}
                  sets={{
                    label: "Tipo Condición",
                    name: "id_condicion",
                    valor: id_condicion,
                    disabled: id ? true : false,
                    opciones: [
                      { label: "Por pago a término", value: "1" },
                      { label: "Sin Plazo", value: "2" },
                    ],
                  }}
                  onChange={onChange}
                />
              </div>
              <div className="col-sm"></div>
            </div>
          </div>
          <div className="col-sm">
            <div className="row">
              <div className="col-sm-10">
                <Input
                  key={"porcentaje"}
                  sets={{
                    label: "Porcentaje",
                    type: "number",
                    name: "porcentaje",
                    placeholder: " ",
                    valor: porcentaje,
                    disabled:
                      ((id_tipoconcesion === "" || id_tipoconcesion === "1") &&
                        idBien === null) ||
                      idBien !== null,
                  }}
                  onChange={onChange}
                />
              </div>
              <div className="col-sm"></div>
            </div>

            <div className="row">
              <div className="col-sm-10">
                <div className="row p-2">
                  <div className="col-sm-3">
                    <label style={{ fontSize: "small" }}>Fecha de vencimiento.</label>
                  </div>
                  <div class="col-sm-9">
                    <input 
                    type="date" 
                    min={format(new Date(), "yyyy-MM-dd").toString()} class="form-control" 
                    name="fecha_inicio_vencimiento" 
                    onChange={(event) => onChange(event)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row d-none">
              <div className="col-sm-10">
                <Input
                  key={"inicio_vencimiento"}
                  sets={{
                    label: "Dia Inicio Vto.",
                    type: "number",
                    name: "inicio_vencimiento",
                    placeholder: " ",
                    valor: inicio_vencimiento,
                    disabled:
                      (id_condicion !== "1" && idBien === null) ||
                      idBien !== null,
                  }}
                  onChange={onChange}
                />
              </div>
              <div className="col-sm"></div>
            </div>

            <div className="row d-none">
              <div className="col-sm-10">
                <div className="form-group row p-2">
                  <div className="col-sm-3">
                    <label style={{ fontSize: "small" }}>Mes Inicio Vto.</label>
                    <br></br>
                  </div>
                  <div className="col-sm-9">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="mes_inicio_vto"
                        id="Mes Actual"
                        value="Mes Actual"
                        checked={mes_inicio_vto === "Mes Actual"}
                        onChange={onChange}
                        disabled={
                          (id_condicion !== "1" && idBien === null) ||
                          idBien !== null
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                      >
                        Mes Actual
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="mes_inicio_vto"
                        id="Mes Siguiente"
                        value="Mes Siguiente"
                        checked={mes_inicio_vto === "Mes Siguiente"}
                        onChange={onChange}
                        disabled={
                          (id_condicion !== "1" && idBien === null) ||
                          idBien !== null
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        Mes Siguiente
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm"></div>
            </div>
          </div>
        </div>
          :  <div className="text-center">
            <span className="fs-4">Seleccione un credito para asignar una concesión</span>
          </div>
        }

        {loading ? <Spinner /> : <MostrarAlerta />}

        {
          creditoSeleccionado
          ? <BotoneraForm
            funcionCancelar={funcionCancelar}
            valorfuncion={null}
            deshabilitado={id ? true : false}
          />
          : ""
        }
      </form>

      <Portal isOpened={isOpened} onClose={closeModal}>
        <BuscarPersonas
          closeModal={closeModal}
          setPersonaSeleccionada={setPersonaSeleccionada}
        />
      </Portal>
    </div>
  );
};
export default FormConcesion;
