import { CuotaDeuda } from "../../../functions/Validaciones/CuotaDeuda";
import { ConcesionesContext, clienteAxios } from "../../shared";
import { tipoConcesion } from "../../shared/Helpers/CalculosConcesiones";
import { format, add, sub } from "date-fns";
import {
  CalculoCuota,
  InsertCuotas,
  SumaExcedentes,
  verificar_bug,
} from "../../../functions/FunctionCuota";
import ResumenContenido from "./ResumenContenido";
import { useEffect } from "react";
import { useState } from "react";
import InfoPago from "./InfoPago";
import AlertSuccess from "../../CustomAlerts/AlertSuccess";
import AlertWarning from "../../CustomAlerts/AlertWarning";
import { tr } from "date-fns/locale";
import Swal from "sweetalert2";
import { FormatterNumber } from "../../../utils/formatter";
import Modal from "../../../module/modal/Modal";

/* 
Sumar menu para gastos totales 
Sumar seccion para gastos en perfil
*/

const ResumenJudicial = ({
  data,
  juicio,
  setModal,
  usuario,
  setSteps,
  steps,
  onClose
}) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    id_persona: null,
    interes_punitorio: "4.00",
    interes_moratiorio: "4.00",
    fecha_otorgamiento: "",
    item: "",
    monto: "",
  });
  const [trash, setTrash] = useState(null);

  const fetchTrashPay = async (id) => {
    let pass = document.getElementById("confirm-password").value;
    let formData = new FormData();
    formData.append("id", id);
    formData.append("password", pass);

    const response = await clienteAxios({
      method: "post",
      url: "/PagosJudicial/delete",
      data: formData,
    });

    console.log(response);
    if(response.status == 200 || response.status == 201){
      let target = {target: {name: "close"}}
      document.getElementById('btn-close-trash-pay').click()
      setSteps(steps+1)
      return onClose(target)
    }
  };

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "":
      case "eliminar-pago":
        {
          setTrash({
            id: value.id,
            body: (
              <div>
              <p>¿Quieres dar de baja el siguiente pago?</p>
              <ul class="list-group"><li class="list-group-item border-0 d-flex justify-content-between"><div>Estado</div><div>
              {value.estado} </div></li><li class="list-group-item border-0 d-flex justify-content-between"><div>Fecha de importe</div><div>{value.fecha_importe} </div></li><li class="list-group-item border-0 d-flex justify-content-between"><div>Tipo de importe</div><div>
              {value.forma_pago} </div></li><li class="list-group-item border-0 d-flex justify-content-between"><div>Importe</div><div>{value.importe} 
              </div></li></ul><div class="my-3 text-muted"> Para confirmar la baja de un pago se necesitan credenciales de administrador, ingrese su clave para completar la baja.</div><div><input type="password" id="confirm-password" class="form-control"/></div></div>
            ),
          });
        }
        break;
      case "close":
        {
          setModal(false);
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "monto":
      case "fecha_otorgamiento":
      case "item":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value,
          });
        }
        break;
    }
  };

  const saldo = (importe, capital, interes) => {
    let resto_interes = parseFloat(interes) - parseFloat(importe);
    let resto_capital = parseFloat(capital);
    if(resto_interes < 0){
      resto_capital = parseFloat(capital) + resto_interes;
    }

    console.log(resto_capital);

    return {
      resto_interes: resto_interes < 0 ? parseFloat(interes) : parseFloat(interes) - resto_interes,
      resto_capital: resto_capital < 0 ? parseFloat(capital) : parseFloat(capital) - resto_capital
    }
  }

  return (
    <div class="body p-4">
      <table className="table table-hover align-middle">
        <thead className="border-0">
          <tr className="border" style={{ borderRadius: "20px", background: "rgba(217, 217, 217, 0.5)" }}>
            <th className="border-0 fs-5 p-1">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Nº
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Fecha de pago
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Forma de pago
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Estado de pago
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Importe
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Capital
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Intereses
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Acciones
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="border-0">
          {juicio.pagos.map((p, index) => (
            <tr>
              <td className="border-0">{p.id}</td>
              <td className="border-0">
                {format(
                  new Date(p.fecha_importe + " 00:00:00"),
                  "dd/M/yyyy"
                ).toString()}
              </td>
              <td className="border-0">{p.forma_pago}</td>
              <td className="border-0">{p.estado}</td>
              <td className="border-0">{FormatterNumber(p.importe)}</td>
              <td className="border-0">{FormatterNumber(saldo(p.importe, p.monto, p.interes_moratorio).resto_capital)}</td>
              <td className="border-0">{FormatterNumber(saldo(p.importe, p.monto, p.interes_moratorio).resto_interes)}</td>
              <td className="border-0">
                {index == juicio.pagos.length - 1 && data? (
                  <label className="btn btn-danger rounded-pill d-flex align-items-center justify-content-center" style={{width: "150px"}}>
                    <i class="me-1 bi bi-trash"></i>Eliminar pago
                    <button
                      className="d-none"
                      name="eliminar-pago"
                      data-bs-toggle="modal"
                      data-bs-target="#trash-pay"
                      onClick={(event) => onClick(event, p)}
                    ></button>
                  </label>
                ) : (
                  ""
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        title="ELIMINAR PAGO"
        id="trash-pay"
        width={"500px"}
        children={trash ? trash.body : ""}
        onClick={{
          title: "CONFIRMAR",
          name: "confirm",
          function: () => fetchTrashPay(trash ?  trash.id : ""),
        }}
        onClose={{
          id: "btn-close-trash-pay",
          name: "close",
          function: onClose
        }}
      />
    </div>
  );
};

export default ResumenJudicial;
