import { links } from "../../../functions/links";
import Modal from "../../../module/modal/Modal";
import ResumenJudicial from "../../Gestion_pagos/Modals/ResumenJudicial";
import Juicio from "./modals/Juicio";
import Tabla_judiciales from "./tablas/Tabla_judiciales";

const GastosComponent = ({
  data,
  onClick,
  onChange,
  setSteps,
  steps,
  usuario,
  form,
  setForm,
  setResumenJudicial,
  resumenJudicial,
  setJuicio,
  juicio,
}) => {
  return (
    <div className="">
      <div className="row">
        <div className="col-md-12 mb-5 d-flex justify-content-start align-items-center">
          <h3 className="text-nowrap me-4 fw-bold">GASTOS JUDICIALES</h3>

          <label className="btn btn-primary fs-6 d-flex me-3">
            <i class="bi bi-plus-lg me-2"></i>
            Crear
            <button
              data-bs-toggle="modal"
              data-bs-target="#crear-gasto"
              className="d-none"
            ></button>
          </label>

          <div class="input-group">
            <button
              class="input-group-text bg-transparent border-0"
              id="basic-addon1"
            >
              <i class="bi bi-search"></i>
            </button>
            <input
              type="text"
              class="form-control"
              style={{ borderRadius: "20px", maxWidth: "400px" }}
              placeholder="Buscar por persona"
              aria-label="Username"
              aria-describedby="basic-addon1"
              name="search"
              onKeyUp={(e) => {
                console.log(e);
                if (e.code === "Enter") {
                  onClick(e, null);
                }
              }}
            />
          </div>
        </div>
        <div className="col-md-12">
          {data ? (
            <div className="w-100 d-flex justify-content-between mb-3">
              <div className="btn btn-light">
                Total de registros {data.total}
              </div>
              <div>
                {
                  data.links.map((l, index) => {
                    if (index == 0) {
                      return (
                        <label className="btn btn-primary fs-5 me-2">
                          <i class="bi bi-arrow-left"></i>
                          <button
                            className="d-none"
                            name="anterior"
                            onClick={(event) => onClick(event)}
                          ></button>
                        </label>
                      )
                    }


                    if (index == (data.links.length - 1)) {
                      return (
                        <label className="btn btn-primary fs-5">
                          <i class="bi bi-arrow-right"></i>
                          <button
                            className="d-none"
                            name="siguiente"
                            onClick={(event) => onClick(event)}
                          ></button>
                        </label>
                      )
                    }

                    return (
                      <label className="btn btn-outline-primary fs-5 me-2">
                        {l.label}
                        <button
                          className="d-none"
                          name="link"
                          onClick={(event) => onClick(event, l.label)}
                        ></button>
                      </label>
                    )
                  })
                }
              </div>
            </div>

          ) : null}
          {data ? (
            <Tabla_judiciales
              data={data.data}
              onClick={onClick}
              onChange={onChange}
            />
          ) : (
            <div className="d-flex justify-content-center border py-5">
              <span className="fs-4 text-muted"></span>
            </div>
          )}

          {data ? (
              <div>
              {
                data.links.map((l, index) => {
                  if (index == 0) {
                    return (
                      <label className="btn btn-primary fs-5 me-2">
                        <i class="bi bi-arrow-left"></i>
                        <button
                          className="d-none"
                          name="anterior"
                          onClick={(event) => onClick(event)}
                        ></button>
                      </label>
                    )
                  }


                  if (index == (data.links.length - 1)) {
                    return (
                      <label className="btn btn-primary fs-5">
                        <i class="bi bi-arrow-right"></i>
                        <button
                          className="d-none"
                          name="siguiente"
                          onClick={(event) => onClick(event)}
                        ></button>
                      </label>
                    )
                  }

                  return (
                    <label className="btn btn-outline-primary fs-5 me-2">
                      {l.label}
                      <button
                        className="d-none"
                        name="link"
                        onClick={(event) => onClick(event, l.label)}
                      ></button>
                    </label>
                  )
                })
              }
            </div>
          ) : null}
        </div>
      </div>

      <Modal
        title="Resumen de pagos"
        id="resumen-gastos"
        width={"1500px"}
        steps={steps}
        setSteps={setSteps}
        onClose={{
          name: "close",
          function: onClick,
        }}
        children={
          resumenJudicial ? (
            <ResumenJudicial
              data={null}
              juicio={juicio}
              setModal={setResumenJudicial}
              setSteps={setSteps}
              steps={steps}
              onClose={onClick}
            />
          ) : (
            ""
          )
        }
      />

      <Modal
        title="CREAR GASTO"
        id="crear-gasto"
        width={"700px"}
        steps={steps}
        setSteps={setSteps}
        onClick={{
          title: "GUARDAR",
          name: "crear-gasto",
          function: () => {
            onClick({ target: { name: "crear-gasto" } });
          },
        }}
        onClose={{
          id: "btn-close-modal",
          name: "close",
          function: onClick,
        }}
        children={
          <Juicio
            setSteps={setSteps}
            usuario={usuario}
            form={form}
            setForm={setForm}
          />
        }
      />
    </div>
  );
};

export default GastosComponent;
