import React, { useState, useEffect } from "react";
import { Input, BotoneraForm } from "../../shared/FormsElements";
import { useAlerta, clienteAxios, Spinner, useLog } from "../../shared/";
import {  BotonQuitar } from "../../shared/Estilos";

const FormDocumentacion = ({ idpersona, closeModal, Seleccionado }) => {
 
  const [inicio, setInicio] = useState(true);
  const [loadingLocal, setLoadingLocal] = useState(null);
  const [deshabilitado, setDeshabilitado] = useState(false);

  const [setAlerta, MostrarAlerta] = useAlerta(null);

  const [InsertarLog] = useLog(null);

  const [file, setFile] = useState(null);
  const [DatosForm, LeerForm] = useState({
    descripcion: "",
    archivo: null,
    id_persona: idpersona,
    id: null,
  });
  const { descripcion, archivo, id } = DatosForm;

  useEffect(() => {
    const cargar = () => {
      if (Seleccionado && inicio) {
        LeerForm(Seleccionado);
        if (Seleccionado.archivo) {
          setFile(
            `https://ayuu.ar/Api/writable/uploads/${Seleccionado.archivo}`
          );
        }
        setInicio(null);
      }
    };

    cargar();
  }, [inicio]);

  const Insertar = async () => {
    if (descripcion.trim() === "" || !archivo || archivo.length === 0) {
      setAlerta({
        msg: "Descripcion y Archivo son obligatorios",
        class: "danger",
      });
      return;
    }

    setAlerta(null);

    try {
      setLoadingLocal(true);
      const formData = new FormData();
      formData.append("archivo", archivo);
      formData.append("descripcion", descripcion);
      formData.append("idpersona", idpersona);
      await clienteAxios.post("/personasdocumentacion", formData);
      InsertarLog( "crear", "Creacion de Documentacion");
      setLoadingLocal(null);
      setAlerta({ msg: "Se Creo con Exito", class: "success" });
      setDeshabilitado(true);
      closeModal(false);
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const Actualizar = async () => {
    if (descripcion.trim() === "" || !archivo || archivo.length === 0) {
      setAlerta({
        msg: "Descripcion y Archivo son obligatorios",
        class: "danger",
      });
      return;
    }

    setAlerta(null);

    try {
      setLoadingLocal(true);

      const formData = new FormData();
      formData.append("id", id);
      if (typeof archivo !== "string") {
        formData.append("archivo", archivo);
      }
      formData.append("descripcion", descripcion);
      formData.append("idpersona", idpersona);

      await clienteAxios.post("/personasdocumentacionup", formData);
      InsertarLog( "editar", "Edicion de Documentacion");
      setLoadingLocal(null);
      setAlerta({ msg: "Se Actualizo con Exito", class: "success" });
      setDeshabilitado(true);
      closeModal(false);
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!Seleccionado) {
      Insertar();
    } else {
      Actualizar();
    }
  };

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });
    //console.log("onchange", DatosForm);
  };

  const handleChange = (e) => {
    if (e.target.files[0]) {
      setFile(URL.createObjectURL(e.target.files[0]));
      LeerForm({ ...DatosForm, archivo: e.target.files[0] });
    }
  };

  const borrarImagenTemp = () => {
    setFile(null);
    LeerForm({ ...DatosForm, archivo: null });
  };

  return (
    <div className="center-block">
      <h5 className="text-center">
        {Seleccionado ? "Editar " : "Agregar "} Documentacion
      </h5>
      <form onSubmit={onSubmit} className="border p-3 form">
       
        <Input
          key={"descripcion"}
          sets={{
            label: "Descripcion *",
            type: "text",
            name: "descripcion",
            placeholder: "Ingrese Descripcion ",
            valor: descripcion,
          }}
          onChange={onChange}
        />
        
        <input type="file" onChange={handleChange} name="archivo" />
        <br></br> <br></br>
        {file ? (
          <>
            <div>
              <br></br>
              <img src={file} width="250px" alt="" />
              <div>
                <BotonQuitar type="button" onClick={() => borrarImagenTemp()}>
                  <i className="fa fa-trash"></i>
                </BotonQuitar>
              </div>
            </div>
            <br></br>
            <br></br>
          </>
        ) : null}
        {loadingLocal ? <Spinner /> : <MostrarAlerta />}
        <BotoneraForm funcionCancelar={closeModal} valorfuncion={true} deshabilitado={deshabilitado}/>
      </form>
    </div>
  );
};
export default FormDocumentacion;
