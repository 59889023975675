import { links } from "../../../functions/links";
import Modal from "../../../module/modal/Modal";
import ResumenJudicial from "../../Gestion_pagos/Modals/ResumenJudicial";
import TablaJudiciales from "./tablas/tablaJudiciales";

const GastosComponent = ({
  data,
  onClick,
  onChange,
  setSteps,
  steps,
  usuario,
  form,
  setForm,
  setResumenJudicial,
  resumenJudicial,
  setJuicio,
  juicio,
}) => {
  return (
    <div className="">
      <div className="row">
        <div className="col-md-12 mb-5 mt-5 d-flex justify-content-start align-items-center">
          <h3 className="text-nowrap me-4 fw-bold">GASTOS JUDICIALES</h3>
        </div>
        <div className="col-md-12">
          {data ? (
            <div className="w-100 d-flex justify-content-between mb-3">
              <div className="btn btn-light">
                Total de registros {data.total}
              </div>
              <div>
                <label className="btn btn-primary fs-5 me-2">
                  <i class="bi bi-arrow-left"></i>
                  <button
                    className="d-none"
                    name="anterior"
                    onClick={(event) => onClick(event)}
                  ></button>
                </label>
                {data.pagina == 1 ? (
                  ""
                ) : (
                  <label className="btn btn-outline-primary fs-5 me-2">
                    ..1
                    <button
                      className="d-none"
                      name="link"
                      onClick={(event) => onClick(event, 1)}
                    ></button>
                  </label>
                )}
                <label className="btn btn-primary fs-5 me-2">
                  {data.pagina}
                  <button
                    className="d-none"
                    name=""
                    onClick={(event) => onClick(event)}
                  ></button>
                </label>
                {links(data.pagina, data.paginas).map((link) => (
                  <label className="btn btn-outline-primary fs-5 me-2">
                    {link}
                    <button
                      className="d-none"
                      name="link"
                      onClick={(event) => onClick(event, link)}
                    ></button>
                  </label>
                ))}
                {data.pagina == data.paginas ? (
                  ""
                ) : (
                  <label className="btn btn-outline-primary fs-5 me-2">
                    {data.paginas}
                    <button
                      className="d-none"
                      name="link"
                      onClick={(event) => onClick(event, data.paginas)}
                    ></button>
                  </label>
                )}
                <label className="btn btn-primary fs-5">
                  <i class="bi bi-arrow-right"></i>
                  <button
                    className="d-none"
                    name="siguiente"
                    onClick={(event) => onClick(event)}
                  ></button>
                </label>
              </div>
            </div>
          ) : null}
          {data ? (
            <TablaJudiciales
              data={data.data}
              onClick={onClick}
              onChange={onChange}
            />
          ) : (
            <div className="d-flex justify-content-center border py-5">
              <span className="fs-4 text-muted"></span>
            </div>
          )}

          {data ? (
            <div className="w-100 d-flex justify-content-between mb-3">
              <div className="btn btn-light">
                Total de registros {data.total}
              </div>
              <div>
                <label className="btn btn-primary fs-5 me-2">
                  <i class="bi bi-arrow-left"></i>
                  <button
                    className="d-none"
                    name="anterior"
                    onClick={(event) => onClick(event)}
                  ></button>
                </label>
                {data.pagina == 1 ? (
                  ""
                ) : (
                  <label className="btn btn-outline-primary fs-5 me-2">
                    ..1
                    <button
                      className="d-none"
                      name="link"
                      onClick={(event) => onClick(event, 1)}
                    ></button>
                  </label>
                )}
                <label className="btn btn-primary fs-5 me-2">
                  {data.pagina}
                  <button
                    className="d-none"
                    name=""
                    onClick={(event) => onClick(event)}
                  ></button>
                </label>
                {links(data.pagina, data.paginas).map((link) => (
                  <label className="btn btn-outline-primary fs-5 me-2">
                    {link}
                    <button
                      className="d-none"
                      name="link"
                      onClick={(event) => onClick(event, link)}
                    ></button>
                  </label>
                ))}
                {data.pagina == data.paginas ? (
                  ""
                ) : (
                  <label className="btn btn-outline-primary fs-5 me-2">
                    {data.paginas}
                    <button
                      className="d-none"
                      name="link"
                      onClick={(event) => onClick(event, data.paginas)}
                    ></button>
                  </label>
                )}
                <label className="btn btn-primary fs-5">
                  <i class="bi bi-arrow-right"></i>
                  <button
                    className="d-none"
                    name="siguiente"
                    onClick={(event) => onClick(event)}
                  ></button>
                </label>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <Modal
        title="Resumen de pagos"
        id="resumen-gastos"
        width={"1500px"}
        steps={steps}
        setSteps={setSteps}
        onClose={{
          name: "close",
          function: onClick,
        }}
        children={
          resumenJudicial ? (
            <ResumenJudicial
              data={null}
              juicio={juicio}
              setModal={setResumenJudicial}
              setSteps={setSteps}
              steps={steps}
              onClose={onClick}
            />
          ) : (
            ""
          )
        }
      />

    </div>
  );
};

export default GastosComponent;
