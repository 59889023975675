import { CuotaDeuda } from "../../../functions/Validaciones/CuotaDeuda";
import { ConcesionesContext, clienteAxios } from "../../shared";
import { tipoConcesion } from "../../shared/Helpers/CalculosConcesiones";
import { format, add, sub } from "date-fns";
import {
  CalculoCuota,
  InsertCuotas,
  SumaExcedentes,
  verificar_bug,
} from "../../../functions/FunctionCuota";
import ResumenContenido from "./ResumenContenido";
import { useEffect } from "react";
import { useState } from "react";
import InfoPago from "./InfoPago";
import AlertSuccess from "../../CustomAlerts/AlertSuccess";
import AlertWarning from "../../CustomAlerts/AlertWarning";


const Juicio = ({ data, setModal, setSteps, usuario, steps }) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    id_persona: data.id,
    interes_moratorio: "8.00",
    fecha_otorgamiento: "",
    item: "",
    monto: "",
  })

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "close":{
        setModal(false)
      }break;
      case "crear": {
        try {
        const result = await clienteAxios.post("/PersonaJudicial/store", form);
        AlertSuccess({ message: "Gasto creado!" });
        setSteps(steps + 1);
        setModal(false)
      } catch (e) {
        setLoading(false);
        AlertWarning({
          message: "",
          title: "Danger",
        });
      }
      } break;
    }
  }

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "monto":
      case "interes_moratorio":
      case "fecha_otorgamiento":
      case "item":{
        setForm({
          ...form,
          [e.target.name]: e.target.value
        })
      }break;
    }
  }

  console.log(data);
  return (
    <div
      class="d-block effect-blur align-middle position-absolute min-vh-100 w-100"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ zIndex: 2, top: 0, left: 0 }}
      id="modal-resumen"
    >
      <div
        class="bg-light modal-dialog animation-modal my-md-5  overflow-hidden  texto-normal "
        style={{ maxWidth: "900px" }}
      >
        <div className="modal-content bg-light">
          <div class="title m-0 bg-white p-4 d-flex justify-content-between">
            <div>
              <h1>Iniciar juicio</h1>
              <span class="fs-6 text-muted"></span>
            </div>
            <div>
              <label className="btn border-0 bg-transparent">
                <i class="bi bi-x-lg"></i>
                <button
                  class="d-none"
                  name="close"
                  onClick={(event) => onClick(event, null)}
                ></button>
              </label>
            </div>
          </div>
          <div class="body p-4">

          <div class="mb-3 w-100">
            <label for="exampleFormControlInput1" class="form-label fw-bold">
              Interes moratorio
            </label>
            <input
              type="number"
              step="0.00"
              class="form-control fs-4"
              id="exampleFormControlInput1"
              name="interes_moratorio"
              onChange={(event) => onChange(event)}
              value={form.interes_moratiorio}
            />
          </div>

          <div class="mb-3 w-100">
            <label for="exampleFormControlInput1" class="form-label fw-bold">
              Fecha de otorgamiento
            </label>
            <input
              type="date"
              class="form-control fs-4"
              id="exampleFormControlInput1"
              name="fecha_otorgamiento"
              onChange={(event) => onChange(event)}
              value={form.fecha_otorgamiento}
            />
          </div>
          
          
          <div class="mb-3 w-100 text-center">
            <label for="exampleFormControlInput1" class="form-label fw-bold">
              Forma de pago
            </label>
            <select
              class="form-select rounded-pill fs-4"
              aria-label="Default select example"
              name="item"
              onChange={(event) => onChange(event)}
            >
              <option selected></option>
              <option value="Tasa de Justicia">Tasa de Justicia</option>
              <option value="Sellados">Sellados</option>
              <option value="Gastos Administrativos">Gastos Administrativos</option>
              <option value="Honorarios">Honorarios</option>
            </select>
          </div>
          
          <div class="mb-3 w-100">
            <label for="exampleFormControlInput1" class="form-label fw-bold">
              Monto
            </label>
            <input
              type="text"
              class="form-control fs-4"
              id="exampleFormControlInput1"
              name="monto"
              onChange={(event) => onChange(event)}
              value={form.monto}
            />
          </div>

          </div>
          <div class="footer bg-white p-4">
            <div class="d-flex justify-content-between">
              <button
                class="btn btn-secondary rounded-pill px-4"
                name="close"
                onClick={(event) => onClick(event)}
              >
                Cerrar
              </button>
              <button
                class="btn btn-primary rounded-pill px-4"
                name="crear"
                onClick={(event) => onClick(event)}
              >
                Crear gasto
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Juicio;
