import PDFcomprobante from "../Archivos/PDFcomprobante"
import PDFcomprobanteGasto from "../Archivos/PDFcomprobanteGasto"

const ComprobanteGastoController = ({
  data,
  persona,
  onClick
}) => {

  return (
<div
      class="d-block effect-blur align-middle position-absolute min-vh-100 w-100"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ zIndex: 2, top: 0, left: 0}}
    >
      <div
        class="bg-light modal-dialog animation-modal my-md-5  overflow-hidden  texto-normal "
        style={{ maxWidth: "800px" }}
      >
        <div className="modal-content bg-light">
          <div class="title m-0 bg-white p-4 d-flex justify-content-between">
            <div>
              <h1>Comprobante de pago</h1>
              <span class="fs-6 text-muted"></span>
            </div>
            <div>
              <label className="btn border-0 bg-transparent">
                <i class="bi bi-x-lg"></i>
                <button
                  class="d-none"
                  name="close"
                  onClick={(event) => onClick(event, null)}
                ></button>
              </label>
            </div>
          </div>
          <div class="body p-4 d-flex justify-content-center">
            {
              <PDFcomprobanteGasto data={data} persona={persona} />
            }
          </div>
          <div class="footer bg-white p-4">
            <div class="d-flex justify-content-between">
              <div></div>
              <button
                class="btn btn-secondary rounded-pill px-4"
                name="close"
                onClick={(event) => onClick(event)}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ComprobanteGastoController