// auth
export const REGISTRO_EXITO = "REGISTRO_EXITO";
export const REGISTRO_ERROR = "REGISTRO_ERROR";
export const USUARIO_AUTENTICADO = "USUARIO_AUTENTICADO";
export const LOGIN_EXITO = "LOGIN_EXITO";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const CERRAR_SESION = "CERRAR_SESION";
export const SELECCIONAR_USUARIO = "SELECCIONAR_USUARIO";
export const ELIMINAR_USUARIO = "ELIMINAR_USUARIO";
export const ORDENAR_FILTRAR_USUARIOS = "ORDENAR_FILTRAR_USUARIOS";
export const DESELECCIONAR_USUARIO = "DESELECCIONAR_USUARIO";

//usuarios
export const OBTENER_USUARIOS = "OBTENER_USUARIOS";
export const ACTUALIZAR_USUARIO = "ACTUALIZAR_USUARIO";

// personas
export const ELIMINAR_PERSONA = "ELIMINAR_PERSONA";
export const SELECCIONAR_PERSONA = "SELECCIONAR_PERSONA";
export const DESELECCIONAR_PERSONA = "DESELECCIONAR_PERSONA";
export const OBTENER_PERSONAS = "OBTENER_PERSONAS";
export const CONSEGUIR_PERSONA = "CONSEGUIR_PERSONA";
export const ORDENAR_FILTRAR_PERSONAS = "ORDENAR_FILTRAR_PERSONAS";
export const MODIFICAR_PERSONA_SELECCIONADA = "MODIFICAR_PERSONA_SELECCIONADA";
export const SELECCIONAR_PRECALIFICAR = "SELECCIONAR_PRECALIFICAR";
export const CARGANDO = "CARGANDO";

// tasas
export const TREGISTRO_EXITO = "TREGISTRO_EXITO";
export const TREGISTRO_ERROR = "TREGISTRO_ERROR";
export const TSELECCIONAR_USUARIO = "TSELECCIONAR_USUARIO";
export const TELIMINAR_USUARIO = "TELIMINAR_USUARIO";
export const TORDENAR_FILTRAR_USUARIOS = "TORDENAR_FILTRAR_USUARIOS";
export const TDESELECCIONAR_USUARIO = "TDESELECCIONAR_USUARIO";
export const TOBTENER_USUARIOS = "TOBTENER_USUARIOS";
export const TACTUALIZAR_USUARIO = "TACTUALIZAR_USUARIO";
export const TCARGANDO = "TCARGANDO";

// creditos
export const CREGISTRO_EXITO = "CREGISTRO_EXITO";
export const CREGISTRO_ERROR = "CREGISTRO_ERROR";
export const CSELECCIONAR_USUARIO = "CSELECCIONAR_USUARIO";
export const CELIMINAR_USUARIO = "CELIMINAR_USUARIO";
export const CORDENAR_FILTRAR_USUARIOS = "CORDENAR_FILTRAR_USUARIOS";
export const CDESELECCIONAR_USUARIO = "CDESELECCIONAR_USUARIO";
export const COBTENER_USUARIOS = "COBTENER_USUARIOS";
export const CACTUALIZAR_USUARIO = "CACTUALIZAR_USUARIO";
export const CCARGANDO = "CCARGANDO";

//concesiones
export const CCREGISTRO_EXITO = "CCREGISTRO_EXITO";
export const CCREGISTRO_ERROR = "CCREGISTRO_ERROR";
export const CCSELECCIONAR_CONSECION = "CCSELECCIONAR_CONSECION";
export const CCELIMINAR_CONCESION = "CCELIMINAR_CONCESION";
export const CCORDENAR_FILTRAR_CONSECIONES = "CCORDENAR_FILTRAR_CONSECIONES";
export const CCDESELECCIONAR_CONCESION = "CCDESELECCIONAR_CONCESION";
export const CCOBTENER_CONSECIONES = "CCOBTENER_CONSECIONES";
export const CCACTUALIZAR_CONCESION = "CCACTUALIZAR_CONCESION";
export const CCCARGANDO = "CCCARGANDO";

//pagos
export const PREGISTRO_ERROR = "PREGISTRO_ERROR";
export const PREGISTRO_EXITO = "PREGISTRO_EXITO";
export const POBTENER_PAGOS = "POBTENER_PAGOS";
export const PACTUALIZAR_PAGO = "PACTUALIZAR_PAGO";
export const PSELECCIONAR_PAGO = "PSELECCIONAR_PAGO";
export const PELIMINAR_PAGO = "PELIMINAR_PAGO";
export const PCARGANDO = "PCARGANDO";
export const PORDENAR_FILTRAR_PAGOS = "PORDENAR_FILTRAR_PAGOS";
export const PDESELECCIONAR_PAGO = "PDESELECCIONAR_PAGO";
