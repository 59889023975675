import React from "react";
import { BotonEditar, BotonEliminar } from "../../shared/FormsElements";

const Documentacion = ({ item, editar, eliminar, soloLectura }) => {
  return (
    <tr>
      <td className="text-center">{item.descripcion}</td>
      <td className="text-center">
        <a
          aria-label="archivo"
          href={`https://ayuu.ar/Api/writable/uploads/${item.archivo}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Ver Archivo
        </a>
      </td>
      <td className="text-center">
        {!soloLectura ? (
          <>
            <BotonEditar editar={editar} item={item} />
            <BotonEliminar eliminar={eliminar} item={item} />
          </>
        ) : null}
      </td>
    </tr>
  );
};

export default Documentacion;
