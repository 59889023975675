import React, { useContext } from "react";
import { PersonasContext, uuidv4 } from "../../shared/";
import Evento from "./Evento";

const TituloDia = ({ item }) => {
  const personaContext = useContext(PersonasContext);
  const { personaSeleccionada } = personaContext;

  const Detallehistorial = (fecha) => {
    const jsonData = JSON.parse(personaSeleccionada.persona.history);

    return jsonData.map((item) => {
      if (fecha === item.date) {
        return (
              <Evento item={item} key={uuidv4()} />
        );
      }
    });
  };

  return (
    <>
    <br></br>
      <li className="header year ellipsis">{item.date}</li>
      <li className="wrapper">
        <ul className="events">
       {Detallehistorial(item.date)}
        </ul>
      </li>
     
    </>
  );
};

export default TituloDia;
