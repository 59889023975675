import React, { useState, useContext, useEffect } from "react";
import { Input, Select, TextArea } from "../shared/FormsElements";
import { Boton } from "../shared/Estilos";
import {
  Spinner,
  useAlerta,
  PersonasContext,
  clienteAxios,
  Swal,
  useHistory, useLog, AuthContext, useRest
} from "../shared/";
import { useRef } from "react";
import { ApiRest } from "../../Helpers/ApiRest";
import { format } from "date-fns";

const FromPreCalificacion = (props) => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;
  const personaContext = useContext(PersonasContext);

  const {
    ocultarMensaje,
    mensaje,
    personaSeleccionada,
    conseguirPersona,
  } = personaContext;

  const [inicio, setInicio] = useState(true);
  const [loadingLocal, setLoadingLocal] = useState(null);

  const [setAlerta, MostrarAlerta] = useAlerta(null);

  const [InsertarLog] = useLog(null);

  const [DatosForm, LeerForm] = useState({
    id: "",
    id_persona: "",
    veraz: "0",
    bcra: "0",
    juicios: "0",
    dgr: "0",
    referencias_personales: "0",
    fecha_vencimiento: "",
    ingresos: "",
    bienes: "0",
    solicito: "",
    limite_x_cuota: "",
    limite: "",
    estado: "",
    promedio: "0.00",
    observacion: "",
    premium: "",
  });

  const {
    veraz,
    bcra,
    juicios,
    dgr,
    referencias_personales,
    fecha_vencimiento,
    ingresos,
    bienes,
    solicito,
    limite_x_cuota,
    limite,
    promedio,
    observacion,
    estado,
    premium,
  } = DatosForm;

  const [propsId] = useState(props.match.params.id);
  const [soloLectura, setSoloLectura] = useState(false);

  useEffect(() => {
    if (window.location.pathname.startsWith("/precalificar/ver/")) {
      setSoloLectura(true);
    }

    const cargar = async () => {
      if (!inicio) return;

      try {
        setLoadingLocal(true);
        const result = await clienteAxios.get("/persona", {
          params: { id: propsId },
        });
        conseguirPersona(result.data);
        setLoadingLocal(null);
      } catch (e) {
        setLoadingLocal(null);
      }
    };
    const alertar = () => {
      if (loadingLocal) {
        setAlerta(null);
      }
      if (inicio) {
        // eslint-disable-next-line
        ocultarMensaje();
        setInicio(null);
        setAlerta(null);
      } else {
        if (mensaje.msg && !loadingLocal) {
          setAlerta({ msg: mensaje.msg, class: mensaje.class });
        }
      }
    };

    cargar();

    alertar();
  }, [mensaje, inicio, propsId, props]);

  useEffect(() => {
    if (personaSeleccionada) {
      LeerForm({
        ...DatosForm,
        ingresos: calcularIngresos(),
        solicito: personaSeleccionada.precalificaciones.solicito
          ? personaSeleccionada.precalificaciones.solicito
          : "0",
        promedio: personaSeleccionada.precalificaciones.promedio
          ? personaSeleccionada.precalificaciones.promedio
          : "0.00",
        veraz: personaSeleccionada.precalificaciones.veraz
          ? personaSeleccionada.precalificaciones.veraz
          : "0",
        bcra: personaSeleccionada.precalificaciones.bcra
          ? personaSeleccionada.precalificaciones.bcra
          : "0",
        dgr: personaSeleccionada.precalificaciones.dgr
          ? personaSeleccionada.precalificaciones.dgr
          : "0",
        juicios: personaSeleccionada.precalificaciones.juicios
          ? personaSeleccionada.precalificaciones.juicios
          : "0",
        referencias_personales: personaSeleccionada.precalificaciones
          .referencias_personales
          ? personaSeleccionada.precalificaciones.referencias_personales
          : "0",
        bienes: personaSeleccionada.precalificaciones.bienes
          ? personaSeleccionada.precalificaciones.bienes
          : "0",
        limite: personaSeleccionada.precalificaciones.limite
          ? personaSeleccionada.precalificaciones.limite
          : "0",
        limite_x_cuota: personaSeleccionada.precalificaciones.limite_x_cuota
          ? personaSeleccionada.precalificaciones.limite_x_cuota
          : "",
        observacion: personaSeleccionada.precalificaciones.observacion
          ? personaSeleccionada.precalificaciones.observacion
          : "",
        estado: personaSeleccionada.precalificaciones.estado
          ? personaSeleccionada.precalificaciones.estado
          : "",
        premium: personaSeleccionada.precalificaciones.premium
          ? personaSeleccionada.precalificaciones.premium
          : "",
        fecha_vencimiento: personaSeleccionada.precalificaciones.fecha_vencimiento
          ? personaSeleccionada.precalificaciones.fecha_vencimiento
          : "",
      });
    }
  }, [personaSeleccionada]);

  const Actualizar = async (datos) => {
    if (
      (veraz.trim() === "" ||
        bcra.trim() === "" ||
        juicios.trim() === "" ||
        dgr.trim() === "" ||
        juicios.trim() === "" ||
        referencias_personales.trim() === "" ||
        bienes.trim() === "" ||
        ingresos.toString().trim() === "" ||
        limite_x_cuota.trim() === "") &&
      datos.estado === "Aceptada"
    ) {
      setAlerta({ msg: "Todos los campos son Obligatorios", class: "danger" });
      return;
    }

    if (limite.toString().trim() === "") {
      setAlerta({
        msg: "Debe completar los datos de Limite de Cuota",
        class: "danger",
      });
    }

    /*
    if (
      (limite.toString().trim() === "" ||
        limite_x_cuota.trim() !== "Monto Libre") &&
      datos.estado === "Aceptada"
    ) {
      setAlerta({
        msg: "Debe completar los datos de Limite de Cuota",
        class: "danger",
      });
      return;
    }
    */

    if (datos.estado === "Observada" && observacion.trim() === "") {
      setAlerta({
        msg:
          "Recuerde que para guardar como Observada, debe ingresar observaciones",
        class: "danger",
      });
      return;
    }

    setAlerta(null);

    try {
      setLoadingLocal(true);
      clienteAxios.put("/PersonaPreCalificacion/update", datos);
      InsertarLog("editar", "Edicion de Analisis Crediticio " + datos.estado);
      setLoadingLocal(null);
      setAlerta({
        msg: "Se Actualizo con Exito, Se Redirige al Escritorio",
        class: "success",
      });

      notificar(datos.estado);

      setTimeout(() => history.push("/dashboard"), 2000);
    } catch (e) {
      setLoadingLocal(null);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  const calcularPromedio = (e) => {
    let cveraz = !veraz ? 0 : parseInt(veraz);
    let cbcra = !bcra ? 0 : parseInt(bcra);
    let cjuicios = !juicios ? 0 : parseInt(juicios);
    let cdgr = !dgr ? 0 : parseInt(dgr);
    let creferencias_personales = !referencias_personales
      ? 0
      : parseInt(referencias_personales);
    let cbienes = !bienes ? 0 : parseInt(bienes);

    const valor = e.target.value === "" ? 0 : parseInt(e.target.value);

    cveraz = e.target.name === "veraz" ? valor : cveraz;
    cbcra = e.target.name === "bcra" ? valor : cbcra;
    cjuicios = e.target.name === "juicios" ? valor : cjuicios;
    cdgr = e.target.name === "dgr" ? valor : cdgr;
    creferencias_personales =
      e.target.name === "referencias_personales"
        ? valor
        : creferencias_personales;
    cbienes = e.target.name === "bienes" ? valor : cbienes;
    const suma =
      cveraz + cbcra + cjuicios + cdgr + creferencias_personales + cbienes;

    const calculo = suma > 0 ? suma / 6 : 0;
    /* console.log(
      cveraz,
      cbcra,
      cjuicios,
      cdgr,
      creferencias_personales,
      cbienes,
      suma,
      calculo
    ); */
    return calculo.toFixed(2);
  };

  const calcularIngresos = () => {
    let ingresos = 0;
    personaSeleccionada.ingresos.map(
      (item) => (ingresos += parseInt(item.ingresos))
    );
    return ingresos;
  };

  const calcularLimite = (e) => {
    let vlimite = parseInt(limite);
    if (e.target.name === "limite_x_cuota") {
      switch (e.target.value) {
        case "30%":
          vlimite = ingresos * 0.3;
          break;
        case "40%":
          vlimite = ingresos * 0.4;
          break;
        case "50%":
          vlimite = ingresos * 0.5;
          break;
        case "Monto Libre":
          vlimite = 0;
          break;
        /*case "Sin Limite":
          //vlimite = e.target.value;
          break;
        default:
          //vlimite = e.target.value;
          break;*/
      }
    }
    if (e.target.name === "limite") {
      vlimite = e.target.value;
    }

    //console.log('vlimite',vlimite);
    return vlimite;
  };

  const onChange = (e) => {

    switch (e.target.name) {
      case "premium": {
        if (e.target.checked) {
          LeerForm({
            ...DatosForm,
            [e.target.name]: "SI",
            promedio: calcularPromedio(e),
            limite: calcularLimite(e),
          });
        } else {
          LeerForm({
            ...DatosForm,
            [e.target.name]: "NO",
            promedio: calcularPromedio(e),
            limite: calcularLimite(e),
          });
        }
      } break;
      case "observacion":
      case "limite":
      case "limite_x_cuota":
      case "solicito":
      case "bienes":
      case "referencias_personales":
      case "bcra":
      case "ingresos":
      case "estado":
      case "dgr":
      case "juicios":
      case "bcra":
      case "veraz": {
        LeerForm({
          ...DatosForm,
          [e.target.name]: e.target.value,
          promedio: calcularPromedio(e),
          limite: calcularLimite(e),
        });
      } break;
    }
  };

  const onClick = (e) => {
    switch (e.target.name) {
      case "estado": {
        LeerForm({
          ...DatosForm,
          [e.target.name]: e.target.value,
          promedio: calcularPromedio(e),
          limite: calcularLimite(e),
        });
      } break;
      case "crear": {
        //console.log(DatosForm);
        let datos = DatosForm;
        //datos.id = personaSeleccionada.precalificaciones.id;
        datos.id_persona = personaSeleccionada.persona.id;
        Swal.fire({
          title: "Esta Seguro que desea crear una nueva calificacion como " + estado + "?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const result = await clienteAxios.post("/PersonaPreCalificacion/store", datos);
            if (datos.estado === "Observada") {
              insertarObservacionCliente(datos);
            }
            //Actualizar
            window.location.reload();
          }
        });
      } break;
    }
  }

  const ir = (url) => {
    //console.log("doing something");
    const win = window.open(url, "_blank");
    win.focus();
  };

  const insertarObservacionCliente = async (datos) => {
    await clienteAxios.post("/personasobservacion", {
      titulo: "Análisis de Crédito - ESTADO NUEVO",
      observacion: datos.observacion,
      prioridad: "Alta",
      id_persona: datos.id_persona,
    });
  };

  const guardar = (vestado) => {
    //console.log(DatosForm);
    let datos = DatosForm;
    datos.estado = vestado;
    datos.id = personaSeleccionada.precalificaciones.id;
    datos.id_persona = personaSeleccionada.persona.id;

    Swal.fire({
      title: "Esta Seguro que desea Calificar como " + vestado + "?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        Actualizar(datos);
        if (datos.estado === "Observada") {
          insertarObservacionCliente(datos);
        }

      }
    });
  };

  const notificar = async (vestado) => {
    try {
      await clienteAxios.post("/Notificaciones/add", {
        user_from: 0,
        user_to: "Vendedor",
        mensaje: `Análisis - ${vestado}`,
        body: `${personaSeleccionada.persona.nombre} ${personaSeleccionada.persona.apellido}`,
        link: `/precalificar/${personaSeleccionada.persona.id} `,
      });
    } catch (e) { }
  };

  /* label: "Sin Límite", value: "Sin Limite" */
  //lOGS
  //console.log(personaSeleccionada);
  //console.log(DatosForm);
  console.log(DatosForm);
  return (
    <>
      {
        personaSeleccionada && usuario.rol !== "Vendedor"
          ? <div className="container-md min-vh-100">
            <br></br>
            <h2 className="text-center">
              {
                personaSeleccionada.precalificaciones.estado
                  ? personaSeleccionada.precalificaciones.estado == "Aceptada" ? "Ultimo Análisis Aceptado"
                    : personaSeleccionada.precalificaciones.estado == "Pendiente" ? "Ultimo Análisis Pendiente"
                      : personaSeleccionada.precalificaciones.estado == "Observada" ? "Ultimo Análisis Observada"
                        : personaSeleccionada.precalificaciones.estado == "Rechazada" ? "Ultimo Análisis Rechazada"
                          : personaSeleccionada.precalificaciones.estado == "Baja" ? "Análisis Crediticio Vencido"
                            : ""
                  : "Análisis Crediticio"
              }
            </h2>
            <br></br>

            <div
              className="row"
              style={{
                borderRadius: "10px",
                backgroundColor: "gray",
                color: "white",
                marginLeft: "2px",
                marginRight: "2px",
              }}
            >
              <div className="col-sm">
                {personaSeleccionada ? (
                  <div style={{ padding: "20px" }}>
                    {personaSeleccionada.persona.nombre}{" "}
                    {personaSeleccionada.persona.apellido}
                    <br></br>
                    {personaSeleccionada.persona.tipo_documento}{" "}
                    {personaSeleccionada.persona.nro_documento}
                    <br></br>
                    {personaSeleccionada.persona.calle}{" "}
                    {personaSeleccionada.persona.numero} -{" "}
                    {personaSeleccionada.persona.provincia},{" "}
                    {personaSeleccionada.persona.ciudad}
                    <br></br>
                    Domicilio: {personaSeleccionada.persona.calle}{" "}
                    {personaSeleccionada.persona.numero}{" "}
                    {personaSeleccionada.persona.ciudad}
                  </div>
                ) : null}
              </div>
              <div className="col-sm">
                <button
                  style={{ margin: "20px", float: "right" }}
                  onClick={() => {
                    window.open(`/clientes/ver/${personaSeleccionada.persona.id}`);
                  }}
                >
                  Mas Informacion
                </button>
              </div>
            </div>
            <br></br>

            <div className="row">
              <div className="col-sm text-center">
                <div style={{ paddingTop: "50px", fontSize: "30px" }}>
                  Monto Solicitado: ${" "}
                  {personaSeleccionada
                    ? personaSeleccionada.precalificaciones.solicito
                    : "0"}{" "}
                </div>
              </div>
              <div className="col-sm">
                <div
                  style={{
                    padding: "20px",
                    backgroundColor: "grey",
                    borderRadius: "10px",
                    color: "white",
                  }}
                >
                  Links <br></br>
                  <br></br>
                  <button
                    onClick={() => {
                      ir("https://www.credixsa.com/");
                    }}
                  >
                    CREDIXSA
                  </button>
                  <button
                    onClick={() => {
                      ir("http://www.bcra.gov.ar/BCRAyVos/Situacion_Crediticia.asp");
                    }}
                  >
                    BCRA
                  </button>
                  <button
                    onClick={() => {
                      ir(
                        "https://www.justiciacordoba.gob.ar/JusticiaCordoba/servicios/ConsultaJuicios.aspx"
                      );
                    }}
                  >
                    Juicios
                  </button>
                  <button
                    onClick={() => {
                      ir(
                        "https://www.rentascordoba.gob.ar/mirentas/rentas.html?page=situacionfiscal"
                      );
                    }}
                  >
                    DGR
                  </button>
                  <button
                    onClick={() => {
                      ir("https://micuilonline.com.ar/averiguar-cuil-cuit");
                    }}
                  >
                    MI CUIT Online
                  </button>
                  <button
                    onClick={() => {
                      ir("http://servicioswww.anses.gob.ar/ooss2/");
                    }}
                  >
                    CODEM
                  </button>
                </div>
              </div>
            </div>
            <br></br>
            <div className="row">
              <div className="col-sm">
                <form onSubmit={onSubmit} className="border p-3 form">
                  <Select
                    key={"veraz"}
                    sets={{
                      requerido: estado === "Aceptada" ? true : false,
                      disabled: soloLectura,
                      label: "CREDIXA",
                      name: "veraz",
                      valor: veraz,
                      opciones: [
                        { label: "Malo(1)", value: "1" },
                        { label: "Regular(2)", value: "2" },
                        { label: "Buena(3)", value: "3" },
                        { label: "Muy Buena(4)", value: "4" },
                        { label: "Excelente(5)", value: "5" },
                      ],
                    }}
                    onChange={onChange}
                  />
                  <Select
                    key={"bcra"}
                    sets={{
                      requerido: estado === "Aceptada" ? true : false,
                      disabled: soloLectura,
                      label: "BCRA",
                      name: "bcra",
                      valor: bcra,
                      opciones: [
                        { label: "Malo(1)", value: "1" },
                        { label: "Regular(2)", value: "2" },
                        { label: "Buena(3)", value: "3" },
                        { label: "Muy Buena(4)", value: "4" },
                        { label: "Excelente(5)", value: "5" },
                      ],
                    }}
                    onChange={onChange}
                  />
                  <Select
                    key={"juicios"}
                    sets={{
                      requerido: estado === "Aceptada" ? true : false,
                      disabled: soloLectura,
                      label: "Juicios",
                      name: "juicios",
                      valor: juicios,
                      opciones: [
                        { label: "Malo(1)", value: "1" },
                        { label: "Regular(2)", value: "2" },
                        { label: "Buena(3)", value: "3" },
                        { label: "Muy Buena(4)", value: "4" },
                        { label: "Excelente(5)", value: "5" },
                      ],
                    }}
                    onChange={onChange}
                  />
                  <Select
                    key={"dgr"}
                    sets={{
                      requerido: estado === "Aceptada" ? true : false,
                      disabled: soloLectura,
                      label: "DGR",
                      name: "dgr",
                      valor: dgr,
                      opciones: [
                        { label: "Malo(1)", value: "1" },
                        { label: "Regular(2)", value: "2" },
                        { label: "Buena(3)", value: "3" },
                        { label: "Muy Buena(4)", value: "4" },
                        { label: "Excelente(5)", value: "5" },
                      ],
                    }}
                    onChange={onChange}
                  />
                  <Select
                    key={"referencias_personales"}
                    sets={{
                      requerido: estado === "Aceptada" ? true : false,
                      disabled: soloLectura,
                      label: "Referencias Personales",
                      name: "referencias_personales",
                      valor: referencias_personales,
                      opciones: [
                        { label: "Malo(1)", value: "1" },
                        { label: "Regular(2)", value: "2" },
                        { label: "Buena(3)", value: "3" },
                        { label: "Muy Buena(4)", value: "4" },
                        { label: "Excelente(5)", value: "5" },
                      ],
                    }}
                    onChange={onChange}
                  />
                  <Select
                    key={"bienes"}
                    sets={{
                      requerido: estado === "Aceptada" ? true : false,
                      disabled: soloLectura,
                      label: "Bienes",
                      name: "bienes",
                      valor: bienes,
                      opciones: [
                        { label: "Malo(1)", value: "1" },
                        { label: "Regular(2)", value: "2" },
                        { label: "Buena(3)", value: "3" },
                        { label: "Muy Buena(4)", value: "4" },
                        { label: "Excelente(5)", value: "5" },
                      ],
                    }}
                    onChange={onChange}
                  />
                  <Input
                    key={"ingresos"}
                    sets={{
                      requerido: estado === "Aceptada" ? true : false,
                      label: "Ingresos",
                      type: "number",
                      name: "ingresos",
                      placeholder: "Ingrese Ingresos $ ",
                      valor: ingresos,
                      disabled: false,
                    }}
                    onChange={onChange}
                  />

                  <Input
                    key={"solicito"}
                    sets={{
                      requerido: estado === "Aceptada" ? true : false,
                      label: "Monto Solicitado",
                      type: "number",
                      name: "solicito",
                      placeholder: "Ingrese monto solicitado ",
                      valor: solicito,
                      disabled: soloLectura,
                    }}
                    onChange={onChange}
                  />

                  <div className="row">
                    <div className="col-sm">
                      <Select
                        key={"limite_x_cuota"}
                        sets={{
                          requerido: estado === "Aceptada" ? true : false,
                          disabled: soloLectura,
                          label: "Límite por Cuota",
                          name: "limite_x_cuota",
                          valor: limite_x_cuota,
                          opciones: [
                            { label: "30% de los Ingresos", value: "30%" },
                            { label: "40% de los Ingresos", value: "40%" },
                            { label: "50% de los Ingresos", value: "50%" },
                            { label: "Monto Libre", value: "Monto Libre" },
                          ],
                        }}
                        onChange={onChange}
                      />
                    </div>
                    <div className="col-sm">
                      <Input
                        key={"limite"}
                        sets={{
                          requerido: estado === "Aceptada" ? true : false,
                          label: "Límite por Cuota",
                          type: "number",
                          name: "limite",
                          placeholder: "Ingresos",
                          valor: limite,
                          disabled: limite_x_cuota == "Monto Libre" || limite_x_cuota == "Sin Limite" ? false : true,
                        }}
                        onChange={onChange}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 form-check border fs-5 d-flex justify-content-center rounded-pill">
                      {
                        premium == "SI"
                          ? <div className="">
                            <input class="form-check-input" type="checkbox" value=""
                              name="premium"
                              onChange={(event) => onChange(event)}
                              checked />
                            <label class="form-check-label" for="flexCheckDefault">
                              Quitar Premium
                            </label>
                          </div>
                          : <div className="">
                            <input class="form-check-input" type="checkbox" value=""
                              name="premium"
                              onChange={(event) => onChange(event)} />
                            <label class="form-check-label" for="flexCheckDefault">
                              Cliente Premium
                            </label>
                          </div>
                      }
                    </div>
                  </div>

                  <TextArea
                    key={"observacion"}
                    sets={{
                      requerido: estado === "Observada" ? true : false,
                      label: "Observacion",
                      name: "observacion",
                      placeholder: "ingresar Observacion",
                      valor: observacion,
                      rows: "5",
                      cols: "10",
                      disabled: soloLectura,
                    }}
                    onChange={onChange}
                  />
                  <br></br>
                  { /*loadingLocal ? <Spinner /> : <MostrarAlerta /> */}
                  {
                    !soloLectura ? (
                      <div className="">

                        <Select
                          key={"estado"}
                          sets={{
                            requerido: estado === "Aceptada" ? true : false,
                            disabled: soloLectura,
                            label: "Estado",
                            name: "estado",
                            valor: estado,
                            opciones: [
                              { label: "Pendiente", value: "Pendiente" },
                              { label: "Aceptada", value: "Aceptada" },
                              { label: "Rechazada", value: "Rechazada" },
                              { label: "Observada", value: "Observada" },
                            ],
                          }}
                          onChange={onChange}
                        />

                      </div>
                    )
                      : null
                  }

                  {
                    <div>
                      <label className={
                        estado == "Baja" ? "btn btn-primary w-100 mb-2 me-2 disabled" : "btn btn-primary w-100 mb-2 me-2"
                      }>
                        Aplicar Cambios
                        <button
                          className="d-none"
                          name="Aplicar"
                          onClick={(event) => guardar(estado)}
                          disabled={
                            estado == "Baja" ? true : false
                          }
                        ></button>
                      </label>
                      <label className="btn btn-primary w-100">
                        Crear Nuevo Análisis
                        <button
                          className="d-none"
                          name="crear"
                          onClick={(event) => onClick(event)}
                        ></button>
                      </label>
                    </div>
                  }
                </form>
                <br></br>
              </div>
              <div className="col-sm text-center">
                <div
                  style={{
                    padding: "20px",
                    backgroundColor: "grey",
                    borderRadius: "10px",
                    color: "white",
                  }}
                >
                  Promedio: <br></br>
                  <div style={{ fontSize: "50px" }}>{promedio}</div>
                  <div>Fecha de vencimiento {fecha_vencimiento !== "" ? format(new Date(fecha_vencimiento + " 00:00:00"), "dd/MM/yyyy").toString() : ""}</div>
                </div>
              </div>
            </div>
          </div>
          : personaSeleccionada
            ? <div className="container-md min-vh-100">
              <br></br>
              <h2 className="text-center">
                Analisis Crediticio
              </h2>
              <br></br>

              <div
                className="row"
                style={{
                  borderRadius: "10px",
                  backgroundColor: "gray",
                  color: "white",
                  marginLeft: "2px",
                  marginRight: "2px",
                }}
              >
                <div className="col-sm">
                  {personaSeleccionada ? (
                    <div style={{ padding: "20px" }}>
                      {personaSeleccionada.persona.nombre}{" "}
                      {personaSeleccionada.persona.apellido}
                      <br></br>
                      {personaSeleccionada.persona.tipo_documento}{" "}
                      {personaSeleccionada.persona.nro_documento}
                      <br></br>
                      {personaSeleccionada.persona.calle}{" "}
                      {personaSeleccionada.persona.numero} -{" "}
                      {personaSeleccionada.persona.provincia},{" "}
                      {personaSeleccionada.persona.ciudad}
                      <br></br>
                      Domicilio: {personaSeleccionada.persona.calle}{" "}
                      {personaSeleccionada.persona.numero}{" "}
                      {personaSeleccionada.persona.ciudad}
                    </div>
                  ) : null}
                </div>
                <div className="col-sm">
                  <button
                    style={{ margin: "20px", float: "right" }}
                    className="btn btn-primary"
                    onClick={() => {
                      window.open(`/clientes/ver/${personaSeleccionada.persona.id}`);
                    }}
                  >
                    Mas Informacion
                  </button>
                </div>
              </div>
              <br></br>

              <div className="row">
                <div className="col-sm text-center">
                  <div style={{ paddingTop: "50px", fontSize: "30px" }}>
                    Monto Solicitado: ${" "}
                    {personaSeleccionada
                      ? personaSeleccionada.precalificaciones.solicito
                      : "0"}{" "}
                  </div>
                </div>
                <div className="col-sm d-none">
                  <div
                    style={{
                      padding: "20px",
                      backgroundColor: "grey",
                      borderRadius: "10px",
                      color: "white",
                    }}
                  >
                    Links <br></br>
                    <br></br>
                    <button
                      onClick={() => {
                        ir("https://www.credixsa.com/");
                      }}
                    >
                      CREDIXSA
                    </button>
                    <button
                      onClick={() => {
                        ir("http://www.bcra.gov.ar/BCRAyVos/Situacion_Crediticia.asp");
                      }}
                    >
                      BCRA
                    </button>
                    <button
                      onClick={() => {
                        ir(
                          "https://www.justiciacordoba.gob.ar/JusticiaCordoba/servicios/ConsultaJuicios.aspx"
                        );
                      }}
                    >
                      Juicios
                    </button>
                    <button
                      onClick={() => {
                        ir(
                          "https://www.rentascordoba.gob.ar/mirentas/rentas.html?page=situacionfiscal"
                        );
                      }}
                    >
                      DGR
                    </button>
                    <button
                      onClick={() => {
                        ir("https://micuilonline.com.ar/averiguar-cuil-cuit");
                      }}
                    >
                      MI CUIT Online
                    </button>
                    <button
                      onClick={() => {
                        ir("http://servicioswww.anses.gob.ar/ooss2/");
                      }}
                    >
                      CODEM
                    </button>
                  </div>
                </div>
              </div>
              <br></br>
              <div className="row">
                <div className="col-sm">
                  <form onSubmit={onSubmit} className="border p-3 form">
                    <label className="fw-bold mb-3 fs-3">Observaciones</label>
                    <textarea className="form-control" disabled rows={5} defaultValue={observacion}>
                    </textarea>
                    <br></br>
                  </form>
                  <br></br>
                </div>
                <div className="col-sm text-center">
                  <div
                    style={{
                      padding: "20px",
                      backgroundColor: "grey",
                      borderRadius: "10px",
                      color: "white",
                    }}
                  >
                    Promedio: <br></br>
                    <div style={{ fontSize: "50px" }}>{promedio}</div>
                  </div>
                </div>
              </div>
            </div>
            : <Spinner />
      }
    </>

  );
};
export default FromPreCalificacion;
