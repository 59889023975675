import { useState } from "react";
import { format, add, sub } from "date-fns";
import { CalculoCuota, InsertCuotas, SumaExcedentes } from "../../../../functions/FunctionCuota";
import { useEffect } from "react";

const DetalleCuota = ({
  data, onClick, onChange 
}) => {
  let deuda = 0.00;
  let resto = 0.0;
  let excedente_pago = null;

  const [detalles, setDetalles] = useState(null);

  console.log(data);

  useEffect(() => {
    setDetalles(data)
  },[data])
  return (
    <div className="table-responsive">
    <table className="table table-hover align-middle">
      <thead className="border-0">
        <tr>
          <th className="border-0 p-1 text-nowrap">
            <div
              className="border p-1"
              style={{ borderRadius: "10px" }}
            >
              Nº Cuota
            </div>
          </th>
          <th className="border-0 p-1 text-nowrap">
            <div
              className="border p-1"
              style={{ borderRadius: "10px" }}
            >
              Vto
            </div>
          </th>
          <th className="border-0 p-1 text-nowrap">
            <div
              className="border p-1"
              style={{ borderRadius: "10px" }}
            >
              Capital
            </div>
          </th>
          <th className="border-0 p-1 text-nowrap">
            <div
              className="border p-1"
              style={{ borderRadius: "10px" }}
            >
              Intereses
            </div>
          </th>
          <th className="border-0 p-1 text-nowrap">
            <div
              className="border p-1"
              style={{ borderRadius: "10px" }}
            >
              Importe
            </div>
          </th>
          <th className="border-0 p-1 text-nowrap">
            <div
              className="border p-1"
              style={{ borderRadius: "10px" }}
            >
              Días de mora
            </div>
          </th>
          <th className="border-0 p-1 text-nowrap">
            <div
              className="border p-1"
              style={{ borderRadius: "10px" }}
            >
              Int Punitorio 
            </div>
          </th>
          <th className="border-0 p-1 text-nowrap">
            <div
              className="border p-1"
              style={{ borderRadius: "10px" }}
            >
              Total a pagar
            </div>
          </th>
          <th className="border-0 p-1 text-nowrap">
            <div
              className="border p-1"
              style={{ borderRadius: "10px" }}
            >
              Deuda acumulada
            </div>
          </th>
          <th className="border-0 p-1 text-nowrap">
            <div
              className="border p-1"
              style={{ borderRadius: "10px" }}
            >
              Estado
            </div>
          </th>
          <th className="border-0 p-1 text-nowrap"></th>
        </tr>
      </thead>
      <tbody>

        {
        detalles
        ?detalles.map((cuota, index) => (
          <>
            <tr>
              <td className="border-0">{index + 1}</td>
              <td className="border-0">
                {format(
                  new Date(
                    cuota.fecha_vencimiento_concesion
                      ? cuota.fecha_vencimiento_concesion+" 00:00:00"
                      : cuota.fecha_vencimiento+" 00:00:00"
                  ),
                  "dd/MM/yyyy"
                ).toString()}
              </td>
              <td className="border-0 text-nowrap">
                ${" "}
                {new Intl.NumberFormat("de-DE").format(cuota.capital)}
              </td>
              <td className="border-0 text-nowrap">
                ${" "} 
                {new Intl.NumberFormat("de-DE").format(cuota.interes)}
              </td>
              <td className="border-0 text-nowrap">
                $ {new Intl.NumberFormat("de-DE").format(cuota.total)}
              </td>
              <td className="border-0">
                {
                  CalculoCuota(cuota).diferencia_moratoria
                }
              </td>
              <td className="border-0 text-nowrap">
                $ {CalculoCuota(cuota).original_interes_punitorio.toFixed(2)}
              </td>
              <td className="border-0 text-nowrap">
              {"$ "+new Intl.NumberFormat("de-DE").format(CalculoCuota(cuota).deuda.toFixed(2))}
              </td>
              <td className="border-0 text-nowrap">
                <span className="d-none">{deuda =  CalculoCuota(cuota, JSON.parse(excedente_pago)).deuda + deuda}</span>
                {"$ "+new Intl.NumberFormat("de-DE").format(deuda.toFixed(2))}
              </td>
              <td className="border-0">
                {
                parseFloat(deuda).toFixed(1) <= 0 ? (
                  <span>Cancelado</span>
                ) : (
                  <span>Espera de pago</span>
                )}
              </td>
              <td className="border-0">
                <div
                  class="accordion accordion-flush"
                >
                  <div class="accordion-item">
                    <h2
                      class="accordion-header"
                      id={"flush-headingOne" + index}
                    >
                      <label
                       class="collapsed btn btn-outline-dark">
                        Pagos
                      <button
                       className="d-none"
                       type="button"
                       data-bs-toggle="collapse"
                       data-bs-target={"#flush-collapseOne" + index}
                       aria-expanded="false"
                      >
                      </button>
                    </label>
                    </h2>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={10} className="border-0 m-0 p-0">
                <div
                  class="accordion accordion-flush"
                >
                  <div class="accordion-item">
                    <div
                      id={"flush-collapseOne" + index}
                      class="accordion-collapse collapse"
                    >
                      <div class="accordion-body">
                        <table className="table table-hover align-middle border-0">
                          <thead className="border-0">
                            <tr>
                              <th className="border-0">
                                Fecha de importe
                              </th>
                              <th className="border-0">
                                Tipo de importe
                              </th>
                              <th className="border-0">
                                Tipo de pago
                              </th>
                              <th className="border-0">Importe</th>
                              <th className="border-0">
                                Int Punitorio
                              </th>
                              <th className="border-0">Interes</th>
                              <th className="border-0">Capital</th>
                              <th className="border-0" colSpan={2}></th>
                            </tr>
                          </thead>
                          <tbody className="border-0">
                            {InsertCuotas(cuota.pagos, JSON.parse(excedente_pago)).map((pago, indexPago) => (
                              <tr>
                                <td className="border-0">
                                  {format(
                                    new Date(pago.fecha_importe+" 00:00:00"), "dd/MM/yyyy"
                                  ).toString()}
                                </td>
                                <td className="border-0">
                                  {pago.estado}
                                </td>
                                <td className="border-0">
                                  {pago.forma_pago}
                                </td>
                                <td className="border-0">
                                  {"$ " +
                                    new Intl.NumberFormat(
                                      "de-DE"
                                    ).format(pago.importe)}
                                </td>
                                <td className="border-0"></td>
                                <td className="border-0"></td>
                                <td className="border-0"></td>
                                <td className="border-0"></td>
                                <td className="border-0 d-flex justify-content-end">
                                  {pago.id ==
                                  parseInt(data.id_ultimo_pago) ? (
                                    <label className="btn btn-outline-danger">
                                      Cancelar pago
                                      <i class="ms-2 bi bi-lock"></i>
                                      <button
                                        className="d-none"
                                        name="delete_payment"
                                        onClick={(event) =>
                                          onClick(event, pago)
                                        }
                                        title="Se necesita clave de administrador para esa acción"
                                      ></button>
                                    </label>
                                  ) : (
                                    <span className="text-success">
                                      &nbsp;
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td className="border-0" colSpan={3}>
                                Total
                              </td>
                              <td className="border-0">
                                {"$ " +
                                  new Intl.NumberFormat(
                                    "de-DE"
                                  ).format(
                                    CalculoCuota(cuota, JSON.parse(excedente_pago)).pagos
                                  )}
                              </td>
                              <td className="border-0">
                                {"$ "+new Intl.NumberFormat("de-DE").format(CalculoCuota(cuota, JSON.parse(excedente_pago)).interes_punitorio)}
                              </td>
                              <td className="border-0">
                                {"$ "+new Intl.NumberFormat("de-DE").format(CalculoCuota(cuota, JSON.parse(excedente_pago)).interes)}
                              </td>
                              <td className="border-0" colSpan={2}>
                                {"$ "+new Intl.NumberFormat("de-DE").format(CalculoCuota(cuota, JSON.parse(excedente_pago)).capital)}
                              </td>
                              <td className="border-0"></td>
                            </tr>
                          </tbody>
                          <tfoot></tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </>
                                  ))
        : ""                        
        }
      </tbody>
      <tfoot></tfoot>
    </table>
  </div>
  )
}

export default DetalleCuota