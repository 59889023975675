import React, { useContext } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import { log } from "util";
import { CreditoDetalles } from "../../../functions/Validaciones/CreditoDetalles";
import { CuotaPago } from "../../../functions/Validaciones/CuotaPago";
import { SaldoCuotas } from "../../../functions/Validaciones/CuotasSaldo";
import ModalNotificacion from "../../personas/modals/ModalNotificacion";
import { PersonasContext, AuthContext, Link, clienteAxios } from "../../shared/";

const PreCalificacionEscritorio = ({
  selectUsuario,
  setSelectUsuario,
  item,
  garantes,
  editar,
  solicitarPrecalificacion,
  precalificar,
  calificaciones,
  generarCredito,
  generarCreditoEspecial,
  modalNoti
}) => {
  const personasContext = useContext(PersonasContext);
  const { SeleccionarPrecalificar } = personasContext;

  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  const [search, setSearch] = useState("");

  const acciones = () => {
    switch (item.precalificaciones?.estado) {
      case null:
        return (
          <>
            <option value="Solicitar Precalificacion">
              Solicitar Precalificacion
            </option>
            <option value="Solicitar Revision">
              Notificar
            </option>
          </>
        );

      case "Solicitada":
        if (usuario.rol === "Precalificador") {
          return (
            <>
              <option value="Pre Calificar">Análizar</option>
              <option value="Ver Calificaciones">Ver Análisis Financiero</option>
              <option value="Solicitar Revision">
                Notificar
              </option>
            </>
          );
        }

        if (usuario.rol === "Admin") {
          return (
            <>
              <option value="Pre Calificar">Análizar</option>
              <option value="Ver Calificaciones">Ver Análisis Financiero</option>
              <option value="Solicitar Revision">
                Notificar
              </option>
              <option value="Generar Credito Especial">Generar Credito Especial</option>
            </>
          )
        }
        break;
      //return <option value="Ver Calificaciones">Ver Calificaciones</option>;
      case "Rechazada":
      case "Observada":
        if (usuario.rol === "Precalificador" || usuario.rol === "Admin") {
          return (
            <>
              <option value="Pre Calificar">Análizar</option>
              <option value="Ver Calificaciones">Ver Análisis Financiero</option>
              <option value="Solicitar Revision">
                Notificar
              </option>
            </>
          );
        } else {
          return <>
            <option value="Ver Calificaciones">
              Ver Análisis Financiero
            </option>
            <option value="Solicitar Revision">Notificar</option>
          </>
        }
        break;
      case "Pendiente":
      case "Aceptada":
        if (usuario.rol == "Vendedor") {
          return (
            <>
              <option value="Generar Credito">Generar Credito</option>
              <option value="Ver Calificaciones">Ver Análisis Financiero</option>
              <option value="Solicitar Revision">
                Notificar
              </option>
            </>
          );
        }

        if (usuario.rol === "Precalificador") {
          return <>
            <option value="Pre Calificar">Análizar</option>
            <option value="Ver Calificaciones">
              Ver Análisis Financiero
            </option>
            <option value="Solicitar Revision">Notificar</option>
          </>
        }

        if (usuario.rol === "Admin") {
          return (
            <>
              <option value="Pre Calificar">Análizar</option>
              <option value="Generar Credito Especial">Generar Credito Especial</option>
              <option value="Generar Credito">Generar Credito</option>
              <option value="Ver Calificaciones">Ver Análisis Financiero</option>
              <option value="Solicitar Revision">
                Notificar
              </option>
            </>
          )
        }

      default:
        break;
    }
  };
  /* 
        <option value="Generar Credito">Generar Credito</option>
        <option value="Ver Calificaciones">Ver Calificaciones</option>
        <option value="Solicitar Calificaciones">Solicitar Calificaciones</option>
        {item.estado ? <option value="Solicitar Precalificacion">Solicitar Precalificacion</option> : null} */

  const onChange = (e) => {
    SeleccionarPrecalificar(item);

    switch (e.target.value) {
      case "Editar":
        editar(item.id);
        break;
      case "Generar Credito":
        generarCredito({...item, iddepersona: item.id});
        break;
      case "Generar Credito Especial": {
        generarCreditoEspecial({...item, iddepersona: item.id})
      } break;
      case "Ver Calificaciones":
        calificaciones(item.id);
        break;
      case "Solicitar Precalificacion":
        solicitarPrecalificacion(item.id);
        break;
      case "Pre Calificar":
        precalificar({...item, iddepersona: item.id});
        break;
      case "Solicitar Revision":
        modalNoti({...item, iddepersona: item.id});
        break;
      default:
        break;
    }
  };


  const saldo = SaldoCuotas(item.creditos);

  //Logs
  //console.log(saldo);
  //console.log(item);
  return (
    <tr
      style={{ backgroundColor: selectUsuario == item.id ? "rgba(233, 232, 232, 1)" : "white", color: "black" }}
    >

      <td className="text-center">
        <select
          name="opciones"
          className="form-select form-select-md"
          id="opciones"
          onChange={onChange}
          style={{ borderRadius: "15px" }}
        >
          <option value="">Seleccione..</option>
          <option value="Editar">Editar</option>
          {acciones()}
        </select>
      </td>
      <td className="text-center"></td>
      <td className="text-start">
        <Link to={`/clientes/ver/${item.id}`} target="_blank"
          className="nav-link fw-bold text-dark"
          onClick={() => setSelectUsuario(item.id)}
        >
          {item.apellido + " "}
          {item.nombre} / {item.nro_documento}
        </Link>
      </td>
      <td className="text-center">
        {item.garantes ? (
          <>
            {//item.garantes.map((garante) => (
              <div key={item.garantes.id_garante}>
                <Link
                  to={`/clientes/ver/${item.garantes.id_garante}`}
                  target="_blank"
                  className="nav-link fw-bold text-dark"
                >
                  {item.garantes.garante.nombre} {item.garantes.garante.apellido}
                </Link>{" "}
              </div>
            /*))*/}
          </>
        ) : (
          "Sin Garantes"
        )}
      </td>
      <td className="text-center" >
        <span style={
          item.precalificaciones
          ? item.precalificaciones.estado == "Aceptada"
            ? { color: "green" }
            : item.precalificaciones.estado == "Solicitada"
              ? { color: "yellow" }
              : item.precalificaciones.estado == "Rechazada"
                ? { color: "red", }
                : item.precalificaciones.estado == "Observada"
                  ? { color: "blue", }
                  : item.precalificaciones.estado == "Pendiente"
                    ? { color: "grey", }
                    : null
          :  { color: "grey", }
        }
          className="fw-bold">
          {item.precalificaciones ? item.precalificaciones.estado : "En espera de análisis financiero"}</span>
      </td>
      <td className="text-center text-nowrap">$ {new Intl.NumberFormat('de-DE').format(item.precalificaciones ? item.precalificaciones.limite : 0)}</td>
      <td className="text-center text-nowrap">$ {new Intl.NumberFormat('de-DE').format(item.precalificaciones ? item.precalificaciones.limite - saldo : 0)}</td>
      <td className="text-center text-nowrap">{item.precalificaciones ? item.precalificaciones.premium : "NO"}</td>



    </tr>
  );
};

export default PreCalificacionEscritorio;
