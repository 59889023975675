import React from "react";
import { BotonEditar, BotonEliminar } from "../../shared/FormsElements";

const DeclaracionBien = ({ item, editar, eliminar, soloLectura }) => {
  return (
    <tr>
      <td className="text-center">{item.tipo}</td>
      <td className="text-center">{item.descripcion}</td>
      <td className="text-center">{item.detalle}</td>
      <td className="text-center">
        {!soloLectura ? (
          <>
            <BotonEditar editar={editar} item={item} />
            <BotonEliminar eliminar={eliminar} item={item} />
          </>
        ) : null}
      </td>
    </tr>
  );
};

export default DeclaracionBien;
