import React, { useReducer } from "react";
import creditosReducer from "./creditosReducer";
import creditosContext from "./creditosContext";
import clienteAxios from "../../config/axios";

import {
  CREGISTRO_ERROR,
  CREGISTRO_EXITO,
  COBTENER_USUARIOS,
  CACTUALIZAR_USUARIO,
  CSELECCIONAR_USUARIO,
  CELIMINAR_USUARIO,
  CCARGANDO,
  CORDENAR_FILTRAR_USUARIOS,
  CDESELECCIONAR_USUARIO,
} from "../../types";

const CreditosState = (props) => {
  const initialState = {
    usuario: null,
    usuarioSeleccionado: null,
    usuarios: [],
    resultado: [],
    cargando: false,
    mensaje: { msg: null, class: null },
  };

  const [state, dispatch] = useReducer(creditosReducer, initialState);


  const ordenarFiltrar = (filtros, campoOrden, tipoOrden) => {
    let elementos = state.usuarios;
    //console.log(elementos);
    if (filtros !== null) {
      elementos = elementos
        .filter((item) =>
          String(item.id)
            .toLowerCase()
            .startsWith(
              `${filtros.id !== undefined ? filtros.id : ""}`
            )
        )
        .filter((item) =>
          String(item.nombre)
            .toLowerCase()
            .startsWith(`${filtros.nombre !== undefined ? filtros.nombre : ""}`)
        )
        .filter((item) =>
          String(item.apellido)
            .toLowerCase()
            .startsWith(`${filtros.apellido !== undefined ? filtros.apellido : ""}`)
        )
        .filter((item) =>
          String(item.nro_documento)
            .toLowerCase()
            .startsWith(`${filtros.nro_documento !== undefined ? filtros.nro_documento : ""}`)
        )
        .filter((item) =>
          String(item.cuotas)
            .toLowerCase()
            .startsWith(`${filtros.cuotas !== undefined ? filtros.cuotas : ""}`)
        )
        .filter((item) =>
          String(item.abonadaCuotas)
            .toLowerCase()
            .startsWith(
              `${filtros.abonadaCuotas !== undefined ? filtros.abonadaCuotas : ""}`
            )
        ) 
        .filter((item) =>
        String(item.vencidaCuotas)
          .toLowerCase()
          .startsWith(
            `${filtros.vencidaCuotas !== undefined ? filtros.vencidaCuotas : ""}`
          )
      )
      .filter((item) =>
      String(item.total)
        .toLowerCase()
        .startsWith(
          `${filtros.total !== undefined ? filtros.total : ""}`
        )
    )
    .filter((item) =>
    String(item.saldo)
      .toLowerCase()
      .startsWith(
        `${filtros.saldo !== undefined ? filtros.saldo : ""}`
      )
  )
  .filter((item) =>
  String(item.estado)
    .toLowerCase()
    .startsWith(
      `${filtros.estado !== undefined ? filtros.estado : ""}`
    )
);
    } else {
      elementos = state.usuarios;
    }

    //console.log("personas", state.personas);

    // ordenar asc y desc
    if (campoOrden !== null && tipoOrden !== null) {
      if (tipoOrden !== null) {
        if (tipoOrden === "asc") {
          elementos = elementos.sort(function (a, b) {
            return String(a[campoOrden])
              .toLowerCase()
              .localeCompare(String(b[campoOrden]).toLowerCase());
          });
        } else {
          elementos = elementos.sort(function (a, b) {
            return String(b[campoOrden])
              .toLowerCase()
              .localeCompare(String(a[campoOrden]).toLowerCase());
          });
        }
      }
    } else {
      // elementos = state.personas;
    }

    dispatch({
      type: CORDENAR_FILTRAR_USUARIOS,
      payload: elementos,
    });
  };

  const deseleccionarUsuario = () => {
    dispatch({
      type: CDESELECCIONAR_USUARIO,
    });
  };

  const ocultarMensaje = () => {
    dispatch({
      type: CREGISTRO_EXITO,
    });
  };

  const actualizarUsuario = (usuario) => {
    //console.log(usuario);
    dispatch({
      type: CACTUALIZAR_USUARIO,
      payload: usuario,
    });
  };

  const eliminarUsuarioII = async (item) => {
    try {
      await clienteAxios.delete("/PersonaCreditos/remove", {
        params: { id: item.id },
      });
      dispatch({
        type: CELIMINAR_USUARIO,
        payload: item,
      });
    } catch (e) {
      dispatch({
        type: CREGISTRO_ERROR,
        payload: "Hubo un error",
      });
    }
  };

  const seleccionarUsuario = (esError, respuesta) => {
    //console.log(respuesta.data);
    if (esError) {
    } else {
      dispatch({
        type: CSELECCIONAR_USUARIO,
        payload: respuesta.data.tasa,
      });
    }
  };

  const infoDetalle = (detalle, id, estado, campo) => {
    //console.log(id, detalle);
    const filtrado = detalle.filter(
      (item) => item.credito_id === id && item.estado === estado
    );
    let valor = "0";
    if (filtrado.length > 0) {
      if (filtrado[0][campo]) {
        valor = filtrado[0][campo];
      }
    }
    return valor;
  };

  const obtenerUsuariosII = async (texto) => {
    try {
      dispatch({
        type: CCARGANDO,
        payload: true,
      });
      const resultado = await clienteAxios.get("/PersonaCreditos/getAll", {
        params: { texto },
      });

      //console.log(resultado);
      let resultadoUnido = [];

      resultado.data.creditos.map(
        (re) =>
          (resultadoUnido = [
            ...resultadoUnido,
            {
              ...re,
              aVencerTotal: infoDetalle(resultado.data.detalleCuotas,re.id,"A Vencer","total"),
              vencidaTotal: infoDetalle(resultado.data.detalleCuotas,re.id,"Vencida","total"),
              abonadaTotal: infoDetalle(resultado.data.detalleCuotas,re.id,"Abonada","total"),
              aVencerCuotas: infoDetalle(resultado.data.detalleCuotas,re.id,"A Vencer","cuotas"),
              vencidaCuotas: infoDetalle(resultado.data.detalleCuotas,re.id,"Vencida","cuotas"),
              abonadaCuotas: infoDetalle(resultado.data.detalleCuotas,re.id,"Abonada","cuotas"),
              total: "",
              saldo: ""
            },
          ])
      );

      //console.log(resultadoUnido);

      dispatch({
        type: COBTENER_USUARIOS,
        payload: resultadoUnido,
      });
    } catch (error) {}
  };

  const registrarUsuario = (esError, respuesta) => {
    const mensaje = {
      msg: esError ? respuesta : "Se Registro el Usuario con Exito. ",
      class: esError ? "danger" : "success",
    };

    dispatch({
      type: CREGISTRO_ERROR,
      payload: mensaje, //respuesta.data.msg
    });
  };

  return (
    <creditosContext.Provider
      value={{
        usuario: state.usuario,
        mensaje: state.mensaje,
        usuarios: state.usuarios,
        usuarioSeleccionado: state.usuarioSeleccionado,
        cargando: state.cargando,
        resultado: state.resultado,
        seleccionarUsuario,
        obtenerUsuariosII,
        ocultarMensaje,
        registrarUsuario,
        actualizarUsuario,
        eliminarUsuarioII,
        ordenarFiltrar,
        deseleccionarUsuario,
      }}
    >
      {props.children}
    </creditosContext.Provider>
  );
};

export default CreditosState;
