import axios from 'axios';


/* 
Api V2 
BackEnd Laravel > 8
http://localhost:8000/api
https://api-laravel.ayuu.ar/public/api
https://api-test.ayuu.ar/public/api
*/

const defaultOptions = {
  baseURL: 'https://api-laravel.ayuu.ar/public/api',
  headers: {
    'Content-Type': 'application/json',
  },
};

//Create instance
let apiAxios = axios.create(defaultOptions);

//Set the AUTH token for any request
apiAxios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

export default apiAxios;