import React from "react";
import CuotaPreCalculada from "./CuotaPreCalculada";

const Precalculo = ({ tasas, montop, Precalcular, saldo_limite_cuota, diasDiferimiento, calificacion }) => {

  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "5px",
        padding: "5px",
      }}
    >
      <h4 className="text-center">Precalculo de Importes de Credito</h4>

      {tasas ? (
        <div className="" style={{ padding: "30px" }}>
          <div className="border-0 text-center">
            {tasas.map((tas) => (
              <CuotaPreCalculada
                calificacion={calificacion}
                key={tas.id}
                tasa={tas}
                montop={montop}
                Precalcular={Precalcular}
                saldo_limite_cuota={parseFloat(saldo_limite_cuota)}
                diasDiferimiento={diasDiferimiento}
              />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Precalculo;
