import { getDate, getMonth, getYear } from "date-fns";
import numeroALetras from "./NumeroALetras";

const numeroFormateado = (nro) => {
  return new Intl.NumberFormat("de-DE").format(parseFloat(nro, 2));
};

const mesLetras = (mes) => {
  const meses = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "setiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  return meses[parseInt(mes)];
};

const FechaVencimiento =(vencimiento)=>{
  const fechaVto = new Date (vencimiento.replace("-","/"));
  const txtvencimiento = `${numeroALetras(getDate(fechaVto)).toLowerCase()} (${getDate(fechaVto)}) de ${mesLetras(getMonth(fechaVto))} del ${getYear(fechaVto)}`;
  return txtvencimiento;
}

const LugaryFecha =(fecha)=>{
  const fechaf = new Date (fecha.replace("-","/"));
  const txtlugaryFecha = `Córdoba, ${numeroALetras(getDate(fechaf)).toLowerCase()} (${getDate(fechaf)}) de ${mesLetras(getMonth(fechaf))} del ${getYear(fechaf)}`;
  return txtlugaryFecha;
}

export { numeroFormateado, mesLetras, FechaVencimiento, LugaryFecha };
