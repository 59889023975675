import { CuotaDeuda } from "../../../functions/Validaciones/CuotaDeuda";
import { ConcesionesContext } from "../../shared";
import { tipoConcesion } from "../../shared/Helpers/CalculosConcesiones";
import { format, add, sub } from "date-fns";
import {
  CalculoCuota,
  InsertCuotas,
  SumaExcedentes,
} from "../../../functions/FunctionCuota";
import { useEffect } from "react";
import { useState } from "react";

const dateExtra = () => {
  return format(add(new Date(), {days: 1}), 'yyyy-MM-dd').toString()
}

const ModalResumen = ({ data, onClick, onChange, usuario }) => {
  let deuda = 0.0;
  let resto = 0.0;
  let excedente_pago = null;

  const [detalles, setDetalles] = useState(null);
  const [concesion, setConcesion] = useState(null);

  const restar = (value, restar) => {
    let result = value - restar;
    //reset
    resto = result > 0 ? 0 : result * -1;

    return result < 0
      ? 0
      : "$ " + new Intl.NumberFormat("de-DE").format(result);
  };

  useEffect(() => {
    setDetalles(data.detalles);
    setConcesion(data.concesion);
  }, []);
  return (
    <div
      class="d-block effect-blur align-middle position-absolute min-vh-100 w-100"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ zIndex: 2, top: 0, left: 0 }}
      id="modal-resumen"
    >
      <div
        class="bg-light modal-dialog animation-modal my-md-5  overflow-hidden  texto-normal "
        style={{ maxWidth: "1600px" }}
      >
        <div className="modal-content bg-light">
          <div class="title m-0 bg-white p-4 d-flex justify-content-between">
            <div>
              <h1>Resumen de pagos</h1>
              <span class="fs-6 text-muted"></span>
            </div>
            <div>
              <label className="btn border-0 bg-transparent">
                <i class="bi bi-x-lg"></i>
                <button
                  class="d-none"
                  name="close"
                  onClick={(event) => onClick(event, null)}
                ></button>
              </label>
            </div>
          </div>
          <div class="body p-4">
            {concesion ? (
              <div
                className="w-100 mb-3"
                style={{
                  borderRadius: "20px",
                  background: "rgba(217, 217, 217, 0.5)",
                }}
              >
                <ul class="list-group bg-transparent">
                  <li class="list-group-item bg-transparent border-0 fw-bold">
                    {tipoConcesion(concesion.id_tipoconcesion)}
                  </li>
                  <li class="list-group-item py-1 bg-transparent border-0 p-3 d-flex justify-content-start">
                    <div className="fs-6 me-2">Fecha de pacto</div>
                    <div className="fs-6 fw-bold">
                      {format(
                        new Date(concesion.fecha_alta),
                        "dd/MM/yyyy"
                      ).toString()}
                    </div>
                  </li>
                  <li class="list-group-item py-1 bg-transparent border-0 p-3 d-flex justify-content-start">
                    <div className="fs-6 me-2">Fecha vencimiento</div>
                    <div className="fs-6 fw-bold">
                      {
                      concesion.inicio_vencimiento == "0000-00-00"
                      ? "Sin plazo"
                      : format(
                        new Date(concesion.inicio_vencimiento + " 00:00:00"),
                        "dd/MM/yyyy"
                      ).toString()
                      }
                    </div>
                  </li>
                  <li class="list-group-item py-1 bg-transparent border-0 p-3 d-flex justify-content-start">
                    <div className="fs-6 me-2">Bonificación</div>
                    <div className="fs-6 fw-bold">
                      {concesion.porcentaje == 0
                        ? "Sin bonificación"
                        : concesion.porcentaje + "%"}
                    </div>
                  </li>
                  <li class="list-group-item py-1 bg-transparent border-0 p-3 d-flex justify-content-start">
                    <div className="fs-6 me-2">Estado</div>
                    {parseInt(concesion.id_estado) == 1 ? (
                      <div className="fs-6 fw-bold alert alert-success py-1">
                        Vigente
                      </div>
                    ) : parseInt(concesion.id_estado) == 2 ? (
                      <div className="fs-6 fw-bold alert alert-danger py-0">
                        Vencido
                      </div>
                    ) : (
                      ""
                    )}
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}

            <div className="table-responsive">
              <table className="table table-hover align-middle">
                <thead className="border-0">
                  <tr>
                    <th className="border-0 p-1 text-nowrap">
                      <div
                        className="border p-1"
                        style={{ borderRadius: "10px" }}
                      >
                        Nº Cuota
                      </div>
                    </th>
                    <th className="border-0 p-1 text-nowrap">
                      <div
                        className="border p-1"
                        style={{ borderRadius: "10px" }}
                      >
                        Vencimiento
                      </div>
                    </th>

                    {concesion ? (
                      <th className="border-0 p-1 text-nowrap">
                        <div
                          className="border p-1"
                          style={{ borderRadius: "10px" }}
                        >
                          Vencimiento Con.
                        </div>
                      </th>
                    ) : (
                      ""
                    )}

                    <th className="border-0 p-1 text-nowrap">
                      <div
                        className="border p-1"
                        style={{ borderRadius: "10px" }}
                      >
                        Capital
                      </div>
                    </th>
                    <th className="border-0 p-1 text-nowrap">
                      <div
                        className="border p-1"
                        style={{ borderRadius: "10px" }}
                      >
                        Intereses
                      </div>
                    </th>
                    <th className="border-0 p-1 text-nowrap">
                      <div
                        className="border p-1"
                        style={{ borderRadius: "10px" }}
                      >
                        Importe
                      </div>
                    </th>
                    <th className="border-0 p-1 text-nowrap">
                      <div
                        className="border p-1"
                        style={{ borderRadius: "10px" }}
                      >
                        Días de mora
                      </div>
                    </th>
                    <th className="border-0 p-1 text-nowrap">
                      <div
                        className="border p-1"
                        style={{ borderRadius: "10px" }}
                      >
                        Int Moratorio
                      </div>
                    </th>
                    <th className="border-0 p-1 text-nowrap">
                      <div
                        className="border p-1"
                        style={{ borderRadius: "10px" }}
                      >
                        Total a pagar
                      </div>
                    </th>
                    <th className="border-0 p-1 text-nowrap">
                      <div
                        className="border p-1"
                        style={{ borderRadius: "10px" }}
                      >
                        Deuda acumulada
                      </div>
                    </th>
                    <th className="border-0 p-1 text-nowrap">
                      <div
                        className="border p-1"
                        style={{ borderRadius: "10px" }}
                      >
                        Estado
                      </div>
                    </th>
                    <th className="border-0 p-1 text-nowrap"></th>
                  </tr>
                </thead>
                <tbody>
                  {detalles
                    ? detalles.map((cuota, index) => (
                        <>
                          <tr>
                            <td className="border-0">{index + 1}</td>
                            <td className="border-0">
                              {format(
                                new Date(cuota.fecha_vencimiento + " 00:00:00"),
                                "dd/MM/yyyy"
                              ).toString()}
                            </td>
                            {concesion ? (
                              <td className="border-0">
                                {format(
                                  new Date(
                                    cuota.fecha_vencimiento_concesion
                                      ? cuota.fecha_vencimiento_concesion +
                                        " 00:00:00"
                                      : cuota.fecha_vencimiento + " 00:00:00"
                                  ),
                                  "dd/MM/yyyy"
                                ).toString()}
                              </td>
                            ) : (
                              ""
                            )}
                            <td className="border-0 text-nowrap">
                              ${" "}
                              {new Intl.NumberFormat("de-DE").format(
                                cuota.capital
                              )}
                            </td>
                            <td className="border-0 text-nowrap">
                              ${" "}
                              {new Intl.NumberFormat("de-DE").format(
                                cuota.interes
                              )}
                            </td>
                            <td className="border-0 text-nowrap">
                              ${" "}
                              {new Intl.NumberFormat("de-DE").format(
                                cuota.total
                              )}
                            </td>
                            <td className="border-0">
                              {CalculoCuota(cuota).diferencia_moratoria}
                            </td>
                            <td className="border-0 text-nowrap">
                              ${" "}
                              {CalculoCuota(
                                cuota
                              ).original_interes_punitorio.toFixed(2)}
                            </td>
                            <td className="border-0 text-nowrap">
                              {"$ " +
                                new Intl.NumberFormat("de-DE").format(
                                  CalculoCuota(cuota).deuda.toFixed(2)
                                )}
                            </td>
                            <td className="border-0 text-nowrap">
                              <span className="d-none">
                                {
                                  (deuda =
                                    CalculoCuota(
                                      cuota,
                                      JSON.parse(excedente_pago)
                                    ).deuda + deuda)
                                }
                              </span>
                              {"$ " +
                                new Intl.NumberFormat("de-DE").format(
                                  deuda.toFixed(2)
                                )}
                            </td>
                            <td className="border-0">
                              {parseFloat(deuda).toFixed(1) <= 0 ? (
                                <span>Cancelado</span>
                              ) : (
                                <span>Espera de pago</span>
                              )}
                            </td>
                            <td className="border-0">
                              <div class="accordion accordion-flush">
                                <div class="accordion-item">
                                  <h2
                                    class="accordion-header"
                                    id={"flush-headingOne" + index}
                                  >
                                    <label class="collapsed btn btn-outline-dark">
                                      Pagos
                                      <button
                                        className="d-none"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={
                                          "#flush-collapseOne" + index
                                        }
                                        aria-expanded="false"
                                      ></button>
                                    </label>
                                  </h2>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={10} className="border-0 m-0 p-0">
                              <div class="accordion accordion-flush">
                                <div class="accordion-item">
                                  <div
                                    id={"flush-collapseOne" + index}
                                    class="accordion-collapse collapse"
                                  >
                                    <div class="accordion-body">
                                      <table className="table table-hover align-middle border-0">
                                        <thead className="border-0">
                                          <tr>
                                            <th className="border-0">
                                              Fecha de importe
                                            </th>
                                            <th className="border-0">
                                              Tipo de importe
                                            </th>
                                            <th className="border-0">
                                              Tipo de pago
                                            </th>
                                            <th className="border-0">
                                              Importe
                                            </th>
                                            <th className="border-0">
                                              Int Punitorio
                                            </th>
                                            <th className="border-0">
                                              Interes
                                            </th>
                                            <th className="border-0">
                                              Capital
                                            </th>
                                            <th
                                              className="border-0"
                                              colSpan={2}
                                            ></th>
                                          </tr>
                                        </thead>
                                        <tbody className="border-0">
                                          {InsertCuotas(
                                            cuota.pagos,
                                            JSON.parse(excedente_pago)
                                          ).map((pago, indexPago) => (
                                            <tr>
                                              <td className="border-0">
                                                {format(
                                                  new Date(
                                                    pago.fecha_importe +
                                                      " 00:00:00"
                                                  ),
                                                  "dd/MM/yyyy"
                                                ).toString()}
                                              </td>
                                              <td className="border-0">
                                                {pago.estado}
                                              </td>
                                              <td className="border-0">
                                                {pago.forma_pago}
                                              </td>
                                              <td className="border-0">
                                                {"$ " +
                                                  new Intl.NumberFormat(
                                                    "de-DE"
                                                  ).format(pago.importe)}
                                              </td>
                                              <td className="border-0"></td>
                                              <td className="border-0"></td>
                                              <td className="border-0"></td>
                                              <td className="border-0">
                                              </td>
                                              <td className="border-0 d-flex justify-content-end">
                                              </td>
                                            </tr>
                                          ))}
                                          <tr>
                                            <td
                                              className="border-0"
                                              colSpan={3}
                                            >
                                              Total
                                            </td>
                                            <td className="border-0">
                                              {"$ " +
                                                new Intl.NumberFormat(
                                                  "de-DE"
                                                ).format(
                                                  CalculoCuota(
                                                    cuota,
                                                    JSON.parse(excedente_pago)
                                                  ).pagos
                                                )}
                                            </td>
                                            <td className="border-0">
                                              {"$ " +
                                                new Intl.NumberFormat(
                                                  "de-DE"
                                                ).format(
                                                  CalculoCuota(
                                                    cuota,
                                                    JSON.parse(excedente_pago)
                                                  ).interes_punitorio
                                                )}
                                            </td>
                                            <td className="border-0">
                                              {"$ " +
                                                new Intl.NumberFormat(
                                                  "de-DE"
                                                ).format(
                                                  CalculoCuota(
                                                    cuota,
                                                    JSON.parse(excedente_pago)
                                                  ).interes
                                                )}
                                            </td>
                                            <td
                                              className="border-0"
                                              colSpan={2}
                                            >
                                              {"$ " +
                                                new Intl.NumberFormat(
                                                  "de-DE"
                                                ).format(
                                                  CalculoCuota(
                                                    cuota,
                                                    JSON.parse(excedente_pago)
                                                  ).capital
                                                )}
                                            </td>
                                            <td className="border-0"></td>
                                          </tr>
                                        </tbody>
                                        <tfoot></tfoot>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      ))
                    : ""}
                </tbody>
                <tfoot></tfoot>
              </table>
            </div>
          </div>
          <div class="footer bg-white p-4">
            <div class="d-flex justify-content-between">
              <div></div>
              <button
                class="btn btn-secondary rounded-pill px-4"
                name="close"
                onClick={(event) => onClick(event)}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalResumen;
