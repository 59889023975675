import React, { useState, useEffect, useCallback, useContext } from "react";
/*
import FormDatosContacto from "./contacto/FormDatosContacto";
import FormDatosPersonales from "./FormDatosPersonales";
import FormDatosDeclaracionBienes from "./declaracionbienes/DeclaracionesBienes";
import FormDatosDocumentacion from "./documentacion/Documentaciones";
import FormDatosDomicilios from "./domicilio/Domicilios";
import FormDatosObservaciones from "./observacion/Observaciones";
import FormDatosLaborales from "./laboral/Laborales";
import FormDatosGarantes from "./garantes/Garantes";
import FormDatosCreditos from "./creditos/FromDatosCreditos";
import FormDatosHistorial from "./historial/FormDatosHistoriales";
*/

import { clienteAxios, PersonasContext } from "../../shared/";
import AlertSuccess from "../../CustomAlerts/AlertSuccess";

const Persona = (props) => {
  const personaContext = useContext(PersonasContext);
  const { conseguirPersona, personaSeleccionada } = personaContext;
  //Vista solo lectura
  const [soloLectura, setSoloLectura] = useState(false);
  const [style, setStyle] = useState()

  const [key, setKey] = useState(1);
  const [maxkey, setMaxKey] = useState(1);
  const [id, setIdpersona] = useState(props.match.params.token);
  const [personaLoc] = useState({ id: null, persona: null });
  const [inicio, setInicio] = useState(true);
  
  const [persona, setPersona] = useState(null)
  const token = props.match.params.token;

  useEffect(() => {
    if (window.location.pathname.startsWith("/clientes/ver/")) {
      setSoloLectura(true);
      setStyle({
        zIndex: 3,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
      });
    }

    const CargarDatosPersonales = async () => {
      if (!inicio) return;

      try {
        const result = await clienteAxios.get("/persona/public_get", { params: { token } });
        setPersona(result.data);
      } catch (e) {
      }
    };
    setInicio(null);
    CargarDatosPersonales();
  }, [key, id]);

  const seleccionarTab = useCallback(
    (tab) => {
      setKey(tab);

      if (id) {
        setMaxKey(11);
      }
    },
    [id]
  );

  const SelectForm = () => {
    /*
    switch (key) {
      case 1:
        return (
          <FormDatosPersonales
            id={id}
            SetearIdPersona={SetearIdPersona}
            personaLoc={personaLoc}
            seleccionarTab={seleccionarTab}
            soloLectura={soloLectura}
          />
        );
      case 2:
        return <FormDatosContacto id={id} soloLectura={soloLectura} />;
      case 3:
        return <FormDatosDomicilios id={id} soloLectura={soloLectura} />;
      case 4:
        return <FormDatosLaborales id={id} soloLectura={soloLectura} />;
      case 5:
        return <FormDatosDocumentacion id={id} soloLectura={soloLectura} />;
      case 6:
        return <FormDatosDeclaracionBienes id={id} soloLectura={soloLectura} />;
      case 7:
        return <FormDatosObservaciones id={id} soloLectura={soloLectura} />;
      case 8:
        return <FormDatosGarantes id={id} soloLectura={soloLectura} />;
      case 9:
        return <FormDatosCreditos id={id} soloLectura={soloLectura} />;
      case 10:
        return <FormDatosHistorial id={id} soloLectura={soloLectura} />;
      case 11:
        return <AnalisisFinanciero id={id} />;
      default:
        return <>Se ha Producido un Error</>;
    }
    */
  };

  const SetearIdPersona = async (id, persona) => {
    try {
      const result = await clienteAxios.get("/persona/public_get", { params: { id } });
      conseguirPersona(result.data);
    } catch (e) {
    }

    setIdpersona(id);
    /* setPersonaLoc({
      ...personaLoc,
      persona,
      id,
    }); */
  };

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "generar-token":{
        let form = {
          id: id,
          token: 1
        }
        try {
          const response = await clienteAxios.put("/personas", form);
          return AlertSuccess({msg: "Token generado!"});
        } catch (error) {
          return "";
        }
      }break;
    }
  }


  console.log({ personaSeleccionada });
  console.log({ persona });
  return (
    <div className={
      soloLectura
        ? "container-fluid min-vh-100 bg-white"
        : "container-fluid min-vh-100 bg-white"
    }
      style={style}
    >
      <h2 className="text-center">
        {personaSeleccionada && token
          ? `Editar Cliente ${personaSeleccionada.persona.nombre} ${personaSeleccionada.persona.apellido}`
          : "Agregar Cliente"}
      </h2>
      <br></br>
      <div className="border p-3 form">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button
              onClick={() => {
                seleccionarTab(1);
              }}
              className={`nav-link ${key === 1 ? "active" : ""} `}
            >
              <i className="fa fa-user"></i> Personales
            </button>
          </li>
          <li className="nav-item">
            <button
              onClick={() => {
                seleccionarTab(2);
              }}
              className={`nav-link ${key === 2 ? "active" : ""} ${maxkey > 1 ? "" : "disabled"
                }`}
            >
              <i className="fa fa-phone"></i> Contacto
            </button>
          </li>
          <li className="nav-item">
            <button
              onClick={() => {
                seleccionarTab(3);
              }}
              className={`nav-link ${key === 3 ? "active" : ""} ${maxkey > 2 ? "" : "disabled"
                }`}
            >
              <i className="fa fa-home"></i> Domicilios
            </button>
          </li>
          <li className="nav-item">
            <button
              onClick={() => {
                seleccionarTab(4);
              }}
              className={`nav-link ${key === 4 ? "active" : ""} ${maxkey > 3 ? "" : "disabled"
                }`}
            >
              <i className="fa fa-building"></i> Laborales
            </button>
          </li>
          <li className="nav-item">
            <button
              onClick={() => {
                seleccionarTab(5);
              }}
              className={`nav-link ${key === 5 ? "active" : ""} ${maxkey > 4 ? "" : "disabled"
                }`}
            >
              <i className="fa fa-copy"></i> Documentacion
            </button>
          </li>
          <li className="nav-item">
            <button
              onClick={() => {
                seleccionarTab(6);
              }}
              className={`nav-link ${key === 6 ? "active" : ""} ${maxkey > 5 ? "" : "disabled"
                }`}
            >
              <i className="fa fa-car"></i> Declaracion de Bienes
            </button>
          </li>
          <li className="nav-item">
            <button
              onClick={() => {
                seleccionarTab(7);
              }}
              className={`nav-link ${key === 7 ? "active" : ""} ${maxkey > 6 ? "" : "disabled"
                }`}
            >
              <i className="fa fa-eye"></i> Observaciones
            </button>
          </li>
          <li className="nav-item">
            <button
              onClick={() => {
                seleccionarTab(8);
              }}
              className={`nav-link ${key === 8 ? "active" : ""} ${maxkey > 7 ? "" : "disabled"
                }`}
            >
              <i className="fa fa-users"></i> Garantes
            </button>
          </li>

          <li className="nav-item">
            <button
              onClick={() => {
                seleccionarTab(9);
              }}
              className={`nav-link ${key === 9 ? "active" : ""} ${maxkey > 8 ? "" : "disabled"
                }`}
            >
              <i className="fa fa-money"></i> Creditos
            </button>
          </li>
          <li className="nav-item">
            <button
              onClick={() => {
                seleccionarTab(10);
              }}
              className={`nav-link ${key === 10 ? "active" : ""} ${maxkey > 9 ? "" : "disabled"
                }`}
            >
              <i className="fa fa-history"></i> Historial
            </button>
          </li>

          <li className="nav-item">
            <button
              onClick={() => {
                seleccionarTab(11);
              }}
              className={`nav-link ${key === 11 ? "active" : ""} ${maxkey > 10 ? "" : "disabled"
                }`}
            >
              <i className="fa fa-money"></i> Análisis financiero
            </button>
          </li>
        </ul>
        <div>{SelectForm()}</div>
      </div>
    </div>
  );
};

export default Persona;
