import React from "react";
import { TituloTabla } from "../Estilos";
import { clienteAxios } from "../../shared/";
import BuscarGarante from "./BuscarPersona";

const BuscarListarPersonas = ({
  lista,
  closeModal,
  setPersonaSeleccionada,
}) => {
  const editar = async (item) => {
    try {
      const result = await clienteAxios.get("/persona/getonepersona", {
        params: { id: item.id },
      });
      result.data.personas[0].solicito = "";
      setPersonaSeleccionada(result.data.personas[0]);
      closeModal();
    } catch (e) {}
  };

  return (
    <div className="table-responsive">
      <table
        className="table table-striped"
        style={{ backgroundColor: "white" }}
      >
        <TituloTabla>
          <tr>
            <th scope="col" className="text-center">
              Nombre y Apellido / DNI
            </th>
            <th scope="col" className="text-center">
              Opciones
            </th>
          </tr>
        </TituloTabla>
        <tbody>
          {lista.map((item) => (
            <BuscarGarante key={item.id} item={item} editar={editar} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BuscarListarPersonas;
