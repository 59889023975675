import React, { useEffect } from "react";
import { numeroFormateado, FechaVencimiento, LugaryFecha } from "../../shared/Helpers/Pagare";
import numeroALetras from "../../shared/Helpers/NumeroALetras";

const Pagare = ({
  monto,
  fecha,
  vencimiento,
  personaSeleccionada,
  interes_moratoria,
  interes_punitorio,
  cuota,
  cuotas,
  idcredito,
  texto,
  garante,
}) => {

  console.log(monto)

  
  useEffect(() => {
    
    if(personaSeleccionada){
    //  window.print();
    }
    

  }, [personaSeleccionada]);


  const armarTexto = (texto) => {
    let textoFinal = texto;

    if (!personaSeleccionada) return "";
    
    const txtdomicilio = ` en la calle ${personaSeleccionada.calle} ${personaSeleccionada.numero} ciudad ${personaSeleccionada.ciudad} provincia ${personaSeleccionada.provincia} `;

    textoFinal = textoFinal.replace("--domicilio--", txtdomicilio);
    textoFinal = textoFinal.replace("--nombre--", personaSeleccionada.nombre.toUpperCase());
    textoFinal = textoFinal.replace("--apellido--", personaSeleccionada.apellido.toUpperCase());
    textoFinal = textoFinal.replace("--documento--", numeroFormateado(personaSeleccionada.nro_documento));
    textoFinal = textoFinal.replace("--monto-letras--", numeroALetras(monto));
    textoFinal = textoFinal.replace("--monto-numero--", numeroFormateado(monto));
    textoFinal = textoFinal.replace("--interes-moratorio--", numeroFormateado(interes_moratoria));
    textoFinal = textoFinal.replace("--interes-punitorio--", numeroFormateado(interes_punitorio));
    textoFinal = textoFinal.replace("--fecha-vencimiento--", FechaVencimiento(vencimiento));
    textoFinal = personaSeleccionada.garantes.length > 0 ? textoFinal.replace("--garante--", "Con el presente garante "+personaSeleccionada.garantes[0].nombre+" "+personaSeleccionada.garantes[0].apellido+", con domicilio en la calle "+personaSeleccionada.garantes[0].calle+" "+personaSeleccionada.garantes[0].barrio+" "+personaSeleccionada.garantes[0].ciudad ) : textoFinal.replace("--garante--", "" ) ;
    textoFinal = `${LugaryFecha(fecha)}. ${textoFinal}`;

    

    return textoFinal;
  };

  const formateoMargenes = (cuota, cuotas)=>{

    let paginasPorHoja = 0;
    if(cuota > 1){
      if(parseInt(cuota) % 3 === 0){
        paginasPorHoja = 3;
      }else{
        paginasPorHoja = 2;
      }
    }else{
      paginasPorHoja = 1;
    }

    
    switch (paginasPorHoja) {
      case 3:
        return "15px 15px 600px 15px";
      case 2:
        return "15px 15px 600px 15px";
      default:
        return "15px 15px 15px 15px";
    }
  }

  if (!personaSeleccionada && !texto) return <></>;

  console.log(personaSeleccionada);
  return (
    <div
        className="center-block" 
        style={{ 
        margin: formateoMargenes(cuota, cuotas) ,
        border: "3px solid black",
        borderStyle: "solid",
        padding: "10px",
        paddingBottom: "30px",
        textAlign: "justify",
        fontSize: "15px",
        width: "813px"
      }}
    >
      <div className="row">
        <div className="col-sm">{`PAGARE $${numeroFormateado(monto)}`}</div>
        <div className="col-sm text-end">{`N° ${cuota}/${idcredito}`}</div>
      </div>
      <br></br>
      <div className="row">
        <div className="col-sm text-end">
          {`Vencimiento ${FechaVencimiento(vencimiento)}`}
          <br></br>
        </div>
      </div>
      <br></br>
      {armarTexto(texto)}
      <br></br>
      <br></br>
      Firma:
      <br></br>
      Aclaración:
      <br></br>
      DNI:
      <br></br>
    </div>
  );
};

export default Pagare;
