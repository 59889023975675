
import { Swal, useHistory, AuthContext, clienteAxios } from "../../shared/";

const Tabla = ({ data, puntoVenta, setSteps, steps }) => {
  const history = useHistory();


  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "editar":{
        history.push("/usuarios/editar/" + value.id);
      }
      break;
      case "cambiar_estado":{
        let formData = {
          user_id: parseInt(value.user.id),
          user_status: value.status
        };
        
        const response = await clienteAxios.post("/User/update", formData);
        setSteps(steps+1)  
        //
        Swal.fire({
          title: 'Usuario '+value.user.name+' Actualizado con exito',
        });

        
        window.location.reload()
      } break;
      case "cambiar_punto_venta":{
        let formData = {
          user_id: parseInt(value.user.id),
          user_punto_venta: value.punto_venta
        };
        
        const response = await clienteAxios.post("/User/update", formData);
        setSteps(steps+1)  
        //
        Swal.fire({
          title: 'Usuario '+value.user.name+' Actualizado con exito',
        });

        window.location.reload()
      } break;
    }
  }

  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead className="border-0">
          <tr className="">
            <th className="border-0 fs-5 p-1">
              <div className="border p-1" style={{ borderRadius: "10px" }}>
                Id User
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border p-1" style={{ borderRadius: "10px" }}>
                Usuario
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border p-1" style={{ borderRadius: "10px" }}>
                Nombre y apellido
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border p-1" style={{ borderRadius: "10px" }}>
                Email
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border p-1" style={{ borderRadius: "10px" }}>
                Rol
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border p-1" style={{ borderRadius: "10px" }}>
                Puntos de venta
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border p-1" style={{ borderRadius: "10px" }}>
                Estados
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border p-1" style={{ borderRadius: "10px" }}>
                Editar
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="border-0">
          {data.map((user) => (
            <tr>
              <td className="border-0">{user.id}</td>
              <td className="border-0">{user.user_username}</td>
              <td className="border-0">{user.name + " " + user.lastname}</td>
              <td className="border-0">{user.email}</td>
              <td className="border-0">{user.user_type}</td>
              <td className="border-0">
                <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {user.puntos_venta? user.puntos_venta.nombre : "Sin punto de venta asignado"}
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    {
                      puntoVenta
                      ? puntoVenta.map((venta) => (
                      <li>
                        <label className="btn">
                        {venta.nombre}
                        <button className="d-none"
                        name="cambiar_punto_venta"
                        onClick={(event) => onClick(event, {user: user, punto_venta: venta.id})}></button>
                      </label>
                      </li>
                      ))
                      : ""
                    }
                  </ul>
                </div>
              </td>
              <td className="border-0">
                <div class="dropdown">
                  <button
                    class=" btn btn-primary rounded-pill dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {user.user_active == 1 ? "Activo" : "Inactivo"}
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <label className="btn">
                        Activo
                        <button className="d-none"
                        name="cambiar_estado"
                        onClick={(event) => onClick(event, {user: user, status: 1})}
                        ></button>
                      </label>
                    </li>
                    <li>
                      <label className="btn">
                        Inactivo
                        <button className="d-none"
                        name="cambiar_estado"
                        onClick={(event) => onClick(event, {user: user, status: 0})}>
                        </button>
                      </label>
                    </li>
                  </ul>
                </div>
              </td>
              <td className="border-0">
                <label className="btn btn-primary rounded-pill">
                  Editar
                  <button
                    className="d-none"
                    name="editar"
                    onClick={(event) => onClick(event, user)}
                  ></button>
                </label>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot></tfoot>
      </table>
    </div>
  );
};

export default Tabla;
