import React, { useState, useContext, useEffect } from "react";
import { Input, BotoneraForm, Select } from "../shared/FormsElements";
import { useRest, Spinner, AuthContext, useAlerta } from "../shared/";

const FormUsuario = (props) => {
  let idBien;

  if (props.match.params.id) {
    idBien = props.match.params.id;
  } else {
    idBien = null;
  }

  const authContext = useContext(AuthContext);
  const {
    usuarioSeleccionado,
    ocultarMensaje,
    registrarUsuario,
    seleccionarUsuario,
    deseleccionarUsuario,
    mensaje,
  } = authContext;

  const [inicio, setInicio] = useState(true);
  const [consultar, setConsultar] = useState(true);

  const [setAlerta, MostrarAlerta] = useAlerta(null);
  const [loading, load] = useRest();
  const [eye, setEye] = useState(false);

  const [DatosForm, LeerForm] = useState({
    user_username: "",
    user_type: "",
    user_password: "",
    user_name: "",
    lastname: "",
    email: "",
    codigo_postal: "",
    user_active: "",
  });

  const {
    user_username,
    user_type,
    user_password,
    user_name,
    lastname,
    email,
    codigo_postal,
  } = DatosForm;

  useEffect(() => {
    const cargar = () => {
      if (consultar) {
        setConsultar(false);
        const api = {
          url: "/user",
          type: "get",
          datosJson: null,
          getParams: { id: idBien },
          funcion: seleccionarUsuario,
        };
        /* eslint-disable */
        load(api);
      }
    };

    const alertar = () => {
      if (loading) {
        setAlerta(null);
      }
      if (inicio) {
        // eslint-disable-next-line
        ocultarMensaje();
        setInicio(null);
        setAlerta(null);
      } else {
        if (mensaje.msg && !loading) {
          setAlerta({ msg: mensaje.msg, class: mensaje.class });
        }
      }
    };

    deseleccionarUsuario();
    if (idBien !== null) {
      cargar();
    }

    alertar();

    if (usuarioSeleccionado !== null) {
      LeerForm(usuarioSeleccionado);
    }
  }, [mensaje, loading, inicio, usuarioSeleccionado, idBien]);

  const sets = [
    {
      label: "Usuario",
      type: "text",
      name: "user_username",
      placeholder: "ingresar en Usuario",
      valor: user_username,
    },
    {
      label: "Password",
      type: "password",
      name: "user_password",
      placeholder: "ingresar el Password",
      valor: user_password,
    },
    {
      label: "Nombre",
      type: "text",
      name: "user_name",
      placeholder: "ingresar el Nombre",
      valor: user_name,
    },
    {
      label: "Apellido",
      type: "text",
      name: "lastname",
      placeholder: "ingresar el Apellido",
      valor: lastname,
    },
    {
      label: "Email",
      type: "email",
      name: "email",
      placeholder: "ingresar el Email",
      valor: email,
    },
    {
      label: "Codigo Postal",
      type: "number",
      name: "codigo_postal",
      placeholder: "ingresar el Codigo Postal",
      valor: codigo_postal,
    },
  ];

  const Insertar = () => {
    if (
      user_username.trim() === "" ||
      user_name.trim() === "" ||
      lastname.trim() === "" ||
      user_type.trim() === "" ||
      user_password.trim() === ""
    ) {
      setAlerta({ msg: "Todos los campos son obligatorios", class: "danger" });
      return;
    }

    const api = {
      url: "/users",
      type: "post",
      datosJson: {
        user_username,
        user_type,
        user_password,
        name: user_name,
        lastname,
        email,
        codigo_postal,
      },
      getParams: null,
      funcion: registrarUsuario,
    };

    load(api);
    setTimeout(() => {
      props.history.push("/usuarios");
    }, 2000);
  };

  const Actualizar = () => {
    if (
      user_username.trim() === "" ||
      user_name.trim() === "" ||
      lastname.trim() === "" ||
      user_type.trim() === ""
    ) {
      setAlerta({ msg: "Todos los campos son obligatorios", class: "danger" });
      return;
    }

    const api = {
      url: "/users",
      type: "put",
      datosJson: {
        user_id: idBien,
        user_username,
        user_type,
        user_password,
        name: user_name,
        lastname,
        email,
        codigo_postal,
      },
      getParams: null,
      funcion: registrarUsuario,
    };

    load(api);
    setTimeout(() => {
      props.history.push("/usuarios");
    }, 2000);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!idBien) {
      Insertar();
    } else {
      Actualizar();
    }
  };

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });
  };

  const funcionCancelar = () => {
    props.history.push("/usuarios");
  };

  console.log(sets);
  return (
    <div className="center-block">
      <br></br>
      <h2 className="text-center">{idBien ? "Editar " : "Agregar "} Usuario</h2>
      <br></br>
      <form onSubmit={onSubmit} className="border p-3 form">
        {
          sets.map((set) => {
            if (set.name == "user_password") {
              return (
                  <div class="input-group mb-3 row p-0 m-0 py-2 border-0">
                    <div style={{ fontSize: "small" }}  className="col-sm-3 d-flex justify-content-between" htmlFor="password">
                      Contraseña
                    <label class="btn btn-primary input-group-text rounded-pill" id="basic-addon1">
                      {
                        eye
                          ? <i class="bi bi-eye"></i>
                          : <i class="bi bi-eye-slash"></i>
                      }
                      <button className="d-none" onClick={(e) => {
                        e.preventDefault()
                        setEye(!eye)
                      }
                      }></button>
                    </label>
                    </div>
                    <div className="d-flex col-sm-9">
                    <input class="form-control form-control-md" placeholder="Contraseña" aria-label="Username" aria-describedby="basic-addon1"
                      type={eye ? "test" : "password"}
                      name="user_password"
                      onChange={onChange}
                      value={set.valor}
                    /></div>
                  </div>
              )
            } else {
              return <Input key={set.name} sets={set} onChange={onChange} />
            }
          })
        }

        <Select
          key={"user_type"}
          sets={{
            label: "Rol de Usuario",
            name: "user_type",
            valor: user_type,
            opciones: [
              { label: "Vendedor", value: "Vendedor" },
              { label: "Precalificador", value: "Precalificador" },
              { label: "Admin", value: "Admin" },
              { label: "Cajero", value: "Cajero" },
            ],
          }}
          onChange={onChange}
        />



        {loading ? <Spinner /> : <MostrarAlerta />}

        <BotoneraForm funcionCancelar={funcionCancelar} valorfuncion={null} />
      </form>
    </div>
  );
};
export default FormUsuario;
