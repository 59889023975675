import { add, format } from "date-fns";
import { useState } from "react";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { CreditoDetalles } from "../../../functions/Validaciones/CreditoDetalles";
import { SaldoCuotas } from "../../../functions/Validaciones/CuotasSaldo";
import AlertSuccess from "../../CustomAlerts/AlertSuccess";
import { clienteAxios, useAlerta, useHistory } from "../../shared";
import { BotoneraForm, Input, TextArea } from "../../shared/FormsElements";

const FormCreditoEspecial = (props) => {
  let id;
  let id_persona;

  if (props.match.params.id) {
    id = props.match.params.id;
  } else {
    id = null;
  }

  if (props.match.params.idpersona) {
    id_persona = props.match.params.idpersona;
  } else {
    id_persona = null;
  }

  const history = useHistory();

  const [setAlerta, MostrarAlerta] = useAlerta(null);
  const [tasa_generica, setTasaGenerica] = useState(null);
  const [form_persona, setFormPersona] = useState({
    id: null,
    estado: "",
    fecha_analisis: "",
    fecha_vencimiento: "",
    id_persona: null,
    ingresos: "",
    limite: "",
    limite_x_cuota: "",
    nombre: "",
    apellido: "",
    nro_documento: "",
    observacion: "",
    premium: "",
    solicito: "",
    veraz: "",
    bcra: "",
    bienes: "",
    dgr: "",
    referencias_personales: "",
    precalificacion: null,
    garantes: null,
    creditos: null,
  });
  const [form_credito, setFormCredito] = useState({
    interes: 0,
    capital: 0,
    total: 0,
    cuotas: 0,
    monto: 0,
    producto: "",
    comentarios: "",
    estado: "Vigente",
    vencimiento: "",
    fecha_finalizacion: "",
    fecha_otorgamiento: "",
    interes_moratorio: "",
    interes_punitorio: "",
    saldo_limite_cuota: 0,
    tipo_impresion: "1",
    credito_detalle: [],
  });

  const CalculoMoratoria = (
    interes_mora,
    capital,

    fecha_actual,
    fecha_vencimiento
  ) => {
    //Punitorios
    let interes_punitorio = 0;
    let diferencia_moratoria = 0;

    //Formatear fecha actual
    fecha_actual = format(fecha_actual, "yyyy-MM-dd").toString();
    fecha_actual = new Date(fecha_actual + " 00:00:00").getTime();
    //Fecha de vencimiento de cuota
    let vto = new Date(fecha_vencimiento + " 00:00:00").getTime();
    //Calculo de moratoria
    diferencia_moratoria = fecha_actual - vto;
    diferencia_moratoria = diferencia_moratoria / (1000 * 60 * 60 * 24);

    if (diferencia_moratoria > 0) {
      interes_punitorio = capital * (interes_mora / 100);
      interes_punitorio = interes_punitorio / 30;

      //intereses punitorios
      interes_punitorio = interes_punitorio * diferencia_moratoria;
    }

    return {
      p_interes: interes_punitorio,
      p_moratoria: diferencia_moratoria,
    };
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      //Persona
      case "id":
        {
        }
        break;
      //Credito
      case "interes":
        {
          setFormCredito({
            ...form_credito,
            [e.target.name]: e.target.value,
            total:
              parseFloat(e.target.value) + parseFloat(form_credito.capital),
          });

          if (
            form_credito.vencimiento.trim() != "" &&
            form_credito.cuotas > 0
          ) {
            let cuotas = form_credito.cuotas;
            let result = [];
            let monto = 0;
            let deuda = 0;

            let fecha_vto = "";

            for (let i = 0; i < cuotas; i++) {
              let interes = parseFloat(e.target.value);
              let capital = parseFloat(form_credito.capital);
              let total = parseFloat(
                parseFloat(e.target.value) + parseFloat(form_credito.capital)
              );

              const hoy = new Date();
              const vto = new Date(
                form_credito.vencimiento.replace("-", "/") + " 00:00:00"
              );

              if (i == 0) {
                fecha_vto = format(vto, "yyyy-MM-dd").toString();
              } else {
                fecha_vto = format(
                  add(new Date(fecha_vto + " 00:00:00"), { months: 1 }),
                  "yyyy-MM-dd"
                ).toString();
              }

              let { p_interes, p_moratoria } = CalculoMoratoria(
                tasa_generica["PUNITORIO"].valor + tasa_generica["MORATORIA"].valor,
                capital,

                hoy,
                fecha_vto
              );

              deuda = parseFloat(total) + parseFloat(p_interes) + deuda;

              let cuota = {
                nro: i + 1,
                vto: fecha_vto,
                interes: interes,
                capital: capital,
                total: total,
                diferencia_dias: p_moratoria < 0 ? 0 : p_moratoria,
                interes_punitorio: p_interes,
                deuda: deuda,
              };

              monto = parseFloat(monto) + parseFloat(total);

              result.push(cuota);
            }

            setFormCredito({
              ...form_credito,
              [e.target.name]: e.target.value,
              total:
                parseFloat(e.target.value) + parseFloat(form_credito.capital),
              credito_detalle: result,
              monto: monto,
            });
          }
        }
        break;
      case "capital":
        {
          setFormCredito({
            ...form_credito,
            [e.target.name]: e.target.value,
            total:
              parseFloat(e.target.value) + parseFloat(form_credito.interes),
          });

          if (
            form_credito.vencimiento.trim() != "" &&
            form_credito.cuotas > 0
          ) {
            let cuotas = form_credito.cuotas;
            let result = [];
            let monto = 0;
            let deuda = 0;

            let fecha_vto = "";

            for (let i = 0; i < cuotas; i++) {
              let interes = parseFloat(e.target.value);
              let capital = parseFloat(form_credito.capital);
              let total = parseFloat(
                parseFloat(e.target.value) + parseFloat(form_credito.capital)
              );

              const hoy = new Date();
              const vto = new Date(
                form_credito.vencimiento.replace("-", "/") + " 00:00:00"
              );

              if (i == 0) {
                fecha_vto = format(vto, "yyyy-MM-dd").toString();
              } else {
                fecha_vto = format(
                  add(new Date(fecha_vto + " 00:00:00"), { months: 1 }),
                  "yyyy-MM-dd"
                ).toString();
              }

              let { p_interes, p_moratoria } = CalculoMoratoria(
                tasa_generica["PUNITORIO"].valor + tasa_generica["MORATORIA"].valor,
                capital,

                hoy,
                fecha_vto
              );

              deuda = parseFloat(total) + parseFloat(p_interes) + deuda;

              let cuota = {
                nro: i + 1,
                vto: fecha_vto,
                interes: interes,
                capital: capital,
                total: total,
                diferencia_dias: p_moratoria < 0 ? 0 : p_moratoria,
                interes_punitorio: p_interes,
                deuda: deuda,
              };

              monto = parseFloat(monto) + parseFloat(total);

              result.push(cuota);
            }

            setFormCredito({
              ...form_credito,
              [e.target.name]: e.target.value,
              total:
                parseFloat(e.target.value) + parseFloat(form_credito.interes),
              credito_detalle: result,
              monto: monto,
            });
          }
        }
        break;
      case "comentarios":
      case "producto":
      case "total":
      case "fecha_otorgamiento":
      case "vencimiento":
      case "monto":
        {
          setFormCredito({
            ...form_credito,
            [e.target.name]: e.target.value,
          });
        }
        break;
      case "cuotas":
        {
          if (form_credito.vencimiento.trim() == "") {
            return Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Debe seleccionar una fecha de vencimiento valida",
            });
          }

          if (form_credito.total == 0) {
            return Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "El capital de la cuota debe ser mayor a 0",
            });
          }

          let cuotas = e.target.value;
          let result = [];
          let monto = 0;
          let deuda = 0;

          let fecha_vto = "";

          for (let i = 0; i < cuotas; i++) {
            let interes = parseFloat(form_credito.interes);
            let capital = parseFloat(form_credito.capital);
            let total = parseFloat(form_credito.total);

            const hoy = new Date();
            const vto = new Date(
              form_credito.vencimiento.replace("-", "/") + " 00:00:00"
            );

            if (i == 0) {
              fecha_vto = format(vto, "yyyy-MM-dd").toString();
            } else {
              fecha_vto = format(
                add(new Date(fecha_vto + " 00:00:00"), { months: 1 }),
                "yyyy-MM-dd"
              ).toString();
            }


            let { p_interes, p_moratoria } = CalculoMoratoria(
              parseFloat(tasa_generica["PUNITORIO"].valor) +  parseFloat(tasa_generica["MORATORIA"].valor),
              capital,

              hoy,
              fecha_vto
            );

            deuda = parseFloat(total) + parseFloat(p_interes) + deuda;

            let cuota = {
              nro: i + 1,
              vto: fecha_vto,
              interes: interes,
              capital: capital,
              total: total,
              diferencia_dias: p_moratoria < 0 ? 0 : p_moratoria,
              interes_punitorio: p_interes,
              deuda: deuda,
            };

            monto = parseFloat(monto) + parseFloat(total);

            result.push(cuota);
          }

          setFormCredito({
            ...form_credito,
            [e.target.name]: e.target.value,
            credito_detalle: result,
            monto: monto,
          });
        }
        break;
    }
  };

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "guardar":
        {
          let formDetalle = [];
          let vencimiento = "";

          form_credito.credito_detalle.map((detalle, index) => {
            let form = {
              pagoCapital: detalle.capital,
              pagoInteres: detalle.interes,
              cuota: detalle.total,
              fechaFinFormat: detalle.vto,
            };

            if (index == form_credito.credito_detalle.length - 1) {
              vencimiento = detalle.vto;
            }

            formDetalle.push(form);
          });

          let formData = {
            id_persona: id_persona,
            id_tasa: null,
            interes: null,
            monto: form_credito.monto,
            cuotas: form_credito.cuotas,
            garante: "",
            producto: form_credito.producto,
            vencimiento: form_credito.vencimiento,
            estado: "Vigente",
            fecha_finalizacion: vencimiento,
            fecha_otorgamiento: form_credito.fecha_otorgamiento,
            comentarios: form_credito.comentarios,
            interes_moratoria: tasa_generica["MORATORIA"].valor,
            interes_punitorio: tasa_generica["PUNITORIO"].valor,
            tipo_impresion: form_credito.tipo_impresion,

            detalleCredito: formDetalle,
          };

          const result = await clienteAxios.post(
            "/PersonaCreditos/add",
            formData
          );

          AlertSuccess({
            message: "Credito creado",
          });

          return history.push(
            "/creditos/especial/nuevo/" + id_persona + "/" + result.data.id
          );
        }
        break;
    }
  };

  useEffect(() => {
    const fetch = async () => {
      if (id_persona) {
        const result = await clienteAxios.get("/persona/getonepersona", {
          params: { id: id_persona },
        });
        setFormPersona(result.data.personas[0]);
      }

      if (id && tasa_generica) {
        const result = await clienteAxios.get("/PersonaCreditos/get", {
          params: { id: id },
        });

        const credito = result.data.credito;
        const credito_detalle = result.data.detalle;
        let detalles = [];
        let deuda = 0;


        credito_detalle.map((detalle, index) => {
          const hoy = new Date();

          let { p_interes, p_moratoria } = CalculoMoratoria(
            parseFloat(credito.interes_moratoria) + parseFloat(credito.interes_punitorio),
            parseFloat(detalle.capital),

            hoy,
            detalle.fecha_vencimiento
          );

          deuda = parseFloat(detalle.total) + parseFloat(p_interes) + deuda;

          let cuota = {
            nro: index + 1,
            vto: detalle.fecha_vencimiento,
            interes: detalle.interes,
            capital: detalle.capital,
            total: detalle.total,
            diferencia_dias: p_moratoria < 0 ? 0 : p_moratoria,
            interes_punitorio: p_interes,
            deuda: deuda,
          };

          detalles.push(cuota);
        });

        setFormCredito({
          interes: detalles[0].interes,
          capital: detalles[0].capital,
          total: parseFloat(detalles[0].interes) + parseFloat(detalles[0].capital),
          cuotas: credito.cuotas,
          monto: credito.monto,
          producto: credito.producto,
          comentarios: credito.comentarios,
          estado: credito.estado,
          vencimiento: credito.vencimiento,
          fecha_finalizacion: credito.fecha_finalizacion,
          fecha_otorgamiento: credito.fecha_otorgamiento,
          interes_moratorio: credito.interes_moratoria,
          interes_punitorio: credito.interes_punitorio,
          saldo_limite_cuota: 0,
          tipo_impresion: credito.tipo_impresion,
          credito_detalle: detalles,
        });
      }
    };

    fetch();
  }, [id_persona, id, tasa_generica]);

  useEffect(() => {
    const fetch = async () => {
      const result = await clienteAxios.get("/tasasGenericas/getAll");
      let tasas = {};

      result.data.tasasGenericas.map((tasa) => {
        tasas = {
          ...tasas,
          [tasa.tipo]: tasa,
        };
      });

      setTasaGenerica(tasas);
    };

    fetch();
  }, []);

  //console.log(form_persona);
  //console.log(form_credito);
  //console.log(form_persona);
  //console.log(tasa_generica)
  return (
    <div className="container-md min-vh-100">
      <h2 className="text-center py-3">Gestión de Crédito Especial</h2>

      <div className="row">
        <div className="col-md-5">
          <form action="" className="row">
            <div className="col-md-12 mb-4">
              <ul class="list-group">
                <li class="list-group-item border-0 d-flex justify-content-between">
                  <div>Nombre</div>
                  <div className="fw-bold">
                    {form_persona.apellido + " " + form_persona.nombre}
                  </div>
                </li>
                <li class="list-group-item border-0 d-flex justify-content-between">
                  <div>Nro Documento</div>
                  <div className="fw-bold">{form_persona.nro_documento}</div>
                </li>
                <li class="list-group-item border-0 d-flex justify-content-between">
                  <div>Promedio</div>
                  <div className="fw-bold">
                    {form_persona.precalificacion
                      ? form_persona.precalificacion.promedio
                      : ""}
                  </div>
                </li>
                <li class="list-group-item border-0 d-flex justify-content-between">
                  <div>Estado</div>
                  <div className="fw-bold">
                    {form_persona.precalificacion
                      ? form_persona.precalificacion.estado
                      : ""}
                  </div>
                </li>
                <li class="list-group-item border-0 d-flex justify-content-between">
                  <div>Observacion</div>
                  <div className="fw-bold">
                    {form_persona.precalificacion
                      ? form_persona.precalificacion.observacion
                      : ""}
                  </div>
                </li>
                <li class="list-group-item border-0 d-flex justify-content-between">
                  <div>Garantes</div>
                  <div className="fw-bold">
                    {form_persona.garantes
                      ? form_persona.garantes.length > 0
                        ? ""
                        : "No posee garantes"
                      : "No posee garantes"}
                  </div>
                </li>
              </ul>
            </div>
          </form>
        </div>
        <div className="col-md-7">
          <div className="row">
            <div className="col-md-12 mb-3">
              <TextArea
                key={"producto"}
                sets={{
                  label: "Producto * ",
                  name: "producto",
                  placeholder: "ingresar Producto",
                  valor: form_credito.producto,
                  rows: "2",
                  cols: "10",
                  requerido: true,
                  disabled: JSON.parse(id) ? true : false,
                }}
                onChange={onChange}
              />
            </div>
            <div className="col-md-12 mb-3">
              <TextArea
                key={"comentarios"}
                sets={{
                  label: "Comentario * ",
                  name: "comentarios",
                  placeholder: "ingresar Comentario",
                  valor: form_credito.comentarios,
                  rows: "2",
                  cols: "10",
                  disabled: JSON.parse(id) ? true : false,
                }}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="col-md-12 mb-3">
          
            <ul class="list-group">
              <li class="list-group-item border-0 d-flex justify-content-between">
                <div>Saldo limite por cuota</div>
                <div className="fw-bold">
                  {form_persona.creditos
                    ? SaldoCuotas(form_persona.creditos).toFixed(2)
                    : 0}
                </div>
              </li>
              <li class="list-group-item border-0 d-flex justify-content-between">
                <div>Interes Punitorio</div>
                <div className="fw-bold">
                  {tasa_generica ? tasa_generica["PUNITORIO"].valor : 0}%
                </div>
              </li>
              <li class="list-group-item border-0 d-flex justify-content-between">
                <div>Interes Moratorio</div>
                <div className="fw-bold">
                  {tasa_generica ? tasa_generica["MORATORIA"].valor : 0}%
                </div>
              </li>
            </ul>
          </div>
          <div className="col-md-12 mb-3 d-flex justify-content-between">
            <div className="fs-4">Nº de Credito</div>
            <div className="fs-4">{JSON.parse(id) ? id : "0000"}</div>
          </div>
          <div className="col-md-12">
            <table className="table">
              <thead>
                <tr>
                  <th colSpan={3} className="text-center">
                    Composicion de la cuota
                  </th>
                </tr>
                <tr>
                  <th>CAPITAL</th>
                  <th>INTERES</th>
                  <th>TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border-0">
                    <input
                      className="form-control"
                      type="number"
                      name="capital"
                      value={form_credito.capital}
                      onChange={(event) => onChange(event)}
                      disabled={JSON.parse(id) ? true : false}
                    />
                  </td>
                  <td className="border-0">
                    <input
                      className="form-control"
                      type="number"
                      name="interes"
                      value={form_credito.interes}
                      onChange={(event) => onChange(event)}
                      disabled={JSON.parse(id) ? true : false}
                    />
                  </td>
                  <td className="border-0">
                    <input
                      className="form-control"
                      type="number"
                      name="total"
                      value={form_credito.total}
                      onChange={(event) => onChange(event)}
                      disabled={JSON.parse(id) ? true : false}
                    />
                  </td>
                </tr>
              </tbody>
              <tfoot></tfoot>
            </table>
          </div>
          <div className="col-md-12 mb-3">
            <Input
              style={{ with: "10px" }}
              key={"fecha_otorgamiento"}
              sets={{
                label: "Fecha de otorgamiento",
                type: "date",
                name: "fecha_otorgamiento",
                placeholder: "Número cuotas",
                valor: form_credito.fecha_otorgamiento,
                requerido: true,
                disabled: JSON.parse(id) ? true : false,
              }}
              onChange={onChange}
            />
          </div>
          <div className="col-md-12 mb-3">
            <Input
              style={{ with: "10px" }}
              key={"vencimiento"}
              sets={{
                label: "Fecha Vto. 1 cuota",
                type: "date",
                name: "vencimiento",
                placeholder: "",
                valor: form_credito.vencimiento,
                requerido: true,
                disabled: JSON.parse(id) ? true : false,
              }}
              onChange={onChange}
            />
          </div>
          <div className="col-md-12 mb-3">
            <Input
              style={{ with: "10px" }}
              key={"cuotas"}
              sets={{
                label: "Número cuotas",
                type: "text",
                name: "cuotas",
                placeholder: "Número cuotas",
                valor: form_credito.cuotas,
                requerido: true,
                disabled: JSON.parse(id) ? true : false,
              }}
              onChange={onChange}
            />
          </div>
          <div className="col-md-12 mb-3">
            <Input
              style={{ with: "10px" }}
              key={"monto"}
              sets={{
                label: "Importe Total",
                type: "text",
                name: "monto",
                placeholder: "Importe Credito ",
                valor: form_credito.monto,
                requerido: true,
                disabled: true,
              }}
              onChange={onChange}
            />
          </div>
          <div className="col-md-12 mb-3">
            <div className="col-sm-10">
              {form_persona.garantes
                ? form_persona.garantes.map((garante) => (
                    <div class="form-check text-center">
                      <input
                        class="form-check-input ms-1"
                        type="radio"
                        id="flexRadioDefault1"
                        name="garante"
                        value={garante.nombre}
                        onChange={(event) => onChange(event)}
                      />
                      <label class="form-check-label" for="flexRadioDefault1">
                        {garante.nombre + " " + garante.apellido}
                      </label>
                    </div>
                  ))
                : form_persona.garantes
                ? form_persona.garantes.map((garante, index) => (
                    <Input
                      key=""
                      sets={{
                        label: "Garante - " + (index + 1),
                        type: "text",
                        name: "saldo_limite_cuota",
                        placeholder: " ",
                        valor: garante.nombre + " " + garante.apellido,
                        disabled: true,
                      }}
                      onChange={onChange}
                    />
                  ))
                : ""}
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="mb-3 fw-bold text-center w-100 fs-3">
            Composicion del Credito
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Nº CUOTA</th>
                <th>VTO</th>
                <th>CAPITAL</th>
                <th>INTERES</th>
                <th>DÍAS DE MORA</th>
                <th>INT MORATORIO</th>
                <th>TOTAL A PAGAR</th>
                <th>DEUDA ACUMULADA</th>
              </tr>
            </thead>
            <tbody>
              {form_credito.credito_detalle.map((detalle, index) => (
                <tr>
                  <td>{detalle.nro}</td>
                  <td>{format(new Date(detalle.vto), "dd/MM/yyyy").toString()}</td>
                  <td>
                    {new Intl.NumberFormat("de-DE").format(
                      parseFloat(detalle.capital).toFixed(2)
                    )}
                  </td>
                  <td>
                    {new Intl.NumberFormat("de-DE").format(
                      parseFloat(detalle.interes).toFixed(2)
                    )}
                  </td>
                  <td>{detalle.diferencia_dias}</td>
                  <td>
                    {new Intl.NumberFormat("de-DE").format(
                      parseFloat(detalle.interes_punitorio).toFixed(2)
                    )}
                  </td>
                  <td>
                    {new Intl.NumberFormat("de-DE").format(
                      (
                        parseFloat(detalle.total) +
                        parseFloat(detalle.interes_punitorio)
                      ).toFixed(2)
                    )}
                  </td>
                  <td>
                    {new Intl.NumberFormat("de-DE").format(
                      parseFloat(detalle.deuda).toFixed(2)
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot></tfoot>
          </table>
        </div>
        <div className="col-md-12 d-flex justify-content-between">
          <label className={JSON.parse(id) ? "btn d-none btn-success rounded-pill w-100 disabled" : "btn btn-success rounded-pill w-100"}>
            Guardar
            <button
              className="d-none"
              name="guardar"
              onClick={(event) => onClick(event)}
            ></button>
          </label>
        </div>
      </div>
    </div>
  );
};

export default FormCreditoEspecial;
