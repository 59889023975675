import React from "react";
import { BotonEditar, BotonEliminar } from "../../../shared/FormsElements";

const Email = ({ email, editar, eliminar, soloLectura }) => {
  return (
    <tr>
      <td className="text-center">{email.email}</td>
      <td className="text-center">{email.referencia_email}</td>
      <td className="text-center">{email.descripcion}</td>
      <td className="text-center">
        {!soloLectura ? (
          <>
            <BotonEditar editar={editar} item={email} />
            <BotonEliminar eliminar={eliminar} item={email} />
          </>
        ) : null}
      </td>
    </tr>
  );
};

export default Email;
