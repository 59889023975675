import React, { useState, useContext, useEffect } from "react";
import { Input, BotoneraForm, Select } from "../../shared/FormsElements";
import { Spinner, useAlerta, PersonasContext, clienteAxios, useLog } from "../../shared/";

const FormObservacion = ({ idpersona, closeModal, Seleccionado }) => {
  const personaContext = useContext(PersonasContext);
  const { ocultarMensaje, mensaje } = personaContext;

  const [inicio, setInicio] = useState(true);
  const [loadingLocal, setLoadingLocal] = useState(null);
  const [deshabilitado, setDeshabilitado] = useState(false);

  const [setAlerta, MostrarAlerta] = useAlerta(null);

  const [InsertarLog] = useLog(null);

  const [DatosForm, LeerForm] = useState({
    titulo: "",
    observacion: "",
    prioridad: "",
    id_persona: idpersona,
  });
  const { titulo, observacion, prioridad } = DatosForm;

  useEffect(() => {
    const cargar = () => {
      if (Seleccionado) {
        LeerForm(Seleccionado);
      }
    };

    const alertar = () => {
      if (loadingLocal) {
        setAlerta(null);
      }
      if (inicio) {
        // eslint-disable-next-line
        ocultarMensaje();
        setInicio(null);
        setAlerta(null);
      } else {
        if (mensaje.msg && !loadingLocal) {
          setAlerta({ msg: mensaje.msg, class: mensaje.class });
        }
      }
    };

    cargar();

    alertar();
  }, [mensaje, inicio]);

  const Insertar = async () => {
    if (
      titulo.trim() === "" ||
      prioridad.trim() === "" ||
      observacion.trim() === ""
    ) {
      setAlerta({
        msg: "Observacion, Titulo, y Prioridad es obligatorio",
        class: "danger",
      });
      return;
    }

    setAlerta(null);

    try {
      setLoadingLocal(true);
      await clienteAxios.post("/personasobservacion", DatosForm);
      InsertarLog( "crear", "Creacion de Observacion");
      setLoadingLocal(null);
      setAlerta({ msg: "Se Creo con Exito", class: "success" });
      setDeshabilitado(true);
      closeModal(false);
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const Actualizar = async () => {
    if (
      titulo.trim() === "" ||
      prioridad.trim() === "" ||
      observacion.trim() === ""
    ) {
      setAlerta({
        msg: "Observacion, Titulo, y Prioridad es obligatorio",
        class: "danger",
      });
      return;
    }

    setAlerta(null);

    try {
      setLoadingLocal(true);
      await clienteAxios.put("/personasobservacion", DatosForm);
      InsertarLog( "editar", "Edicion de Observacion");
      setLoadingLocal(null);
      setAlerta({ msg: "Se Actualizo con Exito", class: "success" });
      setDeshabilitado(true);
      closeModal(false);
    } catch (e) {
      setLoadingLocal(null);
      setAlerta({ msg: e.response.data.messages.msg, class: "danger" });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!Seleccionado) {
      Insertar();
    } else {
      Actualizar();
    }
  };

  const onChange = (e) => {
    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="center-block">
      <h5 className="text-center">
        {Seleccionado ? "Editar " : "Agregar "} Observacion
      </h5>
      <form onSubmit={onSubmit} className="border p-3 form">
        <Input
          key={"titulo"}
          sets={{
            label: "Titulo *",
            type: "text",
            name: "titulo",
            placeholder: "Ingrese Titulo ",
            valor: titulo,
          }}
          onChange={onChange}
        />

        <Select
          key={"prioridad"}
          sets={{
            label: "Prioridad *",
            name: "prioridad",
            valor: prioridad,
            opciones: [
              { label: "Alta", value: "Alta" },
              { label: "Media", value: "Media" },
              { label: "Baja", value: "Baja" },
            ],
          }}
          onChange={onChange}
        />

        <Input
          key={"observacion"}
          sets={{
            label: "Observacion *",
            type: "text",
            name: "observacion",
            placeholder: "Ingrese Observacion ",
            valor: observacion,
          }}
          onChange={onChange}
        />

        {loadingLocal ? <Spinner /> : <MostrarAlerta />}

        <BotoneraForm
          funcionCancelar={closeModal}
          valorfuncion={true}
          deshabilitado={deshabilitado}
        />
      </form>
    </div>
  );
};
export default FormObservacion;
