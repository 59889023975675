import React from "react";
import { BotonEditar, BotonEliminar } from "../../shared/FormsElements";

const Domicilio = ({ domicilio, editar, eliminar, soloLectura }) => {
  return (
    <tr>
      <td className="text-center">{domicilio.calle}</td>
      <td className="text-center">{domicilio.numero}</td>
      <td className="text-center">{domicilio.provincia}</td>
      <td className="text-center">{domicilio.ciudad}</td>
      <td className="text-center">{domicilio.barrio}</td>
      <td className="text-center">{domicilio.departamento}</td>
      <td className="text-center">{domicilio.piso}</td>
      <td className="text-center">{domicilio.codigo_postal}</td>
      <td className="text-center">{domicilio.referencia}</td>
      <td className="text-center">{domicilio.es_principal}</td>
      <td className="text-center">
        {!soloLectura ? (
          <>
            <BotonEditar editar={editar} item={domicilio} />
            {domicilio.es_principal === "NO" ? (
              <BotonEliminar eliminar={eliminar} item={domicilio} />
            ) : null}
          </>
        ) : null}
      </td>
    </tr>
  );
};

export default Domicilio;
