import {
  PREGISTRO_ERROR,
  PREGISTRO_EXITO,
  POBTENER_PAGOS,
  PACTUALIZAR_PAGO,
  PSELECCIONAR_PAGO,
  PELIMINAR_PAGO,
  PCARGANDO,
  PORDENAR_FILTRAR_PAGOS,
  PDESELECCIONAR_PAGO,
} from "../../types";

const concesionesReducer = (state, action) => {
  switch (action.type) {
    case PDESELECCIONAR_PAGO:
      return {
        ...state,
        pagoSeleccionado: null,
      };
    case PORDENAR_FILTRAR_PAGOS:
      return {
        ...state,
        resultado: action.payload,
      };
    case PCARGANDO:
      return {
        ...state,
        cargando: action.payload,
      };

    case PELIMINAR_PAGO:
      return {
        ...state,
        pagos: state.pagos.filter(
          (pago) => pago.id !== action.payload
        ),
        resultado: state.resultado.filter(
          (result) => result.id !== action.payload
        ),
      };
    case PSELECCIONAR_PAGO:
      return {
        ...state,
        pagoSeleccionado: action.payload,
      };

    case PACTUALIZAR_PAGO:
      return {
        ...state,
        pagos: state.pagos.map((pago) =>
          pago.id === action.payload.id ? action.payload : pago
        ),
      };

    case POBTENER_PAGOS:
      return {
        ...state,
        pagos: action.payload,
        resultado: action.payload,
        cargando: false,
      };

    case PREGISTRO_EXITO:
      return {
        ...state,
        mensaje: { msg: null, class: null },
      };

    case PREGISTRO_ERROR:
      return {
        ...state,
        mensaje: action.payload,
      };

    default:
      return state;
  }
};

export default concesionesReducer;
