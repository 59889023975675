import React, { useState, useEffect } from "react";
import ListarEmail from "./ListarEmails";
import { Spinner, clienteAxios, Portal } from "../../../shared/";
import { BotonAgregar } from "../../../shared/Estilos";
import FormEmails from "./FormEmail";

const Emails = ({ id, soloLectura }) => {
  const [loadingLocal, setLoadingLocal] = useState(null);
  const [emails, setEmails] = useState(null);

  const [consultar, setConsultar] = useState(true);
  const [emailSeleccionado, setemailSeleccionado] = useState(null);

  //modal
  const [isOpened, setOpened] = useState(false);
  const openModal = () => {
    setOpened(true);
    window.scrollTo(0, 0);
  };
  const closeModal = (cierro) => {
    if (cierro) {
      setOpened(false);
    }
    setConsultar(true);
    setemailSeleccionado(null);
  };
  //modal

  useEffect(() => {
    const cargar = async () => {
      if (!consultar) return;

      try {
        setLoadingLocal(true);
        
        const result = await clienteAxios.get("/personasemail", {
          params: { id },
        });
        setEmails(result.data.emails);
        setLoadingLocal(null);
      } catch (e) {}
      setConsultar(null);
    };

    cargar();
  }, [consultar, emailSeleccionado, id]);

  return (
    <div className="center-block">
      <br></br>

      <div className="row">

        {!soloLectura ? <div className="col-sm">
          <div className="form-group">

            {!isOpened ? (<BotonAgregar
              onClick={() => {
                openModal();
              }}
              className="btn btn-dark"
            >
              <i className="fa fa-plus-circle"></i> Agregar Email
            </BotonAgregar>) : null}
          </div>
        </div>: null}
        <br></br>
      </div>

      <br></br>
      {loadingLocal || !emails ? (
        <Spinner />
      ) : (
        <ListarEmail
          emails={emails}
          setemailSeleccionado={setemailSeleccionado}
          setConsultar={setConsultar}
          openModal={openModal}
          soloLectura={soloLectura}
        />
      )}

      <Portal isOpened={isOpened} onClose={closeModal}>
        <FormEmails
          idpersona={id}
          closeModal={closeModal}
          emailSeleccionado={emailSeleccionado}
        />
      </Portal>
    </div>
  );
};

export default Emails;
