import React, { useState, useEffect } from "react";
import PreCalificacionEscritorio from "./PreCalificacionEscritorio";
import { TituloTabla } from "../../shared/Estilos";
import FromMonto from "./FromMonto";
import { AuthContext, Portal, useHistory } from "../../shared/";
import { clienteAxios, PersonasContext } from "../../shared";
import { useContext } from "react";
import Swal from "sweetalert2";
import ModalNotificacion from "../../personas/modals/ModalNotificacion";
import Modal from "../../../module/modal/Modal";
import apiAxios from "../../../config/ApiAxios";



const ListarPreCalificacionesEscritorio = ({ lista, setConsultar }) => {
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  const history = useHistory();


  //State
  const [search, setSearch] = useState("");
  const [usuarios, setUsuarios] = useState(null);
  const [selectUsuario, setSelectUsuario] = useState();
  const [clienteSeleccionado, setSeleccionado] = useState(null);
  const [steps, setSteps] = useState(0);

  //Formularios
  const [form, setForm] = useState({
    body: "", //item.nombre+" "+item.apellido,
    link: "", //"/precalificar/"+item.id,
    mensaje: "",
    user_from: usuario.id,
    user_to: [],
    asunto: "",
  });

  //modals
  const [modal_notificacion, setModalNoti] = useState(false);
  const [isOpened, setOpened] = useState(false);
  const openModal = () => {
    setOpened(true);
    window.scrollTo(0, 0);
  };
  const closeModal = (cierro) => {
    if (cierro) {
      setOpened(false);
    }
    setConsultar(true);
    //setemailSeleccionado(null);
  };
  //modal

  const editar = (id) => {
    history.push("/clientes/editar/" + id);
  };

  const solicitarPrecalificacion = (id) => {
    localStorage.setItem("id", id);
    openModal();
    document.getElementById('btn-notificacion-analista').click();
  };

  const precalificar = (id) => {
    history.push("/precalificar/" + id);
  }

  const calificaciones = (id) => {
    history.push("/calificaciones/" + id);
  }

  const generarCredito = (item) => {
    history.push("/creditos/nuevo/" + item.id);
  }

  const generarCreditoEspecial = (item) => {
    history.push("/creditos/especial/nuevo/" + item.id + "/" + null);
  }

  const modalNoti = (item) => {
    setSeleccionado(item);
    setModalNoti(true);
    document.getElementById('btn-notificacion-solicitar').click();
  }


  //Eventos
  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "delete_user_to": {
        let result = form.user_to;
        delete (result[value]);


        result = result.filter((item) => {
          return item != null;
        });

        setForm({
          ...form,
          user_to: result
        })
      } break;
      case "user_to":
        {
          let result = form.user_to
          result.push(value)

          setForm({
            ...form,
            user_to: result
          })

          //Reset
          setSearch("");
        }
        break;
      case "enviar":
        {
          let dataForm = form;

          dataForm.body = clienteSeleccionado.nombre + " " + clienteSeleccionado.apellido;
          dataForm.link = "/precalificar/" + clienteSeleccionado.id_persona;

          try {
            await clienteAxios.post("/Notificaciones/add", dataForm);
            Swal.fire({
              icon: "success",
              title: "Notificacion enviada con exito",
              text: "",
            });

            setModalNoti(false);
          } catch (e) { }
        }
        break;
      case "close":
        {
          setModalNoti(false);
        }
        break;
    }
  };

  //
  useEffect(() => {
    const Load = async () => {
      const result = await clienteAxios.get("/User/getAll");
      setUsuarios(result.data.usuarios)

      //const result = await apiAxios.get("/personas");
      //setUsuarios(result.data)
    }
    Load()
  }, []);


  //Logs
  //console.log(lista);
  console.log(lista);
  return (
    <div className="min-vh-100" >
      <label className="d-none"><button
        className="d-none"
        data-bs-toggle="modal"
        data-bs-target="#notificacion-analista"
        id="btn-notificacion-analista"
      ></button>
      </label>
      <label className="d-none"><button
        className="d-none"
        data-bs-toggle="modal"
        data-bs-target="#notificacion-solicitar"
        id="btn-notificacion-solicitar"
      ></button>
      </label>
      <table className="table table-hover" style={{
        overflowY: "auto"
      }}>
        <thead className="sticky" id="navbar">
          <tr className="tr_sticky">
            <th scope="col" className="text-center">
              Acciones
            </th>
            <th scope="col" className="text-center" >
              Calificación Ayuu
            </th>
            <th scope="col" className="text-center" >
              Nombre y Apellido / DNI
            </th>
            <th scope="col" className="text-center" >
              Garantes
            </th>
            <th scope="col" className="text-center" >
              Estado
            </th>
            <th scope="col" className="text-center" >
              Límite de Cuota
            </th>
            <th scope="col" className="text-center" >
              Saldo para cuota
            </th>
            <th scope="col" className="text-center" >
              Premium
            </th>
          </tr>
        </thead>
        <tbody>
          {lista ? (
            <>
              {lista.data.map((item) => (
                <PreCalificacionEscritorio
                  selectUsuario={selectUsuario}
                  setSelectUsuario={setSelectUsuario}
                  key={item.iddepersona}
                  item={item}
                  garantes={""
                    /*lista.garantes.filter(
                    (garante) => garante.id_persona === item.id_persona
                  )*/}
                  editar={editar}
                  solicitarPrecalificacion={solicitarPrecalificacion}
                  precalificar={precalificar}
                  calificaciones={calificaciones}
                  generarCredito={generarCredito}
                  generarCreditoEspecial={generarCreditoEspecial}
                  modalNoti={modalNoti}
                />
              ))}
            </>
          ) : null}
        </tbody>
      </table>

      {
        /* 
         <Portal isOpened={isOpened} onClose={closeModal}>
          <FromMonto
            idpersona={1}
            closeModal={closeModal}
          />
        </Portal>
        */
      }

      <Modal
        title="Solicitar Precalificacion"
        id="notificacion-analista"
        width={"700px"}
        steps={steps}
        setSteps={setSteps}
        onClose={{
          name: "close",
          function: onClick,
        }}
        children={
          isOpened ? <FromMonto
            idpersona={1}
            closeModal={closeModal}
          /> : ""
        }
      />

      <Modal
        title="Notificacion"
        id="notificacion-solicitar"
        width={"600px"}
        steps={steps}
        setSteps={setSteps}
        onClose={{
          name: "close",
          function: onClick,
        }}
        onClick={{
          title: "ENVIAR",
          name: "enviar",
          function: onClick
        }}
        children={
          modal_notificacion && usuarios ? (
            <ModalNotificacion
              search={search}
              setSearch={setSearch}
              user={usuario}
              usuarios={usuarios}
              onClick={onClick}
              setForm={setForm}
              form={form}
            />
          ) : (
            ""
          )
        }
      />

    </div >
  );
};

export default ListarPreCalificacionesEscritorio;
