import { useContext } from "react";
import {
  clienteAxios,
  PersonasContext,
  AuthContext,
} from "../components/shared/";

const useLog = () => {
  const personaContext = useContext(PersonasContext);
  const { personaSeleccionada, conseguirPersona } = personaContext;

  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  const InsertarLog = async (iconColor, changeType, id=null) => {
    //console.log(personaSeleccionada, usuario);
    /* author: "vendedor"
          changeType: "Creación de la persona"
          date: "2020-05-02"
          icon: "<div class="figure bgcolor-success"><i class="ico-file-plus"></i></div>"
          time: "12:44 pm" */

    // eliminar icon: '<div class="figure bgcolor-red"><i class=""></i></div>',

    // edicion y crear icon: '<div class="figure bgcolor-green"><i class=""></i></div>',

    // crea persona icon: '<div class="figure bgcolor-success"><i class="ico-file-plus"></i></div>',

    let icon = "";
    switch (iconColor) {
      case "crear":
        icon = '<div class="figure bgcolor-green"><i class=""></i></div>';
        break;
      case "editar":
        icon = '<div class="figure bgcolor-green"><i class=""></i></div>';
        break;
      case "eliminar":
        icon = '<div class="figure bgcolor-red"><i class=""></i></div>';
        break;
      case "crear persona":
        icon =
          '<div class="figure bgcolor-success"><i class="ico-file-plus"></i></div>';
        break;
      default:
        break;
    }

    const hoy = new Date();
    let hora = hoy.getHours() > 12 ? hoy.getHours() - 12 : hoy.getHours();
    hora = hora.toString().length === 1 ? "0" + hora : hora;

    const time =
      hora +
      ":" +
      ((hoy.getMinutes() + 1).toString().length === 1 ? "0" : "") +
      hoy.getMinutes() +
      " " +
      (hoy.getHours() > 12 ? "pm" : "am");
    // "12:44 pm",

    const date =
      hoy.getFullYear() +
      "-" +
      ((hoy.getMonth() + 1).toString().length === 1 ? "0" : "") +
      (hoy.getMonth() + 1) +
      "-" +
      (hoy.getDate().toString().length === 1 ? "0" : "") +
      hoy.getDate();
    // date: "2020-05-02",

    const history = personaSeleccionada.persona ? personaSeleccionada.persona.history : "[]";
    //console.log(history);

    const nuevoLog = [
      ...JSON.parse(history),
      {
        time,
        date,
        author: usuario.username + " (" + usuario.rol + ")",
        icon,
        changeType,
      },
    ];

    const Datos = {
      history: JSON.stringify(nuevoLog),
      id: personaSeleccionada.persona ? personaSeleccionada.persona.id : id,
    };

    //console.log(Datos);

    try {
      await clienteAxios.put("/personas", Datos);
      const result = await clienteAxios.get("/persona", {
        params: { id: personaSeleccionada.persona.id },
      });
      conseguirPersona(result.data);
    } catch (e) {}
  };

  return [InsertarLog];
};

export default useLog;
