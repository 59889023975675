import { CuotaDeuda } from "../../../functions/Validaciones/CuotaDeuda";
import { ConcesionesContext, clienteAxios } from "../../shared";
import { tipoConcesion } from "../../shared/Helpers/CalculosConcesiones";
import { format, add, sub } from "date-fns";
import {
  CalculoCuota,
  InsertCuotas,
  SumaExcedentes,
  verificar_bug,
} from "../../../functions/FunctionCuota";
import ResumenContenido from "./ResumenContenido";
import { useEffect } from "react";
import { useState } from "react";
import InfoPago from "./InfoPago";
import Modal from "../../../module/modal/Modal";
import Comprobante from "./Comprobante";

const dateExtra = () => {
  return format(add(new Date(), { days: 1 }), "yyyy-MM-dd").toString();
};

const Resumen = ({ data, onChange, usuario,
  setPago,
  pago,
  //setComprobante,
  //comprobante,
  persona,
  credito,
  setSteps,
  steps,
  onClose }) => {
  let deuda = 0.0;
  let resto = 0.0;
  let excedente_pago = null;

  const [detalles, setDetalles] = useState(null);
  const [concesion, setConcesion] = useState(null);
  const [concesion_baja, setConcesionBaja] = useState(null);
  const [comprobante, setComprobante] = useState(false);

  const [trash, setTrash] = useState(null);

  const fetchTrashPay = async (id) => {
    let pass = document.getElementById("confirm-password").value;
    let formData = new FormData();
    formData.append("id", id);
    formData.append("password", pass);

    const response = await clienteAxios({
      method: "post",
      url: "/Pago/darBaja",
      data: formData,
    });

    if (response.status == 200 || response.status == 201) {
      let target = { target: { name: "close" } }
      document.getElementById('btn-close-trash-pay').click()
      setSteps(steps + 1)
      return onClose(target)
    }
  };

  const restar = (value, restar) => {
    let result = value - restar;
    //reset
    resto = result > 0 ? 0 : result * -1;

    return result < 0
      ? 0
      : "$ " + new Intl.NumberFormat("de-DE").format(result);
  };

  useEffect(() => {
    setDetalles(data.detalles);
    setConcesion(data.concesion);
    setConcesionBaja(data.concesion_de_baja);
  }, []);

  const infoPago = (e, index) => {
    const info = document.getElementById("info-pago-" + index);

    info.classList.add("d-block");
  };

  const infoPagoQuit = (e, index) => {
    const info = document.getElementById("info-pago-" + index);

    info.classList.remove("d-block");
  };

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "print_": {
        console.log(credito);
        setPago({
          pago: {
            importe: Number(value.pago.importe),
            importe_cuota: Number(value.pago.importe),
            estado: value.pago.estado,
            prev_estado: value.pago.prev_estado,
            fecha_importe: format(
              new Date(value.pago.fecha_importe),
              "yyyy-MM-dd"
            ).toString(),
            credito_detalle_id: value.pago.credito_detalle_id,
            id_concesion: value.pago.id_concesion,
            credito_id: value.pago.credito_id,
            nro_cuota: value.pago.nro_cuota,
            total_cuotas: data.cuotas,

            interes_punitorios: Number(value.pago.interes_punitorios),
            capital: Number(value.pago.capital),
            interes: Number(value.pago.interes),
            //Cuotas siguientes
            sig_cuotas: JSON.stringify(null),
            //Credito
            status_credito: "",
          },
          cuota: {
            ...value.cuota,
            nro: value.pago.nro_cuota,
            id_credito: value.cuota.credito_id,
            interes_mora: value.pago.interes_punitorios,
            interes: value.pago.interes,
            capital: value.pago.capital,
          },
        });

        console.log("Comprobante");
        return setComprobante(true);

      }
      break;
      case "eliminar-pago":
        {
          setTrash({
            id: value.id,
            body: (
              <div>
                <p>¿Quieres dar de baja el siguiente pago?</p>
                <ul class="list-group"><li class="list-group-item border-0 d-flex justify-content-between"><div>Estado</div><div>
                  {value.estado} </div></li><li class="list-group-item border-0 d-flex justify-content-between"><div>Fecha de importe</div><div>{value.fecha_importe} </div></li><li class="list-group-item border-0 d-flex justify-content-between"><div>Tipo de importe</div><div>
                    {value.forma_pago} </div></li><li class="list-group-item border-0 d-flex justify-content-between"><div>Importe</div><div>{value.importe}
                    </div></li></ul><div class="my-3 text-muted"> Para confirmar la baja de un pago se necesitan credenciales de administrador, ingrese su clave para completar la baja.</div><div><input type="password" id="confirm-password" class="form-control" /></div></div>
            ),
          });
        }
        break;
    }
  };
  return (
    <div class="body p-4">
      {concesion_baja
        ? concesion_baja.map((concesion) => (
          <div
            className="w-100 mb-3"
            style={{
              borderRadius: "20px",
              background: "rgba(217, 217, 217, 0.5)",
            }}
          >
            <ul class="list-group bg-transparent">
              <li class="list-group-item bg-transparent border-0 fw-bold">
                {tipoConcesion(concesion.id_tipoconcesion)}
              </li>
              <li class="list-group-item py-1 bg-transparent border-0 p-3 d-flex justify-content-start">
                <div className="fs-6 me-2">Fecha de pacto</div>
                <div className="fs-6 fw-bold">
                  {format(
                    new Date(concesion.fecha_alta),
                    "dd/MM/yyyy"
                  ).toString()}
                </div>
              </li>
              <li class="list-group-item py-1 bg-transparent border-0 p-3 d-flex justify-content-start">
                <div className="fs-6 me-2">Fecha vencimiento</div>
                <div className="fs-6 fw-bold">
                  {concesion.inicio_vencimiento == "0000-00-00"
                    ? "Sin plazo"
                    : format(
                      new Date(
                        concesion.inicio_vencimiento + " 00:00:00"
                      ),
                      "dd/MM/yyyy"
                    ).toString()}
                </div>
              </li>
              <li class="list-group-item py-1 bg-transparent border-0 p-3 d-flex justify-content-start">
                <div className="fs-6 me-2">Bonificación</div>
                <div className="fs-6 fw-bold">
                  {concesion.porcentaje == 0
                    ? "Sin bonificación"
                    : concesion.porcentaje + "%"}
                </div>
              </li>
              <li class="list-group-item py-1 bg-transparent border-0 p-3 d-flex justify-content-start">
                <div className="fs-6 me-2">Estado</div>
                {parseInt(concesion.id_estado) == 4 ? (
                  <div className="fs-6 fw-bold alert alert-danger py-1">
                    Baja
                  </div>
                ) : parseInt(concesion.id_estado) == 2 ? (
                  <div className="fs-6 fw-bold alert alert-danger py-0">
                    Vencido
                  </div>
                ) : (
                  ""
                )}
              </li>
            </ul>
          </div>
        ))
        : ""}

      {concesion ? (
        <div
          className="w-100 mb-3"
          style={{
            borderRadius: "20px",
            background: "rgba(217, 217, 217, 0.5)",
          }}
        >
          <ul class="list-group bg-transparent">
            <li class="list-group-item bg-transparent border-0 fw-bold">
              {tipoConcesion(concesion.id_tipoconcesion)}
            </li>
            <li class="list-group-item py-1 bg-transparent border-0 p-3 d-flex justify-content-start">
              <div className="fs-6 me-2">Fecha de pacto</div>
              <div className="fs-6 fw-bold">
                {format(
                  new Date(concesion.fecha_alta),
                  "dd/MM/yyyy"
                ).toString()}
              </div>
            </li>
            <li class="list-group-item py-1 bg-transparent border-0 p-3 d-flex justify-content-start">
              <div className="fs-6 me-2">Fecha vencimiento</div>
              <div className="fs-6 fw-bold">
                {concesion.inicio_vencimiento == "0000-00-00"
                  ? "Sin plazo"
                  : format(
                    new Date(
                      concesion.inicio_vencimiento + " 00:00:00"
                    ),
                    "dd/MM/yyyy"
                  ).toString()}
              </div>
            </li>
            <li class="list-group-item py-1 bg-transparent border-0 p-3 d-flex justify-content-start">
              <div className="fs-6 me-2">Bonificación</div>
              <div className="fs-6 fw-bold">
                {concesion.porcentaje == 0
                  ? "Sin bonificación"
                  : concesion.porcentaje + "%"}
              </div>
            </li>
            <li class="list-group-item py-1 bg-transparent border-0 p-3 d-flex justify-content-start">
              <div className="fs-6 me-2">Estado</div>
              {parseInt(concesion.id_estado) == 1 ? (
                <div className="fs-6 fw-bold alert alert-success py-1">
                  Vigente
                </div>
              ) : parseInt(concesion.id_estado) == 2 ? (
                <div className="fs-6 fw-bold alert alert-danger py-0">
                  Vencido
                </div>
              ) : (
                ""
              )}
            </li>
          </ul>
        </div>
      ) : (
        ""
      )}

      {
        detalles ?
          verificar_bug(detalles) ? (
            <div className="row ">
              <div className="col-md-3 mb-3">
                <label className="btn btn-info text-dark">
                  Reacomodar Pagos
                  <button
                    className="d-none"
                    name="reacomodar-pagos"
                    onClick={(event) => onClick(event, detalles)}
                  ></button>
                </label>
              </div>
            </div>
          ) : (
            ""
          ) : ""}

      <div className="table-responsive">
        <table className="table table-hover align-middle">
          <thead className="border-0">
            <tr className="border" style={{ borderRadius: "20px", background: "rgba(217, 217, 217, 0.5)" }}>
              <th className="border-0 p-1 text-nowrap">
                <div
                  className="border-0 p-1"
                  style={{ borderRadius: "10px" }}
                >
                  ID
                </div>
              </th>
              <th className="border-0 p-1 text-nowrap">
                <div
                  className="border-0 p-1"
                  style={{ borderRadius: "10px" }}
                >
                  Nº Cuota
                </div>
              </th>
              <th className="border-0 p-1 text-nowrap">
                <div
                  className="border-0 p-1"
                  style={{ borderRadius: "10px" }}
                >
                  Vencimiento
                </div>
              </th>

              {concesion ? (
                <th className="border-0 p-1 text-nowrap">
                  <div
                    className="border-0 p-1"
                    style={{ borderRadius: "10px" }}
                  >
                    Vencimiento Con.
                  </div>
                </th>
              ) : (
                ""
              )}

              <th className="border-0 p-1 text-nowrap">
                <div
                  className="border-0 p-1"
                  style={{ borderRadius: "10px" }}
                >
                  Capital
                </div>
              </th>
              <th className="border-0 p-1 text-nowrap">
                <div
                  className="border-0 p-1"
                  style={{ borderRadius: "10px" }}
                >
                  Intereses
                </div>
              </th>
              <th className="border-0 p-1 text-nowrap">
                <div
                  className="border-0 p-1"
                  style={{ borderRadius: "10px" }}
                >
                  Cuota
                </div>
              </th>
              <th className="border-0 p-1 text-nowrap">
                <div
                  className="border-0 p-1"
                  style={{ borderRadius: "10px" }}
                >
                  Días de mora
                </div>
              </th>
              <th className="border-0 p-1 text-nowrap">
                <div
                  className="border-0 p-1"
                  style={{ borderRadius: "10px" }}
                >
                  Int Moratorio
                </div>
              </th>
              <th className="border-0 p-1 text-nowrap">
                <div
                  className="border-0 p-1"
                  style={{ borderRadius: "10px" }}
                >
                  Total a pagar
                </div>
              </th>
              <th className="border-0 p-1 text-nowrap">
                <div
                  className="border-0 p-1"
                  style={{ borderRadius: "10px" }}
                >
                  Deuda acumulada
                </div>
              </th>
              <th className="border-0 p-1 text-nowrap">
                <div
                  className="border-0 p-1"
                  style={{ borderRadius: "10px" }}
                >
                  Estado
                </div>
              </th>
              <th className="border-0 p-1 text-center text-nowrap"></th>
              <th className="border-0"></th>
            </tr>
          </thead>
          <tbody>
            {detalles
              ? detalles.map((cuota, index) => (
                <>
                  <tr>
                    <td className="border-0">{cuota.id}</td>
                    <td className="border-0">{index + 1}</td>
                    <td className="border-0">
                      {format(
                        new Date(cuota.fecha_vencimiento + " 00:00:00"),
                        "dd/MM/yyyy"
                      ).toString()}
                    </td>
                    {concesion ? (
                      concesion.inicio_vencimiento == "0000-00-00" ? (
                        <td className="border-0">Sin plazo</td>
                      ) : (
                        <td className="border-0">
                          {format(
                            new Date(
                              cuota.fecha_vencimiento_concesion
                                ? cuota.fecha_vencimiento_concesion +
                                " 00:00:00"
                                : cuota.fecha_vencimiento + " 00:00:00"
                            ),
                            "dd/MM/yyyy"
                          ).toString()}
                        </td>
                      )
                    ) : (
                      ""
                    )}
                    <td className="border-0 text-nowrap">
                      ${" "}
                      {new Intl.NumberFormat("de-DE").format(
                        cuota.capital
                      )}
                    </td>
                    <td className="border-0 text-nowrap">
                      ${" "}
                      {new Intl.NumberFormat("de-DE").format(
                        cuota.interes
                      )}
                    </td>
                    <td className="border-0 text-nowrap">
                      ${" "}
                      {new Intl.NumberFormat("de-DE").format(
                        cuota.total
                      )}
                    </td>
                    <td className="border-0">
                      {CalculoCuota(cuota).diferencia_moratoria}
                    </td>
                    <td className="border-0 text-nowrap">
                      ${" "}
                      {new Intl.NumberFormat("de-DE").format(
                        CalculoCuota(
                          cuota
                        ).original_interes_punitorio.toFixed(2)
                      )}
                    </td>
                    <td className="border-0 text-nowrap">
                      {"$ " +
                        new Intl.NumberFormat("de-DE").format(
                          CalculoCuota(cuota).deuda.toFixed(2)
                        )}
                    </td>
                    <td className="border-0 text-nowrap">
                      <span className="d-none">
                        {
                          (deuda =
                            CalculoCuota(
                              cuota,
                              JSON.parse(excedente_pago)
                            ).deuda + deuda)
                        }
                      </span>
                      {"$ " +
                        new Intl.NumberFormat("de-DE").format(
                          deuda.toFixed(2)
                        )}
                    </td>
                    <td className="border-0">
                      {parseFloat(deuda).toFixed(0) <= 0 ? (
                        <span className="text-success">Pagado</span>
                      ) : CalculoCuota(
                        cuota
                      ).original_interes_punitorio.toFixed(2) > 0 ? (
                        <span className="text-danger">Vencido</span>
                      ) : (
                        <span className="text-muted">
                          Espera de pago
                        </span>
                      )}
                    </td>
                    <td className="border-0">
                      <div class="accordion accordion-flush">
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id={"flush-headingOne" + index}
                          >
                            <label class="collapsed btn btn-outline-dark">
                              Pagos
                              <button
                                className="d-none"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={
                                  "#flush-collapseOne" + index
                                }
                                aria-expanded="false"
                              ></button>
                            </label>
                          </h2>
                        </div>
                      </div>
                    </td>
                    <td className="border-0">
                      <label
                        className="btn btn-outline-info px-2 py-1 rounded-pill"
                        style={{ opacity: "0.5" }}
                        title="Impacto de pago"
                      >
                        <i class="bi bi-question-lg"></i>
                        <button
                          className="d-none"
                          name="info-pago"
                          onClick={(event) => infoPago(event, index)}
                        ></button>
                      </label>
                      <div
                        id={"info-pago-" + index}
                        className="info-pago"
                        onMouseOut={(event) =>
                          infoPagoQuit(event, index)
                        }
                      >
                        <InfoPago
                          pago={CalculoCuota(
                            cuota,
                            JSON.parse(excedente_pago)
                          )}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={10} className="border-0 m-0 p-0">
                      <div class="accordion accordion-flush">
                        <div class="accordion-item">
                          <div
                            id={"flush-collapseOne" + index}
                            class="accordion-collapse collapse"
                          >
                            <div class="accordion-body">
                              <table className="table table-hover align-middle border-0">
                                <thead className="border-0">
                                  <tr>
                                    <th className="border-0">ID</th>
                                    <th className="border-0 text-nowrap">
                                      Fecha de importe
                                    </th>
                                    <th className="border-0 text-nowrap">
                                      Tipo de importe
                                    </th>
                                    <th className="border-0 text-nowrap">
                                      Tipo de pago
                                    </th>
                                    <th className="border-0 text-nowrap">
                                      Importe
                                    </th>
                                    <th className="border-0 text-nowrap">
                                      Int Punitorio
                                    </th>
                                    <th className="border-0">
                                      Interes
                                    </th>
                                    <th className="border-0">
                                      Capital
                                    </th>
                                    <th
                                      className="border-0"
                                      colSpan={2}
                                    ></th>
                                  </tr>
                                </thead>
                                <tbody className="border-0">
                                  {InsertCuotas(
                                    cuota.pagos,
                                    JSON.parse(excedente_pago)
                                  ).map((pago, indexPago) => (
                                    <tr>
                                      <td className="border-0">
                                        {pago.id}
                                      </td>
                                      <td className="border-0">
                                        {format(
                                          new Date(
                                            pago.fecha_importe +
                                            " 00:00:00"
                                          ),
                                          "dd/MM/yyyy"
                                        ).toString()}
                                      </td>
                                      <td className="border-0">
                                        {pago.estado}
                                      </td>
                                      <td className="border-0">
                                        {pago.forma_pago}
                                      </td>
                                      <td className="border-0">
                                        {"$ " +
                                          new Intl.NumberFormat(
                                            "de-DE"
                                          ).format(pago.importe)}
                                      </td>
                                      <td className="border-0"></td>
                                      <td className="border-0"></td>
                                      <td className="border-0"></td>
                                      <td className="border-0">
                                        {usuario.rol == "Admin" ? (
                                          <label className="btn btn-outline-primary">
                                            Imprimir
                                            <i class="ms-1 bi bi-printer"></i>
                                            <button
                                              className="d-none"
                                              name="print_"
                                              data-bs-toggle="modal"
                                              data-bs-target="#comprobante-pago"
                                              onClick={(event) =>
                                                onClick(event, {
                                                  pago: pago,
                                                  cuota: cuota,
                                                })
                                              }
                                              title="Se necesita clave de administrador para esa acción"
                                            ></button>
                                          </label>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                      <td className="border-0 d-flex justify-content-end">
                                        {pago.id ==
                                          parseInt(
                                            data.id_ultimo_pago
                                          ) ? (
                                          <label className="btn btn-outline-danger">
                                            Cancelar pago
                                            <i class="ms-2 bi bi-lock"></i>
                                            <button
                                              className="d-none"
                                              name="eliminar-pago"
                                              data-bs-toggle="modal"
                                              data-bs-target="#trash-pay"
                                              onClick={(event) => onClick(event, pago)}
                                              title="Se necesita clave de administrador para esa acción"
                                            ></button>
                                          </label>
                                        ) : (
                                          <span className="text-success">
                                            <label className="btn btn-outline-danger disabled">
                                              Cancelar pago
                                              <i class="ms-2 bi bi-lock"></i>
                                              <button
                                                className="d-none"
                                                name="eliminar-pago"
                                                data-bs-toggle="modal"
                                                data-bs-target="#trash-pay"
                                                onClick={(event) => onClick(event, pago)}
                                                title="Se necesita clave de administrador para esa acción"
                                              ></button>
                                            </label>
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                  <tr>
                                    <td
                                      className="border-0"
                                      colSpan={3}
                                    >
                                      Saldo a pagar
                                    </td>
                                    <td className="border-0"></td>
                                    <td className="border-0">
                                      {/*"$ " +
                                        new Intl.NumberFormat(
                                          "de-DE"
                                        ).format(
                                          CalculoCuota(
                                            cuota,
                                            JSON.parse(excedente_pago)
                                          ).pagos
                                          )*/}
                                    </td>
                                    <td className="border-0">
                                      {"$ " +
                                        new Intl.NumberFormat(
                                          "de-DE"
                                        ).format(
                                          CalculoCuota(
                                            cuota,
                                            JSON.parse(excedente_pago)
                                          ).interes_punitorio
                                        )}
                                    </td>
                                    <td className="border-0">
                                      {"$ " +
                                        new Intl.NumberFormat(
                                          "de-DE"
                                        ).format(
                                          CalculoCuota(
                                            cuota,
                                            JSON.parse(excedente_pago)
                                          ).interes
                                        )}
                                    </td>
                                    <td
                                      className="border-0"
                                      colSpan={3}
                                    >
                                      {"$ " +
                                        new Intl.NumberFormat(
                                          "de-DE"
                                        ).format(
                                          CalculoCuota(
                                            cuota,
                                            JSON.parse(excedente_pago)
                                          ).capital
                                        )}
                                    </td>
                                    <td className="border-0"></td>
                                  </tr>
                                </tbody>
                                <tfoot></tfoot>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </>
              ))
              : ""}
          </tbody>
          <tfoot></tfoot>
        </table>
      </div>

      <Modal
        title="ELIMINAR PAGO"
        id="trash-pay"
        width={"500px"}
        children={trash ? trash.body : ""}
        onClick={{
          title: "CONFIRMAR",
          name: "confirm",
          function: () => fetchTrashPay(trash ? trash.id : ""),
        }}
        onClose={{
          id: "btn-close-trash-pay",
          name: "close",
          function: onClose
        }}
      />

      <Modal
        title="Comprobante de pago"
        id="comprobante-pago"
        width={"800px"}
        steps={steps}
        setSteps={setSteps}
        onClose={{
          id: "btn-close-comprobante-pago",
          name: "close",
          function: onClose,
        }}
        children={
          comprobante ? <Comprobante
              data={pago}
              persona={persona}
              onClick={onClick}
            />
          : ""
        }
      />
    </div>
  );
};

export default Resumen;
