import React, { useState, useEffect, useContext } from "react";
import ListarPreCalificacionesEscritorio from "./ListarPreCalificacionesEscritorio";
import { Spinner, clienteAxios, AuthContext, useHistory } from "../../shared/";
import Filtro from "../filtro/Filtro";
import { links } from "../../../functions/links";
import apiAxios from "../../../config/ApiAxios";

const PreCalificacionesEscritorio = () => {
  const history = useHistory();
  //User data
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  const [loadingLocal, setLoadingLocal] = useState(null);
  const [lista, setLista] = useState(null);
  const [mostarFormulario, setMostrarFormulario] = useState(null);
  const [consultar, setConsultar] = useState(true);
  const [Seleccionado, setSeleccionado] = useState(null);
  const [inicio, setInicio] = useState(1);

  const [DatosForm, LeerForm] = useState({
    buscar: "",
    estado: usuario.rol == "Precalificador" ? "Solicitada" : "Todos",
    page: 1,
    paginator: 30,
  });


  const fetch = async (params) => {
    //if (usuario.rol !== "Precalificador") return;

    /*
    try {
      setLoadingLocal(true);
      const result = await clienteAxios.get(
        "/PersonaPreCalificacion/getAll",
        {
          params: {
            texto: DatosForm.buscar,
            estado: DatosForm.estado,
            page: DatosForm.page,
            paginator: DatosForm.paginator,
          },
        }
      );
      setLista(result.data.precalificaciones);
      setLoadingLocal(null);
      setConsultar(null);
      setInicio(0);
    } catch (e) {}
    */

    const result = await apiAxios.get("/personas", { params });
    setLista(result.data);
    setLoadingLocal(null);
    setConsultar(null);
    setInicio(0);
  };

  useEffect(() => {
    if (!lista) {
      let params = {
        ...DatosForm,
      }
      fetch(params);
    }
  }, [mostarFormulario, consultar, Seleccionado, DatosForm]);

  const onChange = (e) => {
    /*LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
      page: 1,
    });*/

    LeerForm({
      ...DatosForm,
      [e.target.name]: e.target.value,
      page: 1,
    });

    let params = {
      ...DatosForm,
      [e.target.name]: e.target.value,
      page: 1,
    }

    fetch(params);

    //if (buscar.length > 1) {
    //}
    //setConsultar(true);
  };

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "anterior":
        {
          if (DatosForm.page !== 1) {
            LeerForm({
              ...DatosForm,
              page: DatosForm.page - 1,
            });

            setConsultar(true);
          }
        }
        break;
      case "siguiente":
        {
          if (DatosForm.page < lista.paginas) {
            LeerForm({
              ...DatosForm,
              page: DatosForm.page + 1,
            });

            setConsultar(true);
          }
        }
        break;
      case "link":
        {
          LeerForm({
            ...DatosForm,
            page: value,
          });

          setConsultar(true);
        }
        break;
      case "ver_credito":
        history.push("/creditos/ver/" + value.id);
        break;
    }
  };

  return (
    <div className="container-md min-vh-100">
      <br></br>
      <div
        className="row"
        style={{
          backgroundColor: "white",
          padding: "0px",
          borderRadius: "10px",
          margin: "0px",
        }}
      >
        <div className="col-md-12 mb-5 d-flex justify-content-start align-items-center">
          <h3 className="text-nowrap me-4 fw-bold">Escritorio</h3>

          <label className="btn btn-primary fs-6 d-flex me-3 text-nowrap">
            <i class="bi bi-plus-lg me-2"></i>
            Agregar Cliente
            <button
              data-bs-toggle="modal"
              data-bs-target="#crear-gasto"
              className="d-none"
              onClick={() => {
                history.push("/clientes/nueva");
              }}
            ></button>
          </label>

          <div class="input-group">
            <input
              type="text"
              class="form-control"
              style={{ borderRadius: "20px", maxWidth: "400px" }}
              placeholder="Buscar por persona"
              aria-label="Username"
              aria-describedby="basic-addon1"
              name="buscar"
              onChange={(e) => onChange(e, null)}
            />
            <span
              class="input-group-text bg-transparent border-0"
              id="basic-addon1"
            >
              <i class="bi bi-search"></i>
            </span>
          </div>
        </div>
      </div>
      <br></br> <br></br>
      <Filtro setForm={LeerForm} form={DatosForm} setConsultar={setConsultar} fetch={fetch} />
      {lista ? (
        <div>

          <div className="w-100 d-flex justify-content-between mb-3">
            <div className="btn btn-light">
              Total de registros {lista.total}
            </div>

            <div>
              {
                lista.links.map((l, index) => {
                  if (index == 0) {
                    return (
                      <label className="btn btn-primary fs-5 me-2">
                        <i class="bi bi-arrow-left"></i>
                        <button
                          className="d-none"
                          name="anterior"
                          onClick={(event) => onClick(event)}
                        ></button>
                      </label>
                    )
                  }


                  if (index == (lista.links.length - 1)) {
                    return (
                      <label className="btn btn-primary fs-5">
                        <i class="bi bi-arrow-right"></i>
                        <button
                          className="d-none"
                          name="siguiente"
                          onClick={(event) => onClick(event)}
                        ></button>
                      </label>
                    )
                  }

                  return (
                    <label className="btn btn-outline-primary fs-5 me-2">
                      {l.label}
                      <button
                        className="d-none"
                        name="link"
                        onClick={(event) => onClick(event, l.label)}
                      ></button>
                    </label>
                  )
                })
              }
            </div>

          </div>

          {loadingLocal ? (
            <Spinner />
          ) : (
            <>
              {lista ? (
                <ListarPreCalificacionesEscritorio
                  lista={lista}
                  setSeleccionado={setSeleccionado}
                  setMostrarFormulario={setMostrarFormulario}
                  setConsultar={setConsultar}
                />
              ) : null}
            </>
          )}

          <div className="w-100 d-flex justify-content-between mb-3">
            <div className="btn btn-light">
              Total de registros {lista.total}
            </div>

            <div>
              {
                lista.links.map((l, index) => {
                  if (index == 0) {
                    return (
                      <label className="btn btn-primary fs-5 me-2">
                        <i class="bi bi-arrow-left"></i>
                        <button
                          className="d-none"
                          name="anterior"
                          onClick={(event) => onClick(event)}
                        ></button>
                      </label>
                    )
                  }


                  if (index == (lista.links.length - 1)) {
                    return (
                      <label className="btn btn-primary fs-5">
                        <i class="bi bi-arrow-right"></i>
                        <button
                          className="d-none"
                          name="siguiente"
                          onClick={(event) => onClick(event)}
                        ></button>
                      </label>
                    )
                  }

                  return (
                    <label className="btn btn-outline-primary fs-5 me-2">
                      {l.label}
                      <button
                        className="d-none"
                        name="link"
                        onClick={(event) => onClick(event, l.label)}
                      ></button>
                    </label>
                  )
                })
              }
            </div>

          </div>

        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default PreCalificacionesEscritorio;
