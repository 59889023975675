import {
  TREGISTRO_ERROR,
  TREGISTRO_EXITO,
  TOBTENER_USUARIOS,
  TACTUALIZAR_USUARIO,
  TSELECCIONAR_USUARIO,
  TELIMINAR_USUARIO,
  TCARGANDO,
  TORDENAR_FILTRAR_USUARIOS,
  TDESELECCIONAR_USUARIO
} from "../../types";

const tasasReducer = (state, action) => {
  switch (action.type) {
    case TDESELECCIONAR_USUARIO:
      return {
        ...state,
        usuarioSeleccionado: null,
      };
    case TORDENAR_FILTRAR_USUARIOS:
      return {
        ...state,
        resultado: action.payload,
      };
    case TCARGANDO:
      return {
        ...state,
        cargando: action.payload,
      };

    case TELIMINAR_USUARIO:
      return {
        ...state,
        usuarios: state.usuarios.filter(
          (usuario) => usuario.id !== action.payload
        ),
        resultado: state.resultado.filter(
          (result) => result.id !== action.payload
        ),
      };
    case TSELECCIONAR_USUARIO:
      return {
        ...state,
        usuarioSeleccionado: action.payload,
      };

    case TACTUALIZAR_USUARIO:
      return {
        ...state,
        usuarios: state.usuarios.map((usuario) =>
          usuario.id === action.payload.id ? action.payload : usuario
        ),
      };

    case TOBTENER_USUARIOS:
      return {
        ...state,
        usuarios: action.payload,
        resultado: action.payload,
        cargando: false,
      };

    
    case TREGISTRO_EXITO:
      return {
        ...state,
        mensaje: { msg: null, class: null },
      };

    case TREGISTRO_ERROR:
      return {
        ...state,
        mensaje: action.payload,
      };

    default:
      return state;
  }
};

export default tasasReducer;
