import { format } from "date-fns";
import { CalculoPagoJudicial } from "../../../../functions/Validaciones/PagoJudicial";
import { FormatterNumber } from "../../../../utils/formatter";

const TablaJudiciales = ({ data, onClick, onChange }) => {
  return (
    <div className="table-responsive">
      <table className="table table-hover align-middle">
        <thead className="border-0">
          <tr
            className="border"
            style={{
              borderRadius: "20px",
              background: "rgba(217, 217, 217, 0.5)",
            }}
          >
            <th className="border-0 fs-5 p-1">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Nº
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Persona
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Detalle
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Vencimiento
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Fecha actual
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Tasa de interes
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Días de mora
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Monto
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Interes
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Deuda
              </div>
            </th>
            <th className="border-0 fs-5 p-1">
              <div className="border-0 p-1" style={{ borderRadius: "10px" }}>
                Resumen
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="border-0">
          {data.map((j, index) => (
            <tr>
              <td className="border-0">{j.id}</td>
              <td className="border-0">{j.persona_nombre+" "+j.persona_apellido}</td>
              <td className="border-0">{j.item}</td>
              <td className="border-0">
                {format(
                  new Date(j.fecha_otorgamiento + " 00:00:00"),
                  "dd/M/yyyy"
                ).toString()}
              </td>
              <td className="border-0">
                {format(new Date(), "dd/M/yyyy").toString()}
              </td>
              <td className="border-0">{j.interes_moratorio}</td>
              <td className="border-0">{CalculoPagoJudicial(j).diferencia}</td>
              <td className="border-0">{FormatterNumber(j.monto)}</td>
              <td className="border-0">
                {FormatterNumber(CalculoPagoJudicial(j).interes)}
              </td>
              <td className="border-0">
                {CalculoPagoJudicial(j).deuda < 1
                  ? 0
                  : FormatterNumber(CalculoPagoJudicial(j).deuda)}
              </td>
              <td className="border-0">
                <label className="me-1 btn btn-dark rounded-pill">
                  <i class="me-1 bi bi-plus-circle"></i>
                  Resumen
                  <button
                    className="d-none"
                    name="resumen-judicial"
                    data-bs-toggle="modal"
                    data-bs-target="#resumen-gastos"
                    onClick={(event) => onClick(event, j)}
                  ></button>
                </label>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot></tfoot>
      </table>
    </div>
  );
};

export default TablaJudiciales;
