import { format, add } from "date-fns";

const dateExtra = () => {
  return format(add(new Date(), {days: 1}), 'yyyy-MM-dd').toString()
}

export const CuotasDetalle = (cuotas, concesion) => {
  //console.log(concesion);
  let cuotasPagas = 0;
  let cuotasVencidas = 0;
  let pagos = 0;
  let deuda = 0;

  //fecha de hoy
  const hoy = format(new Date(),"yyyy/MM/dd").toString();
  //intereses punitorios
  let actual = new Date();
  //fecha de vto concesion
  let fecha_vto = concesion? concesion.inicio_vencimiento == "0000-00-00" ? new Date(dateExtra()+" 00:00:00") : new Date(concesion.inicio_vencimiento +" 00:00:00") : null;
  let concesion_fecha_vencimiento = concesion?  new Date(concesion.inicio_vencimiento+" 00:00:00"): null;
  let concesion_fecha_alta = concesion? new Date(concesion.fecha_alta) : null;
  //
  let bonificacion = null
  //
  let result = [];

  cuotas.map((cuota) => {
    //console.log(fecha_vto);
    //concesion
    if(concesion){
      //concesion por congelamiento de IP
      if(parseInt(concesion.id_tipoconcesion) == 1 || parseInt(concesion.id_tipoconcesion) == 3){
          //fecha de pacto
          actual = concesion_fecha_alta;
          //Nuevas fechas de vencimiento
          cuota.fecha_vencimiento_concesion = fecha_vto.toString() === concesion_fecha_vencimiento.toString()
          ? format(fecha_vto, "yyyy/MM/dd").toString()
          : format(add(fecha_vto, { months:  1}), "yyyy/MM/dd").toString();
    
          //GUARD
          fecha_vto = new Date(cuota.fecha_vencimiento_concesion);
      }

      //concesion por bonificacion y corre IP
      if(parseInt(concesion.id_tipoconcesion) == 2 || parseInt(concesion.id_tipoconcesion) == 3){
          //bonificacion
          bonificacion = concesion.porcentaje;
          cuota.bonificacion = bonificacion;
      }
    }


    //calculo de cuotas pagas y vencidas
    let state = true;
    cuota.pagos.map((pago) => {
      if(pago.estado == "TOTAL"){
        cuotasPagas = cuotasPagas+1;
        state = false;
        //Si el pago fue realizado antes del pacto
        if(pago.fecha_importe < concesion_fecha_alta){
          actual = new Date(pago.fecha_importe);
        }
        //sumar importes
        pagos = parseFloat(pago.importe)+pagos;
      } else {
        //sumar importes
        pagos = parseFloat(pago.importe)+pagos;
      }
    })
    //cuotas vencidas
    let vencimiento = format(add(new Date(cuota.fecha_vencimiento), {days: 1}),"yyyy/MM/dd").toString();
    if(vencimiento < hoy && state){
    cuotasVencidas = cuotasVencidas+1;
    }

    //intereses punitorios
    //validacion hasta que fecha se estiman los IP
    actual = format(actual, "yyyy-MM-dd").toString();
    actual = new Date(actual).getTime();
    let vto = new Date(cuota.fecha_vencimiento).getTime();
    

    let diferencia_moratoria = actual - vto;
    diferencia_moratoria = diferencia_moratoria / (1000 * 60 * 60 * 24);


    let calculo_punitorios = 0;

    if (diferencia_moratoria > 0 ){

      calculo_punitorios = parseFloat(cuota.capital) * (parseInt(cuota.interes_mora) / 100);
      calculo_punitorios = calculo_punitorios / 30;

      //intereses punitorios
      calculo_punitorios = calculo_punitorios * diferencia_moratoria;
    }

    //Calculo de deuda
    deuda = calculo_punitorios + parseFloat(cuota.total) + deuda;

  })

  return {
    pagas: cuotasPagas,
    vencidas: cuotasVencidas,
    deuda: bonificacion? (deuda - ((deuda/100)*bonificacion) - pagos).toFixed(2)    : (deuda - pagos).toFixed(2), 
    cuotas: result,
  }
}