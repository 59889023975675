import { useContext } from "react";
import { AuthContext, useRest } from "../shared";
import MisDatos from "./formularios/form.misDatos";
import { useState } from "react";
import { useEffect } from "react";
import AlertSuccess from "../CustomAlerts/AlertSuccess";

const MiPerfil = () => {
  const authContext = useContext(AuthContext);
  const { registrarUsuario, seleccionarUsuario, usuario, cerrarSesion, usuarioSeleccionado } = authContext;
  const [loading, load] = useRest();

  const [eye, setEye] = useState(false)
  const [consultar, setConsultar] = useState(true);
  const [form, setForm] = useState({ ...usuarioSeleccionado });
  const roles = [
    { label: "Vendedor", value: "Vendedor" },
    { label: "Precalificador", value: "Precalificador" },
    { label: "Admin", value: "Admin" },
    { label: "Cajero", value: "Cajero" },
  ];

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "user_name":
      case "lastname":
      case "user_password":
      case "user_username":
      case "email":
      case "user_type": {
        setForm({
          ...form,
          [e.target.name]: e.target.value
        })
      } break;
    }
  }

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "aplicar": {
        const api = {
          url: "/users",
          type: "put",
          datosJson: {
            user_id: usuario.id,
            user_username: form.user_username,
            user_type: form.user_type,
            user_password: form.user_password,
            name: form.user_name,
            lastname: form.lastname,
            email: form.email,
            codigo_postal: form.codigo_postal,
          },
          getParams: null,
          funcion: registrarUsuario,
        };

        load(api);
        setTimeout(() => {
          return AlertSuccess({ message: "Usuario actualizado" });
        }, 2000);
      } break;
      case "eye": {
        setEye(!eye);
      } break;
    }
  }

  useEffect(() => {
    if (consultar) {
      setConsultar(false);
      const api = {
        url: "/user",
        type: "get",
        datosJson: null,
        getParams: { id: usuario.id },
        funcion: seleccionarUsuario,
      };
      /* eslint-disable */
      load(api);
    }
  }, [])

  useEffect(() => {
    if (usuarioSeleccionado) {
      setForm(usuarioSeleccionado);
    }
  }, [usuarioSeleccionado])

  console.log({ form });
  console.log({ usuario });
  return (
    <div className="container-md min-vh-100" style={{ maxWidth: "1500px" }}>
      <div className="text-center p-4">
        <h1>Perfil de usuario | {usuario.rol}</h1>
      </div>
      <div>
        <MisDatos
          onClick={onClick}
          onChange={onChange}
          form={form}
          roles={roles}
          usuario={usuario}
          eye={eye}
        />
      </div>
    </div>
  )
}

export default MiPerfil