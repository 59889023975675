import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { CreditoDetalles } from '../../../functions/Validaciones/CreditoDetalles';
import { ApiCreditos } from '../../../Helpers/ApiCreditos';
import Resumen from '../../Gestion_pagos/Modals/Resumen';
import { AuthContext, Spinner, useHistory } from '../../shared';
import ModalResumen from '../modals/ModalResumen';
import Tabla_creditos from '../tablas/TablaCreditos';

const FromDatosCreditos = ({
    id,
    soloLectura
}) => {
    const authContext = useContext(AuthContext);
    const { usuario, cerrarSesion } = authContext;

    const history = useHistory();
    const [creditos, setCreditos] = useState(null);
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [credito, setCredito] = useState(null);
    const [pago, setPago] = useState(false)


    const [params_credito, setParamsCredito] = useState({
        persona: {
            id: id,
            //concesion: "false"
        },
    });

    //Eventos
    const onClick = (e, value) => {
        switch (e.target.name) {
            case "ver_credito": {
                let credito = (() => CreditoDetalles(value));
                setCredito(credito);
                setModal(true);
            } break;
            case "close":
                {
                    setModal(false);
                    setPago(null);
                }
                break;
        }
    }

    const onChange = (e) => {

    }

    useEffect(() => {
        const load = async () => {
            if (params_credito.persona) {
                const response = await ApiCreditos({
                    url: "/PersonaCreditos/getAllByIdPersona",
                    params: params_credito,
                    setLoading: setLoading,
                });

                setCreditos(response);
            }
        };
        load();
    }, [])
    return (
        <div className='center-block min-vh-100'>
            <br></br>
            <h2 className="text-center">
                Creditos
            </h2>
            {
                creditos ? <Tabla_creditos
                    data={creditos}
                    onClick={onClick}
                    onChange={onChange}
                    soloLectura={soloLectura}
                />
                    : <Spinner />
            }


            {modal && credito ? (
                <ModalResumen data={credito} setPago={setPago} setComprobante={null} usuario={usuario} onClick={onClick} onChange={onChange} />
            ) : (
                ""
            )}
        </div>
    );
};

export default FromDatosCreditos;