import {
  Document,
  Page,
  Text,
  View,
  Font,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";

//Font
/*
Font.register({
  family: "Roboto Slab",
  src: "/assets/fonts/RobotoSlab-Light.ttf",
});
*/

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "black",
  },
  section: {
    textAlign: "center",
    margin: 10,
    padding: 10,
    fontSize: "10px",
  },
  viewer: {
    width: 700, //window.innerWidth, //the pdf viewer will take up all of the width and height
    height: 700,
  },
  tr: {
    width: 170,
    margin: "auto",
    flexDirection: "row",
  },
  th: {
    fontWeight: 900,
    textAlign: "center",
    fontSize: "7px",
    margin: 0,
    marginBottom: 3,
    padding: 0,
    flex: 1,
  },
  td: {
    textAlign: "center",
    fontSize: "5px",
    margin: 0,
    marginBottom: 3,
    padding: 0,
    flex: 1,
  },
});

function PDFcaja({ data }) {
  return (
    <PDFViewer style={styles.viewer}>
      {/* Start of the document*/}
      <Document>
        {/*render a single page*/}
        <Page size={"A4"} style={styles.page}>
          <View style={styles.section}>
            <Text>Balance</Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default PDFcaja;
