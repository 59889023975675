import React from "react";
import PreCalificacionesEscritorio from "./precalificacionesEscritorio/PreCalificacionesEscritorio";

const Dashboard = () => {
  return (
    <>
      <PreCalificacionesEscritorio />
     </>
  );
};

export default Dashboard;
