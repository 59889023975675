const LoginComponent = ({
  onSubmit,
  onChange,
  form,
  setEye,
  eye
}) => {



  return <div className="d-flex justify-content-center align-items-center">
    <form onSubmit={onSubmit} className="shadow-sm px-5 py-4"  
    style={{ height: "auto", background: "#f4f4f4", borderRadius: "10px" }}>
      <h3 className="text-center mb-4">
        <img src="./assets/images/iconos-para-prestamos_Credixpress-05.jpg" width="50px" className="me-2" alt="logo" />
        Iniciar Sesion
      </h3>

      <div className="form-group mb-4">
        <label htmlFor="email">Usuario</label>
        <input
          type="text"
          name="email"
          className="form-control"
          id="email"
          aria-describedby="emailHelp"
          placeholder="Ingrese su Usuario"
          style={{ borderRadius: "10px" }}
          onChange={onChange}
          value={form.email}
        />
      </div>

      <label htmlFor="email">Contraseña</label>
      <div class="input-group mb-4">
        <label class="input-group-text rounded-pill border-0" style={{ background: "none" }} id="basic-addon1">
          {
            eye
              ? <i class="bi bi-eye"></i>
              : <i class="bi bi-eye-slash"></i>
          }
          <button className="d-none" onClick={(e) => {
            e.preventDefault()
            setEye(!eye)
          }}></button>
        </label>
        <input
          className="form-control"
          style={{ borderRadius: "10px" }}
          placeholder="Contraseña"
          type={eye ? "test" : "password"}
          name="password"
          onChange={onChange}
          value={form.password}
          aria-label="Username" aria-describedby="basic-addon1" />
      </div>

      <div className="text-center">
        <button type="submit" class="btn btn-dark mx-auto" 
        style={{backgroundColor: "#c12b35"}}>Ingresar</button>
      </div>
    </form>
  </div>
}

export default LoginComponent